import React, { useState, useEffect, useContext } from 'react';
import './KitFilling.scss';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Context } from '../../context';

import { getGoodsById } from '../../utils/api';

import MainTitle from '../MainTitle/MainTitle';
import MainItem from '../MainItem/MainItem';

const KitFilling = ({ arrOfElem }) => {

  const [data, setData] = useState([]);

  const { staticLocalization } = useContext(Context);
  const { individualGood } = staticLocalization;
    
  useEffect(()=>{

    if(arrOfElem.length){
      getGoodsById(arrOfElem, 'goodsArray').then((response)=>{
        return response.json();
      }).then((res)=>{
        setData(res);
      });
    }

  }, []);


  return(
    <Container fluid className='kit-filling'>
      <Row>
        <Col xs={12} className='kit-filling__title-wrapper'>
          <MainTitle secondType>
            {individualGood.fillingTheBox}
          </MainTitle>
        </Col>
                
        <Col xs={12} className='kit-filling__items-wrapper'>
          {
            (data.length) ?
              data.map((el)=> <MainItem
                key={el.id}
                withCounter={false}
                item={el}
              />
              )
              :
              null
          }
        </Col>
      </Row>
    </Container>
  );
};

KitFilling.propTypes = {
  arrOfElem: PropTypes.array
};

KitFilling.defaultProps = {
  arrOfElem: []
};

export default KitFilling;