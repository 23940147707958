import React, { useState, useContext } from 'react';
import './SignUpForm.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import MainBatton from '../../MainButton/MainButton';
import ShowPassword from '../ShowPassword/ShowPassword';
import Loader from '../../Loader/Loader';

import { register } from '../../../utils/api';
import { scrollToTop } from '../../../utils/scrollToTop';
import { Context } from '../../../context';


const SignUpForm = ({ changeStep, showRegisterForm, formID, history, showVerifyForm, data, setData }) => {

  const url = '/public';
  const [formIsSent, setFormIsSent] = useState(false);
  const { staticLocalization } = useContext(Context);
  const { registrationForm } = staticLocalization;

  const [phone, setPhone] = useState(false);
  const phoneRegex = /^(\+380-|\+380|0)?\d{9}$/; // Change this regex based on requirement
  const phoneIntRegex = /^(\+-|\+|0)?\d{16}$/; // Change this regex based on requirement

  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(registrationForm.nameError),
    lastName: Yup.string()
      .required(registrationForm.lastNameError),
    email: Yup.string()
      .email(registrationForm.emailErrorInvalidEmail)
      .required(registrationForm.emailError),
    telephone: Yup.string()
      .required(registrationForm.telephoneError)
      .test('test', registrationForm.telephoneRegexError,
        function(value) {
          const isValidPhone = phoneRegex.test(value);
          const isValidPhoneInt = phoneIntRegex.test(value);
          if (phoneRegex.test(value)) {
            setPhone(true);
          }

          if (!isValidPhone && !isValidPhoneInt) {
            return false;
          }
          return true;
        }),
    password: Yup.string()
      .required(registrationForm.passwordError)
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, registrationForm.passwordRegexError)
      .notOneOf([Yup.ref('firstName'), null], registrationForm.passwordErrorLikeName)
      .notOneOf([Yup.ref('email'), null], registrationForm.passwordErrorLikeEmail),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], registrationForm.passwordConfirmationError)
  });

  const handleChange = (event) => {
    localStorage.setItem(`registration-${event.target.name}`, event.target.value);
  };

  return (
    <Formik
      initialValues={{
        firstName: localStorage.getItem('registration-firstName') || '',
        lastName: localStorage.getItem('registration-lastName') || '',
        email: localStorage.getItem('registration-email') || '',
        telephone: localStorage.getItem('registration-telephone') || '',
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {

        const { firstName, lastName, email, telephone, password } = values;

        let modeLogin;
        if (phone && (telephone.split('')[0] == 0)) {
          modeLogin = '+38' + telephone;
        } else {
          modeLogin = telephone;
        }

        setFormIsSent(true); // Show spiner

        register(firstName, lastName, email, modeLogin, modeLogin, password).then(res => {

          setFormIsSent(false); // Remove spiner

          if (!res.ok) { // if some server error set his message

            res.json().then(err => {

              // remove previous errors
              setPhoneError(false);
              setEmailError(false);

              if (err.message === 'Phone already exists') {
                setPhoneError(true);
              } else if (err.message === 'Email already exists') {
                setEmailError(true);
              }
            });
            return null;
          }
          showVerifyForm(true, 'registration');

          res.json().then(e => {
            setData(e);
          });

          return res;
          

        });
      }}
    >
      {({ handleSubmit }) => (
        <Form className='g-form-style signup-form'>

          <label>
            <p className='required'>{registrationForm.name}</p>
            <Field type='text' name='firstName' placeholder={registrationForm.namePlaceholder} onKeyUp={handleChange} />
            <ErrorMessage name='firstName'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{registrationForm.lastName}</p>
            <Field type='text' name='lastName' placeholder={registrationForm.lastNamePlaceholder}
              onKeyUp={handleChange} />
            <ErrorMessage name='lastName'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{registrationForm.email}</p>
            <Field type='email' name='email' placeholder={registrationForm.emailPlaceholder} onKeyUp={handleChange} />
            <ErrorMessage name='email'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{registrationForm.telephone}</p>
            <Field type='tel' name='telephone' placeholder={registrationForm.telephonePlaceholder}
              onKeyUp={handleChange} />
            <ErrorMessage name='telephone'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{registrationForm.password}</p>
            <Field type='password' name='password' placeholder={registrationForm.passwordPlaceholder}
              component={ShowPassword} />
            <ErrorMessage name='password'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{registrationForm.passwordConfirmation}</p>
            <Field type='passwordConfirmation' name='passwordConfirmation'
              placeholder={registrationForm.passwordConfirmationPlaceholder} component={ShowPassword} />
            <ErrorMessage name='passwordConfirmation'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
            {formIsSent ? <div className='form-spiner'><Loader /></div> : null}
            {phoneError ? <div className='error'>{registrationForm.telephoneCreateError}</div> : null}
            {emailError ? <div className='error'>{registrationForm.emailCreateError}</div> : null}
          </label>

          <div className='additional-text'>
            <p>{registrationForm.additionalText}</p>
            <p>{registrationForm.userAgreement} <Link to={url}>{registrationForm.userAgreementLinkText}</Link></p>
          </div>

          <MainBatton type='submit' palette='primary' onMouseDown={handleSubmit}>
            {registrationForm.register}
          </MainBatton>
          <MainBatton type='button' palette='secondary' onClick={() => {
            showRegisterForm();
            scrollToTop();
          }}>
            {registrationForm.logIn}&nbsp;<span>{registrationForm.gender}</span>
          </MainBatton>

        </Form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = {
  changeStep: PropTypes.func,
  showRegisterForm: PropTypes.func,
  formID: PropTypes.string,
  history: PropTypes.object,
  showVerifyForm: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func
};

export default withRouter(SignUpForm);
