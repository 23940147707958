import React, { useState, useEffect, useContext } from 'react';
import './YouMayLikeIt.scss';
import PropTypes from 'prop-types';
import { Context } from '../../context';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { Container, Row, Col } from 'react-bootstrap';
import { getYouMayLikedList } from '../../utils/api';
import { getEllementOffset } from '../../utils/getEllementOffset';

import MainTitle from '../MainTitle/MainTitle';
import MainItem from '../MainItem/MainItem';
import MainButton from '../MainButton/MainButton';
import Loader from '../Loader/Loader';


const YouMayLikeIt = ({ tags, categoryId }) => {

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { width } = useWindowSize();
  const [howMuchToGet, setHowMuchToGet] = useState(getEllementOffset(width, 6 , 5, 4, 2));
  const [canLoadMore, setCanLoadMore] = useState(true);

  const { staticLocalization } = useContext(Context);
  const { individualGood } = staticLocalization;

  const loadItems = (quantity) => {

    const tagsArray = tags.map(el => el.id);

    getYouMayLikedList(quantity, 0, categoryId, tagsArray).then(res => {
      return res.json();
    }).then(elements => {
      setData(elements.result);
      setIsLoaded(true);
      setCanLoadMore(quantity <= elements.count);
    });
  };

  useEffect(()=>{
    loadItems(howMuchToGet);
  }, []);

  const [disab, setDisab] = useState('flex');
  const [count, setCount] = useState(0);

  const loadMore = () => {
    if (count < 4) {
      const newHowMuchToGet = howMuchToGet + getEllementOffset(width, 6, 5, 4, 2);
      setHowMuchToGet(newHowMuchToGet);
      loadItems(newHowMuchToGet);
      setCount(count+1);
    }
    else{
      setDisab('none');
    }
  };

  return(
    <Container fluid className='you-may-like-it'>
      <Row>
        <Col xs={12} className='title-wrapper'>
          <MainTitle secondType>
            {individualGood.youMightLikeIt}
          </MainTitle>
        </Col>
        <Col xs={12} className='you-may-like-it__items'>
          {
            isLoaded && data !== undefined ?
              <div className='items-wrapper'>
                {
                  data.map((el)=> <MainItem
                    key={el.id}
                    withCounter={true}
                    item={el}
                    additionalClass={'you-may-like'}
                  />)
                }
              </div>

              :
              <Loader />

          }
          <div className='show-more-btn'>
            {
              canLoadMore ?
                <MainButton className='btns--main btns--showmore' onClick={()=>loadMore()} style={{display: disab}} >
                  {individualGood.moreCandy}
                </MainButton>
                :
                null
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
};

YouMayLikeIt.propTypes = {
  tags: PropTypes.array,
  categoryId: PropTypes.number
};

export default YouMayLikeIt;
