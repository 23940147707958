import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckButton } from './../../assets/icons/button-check.svg';
import classNames from 'classnames';
import './CheckBox.scss';
import { Context } from '../../context';

const Checkbox = ({ children, name, value, checked, onChange, className }) => {

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const { darkTheme } = useContext(Context);
  const theme = darkTheme ? 'dark' : 'light';

  const onChangeHandler = (e) => {
    setIsChecked(e.target.checked);
    onChange(name, value, e.target.checked);
  };

  const classes = classNames(
    'check-button',
    `check-button--${theme}`,
    className
  );

  return (
    <label className={classes}>
      <input className='check-button__input'
        type='checkbox'
        onChange={onChangeHandler}
        checked={isChecked}
        name={name}
        value={value}
      />
      <div className='check-button__box'>
        <CheckButton />
      </div>
      { children }
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

Checkbox.defaultProps = {
  children: '',
  onChange: () => {},
  className: '',
  name: '',
  value: '',
  checked: false,
  theme: 'dark'
};

export default Checkbox;
