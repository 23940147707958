import React, { useState, useContext, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Context } from '../../../context';
import { imagesUrl, getItemsBySerchBar } from '../../../utils/api';
import { getItemFieldWithTranslation } from '../../../utils/renderFieldWithTransition';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';


const SerchedItem = ({ data, setShowResult }) => {

  const { settings, categories, staticLocalization, curentLanguageId } = useContext(Context);

  const { id, images, category, price, slug } = data;
  const elementCategory = categories.find(el => el.id === category);
  const categoryName = getItemFieldWithTranslation(elementCategory, 'name', curentLanguageId);
  const img = images.find(el => el.includes('technical'));


  return (
    <li>
      <Link to={`/product/${slug}`} className='serched-item' onClick={() => setShowResult(false)}>
        <div className='img-wrapper'>
          <img src={`${imagesUrl}${encodeURIComponent(images[0])}?v=${settings.frontendVersion}`} alt='serc bar result img' />
        </div>
        <div className='info'>
          <div className='categiries'>
            {categoryName}
          </div>
          <div className='name'>
            {getItemFieldWithTranslation(data, 'name', curentLanguageId)}
          </div>
        </div>
        <div className='price'>
          <p>
            {price}
            <small>
              {staticLocalization.general.currency}
            </small>
          </p>
        </div>
      </Link>
    </li>
  );
};

const SearchBar = ({ mobileMenuIsOpen, handleChangeStatusOfMobileMenu, hardCloseSerchBar }) => {

  const { staticLocalization } = useContext(Context);

  const [inputText, setInputText] = useState('');
  const [showSerchBar, setShowSerchBar] = useState(false);
  const [serchedItems, setSerchedItems] = useState([]);
  const [showResults, setShowResult] = useState(false);
  const [resultIsEmpty, setResultIsEmpty] = useState(true);

  const { blackTheme } = useContext(Context);

  const classes = classNames(
    'header-button-group__item search-bar',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const myRef = React.createRef();

  useEffect(() => {

    if (showSerchBar === true && mobileMenuIsOpen === true) {
      setInputText('');
      setSerchedItems([]);
      setResultIsEmpty(false);
      setShowResult(false);
      setShowSerchBar(false);
    }

  }, [mobileMenuIsOpen]);

  useEffect(() => {

    setInputText('');
    setSerchedItems([]);
    setResultIsEmpty(false);
    setShowResult(false);
    return setShowSerchBar(false);

  }, [window.location.pathname]);

  useEffect(() => {
    setShowSerchBar(false);
  }, [hardCloseSerchBar]);

  const handleShowSerchBar = () => {

    handleChangeStatusOfMobileMenu(false);

    if (showSerchBar === true) {
      setInputText('');
      setSerchedItems([]);
      setResultIsEmpty(false);
      setShowResult(false);
      setShowSerchBar(false);
    } else {
      setShowSerchBar(true);
    }

  };

  const search = (e) => {
    e.preventDefault();
    if (inputText.length > 0) {
      getItemsBySerchBar(inputText.replace('%',"%25")).then(res => {
        return res.json();
      }).then(elements => {
        setSerchedItems(elements.result);
        setShowResult(true);
        setResultIsEmpty(elements.count === 0);
      });
    }
  };

  // const blur = (e) => {
  //   if(e.nativeEvent.relatedTarget){
  //     if(!e.nativeEvent.relatedTarget.closest('.search-bar')){
  //       setShowSerchBar(false);
  //     }
  //   }else{
  //     setShowSerchBar(false);
  //   }
  // };
  const onCloseSearchList = () => {
    setShowSerchBar(false);
  };

  return (
      <div className={classes}>
          <button type='button' href='#' onClick={() => handleShowSerchBar()}>
              <SearchIcon/>
          </button>
          {
              showSerchBar ?
                  <OutsideClickHandler onOutsideClick={onCloseSearchList}>
                      <div className='input-wrapper'>
                          <form
                              onSubmit={(e) => search(e)}
                              // onBlur={(e) => blur(e)}
                          >
                              <input type='text'
                                     value={inputText}
                                     autoFocus={true}
                                     ref={myRef}
                                     onChange={(e) => setInputText(e.target.value)}
                              />
                              <input
                                  type='submit'
                                  value=''
                                  onClick={() => myRef.current.focus()}
                              />
                          </form>
                          {showResults
                              ?
                              resultIsEmpty ?
                                  <div className='empty-serch-bar'>
                                      <h3>{staticLocalization.searchBar.text}</h3>
                                  </div>
                                  :
                                  <ul className='items-wrapper'>
                                      {serchedItems.map(el =>
                                          <SerchedItem
                                              onClick={() => setShowSerchBar(false)}
                                              data={el} key={el.id} setShowResult={setShowResult}/>
                                      )}
                                  </ul>
                              :
                              null
                          }
                      </div>
                  </OutsideClickHandler>
                  :
                  null
          }
      </div>
  );
};

SerchedItem.propTypes = {
  data: PropTypes.object
};

SearchBar.propTypes = {
  mobileMenuIsOpen: PropTypes.bool,
  handleChangeStatusOfMobileMenu: PropTypes.func
};

export default SearchBar;
