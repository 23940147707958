import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../context';
import classes from 'classnames';
import { Link } from 'react-router-dom';

import NavigationLink from './NavigationLink';
import Navbar from 'react-bootstrap/Navbar';
import './Header.scss';
import Logo from '../../assets/images/logo_lmsh-2.svg';
import HeaderButtonGroup from '../HeaderButtonGroup/HeaderButtonGroup';
import MainButton from '../../components/MainButton/MainButton';

import { useWindowSize } from '../../utils/useWindowSizeHook';


const Header = () => {

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const { navigation } = useContext(Context);

  const { width } = useWindowSize();
  const MOBILE = 1200;

  const mobileMenu = classes(
    mobileMenuIsOpen ? 'open' : null
  );

  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    setMobileMenuIsOpen(false);
  }, [window.location]);

  const onMouseEnterHandler = (item) => {
    if (item?.type === 'CATEGORY_GROUP' && width > MOBILE) {
      setShowShadow(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (showShadow === true && width > MOBILE) setShowShadow(false);
  };

  const openMobileMenuHandler = () => {
    if (width <= MOBILE) {
      setMobileMenuIsOpen(!mobileMenuIsOpen);
      setShowShadow(shadow => !shadow);
    }
  };

  return (
    <>
      <header className="header">
        {/* must have for svg use */}
        <MainButton className="btns--hidden"/>
        {/* must have for svg use */}
        <Link to="/" onClick={() => setMobileMenuIsOpen(false)}>
          <img src={Logo} alt="LMHC logo"/>
        </Link>
        <Navbar className={mobileMenu} variant="light" expand="lg">
          <ul className="navbar-nav">
            {
              navigation.map(item => <li key={item.id} className="nav-link"
                                         onMouseOver={() => onMouseEnterHandler(item)}
                                         onMouseLeave={onMouseLeaveHandler}>
                <NavigationLink
                  item={item}
                  openMobileMenu={openMobileMenuHandler}
                  setShowShadow={setShowShadow}
                  showShadow={showShadow}
                />
              </li>)
            }
          </ul>
          <div className="mobile-header-btn-group">
            <HeaderButtonGroup openMobileMenu={(val) => setMobileMenuIsOpen(val)} showShadow={setShowShadow}/>
          </div>
        </Navbar>
        <HeaderButtonGroup openMobileMenu={(val) => setMobileMenuIsOpen(val)} mobileMenuIsOpen={mobileMenuIsOpen}
                           showShadow={setShowShadow}/>
      </header>
      {
        showShadow && <div className="header-shadow"></div>
      }
    </>
  );
};

export default Header;
