import React, { useState, useContext } from 'react';
import './ProfileMenu.scss';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch, withRouter } from 'react-router-dom';
import { Context } from '../../context';

import MainButton from '../../components/MainButton/MainButton';
import MenuMask from '../../components/MenuMask/MenuMask';

import { ReactComponent as ArrowIcon } from './../../assets/icons/filter-arow.svg';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { localLogOut } from '../../utils/api';
import { clearStorageAfterLogout } from '../../utils/clearStorage';

const ProfileMenuItem = (itemData) => {

  const { content } = itemData;
  const active = useRouteMatch(content.url);
  const [hover, setHover] = useState(false);
  
  const { width } = useWindowSize();
  const LAPTOP = 991;
  
  return (
    <NavLink to={content.url} className='profile-menu__item'
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}
    >
      <span className='profile-menu__item-text'>{content.name}</span>
      {
        (active || hover) && <>
          {  width > LAPTOP && <>
            <MenuMask />
            <ArrowIcon/>
          </>
          }
        </>
      }
      { width <= LAPTOP && <ArrowIcon/>}
    </NavLink>
  );
};

function ProfileMenu({ history }) {

  const { blackTheme, changeLoginationStatus, changeBasketItemsLength, staticLocalization } = useContext(Context);
  const { personalOfficeMenu } = staticLocalization;
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const menuData = [
    {
      id: 'menu1',
      name: personalOfficeMenu.personalData,
      url: '/profile/personal'
    },
    {
      id: 'menu2',
      name: personalOfficeMenu.myOrders,
      url: '/profile/orders'
    },
    {
      id: 'menu3',
      name: personalOfficeMenu.localCard,
      url: '/profile/local'
    },
    {
      id: 'menu4',
      name: personalOfficeMenu.picking,
      url: '/profile/liked'
    },
    {
      id: 'menu5',
      name: personalOfficeMenu.templatesToSend,
      url: '/profile/template'
    }
  ];


  const exitFromAcount = (lokalLogout = true)=>{
    if (lokalLogout) {
      localLogOut();
    }

    clearStorageAfterLogout();

    history.push('/');
    changeLoginationStatus(false);
    changeBasketItemsLength(0);

    window.location.reload();
  };

  return (
    <div className={`profile-menu ${getTheme}`}>
      <div className='profile-menu__list'>
        {
          menuData.map(item => <ProfileMenuItem key={item.id} content={item} />)
        }
      </div>
      <MainButton palette='secondary' className='profile-menu__button' onClick={()=>exitFromAcount(false)}>
        {personalOfficeMenu.accountQuitButtonTitle}
      </MainButton>
    </div>
  );
}

ProfileMenu.propTypes = {
  history: PropTypes.object
};

export default withRouter(ProfileMenu);
