export const toUpperCase = (str) => {
  const firstChar = str[0];
  const rest = str.substring(1);
  return `${firstChar.toUpperCase()}${rest}`;
};

export const isStringEmpty = (str) => {
  return typeof str === 'string' && !!str.trim().length;
}

export const dotToСoma = (currentLanguageId, str) => {
  switch (currentLanguageId) {
  case 1: // Ukrainian
    return str.toString().replace('.', ',');
    break;
  default:
    return str;
  }
};
