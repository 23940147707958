import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Context } from '../../../../context';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { novaPoshtaGetCities } from '../../../../utils/api';

const City = ({ setFieldValue, ...props }) => {
  const [data, setData] = useState([]);
  const { staticLocalization, langKey } = useContext(Context);
  const { deliveryForm } = staticLocalization;
  const [selectedCity, setSelectedCity] = useState(props.form.values.city);

  useEffect(() => {
    let search = langKey === 'uk' ? 'а' : 'a';
    if (selectedCity) {
      search = selectedCity.value;
    }

    novaPoshtaGetCities(search, langKey).then(res => {
      if (res.ok) {
        return res.json().then(el => {
          const options = el.result.map(cities => {
            const { id, name, cityRef } = cities;
            return { value: name, label: name, id, cityRef };
          });
          setData(options);
        });
      }
    });
  }, [langKey]);

  const handleChange = (val) => {
    const array = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
    array['city'] = val;
    localStorage.setItem('regestration', JSON.stringify(array));

    setFieldValue('city', '');
    setTimeout(() => {
      setFieldValue('city', val);
      setSelectedCity(val); // Обновляем состояние selectedCity
      setFieldValue('deliveryAndPaymentTypeData', '');
      setFieldValue('address', '');
      setFieldValue('building', '');
    });
  };

  const handleInputChange = (val, actions) => {
    if (actions.action === 'set-value') {
      setFieldValue('deliveryAndPaymentTypeData', '');
    }

    if (val.length) {
      novaPoshtaGetCities(val, langKey).then(res => { // Передаем langKey в novaPoshtaGetCities
        if (res.ok) {
          return res.json().then(el => {
            const options = el.result.map(cities => {
              const { id, name, cityRef } = cities;
              return { value: name, label: name, id, cityRef };
            });
            setData(options);
          });
        }
      });
    }
  };

  const cityClassNames = classNames(
    'drop-down',
    typeof (props.form.values.city) === 'object' ? 'like-value' : ''
  );

  useEffect(() => {
    if (selectedCity) {
      const newSelectedCity = data.find(city => city.cityRef === selectedCity.cityRef);
      if (newSelectedCity) {
        setSelectedCity(newSelectedCity);
        setFieldValue('city', newSelectedCity);
      }
    }
  }, [data]);

  return (
    <Select
      key={JSON.stringify(langKey)}
      name='city'
      value={selectedCity}
      onChange={val => handleChange(val)}
      onInputChange={(val, actions) => handleInputChange(val, actions)}
      placeholder={selectedCity?.value || deliveryForm.city}
      noOptionsMessage={() => deliveryForm.city}
      className={cityClassNames}
      classNamePrefix='react-select'
      options={data}
    />
  );
};

City.propTypes = {
  setFieldValue: PropTypes.func,
  selectedCity: PropTypes.object,
  removeError: PropTypes.func,
  form: PropTypes.object
};

export default City;