import React from 'react';
import { ReactComponent as MenuSide } from './../../assets/images/menu-side.svg';
import { ReactComponent as MenuBody } from './../../assets/images/menu-body.svg';
import './MenuMask.scss';

function MenuMask({className}) {
  return (
    <div className={`menu-mask ${className || ''}`}>
      <MenuSide/>
      <MenuBody/>
      <MenuSide/>
    </div>
  );
}

export default MenuMask;
