import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RadioButtonSVG } from './../../assets/icons/button-radio.svg';
import { Context } from '../../context';
import classNames from 'classnames';
import './RadioButton.scss';

const RadioButton = ({ children, name, value, checkedValue, onChange, className }) => {

  const onChangeHandler = (e) => {
    onChange(name, value);
  };
  const { blackTheme } = useContext(Context);

  const theme = blackTheme ? 'dark' : 'light';

  const classes = classNames(
    'radio-button',
    `radio-button--${theme}`,
    className
  );

  return (
    <label className={classes}>
      <input className='radio-button__input'
        type='radio'
        onChange={onChangeHandler}
        checked={value === checkedValue}
        name={name}
        value={value}
      />
      <div className='radio-button__box'>
        <RadioButtonSVG />
      </div>
      <div className='radio-button__content'>
        { children }
      </div>
    </label>
  );
};

RadioButton.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checkedValue: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
};

RadioButton.defaultProps = {
  children: '',
  onChange: () => {},
  className: '',
  name: '',
  value: '',
  checkedValue: 'null',
  checked: false,
  theme: 'dark'
};

export default RadioButton;