import { da } from 'date-fns/locale';

export const modifidedItemWithTranslationField = (content) => {
  let modifidedObj = content;

  for (const property in content){

    if(content[property]  && content[property].translations){

      const translatibleValue = {};
      const modifidedTranslation = {};

      content[property].translations.forEach(el => {
        modifidedTranslation[el.language] = el.text;
      });

      translatibleValue[property] = modifidedTranslation;


      const newObj = { ...modifidedObj, ...translatibleValue };
      modifidedObj = newObj;
    };

  }

  return modifidedObj;
};

export const getItemFieldWithTranslation = (getData, field, languageId = 1) => {
  try{
    // return getData[field].translations.map((el)=>{
    //     return el.text;
    // });
    const thisField = getData[field].translations.find(el => el.language === languageId);
    return thisField.text;

  }catch(e){
    // console.log(e);
  }
};

export const getTranslation = (data, modifitedData, field, languageId = 1) => {
  try{
    return modifitedData[field][languageId] ? modifitedData[field][languageId] : data[field].translations[languageId === 1 ? 0 : 1].text;
  }catch(e){
    //console.log(`getTranslation error: ${e}`);
  }
};

export const getCategoryName = (id, arr, languageId = 1) => {
  try{
    const item = arr.find(element => element.id === id);
    const modifidedCategoryArr = modifidedItemWithTranslationField(item);
    return modifidedCategoryArr.name[languageId] || item.name.translations[0].text;
  }catch(err){
    return '';
  }
};
