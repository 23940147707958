import React, {useEffect, useContext, useRef} from 'react';
import './Winter.scss';
import classNames from 'classnames';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../utils/useWindowSizeHook';

import Parallax from 'parallax-js' // Now published on NPM

const Winter = ({test}) => {

  const { blackTheme, staticLocalization }  = useContext(Context);
  const { general } = staticLocalization;
  
  const scene = useRef();
  const starsCanv = useRef();
  const snowCanv = useRef();

  const windowWidth = useWindowSize().width;

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const getRandomIntFromMinToMax = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення       
  }

  useEffect(() => {
    let snowFallAnimationFrame;
    if(windowWidth > 575){
      const snowCanvas = snowCanv.current;

      var parallaxInstance = new Parallax(scene.current, {
        relativeInput: true,
        hoverOnly: true
      });
  
      if(snowCanvas){
        if (snowCanvas.getContext){
          const ctx2 = snowCanvas.getContext('2d');
    
          const snowflakesQuintuty = 250;
    
          snowCanvas.width = scene.current.clientWidth;
          snowCanvas.height = scene.current.clientHeight;
        
          function Snowflake (x, y, radius, color) {
            this.x = x;
            this.y = y;
            this.radius = radius;
    
            this.dy = (Math.random() * 1) + 1;
    
            this.draw = function(){
              ctx2.beginPath();
              ctx2.fillStyle = blackTheme ? color : 'white';
              ctx2.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
              ctx2.fill();
            }
    
            this.animate = function(){
              if(this.y + this.dy > (snowCanvas.height + (this.radius * 2))){
                this.y = -(this.radius * 2);
              }else{
                this.y += this.dy;
              }
              this.draw();
    
            }
          }
    
          const snowflakes = [];
    
          for(let i = 0; i <= snowflakesQuintuty; i++){
            const x = getRandomIntFromMinToMax(0, scene.current.clientWidth);
            const y = getRandomIntFromMinToMax(0, scene.current.clientHeight);
            const radius = getRandomIntFromMinToMax(1, 4);
            snowflakes.push(new Snowflake(x, y, radius, `rgba(223, 179, 149, ${Math.random()})`))
          }
    
          function Update () {
            try{
              ctx2.clearRect(-15, 0 , (scene.current.clientWidth + 30), scene.current.clientHeight);
    
              for(let i = 0; i <= snowflakesQuintuty; i++){
                snowflakes[i].animate();
              }
              const b = 'aaaa';
              
              snowFallAnimationFrame = requestAnimationFrame(Update)
            }catch(err){
              console.log(err)
            }
          }
    
          Update();
        }
      }
    }

    return () => {
      cancelAnimationFrame(snowFallAnimationFrame);
    }
  }, [blackTheme])

  useEffect(() => {

    if(windowWidth > 575){
      const starsCanvas = blackTheme ? starsCanv.current : null;

      const windowWidth = window.innerWidth;
      const windowInnerHeight = window.innerHeight; 

      if(blackTheme){
        if (starsCanvas.getContext){
          const ctx = starsCanvas.getContext('2d');
    
          const starsCanvasWidth = windowWidth - 120;
          const starsCanvasHeight = windowInnerHeight * 0.7; 
          const starsQuintity = 150;
          
          starsCanvas.width = starsCanvasWidth;
          starsCanvas.height = starsCanvasHeight;
          
          for(let i = 0; i <= starsQuintity; i++){
            ctx.beginPath();
            var x = getRandomInt(starsCanvasWidth); // x coordinate
            var y = getRandomInt(starsCanvasHeight); // y coordinate
            var radius = 2; // Arc radius
            var anticlockwise = false; // clockwise or anticlockwise
            const fillOpacity = getRandomIntFromMinToMax(6, 11) / 10;
      
            // ctx.fillStyle = `rgba(247, 201, 101, ${Math.random()})`;
            ctx.fillStyle = `rgba(247, 201, 101, ${fillOpacity})`;
            ctx.arc(x, y, radius, 0, Math.PI * 2, anticlockwise);
      
            ctx.fill();
          }
        
        }
      }

    }

  }, [blackTheme])
  
  const winterClassNames = classNames(
    'scene',
    'winter',
    blackTheme ? 'black-theme' : 'light-theme'
  )

  const winterMobileClassNames = classNames(
    'mobile-plug',
    'mobile-plug--winter',
    blackTheme ? 'black-theme' : 'light-theme'
  )

  return (
    <>
      {
        windowWidth > 575 ?
          <div ref={scene} className={winterClassNames}>
              <div data-depth="0.21" className='layer layer-5'></div>
              {
              blackTheme ?
              <div data-depth="0.21" className='layer stars'>
                  <canvas ref={starsCanv}></canvas>
              </div>
              : 
              null
              }
              <div data-depth="0.19" className='layer layer-4'></div>
              <div data-depth="0.16" className='layer layer-3'></div>
              <div data-depth="0.13" className='layer layer-6'></div>
              <div data-depth="0.1" className='layer layer-2'></div>
              <div data-depth="0.13" className='layer snow'>
                <canvas ref={snowCanv}></canvas>
              </div>
              <div data-depth="0.13" className='layer layer-1'></div>
              <div data-depth="0" className='layer text'>
              <h1>{general.name}</h1>
              </div>
          </div>
          :
          <div className={winterMobileClassNames}>
              <h1>{general.name}</h1>
          </div>
      }
    </>
  )
}

export default Winter;