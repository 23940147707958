import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './MainTitle.scss';

const MainTitle = ({
  children, className, secondType
}) => {

  const classes = classNames(
    'main-title',
    className,
    secondType ? 'main-title--second-type' : ''
  );

  const Tag = secondType ? 'h2' : 'h1';

  return(
    <div className={classes}>
      <div className='wrapper'>
        <Tag>
          {children}
        </Tag>
      </div>
    </div>
  );
};

MainTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  secondType: PropTypes.bool
};
  
MainTitle.defaultProps = {
  children: '',
  className: '',
  secondType: false
};

export default MainTitle;