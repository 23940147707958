import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { Context } from '../../../../context';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { novaPoshtaGetStreets } from '../../../../utils/api';

const Street = ({ setFieldValue, selectedCityId, handleChange, ...props }) => {

  const [data, setData] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState(props.form.values.address);

  const { staticLocalization, langKey } = useContext(Context);
  const { deliveryForm } = staticLocalization;

  useEffect(() => {
    if (!selectedCityId) return;

    if (props.form.values.address) {
      getStreets(selectedCityId, props.form.values.address, null, langKey, true);
    } else {
      getStreets(selectedCityId, langKey === 'uk' ? 'а' : 'a', null, langKey);
    }
  }, [selectedCityId]);

  useEffect(() => {
    if (!selectedCityId) return;

    const search = langKey === 'uk' ? 'а' : 'a';
    const ref = selectedStreet ? selectedStreet.ref : null;

    getStreets(selectedCityId, search, ref, langKey);
  }, [langKey]);

  const handleStreetChange = (val) => {
    const array = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
    array['address'] = val;
    localStorage.setItem('regestration', JSON.stringify(array));

    setFieldValue('address', '');
    setTimeout(() => {
      setFieldValue('address', val);
      setSelectedStreet(val); // Обновляем состояние selectedStreet
    });

    handleChange({ target: { name: 'address', value: val } });
  };

  const handleInputChange = (val, actions) => {
    if (val.length && selectedCityId) {
      getStreets(selectedCityId, val, null, langKey);
    }
  };

  function getStreets(selectedCityId, name, ref, langKey, setSelectValue = false) {
    novaPoshtaGetStreets(selectedCityId, name, ref, langKey).then(res => {
      if (res.ok) {
        return res.json().then(el => {
          const options = el.map(streets => {
            const { id, description, streetsType, ref } = streets;
            const streetName = `${streetsType} ${description}`;
            return { value: streetName, label: streetName, id, ref };
          });
          setData(options);

          // If setSelectValue is true, set the first option as the selected option
          if (setSelectValue && options.length > 0) {
            handleStreetChange(options[0]);
          }
        });
      }
    });
  }

  const streetClassNames = classNames(
    'drop-down',
    typeof (props.form.values.address) === 'object' ? 'like-value' : ''
  );

  useEffect(() => {
    if (selectedStreet) {
      console.log('selectedStreet', selectedStreet);
      const newSelectedStreet = data.find(street => street.id === selectedStreet.id);
      if (newSelectedStreet) {
        setSelectedStreet(newSelectedStreet);
        setFieldValue('address', newSelectedStreet);
      }
    }
  }, [data]);

  return (
    <Select
      name='address'
      value={selectedStreet}
      onChange={val => handleStreetChange(val)}
      onInputChange={(val, actions) => handleInputChange(val, actions)}
      placeholder={selectedStreet?.value || deliveryForm.adress}
      noOptionsMessage={() => deliveryForm.addressNoOptions}
      className={streetClassNames}
      classNamePrefix='react-select'
      options={data}
    />
  );
};

Street.propTypes = {
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  selectedCityId: PropTypes.number,
  removeError: PropTypes.func,
  form: PropTypes.object
};

export default Street;