import { TYPES, ERROR_VALUE } from '../constants/app';
import {getCustomInscriptionAmount} from '../utils/getCustomInscriptionValues'

const defaultValues = {
  isLogined: false,
  getedData: [{
    price: 0,
    quantity: 0
  }]
};

const INDIVIDUAL_PRICE_COMPONENTS = ['shape', 'chocolateType', 'additions', 'inscription', 'box', 'postcard', 'decor'];
const BOX_PRICE_COMPONENTS = ['box', 'goods'];

const getPriceBoxesValue = (item, priceComponents) => {

  return priceComponents.reduce((finalPrice, component) => {

    if (component === 'goods') {
      const componentPrice = item[component].reduce((price, curr) => price + (+curr.quantity * +curr.goods.price), 0);
      
      // TODO: for debugging
      // console.log(component)
      // console.log(componentPrice)
      // console.log(finalPrice + componentPrice)
      return finalPrice + componentPrice;
    }
    
    // TODO: for debugging
    // console.log(component)
    // console.log(item[component].price)
    // console.log(finalPrice + component.price)
    return finalPrice + item[component].price;
  }, 0);
};

const getPriceIndividualValue = (item, priceComponents) => {

  return priceComponents.reduce((finalPrice, component) => {

    
    const settings = item[component]?.settings;

    if (component === 'shape' || component === 'postcard') {
      // TODO: for debugging
      // console.log(component)
      // console.log((+item[component]?.price || 0))
      // console.log(finalPrice + (+item[component]?.price || 0))
      return finalPrice + (item[component]?.price || 0);
    }
    
    if (component === 'additions') {
      const componentPrice = item[component].reduce((price, curr) => price + (curr.settings[0].price || 0), 0);
      // TODO: for debugging
      // console.log(component)
      // console.log(componentPrice)
      // console.log(finalPrice + componentPrice)
      return finalPrice + componentPrice;
    }

    if (component === 'inscription') {

      const componentPrice = item[component]?.isCustomInscription
      ? getCustomInscriptionAmount(item[component].customSettings[0].ranges, item.customInscriptionText)
      : item[component]?.price
        ? item[component].price
        : 0;
      // TODO: for debugging
      // console.log(component)
      // console.log(componentPrice)
      // console.log(finalPrice + componentPrice)

      return finalPrice + componentPrice;
    }

    // TODO: for debugging
    // console.log(component)
    // console.log(settings ? settings[0].price : finalPrice)
    // console.log(settings ? finalPrice + settings[0].price : finalPrice)
    return settings ? finalPrice + settings[0].price : finalPrice;
  }, 0);
};

export const getPrice = (item, type, isLoggedIn, notRegisterCustomerId) => {
  try{
    if (isLoggedIn || notRegisterCustomerId > 0) {
      if (type === TYPES.GOODS || type === TYPES.BOX) {
        return +item.price;
      }
  
      if (type === TYPES.BOX_WITH_GOODS) {
        return getPriceBoxesValue(item, BOX_PRICE_COMPONENTS);
      }
  
      if (type === TYPES.INDIVIDUAL_ORDER) {
        return getPriceIndividualValue(item, INDIVIDUAL_PRICE_COMPONENTS);
      }
    }
  
    if (Object.keys(TYPES).indexOf(item.type) > -1) {
      return +item.price;
    }
  
    return ERROR_VALUE;
  }catch(err){
    console.log(err);
  }
};

export const getGeneralPrice = (isLogined = defaultValues.isLogined, getedData = defaultValues.getedData) => {
  if (getedData) {
    return getedData.reduce((price, item) =>
      price + getPrice(isLogined ? item.itemEntity : item, item.type, isLogined) * item.quantity, 0);
  }

  return 0;

};
