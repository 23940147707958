import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import CollectionGroup from '../../components/CollectionGroup/CollectionGroup';
import Loader from '../../components/Loader/Loader';

import { getAllCollections } from '../../utils/api';

const Collection = () => {

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(()=>{

    try{
      
      getAllCollections().then((res)=>{
        return res.json();
      }).then((collections)=>{
        setData(collections.result);
        setIsLoaded(true);
      });
    }catch(err){
      console.log(err);
    }

  }, []);


  return (
    <>
      {
        isLoaded
          ?
          <>
            <Helmet>
              <title>
                Колекції шоколадних виробів для свята та подарунків
              </title>
              <meta
                name='description'
                content='Купляй шоколад для подарунку та свята ❖ Тематичні колекції фігурок, тафель, цукерок, шоколаду, наборів цукерок ❖ Львівська майстерня шоколаду — інтернет-магазин цукерок, шоколаду та шоколадних фігурок'
                data-react-helmet='true'
              />
            </Helmet>
            <CollectionGroup content={data} />
          </>
          :
          <Loader />
      }
    </>
  );
};

export default Collection;