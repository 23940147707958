import React, { useState, useEffect, useContext } from 'react';
import './StaticPage.scss';
import { Container, Row, Col } from 'react-bootstrap';

import { clearHtml } from '../../utils/clearHtml';
import { getStaticPages } from '../../utils/api';
import { getItemFieldWithTranslation } from '../../utils/renderFieldWithTransition';
import { scrollToTop } from '../../utils/scrollToTop';

import Loader from '../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainTitle from '../../components/MainTitle/MainTitle';
import { Context } from '../../context';

const StaticPage = () => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const { curentLanguageId } = useContext(Context);

  useEffect(() => {
    scrollToTop();
    getStaticPages().then(res => {
      return res.json();
    }).then(el => {
      const currentStaticPage = el.find(el => '/' + el.slug === history.location.pathname);
      setData(currentStaticPage);
      setIsLoaded(true);
    });
  }, [history.location.pathname]);

  // DATA:
  let content;
  let title;
  let metaDescription;
  let clearContent;
  if (isLoaded){
    content = getItemFieldWithTranslation(data, 'text', curentLanguageId);
    title = getItemFieldWithTranslation(data, 'metaTitle', curentLanguageId);
    metaDescription = getItemFieldWithTranslation(data, 'metaDescription', curentLanguageId);
    clearContent = clearHtml(content);
  }
  return (
    isLoaded ?
      <section className='static-page'>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaDescription} data-react-helmet="true"/>
        </Helmet>
        <Container fluid className='static-page-mob'>
          <Row>
            <Col xs={12}>
              <MainTitle>
                {title}
              </MainTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={12} dangerouslySetInnerHTML={{ __html: clearContent }}></Col>
          </Row>
        </ Container>
      </section>
      :
      <div className='main-loader'>
        <Loader />
      </div>
  );
};

export default StaticPage;
