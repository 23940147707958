import React, { useState, useContext } from 'react';
import './LocalNumberConfirmationPopup.scss';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Context } from '../../../context';

import Loader from '../../../components/Loader/Loader';
import MainButton from '../../MainButton/MainButton';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import {
  bindLocalCardsByMobile,
  lokalLoginOrRegister, getCurrentUser, deleteAllGoodsFromBasket, getGoodsForBasket,
} from '../../../utils/api';

import { useHistory } from 'react-router-dom';
import { setBasketFromLocalStorage } from '../../../utils/setBasketFromLocalStorage';
import PropTypes from 'prop-types';

const LocalNumberConfirmationPopup = ({
  type,
  formLocation,
  hideVerificationPopup,
  customerPhoneNumber,
  setLocalCardNumber,
  notRegisterCustomerId,
  setProposeBurnAmount,
  setIsItPossibleToWithdraw,
  setHowMuchcanWithdraw,
  setShowLoginedLocalForm,
  changeStepLookAfterLoading,
  formID,
  changeStep,
  setCardNumberChanged,
  setUserHaveLocalCard
}) => {

  const [isLoaded, setIsLoaded] = useState(true);
  const [showGeneralError, setShowGeneralError] = useState(false);

  const {
    staticLocalization,
    setBodyUnscroll,
    changeLoginationStatus,
    setDataAboutUser,
    changeBasketItemsLength,
    isLogined,
    userData
  } = useContext(Context);
  const { localNumberConfirmationPopup } = staticLocalization;

  const PhoneVerification = Yup.object().shape({
    code: Yup.string()
      .required(localNumberConfirmationPopup.errorMessage)
  });

  const history = useHistory();

  const handleClosePopup = () => {
    setBodyUnscroll(false);
    hideVerificationPopup();
    sessionStorage.removeItem('lokal-login-or-registration');
  };

  return (
    <div className='local-number-confirmation-popup'>
      <Formik
        initialValues={{
          code: ''
        }}
        validationSchema={PhoneVerification}
        onSubmit={values => {

          const { code } = values;

          setIsLoaded(false);

          if (type === 'LOCAL_LOGIN_PAGE') {
            lokalLoginOrRegister(customerPhoneNumber, code, null, formLocation, isLogined).then(res => {
              if (res.ok) {
                res.json().then(data => {
                  sessionStorage.removeItem('lokal-login-or-registration');

                  if (formLocation === 'ORDER_FOURTH_STEP') {
                    setIsItPossibleToWithdraw(data.balanceAfter);
                    setHowMuchcanWithdraw(data.proposeBurnAmount);
                    setProposeBurnAmount(data.proposeBurnAmount);
                    setLocalCardNumber(data.cardNo);
                    setShowLoginedLocalForm();
                    setBodyUnscroll(false);
                    hideVerificationPopup();


                    userData.cardNo = data.cardNo;
                    setDataAboutUser(userData);
                    return;
                  }


                  if (formLocation === 'PROFILE') {
                    setLocalCardNumber(data.cardNo);
                    setBodyUnscroll(false);
                    hideVerificationPopup();

                    userData.cardNo = data.cardNo;
                    setDataAboutUser(userData);
                    setCardNumberChanged(true);
                    setUserHaveLocalCard(true);

                    // hotfix for updating user data
                    // todo: for now I don't know why cardNo is not updated in the context
                    window.location.reload();
                    return;
                  }

                  setBodyUnscroll(false);

                  const { access_token } = data;
                  const date = new Date();

                  localStorage.setItem('lmsh-token', access_token);
                  localStorage.setItem('refresh-token', date.getTime());

                  const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));
                  getCurrentUser(access_token).then(response => {
                    response.json().then(dataAboutUser => {

                      const { email } = dataAboutUser;

                      setDataAboutUser(dataAboutUser);

                      if (localBasket) {
                        if (localBasket.length > 0) {
                          deleteAllGoodsFromBasket(0).then(() => {
                            setBasketFromLocalStorage(changeBasketItemsLength, 0, false, changeLoginationStatus).then(() => {
                              changeLoginationStatus(true);
                              if (parseInt(formID) === 1) { // Check which form we use (1 === form from personal office)
                                history.push('/profile/personal');
                              } else { // (2 == form from order)
                                changeStepLookAfterLoading();
                              }
                            });
                          });
                        } else {
                          changeLoginationStatus(true);
                          getGoodsForBasket().then(res => {
                            return res.json();
                          }).then(el => {
                            changeBasketItemsLength(el.count);
                            if (formID === '1') { // Check which form we use (1 === form from personal office)
                              history.push('/profile/personal');
                            } else { // (2 == form from order)
                              changeStepLookAfterLoading();
                            }
                          });
                        }
                      } else {
                        changeLoginationStatus(true);
                        getGoodsForBasket().then(res => {
                          return res.json();
                        }).then(el => {
                          changeBasketItemsLength(el.count);
                          if (formID === '1') { // Check which form we use (1 === form from personal office)
                            history.push('/profile/personal');
                          } else { // (2 == form from order)
                            changeStepLookAfterLoading();
                          }
                        });
                      }

                    });
                  });
                  return;
                });
              } else {
                setIsLoaded(true);
                setShowGeneralError(true);
              }
            });

          } else {
            bindLocalCardsByMobile(customerPhoneNumber, code).then(res => {
              if (res.ok) {
                res.json().then(el => {
                  setLocalCardNumber(el.localCard);
                  window.location.reload();
                });

              } else {
                setIsLoaded(true);
                setShowGeneralError(true);
              }
            });
          }
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form className='g-form-style local-number-confirmation-form'>
            <div className='close'>
              <button type='button' onClick={handleClosePopup}>
                <CloseIcon />
              </button>
            </div>
            <div className='wrapper'>
              <div className='title'>
                <h3>{localNumberConfirmationPopup.title || 'Введіть ваш код з смс'}</h3>
                <p>{localNumberConfirmationPopup.subtitle || 'Будь ласка, введіть ваш код з смс, по вказаному номеру телефону.'}</p>
              </div>
              <label>
                <p className='required'>{localNumberConfirmationPopup.code || 'Код з СМС'}</p>
                <Field type='code' name='code'
                  placeholder={localNumberConfirmationPopup.codePlaceholder || 'Введіть код'} />
                {errors.code && touched.code ? ( // Error
                  <div className='error'>{errors.code}</div>
                ) : null}
              </label>
              {
                isLoaded ?
                  null
                  :
                  <Loader />
              }
              {
                showGeneralError ?
                  <div className='error general-error'>{localNumberConfirmationPopup.generalError}</div>
                  :
                  null
              }
              <MainButton type='submit' palette='primary' onMouseDown={handleSubmit}>
                {localNumberConfirmationPopup.btnText || 'Продовжити'}
              </MainButton>

            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LocalNumberConfirmationPopup.propTypes = {
  type: PropTypes.string,
  formLocation: PropTypes.string,
  changeStepLookAfterLoading: PropTypes.func,
  formID: PropTypes.string,
  changeStep: PropTypes.func
};


export default LocalNumberConfirmationPopup;
