import React, { useContext } from 'react';
import './PreFooterCityImage.scss';
import { Context } from '../../context';

import CityImageDark from '../../assets/images/pre-footer-img.svg';
import CityImageLight from '../../assets/images/pre-footer-img--light.svg';

const PreFooterCityImage = () => {

  const { blackTheme } = useContext(Context);

  return(
    <div className='pre-footer-image'>
      <img src={blackTheme ? CityImageDark : CityImageLight} alt='Lviv-City'/>
    </div>
  );
};

export default PreFooterCityImage;