import React, { useContext } from 'react';
import { ReactComponent as CloseSVG } from '../../assets/icons/close.svg';
import MainButton from '../MainButton/MainButton';
import { Context } from '../../context';


const ConfirmDeleteItem = ({ setIsModalOpen, deleteGoodFromBasket, title, deleteText, saveText }) => {
  const { staticLocalization } = useContext(Context);
  return(
    <div className='custom-inscription-form__wrapper'>
      <div className='border-wrapper'>
        <div className='custom-inscription-form__title-wrapper'>
          <h3 className='custom-inscription-form__title'>{staticLocalization.basket.deleteItemText}</h3>
          <CloseSVG onClick={() => setIsModalOpen(false)}/>
          <div className='individual-order__button-group individual-order--margin-top'>
            <MainButton palette='secondary' onMouseDown={() => deleteGoodFromBasket()} className='reset-z-index'>
              {staticLocalization.basket.deleteItemBtns}
            </MainButton>
            <MainButton palette='primary' onMouseDown={() => setIsModalOpen(false)}  className='reset-z-index margin-top-mob'>
              {staticLocalization.basket.saveItemBtns}
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteItem;
