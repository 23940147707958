import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './Steps.scss';

import { ReactComponent as StepActiveIcon } from './../../assets/icons/step-active.svg';
import { ReactComponent as StepPrevIcon } from './../../assets/icons/step-prev.svg';
import { ReactComponent as StepNextIcon } from './../../assets/icons/step-next.svg';
import { ReactComponent as StepActiveIconLong } from './../../assets/icons/step-active-long.svg';
import { ReactComponent as StepPrevIconLong } from './../../assets/icons/step-prev-long.svg';
import { ReactComponent as StepNextIconLong } from './../../assets/icons/step-next-long.svg';

import { useWindowSize } from '../../utils/useWindowSizeHook';

const Border = ({ content, type }) => {

  const { width } = useWindowSize();
  const MOBILE = 991;

  
  if (type ==='short' || width <= MOBILE )
    return (
      <>
        { content === 'active' && <StepActiveIcon />}
        { content === 'passed' && <StepPrevIcon />}
        { content === 'next' && <StepNextIcon />}
      </>
    );

  return (
    <>
      { content === 'active' && <StepActiveIconLong />}
      { content === 'passed' && <StepPrevIconLong />}
      { content === 'next' && <StepNextIconLong />}
    </>
  );

};

const StepItem = ({ content, type }) => {
  if (content.id !== 'last') {
    return (
      <div className='steps__item'>
        <Border content={content.state} type={type}/>
        {content.name}
      </div>
    );
  }
  return null;
};

const getContent = (content, quantity, page) => {

  const quantityInc = page === 'individual' ? quantity + 1 : quantity;
  const stepsLength = content.length;
  let _content = [];
  if (!quantity || stepsLength <= quantity) {
    _content = content;
  } else {
    const index = content.findIndex(item => item.state === 'active');
    if (index + quantity  + 1 <= stepsLength) {
      _content = content.slice(index, index + quantity);
    } else {
      _content =  content.slice(stepsLength - quantityInc, stepsLength);
    }
  }

  return _content;
};

function Steps({ content: stepsContent, quantity, type = 'short', page }) {
  
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(getContent(stepsContent, quantity, page));
  }, [stepsContent, quantity, page]);

  const classes = classNames(
    'steps',
    { 'steps--long': type === 'long' }
  );

  return (
    <>
      { content &&
        <div className={classes}>
          {content.map(item => <StepItem key={item.id} content={item} type={type}/>)}
        </div>
      }
    </>
  );
}

export default Steps;
