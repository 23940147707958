import React, { useState, useEffect, useContext } from 'react';
import './MostPopular.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Context } from '../../context';

import { imagesUrl, getGroupItems } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';

import MainItem from '../MainItem/MainItem';
import Loader from '../Loader/Loader';

import { ReactComponent as ButtonSide } from './../../assets/images/button-side.svg';
import { ReactComponent as ButtonBody } from './../../assets/images/button-body.svg';

function MostPopular({ data, elemetsLimit }) {

  const [modifidedData, setModifidedData] = useState(data.templateItemValue);
  const [nonBanerItemsData, setNonBanerItemsData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { settings, staticLocalization, curentLanguageId, blackTheme } = useContext(Context);

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  useEffect(() => {
    const modifided = modifidedItemWithTranslationField(data.templateItemValue);
    setModifidedData(modifided);
    getGroupItems({
      limit: elemetsLimit,
      sort: 'NEW'
    }).then(res => {
      return res.json();
    }).then(el => {
      setNonBanerItemsData(el.result);
      setIsLoaded(true);
    }).catch(err => {
      console.log(err);
    });
  }, [elemetsLimit]);

  const { images, imagesMedium, imagesSmall, buttonUrl, url } = data.templateItemValue;
  const name = getTranslation(data, modifidedData, 'name', curentLanguageId);
  const description = getTranslation(data, modifidedData, 'text', curentLanguageId);
  const btnText = getTranslation(data, modifidedData, 'buttonTitle', curentLanguageId);

  return (
    <>
      <div className='main-page-template most-popular'>
        <div className='main-page-template__title-wrapper main-page-template__title-wrapper--1'>
          <h2 className='main-page-template__title'>{staticLocalization.homePage.novelty}</h2>
        </div>

        {
          isLoaded ?
            <div className='main-page-template__content'>
              <div className={classNames('baner-wrapper', whatTheme)}>
                <div className='baner'>
                  <div className='text'>
                    <a href={url ? url : null} target='blank' className='text__wrapper'>
                      <h4>
                        {name}
                      </h4>
                      <p>
                        {description || ''}
                      </p>
                    </a>
                    {
                      buttonUrl ?
                        <a href={buttonUrl} className='btns btns--primary desctop-btn' target='_black'>
                          {btnText}
                          <div className='btns__buttons'>
                            <ButtonSide/>
                            <ButtonBody/>
                            <ButtonSide/>
                          </div>
                        </a>
                        :
                        null
                    }
                  </div>
                  <a href={url ? url : null} target='_blank'>
                    <picture>

                      {imagesMedium[0] ? <source media='(max-width: 1024px)' srcSet={`${imagesUrl}${imagesMedium[0]}?v=${settings.frontendVersion}`}/> : null}
                      {imagesSmall[0] ? <source media='(max-width: 545px)' srcSet={`${imagesUrl}${imagesSmall[0]}?v=${settings.frontendVersion}`}/> : null}
                      <img src={`${imagesUrl}${images[0]}?v=${settings.frontendVersion}`} alt='Flowers'/>
                    </picture>
                  </a>
                  <div className='mobile-btn'>
                    {
                        buttonUrl ?
                          <a href={buttonUrl} className='btns btns--primary desctop-btn' target='_black'>
                            {btnText}
                            <div className='btns__buttons'>
                              <ButtonSide/>
                              <ButtonBody/>
                              <ButtonSide/>
                            </div>
                          </a>
                          :
                          null
                      }
                  </div>
                </div>
              </div>
              {nonBanerItemsData.map(el => <MainItem key={el.id} item={el} withCounter={true}
                withDescription={true} buttonLink='/product/'
                buttonTitle={staticLocalization.homePage.mainItemBtnText}/>)}
            </div>
            :
            <Loader/>
        }
      </div>
    </>
  );
}

MostPopular.propTypes = {
  data: PropTypes.object,
  elemetsLimit: PropTypes.number
};

export default MostPopular;
