import React, { useState, useContext } from 'react';
import './FirstStepTemplate.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../../../components/Loader/Loader';
import CheckBox from '../../../components/CheckBox/CheckBox';
import MainButton from '../../../components/MainButton/MainButton';

import { getTranslation, modifidedItemWithTranslationField } from '../../../utils/renderFieldWithTransition';
import { Context } from '../../../context';
import { imagesUrl, addGiftBoxToBasket } from '../../../utils/api';

import { ReactComponent as RadioButtonSVG } from '../../../assets/icons/button-radio.svg';

const GiftBoxWrapper = ({
  setState,
  setGiftBoxIsCheked,
  giftBoxData,
  giftBoxIsLoaded,
  noGiftBoxes,
  setRerender
}) => {

  const {
    isLogined,
    blackTheme,
    staticLocalization,
    curentLanguageId,
    changeBasketItemsLength,
    settings
  } = useContext(Context);
  const { firstOrderStep } = staticLocalization;
  const [selectedBox, setSelectedBox] = useState(null);

  const giftBoxClassNames = classNames(
    'main-item',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const handleClick = async (el) => {
    localStorage.setItem('lmsh-box-id', el.id);
    if (isLogined) {
      addGiftBoxToBasket(isLogined, el, changeBasketItemsLength).then(() => {
        setRerender();
        setSelectedBox(el);
      });
    } else {
      await addGiftBoxToBasket(isLogined, el, changeBasketItemsLength);
      setRerender();
      setSelectedBox(el);
    }
  };

  return (
    <div className='gift-box'>
      <div className='gift-box__wrapper'>
        {
          giftBoxIsLoaded ?
            noGiftBoxes ?
              <div className='no-gift-boxes'>
                <h3>
                  {firstOrderStep.giftBoxNotFind} <a href='/category/package'>{firstOrderStep.packaging}</a>
                </h3>
              </div>
              :
              giftBoxData.map((el) => {
                const modifidedData = modifidedItemWithTranslationField(el);
                const name = getTranslation(el, modifidedData, 'name', curentLanguageId);
                const title = getTranslation(el, modifidedData, 'title', curentLanguageId);
                const description = getTranslation(el, modifidedData, 'description', curentLanguageId);
                const price = el.price;

                return (
                  <div className={giftBoxClassNames} key={el.id}>
                    <button
                      onClick={() => handleClick(el)}
                    ></button>
                    <div className='wrapper'>
                      <div className='img-wrapper'>
                        <img src={`${imagesUrl}${el.images[0]}?v=${settings.frontendVersion}`} alt='Box' />
                      </div>
                      <div className='name-and-price'>
                        <div className='name'>
                          <p>{title}</p>
                        </div>
                        <div className='price'>
                          <p>
                            {price}
                            <small>{staticLocalization.general.currency}</small>
                          </p>
                          <MainButton className='btns--main add-button' onClick={() => handleClick(el)}>
                            {selectedBox && selectedBox.id === el.id ? 'Обрано' : 'Обрати'}
                          </MainButton>
                        </div>
                      </div>
                      <div className='description' dangerouslySetInnerHTML={{ __html: description }}></div>
                    </div>
                  </div>
                );
              })
            :
            <Loader />
        }
      </div>
    </div>
  );
};

const FirstStepTemplate = ({
  handleChangeStap,
  additionalProtectionPrice,
  setAdditionalProtection,
  additionalProtection,
  orderBasketAdditionalProtection,
  setOrderBasketAdditionalProtection,
  state,
  setState,
  giftBoxIsCheked,
  setGiftBoxIsCheked,
  giftBoxIsLoaded,
  giftBoxData,
  setGiftBoxData,
  noGiftBoxes,
  setNoGiftBoxes,
  setRerender
}) => {


  // const { blackTheme, staticLocalization, generalPrice, changeGeneralPrice } = useContext(Context);
  const { blackTheme, staticLocalization } = useContext(Context);
  const { firstOrderStep } = staticLocalization;

  const radioBtnsOnChangeHandler = (name, value) => {
    setState(value);
    setGiftBoxIsCheked(value === 'giftBox');
  };

  const ChangeStap = () => {
    handleChangeStap('next');
  };

  const classes = classNames(
    'first-step',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const addAdditionalProtection = (name, value, checked) => {
    setAdditionalProtection(checked);

    setOrderBasketAdditionalProtection(checked ? additionalProtectionPrice : 0 || 0);
  };

  const theme = blackTheme ? 'dark' : 'light';

  const radioButtonsClasses = classNames(
    'radio-button',
    `radio-button--${theme}`
  );

  return (
    <div className={classes}>
      <div className='radio-buttons-wrapper'>

        <label className={radioButtonsClasses}>
          <input className='radio-button__input'
            type='radio'
            name='choseBox'
            value='standartBox'
            onClick={e => radioBtnsOnChangeHandler(e.target.name, e.target.value)}
            readOnly
            checked={state === 'standartBox'}
          />
          <div className='radio-button__box'>
            <RadioButtonSVG />
          </div>
          <div className='radio-button__content'>
            <div className='title'>
              {firstOrderStep.standardPackagingTitle}
            </div>
            <div className='description'>
              {firstOrderStep.standardPackagingDescription}
            </div>
          </div>
        </label>
        <label className={radioButtonsClasses}>
          <input className='radio-button__input'
            type='radio'
            name='choseBox'
            value='giftBox'
            onClick={e => radioBtnsOnChangeHandler(e.target.name, e.target.value)}
            readOnly
            checked={state === 'giftBox'}
          />
          <div className='radio-button__box'>
            <RadioButtonSVG />
          </div>
          <div className='radio-button__content'>
            <div className='title'>
              {firstOrderStep.giftPackagingTitle}
            </div>
            <div className='description'>
              {firstOrderStep.giftPackagingDescription}
            </div>
          </div>
        </label>

      </div>

      {giftBoxIsCheked ?
        <GiftBoxWrapper
          setState={() => setState('standartBox')}
          setGiftBoxIsCheked={val => setGiftBoxIsCheked(val)}
          giftBoxData={giftBoxData}
          giftBoxIsLoaded={giftBoxIsLoaded}
          setGiftBoxData={val => setGiftBoxData(val)}
          noGiftBoxes={noGiftBoxes}
          setNoGiftBoxes={val => setNoGiftBoxes(val)}
          setRerender={setRerender}
        />
        :
        null
      }

      <div className='additional-protection'>
        <div className='additional-protection__wrapper'>
          <CheckBox
            name='additional-protection'
            checked={additionalProtection}
            value={'additionalProtection'}
            onChange={(name, value, checked) => addAdditionalProtection(name, value, checked)}>
            <div className='text'>
              <div className='title'>
                {firstOrderStep.additionalBoxTitle}
              </div>
              <div className='description'>
                {firstOrderStep.additionalBoxDescription}
              </div>
            </div>
          </CheckBox>
        </div>
      </div>
      <div className='mobile-general-price'>
        <p>{staticLocalization.order.currency}</p>
        <div className='mobile-general-price__wrapper'>
          {additionalProtectionPrice}
          <div className='currency'>
            {staticLocalization.general.currency}
          </div>
        </div>
      </div>
      <div className='foot-btn-group'>
        <MainButton palette='secondary' href='/basket'>
          {staticLocalization.order.goBack}
        </MainButton>
        <MainButton onClick={() => ChangeStap('next')}>
          {staticLocalization.order.nextStep}
        </MainButton>
      </div>
    </div>
  );
};

FirstStepTemplate.propTypes = {
  handleChangeStap: PropTypes.func,
  additionalProtection: PropTypes.bool,
  setAdditionalProtection: PropTypes.func,
  additionalProtectionPrice: PropTypes.number,
  giftBoxIsCheked: PropTypes.bool,
  setGiftBoxIsCheked: PropTypes.func,
  state: PropTypes.string,
  setState: PropTypes.func
};

GiftBoxWrapper.propTypes = {
  showGiftBoxWrapper: PropTypes.bool
};

export default FirstStepTemplate;
