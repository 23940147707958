import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getUserOrderStatusById } from '../../utils/api';
import { useParams } from 'react-router-dom';

function OrderStatus() {
  const slug = useParams();
  const [status, setStatus] = useState('PAID');

  useEffect(() => {
    getUserOrderStatusById(slug.id).then(res => {
      return res.text();
    }).then(el => {
      setStatus(el);
    });
  }, []);

  return (
    <Container fluid>
      <Row className='h-100'>
        <Col className='h-100'>
          <div className='not-found__wrapper'>
            <p className='g-like-h1'>{(status === 'PAID' || status === 'PREAUTHORIZED') ? 'Успішна оплата' : 'Помилка'}</p>
            <p className='g-like-h5'>{(status === 'PAID' || status === 'PREAUTHORIZED') ? 'Дякуємо! Ви успішно оплатили ваше замовлення.' : 'Щось пішло не так при оплаті замовлення.'}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderStatus;
