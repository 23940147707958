import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Context } from '../../../context';
import './PersonalMenu.scss';

import MainButton from '../../MainButton/MainButton';
import { ReactComponent as ButtonExit } from '../../../assets/icons/button-exit.svg';
import { clearStorageAfterLogout } from '../../../utils/clearStorage';

const ProfileMenuItem = (itemData) => {

  const { content } = itemData;
  
  return (
    <Link to={content.url} className='header-personal-menu__item'
    >
      <span className='header-personal-menu__item-text'>{content.name}</span>
    </Link>
  );
};

function PersonalMenu() {

  const { staticLocalization, changeLoginationStatus, changeBasketItemsLength } = useContext(Context);
  const { personalOfficeMenu } = staticLocalization;
  const history = useHistory();

  const menuData = [
    {
      id: 'menu1',
      name: personalOfficeMenu.personalData,
      url: '/profile/personal'
    },
    {
      id: 'menu2',
      name: personalOfficeMenu.myOrders,
      url: '/profile/orders'
    },
    {
      id: 'menu3',
      name: personalOfficeMenu.localCard,
      url: '/profile/local'
    },
    {
      id: 'menu4',
      name: personalOfficeMenu.picking,
      url: '/profile/liked'
    },
    {
      id: 'menu5',
      name: personalOfficeMenu.templatesToSend,
      url: '/profile/template'
    }
  ];

  const exitFromAcount = ()=>{
    clearStorageAfterLogout();

    history.push('/');
    changeLoginationStatus(false);
    changeBasketItemsLength(0);
    window.location.reload();
  };

  return (
    <div className='header-personal-menu'>
      <div className='header-personal-menu__list'>
        {
          menuData.map(item => <ProfileMenuItem key={item.id} content={item}/>)
        }
      </div>
      <MainButton palette='secondary' className='header-personal-menu__button' onClick={()=>exitFromAcount()}>
        <ButtonExit />{personalOfficeMenu.accountQuitButtonTitleHeader}
      </MainButton>

    </div>
  );
}

export default PersonalMenu;
