import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import Dropdown from '../../components/Dropdown/Dropdown';
import MainButton from '../../components/MainButton/MainButton';
import Filter from '../../components/Filter/Filter';
import MainItem from '../../components/MainItem/MainItem';
import Modal from '../../components/Modal/Modal';
import Counter from '../../components/Counter/Counter';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import SingleCandyPage from '../../containers/SingleCandyPage/SingleCandyPage';

import { ReactComponent as BoxTile } from '../../assets/images/box-tile-with-check.svg';
import { ReactComponent as Basket } from '../../assets/icons/basket-icon.svg';
import { ReactComponent as CloseSVG } from './../../assets/icons/close.svg';
import { ReactComponent as BoxMenuOpenIcon } from './../../assets/icons/filter-open.svg';
import { ReactComponent as PreviousArrowSVG } from './../../assets/icons/previous-arrow.svg';

import { imagesUrl, getGroupItems, getFiltersForBox, addBoxedGoodsToBasket } from '../../utils/api';
import { getEllementOffset } from '../../utils/getEllementOffset';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { getItemFieldWithTranslation } from '../../utils/renderFieldWithTransition';
import { scrollToTop } from '../../utils/scrollToTop';

import { Context } from '../../context';

const ModalPanelItem = ({ content, deleteitem, idForDelete }) => {

  const { staticLocalization, settings } = useContext(Context);
  const { general } = staticLocalization;
  const { curentLanguageId } = useContext(Context);
  const checkTechnocalImage = content.images.find(el => el.includes('technical')) || content.images[0];
  const modalPanelItemImg = `${imagesUrl}${checkTechnocalImage}?v=${settings.frontendVersion}`;
  const modalPanelItemTitle = getItemFieldWithTranslation(content, 'name', curentLanguageId);
  const { blackTheme } = useContext(Context);

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  const classes = classNames(
    'boxes-modal__item',
    whatTheme
  );
  return (
    <div className={classes}>
      <CloseSVG onClick={() => deleteitem(idForDelete)}/>
      <img className="boxes-modal__item-img" src={`${modalPanelItemImg}`} alt={modalPanelItemTitle}/>
      <p className="boxes-modal__item-description">{modalPanelItemTitle}</p>
    </div>
  );
};

const ModalPanel = ({
  fillingContent,
  packingContent,
  toggleModal,
  clearBox,
  deleteitem,
  goodsAmount,
  goodsAmountMultiplier,
  setGoodsAmountMultiplier,
  addToBasket
}) => {


  const { staticLocalization, blackTheme, settings } = useContext(Context);
  const { general, fillingLang } = staticLocalization;
  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';
  const { curentLanguageId } = useContext(Context);
  const boxTitleNumber = `(${fillingContent.length} ${fillingLang.boxTitleNumberPreposition} ${packingContent.goodsQuantity})`;
  const packingTitle = getItemFieldWithTranslation(packingContent, 'title', curentLanguageId);
  const packingImg = `${imagesUrl}${packingContent.images}?v=${settings.frontendVersion}`;
  const packingDescription = `${packingTitle}`;

  const clearBoxHandler = (e) => {
    toggleModal(e, true);
    clearBox();
  };

  const closeHandler = (e) => {
    toggleModal(e, true);
  };

  return (
    <div className="boxes-modal__wrapper">

      <div className={`boxes-modal ${whatTheme}`}>
        <div className="boxes-modal__title-wrapper">
          <h3 className="boxes-modal__title">
            {fillingLang.boxTitle}
            <small>({fillingContent.length}
              <span>{fillingLang.boxTitleNumberPreposition}</span> {packingContent.goodsQuantity})</small>
          </h3>
          <CloseSVG onClick={closeHandler}/>
        </div>
        <div className="boxes-modal__packing-wrapper">
          <img className="boxes-modal__packing-img" src={packingImg} alt={packingTitle}/>
          <div className="boxes-modal__packing-text">
            <p className="boxes-modal__packing-description g-like-p16">
              <small>
                {fillingLang.packingType}
              </small>
              {packingDescription}
            </p>
            <div className="boxes-modal__packing-price-wrapper">
              <Counter counterValue={goodsAmountMultiplier} counterValueChanged={setGoodsAmountMultiplier}/>
              <div className="boxes-modal__packing-amount">
                {goodsAmount}
                <small>{general.currency}</small>
              </div>
            </div>
          </div>
        </div>
        <div className="boxes-modal__items">
          {
            fillingContent.map((item, index) => <ModalPanelItem key={`modal-${item.id}-${index}`} idForDelete={index}
                                                                content={item} deleteitem={deleteitem}/>)
          }
        </div>
        <div className="boxes-modal__amount">
          <span>{fillingLang.boxAmountTitle}</span>
          {goodsAmount}
          <small>{general.currency}</small>
        </div>
        <div className="boxes-modal__btns-group">
          <MainButton palette="secondary" onClick={clearBoxHandler}>
            {fillingLang.btnsClearTitle}
          </MainButton>
          <MainButton palette="primary" onClick={addToBasket}>
            <Basket/>{fillingLang.btnsAddToBasketTitle}
          </MainButton>
        </div>

      </div>
    </div>
  );

};

const BoxCells = ({ cellsNumber, filled }) => {

  const BoxCellsItem = (<BoxTile className="filled"/>);
  const BoxCellsItemEmpty = (<BoxTile className="empty"/>);

  const fullArr = Array.from(Array(cellsNumber)).map((v, i) => i >= filled ? BoxCellsItemEmpty : BoxCellsItem);
  const array = prepareArray(fullArr);

  return (
    <>
      {array.map((row, i) => {
        return (
          <div key={i}>
            {React.Children.toArray(row)}
          </div>
        );
      })}
    </>
  );

};

const prepareArray = (array) => {
  const availableNumber = [4, 5, 6, 7, 8];
  const rowsVariants = availableNumber.map(v => Math.abs(v - array.length / v));
  const bestRows = Math.min(...rowsVariants);
  const beforeRound = availableNumber.find(v => Math.abs(v - array.length / v) === bestRows);
  const bestCells = beforeRound ? Math.round(beforeRound) : 4;

  const resultArr = [];

  while (array.length > 0) {
    const arr = array.splice(0, bestCells).filter(v => v);
    resultArr.push(arr);
  }

  return resultArr;
};

const FillinBox = ({ packingContent, fillingContent, onClick, goodsAmount }) => {

  const { staticLocalization } = useContext(Context);
  const { general, fillingLang } = staticLocalization;

  return (
    <div className="filling-box">
      <h3 className="filling-box__title" onClick={onClick}>
        {fillingLang.boxTitle}
        <small>({fillingContent.length}
          <span>{fillingLang.boxTitleNumberPreposition}</span> {packingContent.goodsQuantity})</small>
      </h3>
      <div className="filling-box__cells" onClick={onClick}>
        {
          <BoxCells cellsNumber={packingContent.goodsQuantity} filled={fillingContent.length}/>
        }
      </div>
      <div className="filling-box__amount">
        <small>{fillingLang.boxAmountTitle}</small>
        {goodsAmount}
        <small>{general.currency}</small>
      </div>
      <MainButton palette="secondary" className="filling-box__btns-edit" onClick={onClick}>
        {fillingLang.btnsEditTitle}
      </MainButton>
    </div>
  );
};


function Filling({ content: packingContent, steps, setSteps }) {

  const {
    blackTheme,
    isOpenModal,
    setIsOpenModal,
    isLogined,
    changeBasketItemsLength,
    staticLocalization,
    curentLanguageId
  } = useContext(Context);
  const history = useHistory();

  const { general, fillingLang, boxesLang } = staticLocalization;

  const [showCandyDetalisation, setShowCandyDetalisation] = useState(false);
  const [showCandyDetalisationId, setShowCandyDetalisationId] = useState(null);

  const [filterLoader, setFilterLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [data, setData] = useState([]);
  const [dropdownContent, setDropdownContent] = useState(null);

  const [filters, setFilters] = useState([]);
  const [fillingContent, setFillingContent] = useState([]);
  const [goodsAmount, setGoodsAmount] = useState(0);      // amount * goodsAmountMultiplier
  const [itemGoodsAmount, setItemGoodsAmount] = useState(0); // amount for 1 set
  const [goodsAmountMultiplier, setGoodsAmountMultiplier] = useState(1);

  const boxTitleNumber = `(${fillingContent.length} з ${packingContent.goodsQuantity})`;

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const [categoryForFilter, setCategoryForFilter] = useState('');
  const [categoryForFilterItem, setCategoryForFilterItem] = useState('');
  const [sortValue, setSortValue] = useState('NEW');
  const [sortToCheapest, setSortToCheapest] = useState('');

  const [pageOffset, setPageOffset] = useState(1);
  const [initialPaginationPage, setInitialPaginationPage] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [elementsOffset] = useState(getEllementOffset(width, 25, 20, 15, 6));

  const [countOfElements, setCountOfElements] = useState(0);
  const [showPagination, setShowPagination] = useState(false);

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  // Browser back (need check mounted in main routs)
  // useEffect(() => {
  //   const prevLocation = history.location.pathname;
  //   return () => {
  //     if (history.action === 'POP') {
  //       history.push(prevLocation);
  //     }
  //   };
  // }, [history]);

  useEffect(() => {
    const _goodsAmount = fillingContent.reduce((acc, curr) => {
      return acc + +curr.price;
    }, +packingContent.price);

    setItemGoodsAmount(_goodsAmount);

    const _totalAmount = _goodsAmount * goodsAmountMultiplier;

    setGoodsAmount(_totalAmount);

  }, [packingContent, fillingContent, goodsAmountMultiplier]);

  useEffect(() => {

    const newGetFiltersForBox = new Promise((resolve, reject) => {
      getFiltersForBox().then(res => {
        resolve(res.json());
      });
    });

    const newGetGropItem = new Promise((resolve, reject) => {
      getGroupItems({
        limit: elementsOffset,
        offset: 0,
        fitlerIds: '',
        fitlerValues: '',
        sort: null,
        forBox: true,
        langId: curentLanguageId
      }).then((res) => { // Get all group items
        resolve(res.json());
      });
    });

    Promise.all([newGetFiltersForBox, newGetGropItem]).then(res => {
      const [newGetFiltersForBox, newGetGropItem] = res;

      setData(newGetGropItem.result);
      setCountOfElements(newGetGropItem.count);

      setFilters(newGetFiltersForBox);

      newGetGropItem.count <= elementsOffset ? setShowMoreBtn(false) : setShowMoreBtn(true);

      setShowPagination(newGetGropItem.result.length < newGetGropItem.count);

      setIsLoaded(true);
      setFilterLoader(true);
    });

  }, []);

  const toggleModal = (e, forcedEvent) => {
    //if ((e.target === e.currentTarget) || (forcedEvent)) // for synthetic events
    setIsOpenModal(!isOpenModal);
  };

  const addToBox = (content) => {
    if (packingContent.goodsQuantity > fillingContent.length) {
      setFillingContent([...fillingContent, content]);
    }
  };

  const clearBox = () => {
    setGoodsAmountMultiplier(1);
    setFillingContent([]);
    //scrollToTop();
  };

  const addToBasket = () => {

    if (fillingContent.length === 0) return; // Exit if no candies id box

    // quantity for same goods
    const goodsObj = {};

    // object with goods for local storage
    let goodsItemsObj = {};

    fillingContent.forEach(item => {
      const quantity = goodsObj[item.id] === undefined ? 1 : goodsObj[item.id] + 1;
      goodsObj[item.id] = quantity;

      const id = item.id;
      goodsItemsObj = {
        ...goodsItemsObj,
        [id]: item[id],
        [id]: {
          quantity: quantity,
          goods: item
        }
      };
    });

    const goodsArr = [];
    for (const key in goodsObj) {
      goodsArr.push({
        goodsId: key,
        quantity: goodsObj[key]
      });
    }

    const goodsItemsArr = [];
    for (const key in goodsItemsObj) {
      goodsItemsArr.push({
        quantity: goodsItemsObj[key].quantity,
        goods: goodsItemsObj[key].goods
      });
    }

    const goods = {
      boxId: packingContent.id,
      goods: goodsArr,
      itemEntity: {
        box: packingContent,
        goods: goodsItemsArr
      }
    };

    setIsOpenModal(false);

    addBoxedGoodsToBasket(isLogined, goods, goodsAmountMultiplier, changeBasketItemsLength, packingContent.images, itemGoodsAmount, null, () => {
      history.push('/basket');
    });

    scrollToTop();
  };

  const deleteFillingItem = (id) => {

    const _fillingContent = [...fillingContent];
    _fillingContent.splice(id, 1);

    setFillingContent(_fillingContent);
  };

  const loadDataWithFilters = (arr) => {
    setIsLoaded(false);
    const filterCategory = [];
    const filterCategoryItem = [];
    arr.map(el => {
      filterCategory.push(el.filterCategoryId);
      filterCategoryItem.push(el.filterCategoryItemId);
    });

    const fitlerIds = filterCategory.join(',');
    const fitlerValues = filterCategoryItem.join(',');

    getGroupItems({
      limit: elementsOffset,
      offset: 0,
      fitlerIds,
      fitlerValues,
      sort: sortValue,
      sortAsc: sortToCheapest,
      forBox: true,
      langId: curentLanguageId
    }).then(res => {
      return res.json();
    }).then(el => {
      setCategoryForFilter(fitlerIds);
      setCategoryForFilterItem(fitlerValues);
      setData(el.result);
      setCountOfElements(el.count);
      setShowMoreBtn(el.count > elementsOffset);
      setShowPagination(el.result.length < el.count);
      setIsLoaded(true);
    });
  };

  const sortingLogic = (val) => {

    setIsLoaded(false);
    let { value, label } = val;
    let sortAsc = false;

    const afterDataLoading = (el) => {
      setData(el.result);
      setIsLoaded(true);
      setShowMoreBtn(el.result.length < el.count);
      setShowPagination(el.result.length < el.count);
    };

    if (value === 'PRICE UP' || value === 'NAME DOWN') {
      sortAsc = true;
    }

    if (value === 'PRICE DOWN' || value === 'PRICE UP') {
      value = 'PRICE';
    }

    if (value === 'NAME DOWN' || value === 'NAME UP') {
      value = 'NAME';
    }

    return getGroupItems({
      limit: elementsOffset,
      offset: 0,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: value,
      sortAsc,
      forBox: true,
      langId: curentLanguageId
    }).then((res) => { // Get all group items
      return res.json();
    }).then((el) => {
      setSortValue(value);
      setSortToCheapest('');
      afterDataLoading(el);
      setPageOffset(1);
      setInitialPaginationPage(!initialPaginationPage);
    });
  };

  const paginationLogick = (page) => {
    setShowMoreBtn(false);

    const offset = elementsOffset * page;

    getGroupItems({
      limit: elementsOffset,
      offset,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: sortValue,
      sortAsc: sortToCheapest,
      forBox: true,
      langId: curentLanguageId
    }).then((res) => {
      return res.json();
    }).then((el) => {
      setShowMoreBtn(offset + el.result.length < el.count);
      setPageOffset(page + 1);
      setData(el.result);
      setCountOfElements(el.count);
    });
  };

  const onShowMoreClick = () => {
    setShowMoreLoader(true);
    const newPage = pageOffset * elementsOffset;
    getGroupItems({
      limit: elementsOffset,
      offset: newPage,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: sortValue,
      sortAsc: sortToCheapest,
      forBox: true,
      langId: curentLanguageId
    }).then((res) => {
      return res.json();
    }).then((el) => {
      const newData = [...data, ...el.result];
      setShowMoreBtn(newData.length < el.count);
      setData(newData);
      setPageOffset(pageOffset + 1);
      setShowMoreLoader(false);
    });
  };

  const goToStartHandler = () => {

    setSteps(steps => ({
      ...steps,
      step: 0
    }));
  };

  const onItemClickHandler = (content) => {
    setShowCandyDetalisationId(content.id);
    setShowCandyDetalisation(true);
  };

  const onItemBackClickHandler = () => {
    setShowCandyDetalisation(false);
    setShowCandyDetalisationId(null);
  };

  return (
    <>
      {!showCandyDetalisation && <>
        <Row>
          <Col xs={12}>
            <h3 className="boxes__subtitle">{fillingLang.subtitle}</h3>
            <p className="boxes__description">{fillingLang.subtitleDescr}</p>
          </Col>
        </Row>
        {
          width > LAPTOP && <>
            <Row>
              <Col xs={12}>
                <div className="dropdown-wrapper">
                  <MainButton palette="secondary" className="btns--prev" onClick={goToStartHandler}>
                    <PreviousArrowSVG/>
                    <span>{boxesLang.btnsBack}</span>
                  </MainButton>
                  <Dropdown isSearchable={false} onChange={(val) => sortingLogic(val)}/>
                </div>
              </Col>
            </Row>
          </>
        }
        {
          width <= LAPTOP && <>
            <Row>
              <Col xs={12}>
                <div className="dropdown-wrapper">
                  <MainButton palette="secondary" className="btns--prev" onClick={goToStartHandler}>
                    <PreviousArrowSVG/>
                    <span>{boxesLang.btnsBack}</span>
                  </MainButton>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className={`boxes__mobile-menu ${whatTheme}`}>
                  <h3 className="boxes__box-title">
                    {fillingLang.boxTitle}
                    <small>({fillingContent.length}
                      <span>{fillingLang.boxTitleNumberPreposition}</span> {packingContent.goodsQuantity})</small>
                  </h3>
                </div>
              </Col>
            </Row>
          </>
        }
        <div className="boxes__grid-wrapper">
          <div className="boxes__grid">
            <div className="boxes__aside">
              <div className="boxes__aside-inner">
                <FillinBox
                  packingContent={packingContent}
                  fillingContent={fillingContent}
                  onClick={toggleModal}
                  goodsAmount={goodsAmount}
                />
                <MainButton palette="primary" className="boxes__btns-to-basket mob-hide" onClick={() => addToBasket()}>
                  <Basket/>{fillingLang.btnsAddToBasketTitle}
                </MainButton>
                {
                  filterLoader && <Filter classNameCustom="mob-hide" data={filters} loadDataWithFilters={(arr) => {
                    loadDataWithFilters(arr);
                  }} filterBtnText={fillingLang.filterBtnText}/>
                }
              </div>
            </div>
            <div className="boxes__filling">

              {
                isLoaded
                  ?
                  <>
                    <div className="main-item-wrapper">
                      {data.map((el) => <MainItem
                          key={`goods-${el.id}`}
                          onItemClick={onItemClickHandler}
                          withCounter={true}
                          hideCount={true}
                          buttonTitle={fillingLang.mainItemButtonTitle}
                          onClick={addToBox}
                          item={el}
                          // for new page link
                          // buttonLink='/product/'
                          // target='_blank'
                          // relAttr='noopener noreferrer'
                        />
                      )}
                    </div>
                    {showMoreLoader && <Loader/>}
                  </>
                  :
                  <Loader/>
              }

            </div>
          </div>
          {
            (width > LAPTOP && showPagination) && <>
              <Row>
                <Col xs={12}>
                  {showMoreBtn && <>
                    <MainButton palette="primary" className="boxes__btns-more boxes__btns-more--desktop btns--showmore"
                                onClick={onShowMoreClick}>
                      {boxesLang.showMoreBtnText}
                    </MainButton>
                  </>
                  }
                  <Pagination
                    elementCount={countOfElements}
                    elementLimit={elementsOffset}
                    returnPageOffset={paginationLogick}
                    initialOffset={initialPaginationPage}
                  />
                </Col>
              </Row>
            </>
          }

          {
            (width <= LAPTOP && showMoreBtn) && <>
              <Row>
                <Col xs={12}>
                  <MainButton palette="secondary" className="boxes__btns-more boxes__btns-more--mobile"
                              onClick={onShowMoreClick}>
                    {boxesLang.showMoreBtnText}
                  </MainButton>
                </Col>
              </Row>
            </>
          }

          {
            (width <= LAPTOP) && <>
              <div className="boxes__mobile-menu-items">
                <MainButton palette="secondary" className="boxes__mobile-menu-btns-edit" onClick={toggleModal}>
                  {fillingLang.btnsEditTitle}
                </MainButton>
                <MainButton palette="primary" className="boxes__mobile-menu-btns-to-basket" onClick={addToBasket}>
                  {fillingLang.btnsAddToBasketTitle}
                </MainButton>
              </div>
            </>
          }
        </div>


        {isOpenModal && <>
          <Modal close={toggleModal}>
            <ModalPanel
              packingContent={packingContent}
              fillingContent={fillingContent}
              toggleModal={toggleModal}
              clearBox={clearBox}
              deleteitem={deleteFillingItem}
              goodsAmount={goodsAmount}
              goodsAmountMultiplier={goodsAmountMultiplier}
              setGoodsAmountMultiplier={setGoodsAmountMultiplier}
              addToBasket={addToBasket}
            />
          </Modal>
        </>
        }
      </>}
      {(showCandyDetalisation || showCandyDetalisationId !== null) && <>
        <SingleCandyPage onClick={addToBox}
                         onItemBackClick={onItemBackClickHandler}
                         otherContent="fillingBox"
                         buttonTitle={fillingLang.mainItemButtonTitle}/>

      </>
      }
    </>
  );
}

export default Filling;
