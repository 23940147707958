import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context } from '../../../context';

import { ReactComponent as BelovedIcon } from './../../../assets/icons/beloved.svg';

const Beloved = ({ closeMobileMenu, setHardCloseSerchBar }) => {

  const { isLogined } = useContext(Context);


  const linkUrl = isLogined ? '/profile/liked' : '/profile';

  return(
    <div className='header-button-group__item beloved'>
      <Link to={linkUrl} onClick={() => {closeMobileMenu(); setHardCloseSerchBar();}}>
      <BelovedIcon />
      </Link>
    </div>
  );
};

Beloved.propTypes = {
  closeMobileMenu: PropTypes.func
};

export default Beloved;