import React, { useState } from 'react';
import './ShowPassword.scss';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';

const ShowPassword = ({ field, form, ...props }) => {

  const [whetherToShowPassword, setWhetherToShowPassword] = useState(false);

  const hasError = form.touched[field.name] && form.errors[field.name];
  
  return (
    <div className='show-password'>
      <input
        type={whetherToShowPassword ? 'text' : 'password'}
        placeholder={props.placeholder}
        {...field}
      />
      <button type='button' tabIndex={-1} onClick={() => setWhetherToShowPassword(!whetherToShowPassword)}>
        <EyeIcon />
      </button>
    </div>
  );
};

export default ShowPassword;