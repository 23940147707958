import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defaultItemProps } from '../../utils/defaultItemProps';
import { imagesUrl, methodForDeletingOneItemFromBasket } from '../../utils/api';
import { Context } from '../../context';
import {
  modifidedItemWithTranslationField,
  getTranslation,
  getCategoryName
} from '../../utils/renderFieldWithTransition';
import { changeItemCounterValueInBasket, getImages, getItemCategories, getName, } from '../../utils/basketItems';
import { getGeneralPrice, getPrice } from '../../utils/getGeneralPrice';

import Modal from '../../components/Modal/Modal';
import Counter from '../../components/Counter/Counter';
import MainButton from '../../components/MainButton/MainButton';
import BasketBoxesDetail from '../BasketItem/BasketBoxesDetail';
import BasketIndividualDetail from '../BasketItem/BasketIndividualDetail';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-btn.svg';
import { TYPES } from '../../constants/app';
import ConfirmDeleteItem from '../BasketItem/ConfirmDeleteItem';

const OrderBascketItem = ({
  item,
  setIsLoaded,
  setBasketData,
  setOrderBasketIsEmpty,
  buyPrice,
  handleChangeGeneralPrice,
  notRegisterCustomerId,
  isOrder,
  orderItemsCallback
}) => {
  const { quantity, id, type } = item;

  const [data, setData] = useState(item);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [counter, setCounter] = useState(quantity);
  // const [allBasketItems, setAllBasketItems] = useState([]);
  const [detailToShow, setDetailToShow] = useState(null);
  const [_item, _setItem] = useState(item);
  const {
    settings,
    isLogined,
    blackTheme,
    changeGeneralPrice,
    basketItemsLength,
    changeBasketItemsLength,
    staticLocalization,
    curentLanguageId,
    isOpenModal,
    setIsOpenModal,
    ...getCategories
  } = useContext(Context);
  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';
  const entityId = item?.itemEntity?.id || id;

  useEffect(() => {
    // const allBasketItems = isLogined || notRegisterCustomerId > 0 ? item.itemEntity : JSON.parse(localStorage.getItem('lmsh-basket'));
    // setAllBasketItems(allBasketItems);
    const newData = modifidedItemWithTranslationField(_item);
    setData(newData);

  }, []);

  useEffect(() => {
    _setItem(isLogined || notRegisterCustomerId > 0 ? item.itemEntity : item);
  }, [isLogined]);

  const category = () => {
    try {
      return getCategoryName(_item.category, getCategories.categories, curentLanguageId);
    } catch (err) {
      return 'wert';
    }
  };
  const images = getImages({ item: _item, type, isLoggedIn: isLogined, notRegisterCustomerId });
  const price = getPrice(_item, type, isLogined, notRegisterCustomerId);

  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(name());
  }, [curentLanguageId]);

  const name = () => {

    switch (item.type) {
    case 'GOODS':
      return getTranslation(_item, _item, 'name', curentLanguageId);
    case 'BOX_WITH_GOODS':
      return `${staticLocalization.boxesLang.titleForBasket}`;
    case 'INDIVIDUAL_ORDER':
      return `${staticLocalization.individualLang.titleForBasket}`;
    case 'BOX':
      return getTranslation(_item, _item, 'name', curentLanguageId);
    default:
      return '';
    }
  };

  useEffect(() => {
    if (!isOrder) {
      orderItemsCallback({ [entityId]: { price: (+counter * +price), type: type } });
    }
  }, [counter, price]);

  const counterValueChanged = (val, isIncrement) => {
    if (!(counter === 1 && val === 1 && !isIncrement)) {
      handleChangeGeneralPrice(price, isIncrement);
      changeItemCounterValueInBasket(isLogined, item, val, notRegisterCustomerId, notRegisterCustomerId > 0 ? item.itemEntity.id : null);
      setCounter(val);
    }
  };

  const deleteGoodFromBasket = () => {
    setIsModalOpen(false);
    const getItemLocalId = () => {
      if (notRegisterCustomerId > 0) {
        if (item.type === 'GOODS' || item.type === 'BOX') {
          return item.itemEntity.id;
        }
        // eslint-disable-next-line no-else-return
        else {
          const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));
          if (item.type === 'BOX_WITH_GOODS') {
            const allItems = localBasket.filter(el => el.type === item.type);
            const allItemsFiltredByBox = allItems.filter(el => el.boxId === item.itemEntity.box.id);
            const allItemsFiltredByQuintity = allItemsFiltredByBox.filter(el => el.quantity === item.quantity);
            const allItemsFiltredByGoods = allItemsFiltredByQuintity.filter(el => el.goods.length === item.itemEntity.goods.length);
            return allItemsFiltredByGoods[0].id;
          }
          const allItems = localBasket.filter(el => el.type === item.type);
          const filtredItem = allItems.filter(
            el =>
              el.categoryId === item.itemEntity.category.id &&
              el.chocolateTypeId === item.itemEntity.chocolateType.id &&
              el.shapeId === item.itemEntity.shape.id &&
              el.additionIds ? el.additionIds.length === item.itemEntity.additions.length : !item.itemEntity.additions.length &&
              el.postcardId ? el.postcardId === item.itemEntity.postcard.id : item.itemEntity.postcard.length === 0 &&
              el.boxId ? el.boxId === item.itemEntity.box.id : !item.itemEntity.box &&
              el.inscriptionId ? el.inscriptionId === item.itemEntity.inscription.id : !item.itemEntity.inscription.length
          );
          return filtredItem[0].id;
        }

      }
    };

    methodForDeletingOneItemFromBasket(
      isLogined,
      item.id,
      setIsLoaded,
      setBasketData,
      getGeneralPrice,
      changeGeneralPrice,
      changeBasketItemsLength,
      notRegisterCustomerId,
      getItemLocalId()
    );
    setOrderBasketIsEmpty((basketItemsLength - 1) === 0);
    orderItemsCallback({ [entityId]: false });

    const orderItemDel = JSON.parse(localStorage.getItem('orderItemDel'));
    let array = [];

    if (orderItemDel) {
      array = orderItemDel;
    }

    const id = isLogined ? entityId : item.id;
    array.push({ id });
    localStorage.setItem('orderItemDel', JSON.stringify(array));
  };

  const itemClassNames = classNames(
    'order-bascket__item',
    blackTheme ? 'black-theme' : 'light-theme',
    (item.type === TYPES.BOX_WITH_GOODS || item.type === TYPES.INDIVIDUAL_ORDER) ? 'no-bottom-border' : 'with-bottom-border'
  );

  const getOrder = () => {
    if (item.type === 'GOODS' || item.type === 'BOX') {
      return 2;
    }
    return 1;
  };

  const toggleModal = (e, forcedEvent) => {
    // if ((e.target === e.currentTarget) || (forcedEvent)) { // for synthetic events
    setIsOpenModal(!isOpenModal);
  };

  const detailToShowHandler = (itemType) => {
    setDetailToShow(itemType);
    toggleModal();
  };

  const detailCloseHandler = () => {
    setDetailToShow(null);
    toggleModal();
  };

  const handleDeleteItemFromBasket = () => {
    setIsModalOpen(true);
  };

  const Tag = (item.type === TYPES.BOX_WITH_GOODS || item.type === TYPES.INDIVIDUAL_ORDER) ? 'div' : 'span';

  return (
    <>
      <Tag className={itemClassNames} style={{ order: getOrder() }}>
        <>
          <div className='wrapper'>
            <div className='img-wrapper'>
              <img src={`${imagesUrl}${images}?v=${settings.frontendVersion}`} alt='Basket Item img'/>
            </div>
            <div className='description'>
              <p>
                <small>{category()}</small>
                {title ? title: name()}
              </p>
              {
                item.type === TYPES.BOX && !buyPrice ?
                  null
                  :
                  <Counter counterValue={counter}
                    counterValueChanged={(val, isIncrement) => counterValueChanged(val, isIncrement)}/>
              }
              {(item.type === TYPES.BOX_WITH_GOODS || item.type === TYPES.INDIVIDUAL_ORDER) && <>
                <MainButton palette='secondary' className='btns__order-basket-item'
                  onClick={() => detailToShowHandler(item.type)}>
                  {staticLocalization.order?.showDetailBtn || 'Переглянути'}
                </MainButton>
              </>
              }
            </div>
            <div className='price-and-close'>
              <button className='remove-item-btn' onClick={handleDeleteItemFromBasket}>
                <CloseIcon/>
              </button>
              <div className='price'>
                {buyPrice * counter || price * counter}
                <div className='currency'>
                  {staticLocalization.general.currency}
                </div>
              </div>
            </div>
          </div>
          {(isOpenModal && detailToShow === TYPES.BOX_WITH_GOODS) && <>
            <Modal className='align-items-start'>
              <BasketBoxesDetail
                item={item}
                onClose={detailCloseHandler}
                counter={counter}
                handleChangeGeneralPrice={handleChangeGeneralPrice}
                changeItemCounterValueInBasket={changeItemCounterValueInBasket}
                setCounter={setCounter}
                isLogined={isLogined}
                price={buyPrice || price}
                notRegisterCustomerId={notRegisterCustomerId}
                isOrder={isOrder}
              />
            </Modal>
          </>
          }
          {(isOpenModal && detailToShow === TYPES.INDIVIDUAL_ORDER) && <>
            <Modal className='align-items-start'>
              <BasketIndividualDetail
                detailCloseHandler={detailCloseHandler}
                images={images}
                category={`${staticLocalization.individualLang.titleForBasket}`}
                counter={counter}
                setCounter={setCounter}
                price={buyPrice || price}
                handleChangeGeneralPrice={handleChangeGeneralPrice}
                changeItemCounterValueInBasket={changeItemCounterValueInBasket}
                isLogined={isLogined}
                onClose={detailCloseHandler}
                item={item}
                notRegisterCustomerId={notRegisterCustomerId}
                isOrder={isOrder}
              />
            </Modal>
          </>
          }
        </>
      </Tag>
      {isModalOpen && <Modal className='align-items-center'>
        <ConfirmDeleteItem setIsModalOpen={setIsModalOpen} deleteGoodFromBasket={deleteGoodFromBasket}/>
      </Modal>}
    </>
  );
};

OrderBascketItem.propTypes = {
  item: PropTypes.object,
  setIsLoaded: PropTypes.func,
  setBasketData: PropTypes.func,
  setOrderBasketIsEmpty: PropTypes.func,
  buyPrice: PropTypes.any,
  handleChangeGeneralPrice: PropTypes.func,
  orderItemsCallback: PropTypes.func,
  notRegisterCustomerId: PropTypes.number
};

OrderBascketItem.defaultProps = {
  data: defaultItemProps
};

export default OrderBascketItem;
