import React, { useState, useContext } from 'react';
import './LocalForm.scss';
import classNames from 'classnames';
import { Context } from '../../../context';
import PropTypes from 'prop-types';
import MainButton from '../../../components/MainButton/MainButton';
import Loader from '../../../components/Loader/Loader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { lokalLoginOrRegister } from '../../../utils/api';
import PhoneMask from '../TelephoneWithMask/PhoneMask';
import CaptchaWidget from '../Captcha/CaptchaWidget';

const LocalForm = ({
  type,
  formLocation,
  useLocalCard,
  setShowLoginedLocalForm,
  setErrorQuintity,
  setShowLocalNumberConfirmationPopup,
  setCustomerPhoneNumber
}) => {

  const [showGeneralError, setShowGeneralError] = useState(false);
  const [showBackendError, setShowBackendError] = useState(null);
  const [formIsSent, setFormIsSent] = useState(false);
  const [isCaptchaValid, setCaptchaValid] = useState(false);

  const { blackTheme, staticLocalization, isLogined, userData } = useContext(Context);
  const { localForm, phoneVerificationForm } = staticLocalization;

  const localFormClassNames = classNames(
    'local-card-form',
    'g-form-style',
    'login-form',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const FormIfPersonHaveLocalCadSchema = Yup.object().shape(
    {
      telephone: Yup.string()
        .required(localForm.telephoneError)
        .matches(/^\+(?:[0-9] ?){11,14}[0-9]$/, 'Номер повинен бути формату +380#########'),
      captchaToken: Yup.string().required('Captcha is required'),
    }
  );

  return (
    <Formik
      initialValues={{
        telephone: isLogined ? userData.phone : '',
        captchaToken: '',
      }}
      validationSchema={useLocalCard ? null : FormIfPersonHaveLocalCadSchema}
      onSubmit={(values) => {
        let { telephone } = values;
        telephone = telephone.replace(/ /g, '');

        setFormIsSent(true);
        setShowGeneralError(false);
        setShowBackendError(null);

        lokalLoginOrRegister(telephone,0, values.captchaToken, formLocation, isLogined).then(res => {
          if (res.ok) {
            res.json().then(data => {
              if (data.nextRequestType === 'ANONYMOUS_USER_WITHOUT_CARD') {
                setFormIsSent(false);
                setShowBackendError('Даний номер не зареєстрований в системі.');
                return;
              }

              setCustomerPhoneNumber(telephone);
              setFormIsSent(false);
              setShowLocalNumberConfirmationPopup(true);

              // If the form is on the local login page,
              // then we save the next request type in local storage
              if (type === 'LOCAL_LOGIN_PAGE') {
                sessionStorage.setItem(
                  'lokal-login-or-registration',
                  JSON.stringify({ nextRequestType: data.nextRequestType })
                );
              }

            });
          } else {
            res.json().then(data => {
              setFormIsSent(false);
              setShowBackendError(data.message);
              // setShowGeneralError(true);
              // setErrorQuintity();
            });
          }
        });
      }}
    >
      {({ errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur, values }) => (
        <Form className={localFormClassNames}>

          <div className='inputs-wrapper'>
            <label>
              <p className='required'>{localForm.telephone}</p>
              <PhoneMask handleChange={handleChange}
                handleBlur={handleBlur}
                placeholder={localForm.telephonePlaceholder}
                value={values.telephone}
                readOnly={isLogined}>
              </PhoneMask>
              {errors.telephone ? ( // Error
                <div className='error'>{errors.telephone}</div>
              ) : null}
            </label>
            {
              showGeneralError ?
                <div className='error general-error'>{localForm.generalError}</div>
                :
                null
            }
            {
              showBackendError ?
                <div className='error general-error'>{showBackendError}</div>
                :
                null
            }
            {formIsSent ? <div className='form-spiner'><Loader /></div> : null}

            <CaptchaWidget onCaptchaChange={setCaptchaValid} />
          </div>

          <MainButton type='submit'
            onMouseDown={handleSubmit}
            disabled={!isCaptchaValid}>
            {type === 'LOCAL_LOGIN_PAGE' ? phoneVerificationForm.btnText : localForm.logIn}
          </MainButton>
        </Form>
      )}
    </Formik>
  );
};

LocalForm.propTypes = {
  useLocalCard: PropTypes.bool,
  setShowLoginedLocalForm: PropTypes.func,
  setShowLocalNumberConfirmationPopup: PropTypes.func,
  setCustomerPhoneNumber: PropTypes.func,
  setErrorQuintity: PropTypes.func,
  setShowBackendError: PropTypes.func,
  type: PropTypes.string,
  formLocation: PropTypes.string
};

export default LocalForm;
