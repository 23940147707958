import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Context } from '../../context';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import LocalNumberConfirmationPopup
from '../../components/Forms/LocalNumberConfirmationPopup/LocalNumberConfirmationPopup';
import Loader from '../../components/Loader/Loader';

import { ReactComponent as PrevIcon } from './../../assets/icons/previous-arrow.svg';
import { useLocation } from 'react-router-dom';
import './Profile.scss';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { scrollToTop } from '../../utils/scrollToTop';

import { lokalGetBalance, localLogOut } from '../../utils/api';
import LocalForm from '../../components/Forms/LocalForm/LocalForm';

function ProfileLocal({ history }) {

  const [userHaveLocalCard, setUserHaveLocalCard] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [bofonQuintity, setBofonQuintity] = useState(0);
  const [cardNumberChanged, setCardNumberChanged] = useState(false);
  const [cardNumber, setCardNumber] = useState(0);

  const [showLocalNumberConfirmationPopup, setShowLocalNumberConfirmationPopup] = useState(false);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(0);

  const { isLogined, blackTheme, staticLocalization, userData, setDataAboutUser, setBodyUnscroll } = useContext(Context);
  const { personalOffice, personalOfficeLocalCard } = staticLocalization;

  const title = personalOffice.mainTitle;
  const subtitle = personalOfficeLocalCard.mainTitle;
  const backButtonTitle = personalOffice.backButtonTitle;

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [localCardNumber, setLocalCardNumber] = useState(0);

  useEffect(() => {

    scrollToTop();

    if (!isLogined) {
      history.push('/profile');
    }

    if (showMenu !== false) {
      setShowMenu(false);
    }

    if (userData.localCard) {
      lokalGetBalance().then(res => {
        if (res.ok) {
          res.json().then(el => {
            setUserHaveLocalCard(true);
            setBofonQuintity(el);
            setCardNumber(userData.localCard);
            setIsLoaded(true);
          });
        } else {
          setUserHaveLocalCard(false);
        }
      });
    } else {
      setIsLoaded(true);
    }
  }, [location.key]);

  useEffect(() => {
    if (userData.localCard && cardNumberChanged) {
      lokalGetBalance().then(res => {
        if (res.ok) {
          res.json().then(el => {
            setBofonQuintity(el);
            setUserHaveLocalCard(true);
            setBodyUnscroll(false);
            setShowLocalNumberConfirmationPopup(false);
          });
        } else {
          setUserHaveLocalCard(false);
        }
      });
    }
  }, [cardNumberChanged]);


  const showMenuHandler = () => {
    setShowMenu(true);
  };

  const сlassNamess = ClassNames(
    'data-about-local-card',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const handleLocalLogOut = () => {
    console.log('handleLocalLogOut');
    localLogOut();

    sessionStorage.clear();

    setUserHaveLocalCard(false);

    userData.localCard = null;
    setDataAboutUser(userData);
  };

  const handleLocalLogIn = () => {
    setUserHaveLocalCard(true);
  };

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='profile-local'>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {title}
            </MainTitle>
          </Col>
        </Row>
        {width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous' onClick={showMenuHandler}>
                <PrevIcon className='profile__icon-previous' />
                <span>{backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12}>
            {((width <= LAPTOP && !showMenu) || width > LAPTOP) && <>
              <h3 className='profile__subtitle'>{subtitle}</h3>
            </>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='profile-local__wrapper'>
              {((width <= LAPTOP && showMenu) || width > LAPTOP) && <>
                <div className='profile__menu-wrapper'>
                  <ProfileMenu />
                </div>
              </>
              }
              {((width <= LAPTOP && !showMenu) || width > LAPTOP) && <>
                {
                  isLoaded ?
                    <div className='profile-local__form-wrapper local-card'>
                      {
                        userHaveLocalCard ?
                          <>
                            <div className={сlassNamess}>
                              <p>
                                <small>{personalOfficeLocalCard.localCardNumber}</small>
                                {cardNumber}
                              </p>
                              <p>
                                <small>{personalOfficeLocalCard.balance}</small>
                                {bofonQuintity}
                              </p>

                            </div>
                            <MainButton className='change-local-card' onClick={() => handleLocalLogOut()}
                              palette='secondary'>
                              {personalOfficeLocalCard.attachAnotherCard}
                            </MainButton>
                          </>
                          :
                          <LocalForm
                            type='LOCAL_LOGIN_PAGE'
                            formLocation='PROFILE'
                            setShowLocalNumberConfirmationPopup={(val) => setShowLocalNumberConfirmationPopup(val)}
                            showVerificationPopup={() => setShowLocalNumberConfirmationPopup(true)}
                            setCustomerPhoneNumber={number => setCustomerPhoneNumber(number)}
                          />
                      }
                    </div>
                    :
                    <Loader />
                }
              </>
              }

            </div>
          </Col>
        </Row>
        {
          showLocalNumberConfirmationPopup ?
            <LocalNumberConfirmationPopup
              type='LOCAL_LOGIN_PAGE'
              formLocation='PROFILE'
              hideVerificationPopup={() => setShowLocalNumberConfirmationPopup(false)}
              customerPhoneNumber={customerPhoneNumber}
              setLocalCardNumber={(number) => setLocalCardNumber(number)}
              setCardNumberChanged={(val) => setCardNumberChanged(val)}
              setUserHaveLocalCard={(val) => setUserHaveLocalCard(val)}
            />
            :
            null
        }
      </Container>
    </>
  );
}

ProfileLocal.propTypes = {
  history: PropTypes.object
};

export default withRouter(ProfileLocal);
