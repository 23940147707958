const url = new URL(window.location);

export const regexForGettingId = (history) => {
  if (history.location.search) {
    return new URLSearchParams(history.location.search).get('id');
  }
  return new URLSearchParams(history.location.state).get('id');

};
export const regexForGettingCategoryId = () => url.searchParams.get('categoryId');
export const regexForGettingAccessToken = () => url.searchParams.get('access_token') || null;

export const getParam = (param) => {
  return url.searchParams.get(param) ? url.searchParams.get(param).split(',') : false;
};
