import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context } from '../../../context';
import { useWindowSize } from '../../../utils/useWindowSizeHook';

import MainButton from '../../MainButton/MainButton';
import PersonalMenu from '../PersonalMenu/PersonalMenu';

import { ReactComponent as PersonalIcon } from './../../../assets/icons/personal.svg';

const PersonalOffise = ({ closeMobileMenu, setHardCloseSerchBar }) => {

  const WIDTH_TO_MOBILE = 1200;
  const { width } = useWindowSize();
  const { isLogined, staticLocalization } = useContext(Context);
  const { header } = staticLocalization;
  
  const renderButton = () => {
    if(isLogined || width < WIDTH_TO_MOBILE){
      return(
        <Link to={isLogined ? '/profile/personal' : '/profile'} className='logined' onClick={() => {closeMobileMenu(); setHardCloseSerchBar();}}>
          <PersonalIcon />
        </Link>
      );
    }
    return(
      <MainButton href='/profile' palette='secondary' onClick={() => setHardCloseSerchBar()}>
        <PersonalIcon />
        {header.login}
      </MainButton>
    );
  };
  return(
    <div className='header-button-group__item personal-offise'>
      {renderButton()}
      { isLogined && <PersonalMenu /> }
    </div>
  );
};

PersonalOffise.propTypes = {
  closeMobileMenu: PropTypes.func
};

export default PersonalOffise;
