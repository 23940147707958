import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Context } from '../../context';
import { getCategoryGroupItems, imagesUrl } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';

const NavigationLink = ({ item, openMobileMenu, setShowShadow, showShadow }) => {
  const [data, setData] = useState([]);
  const [categoryGroupLoaded, setCategoryGroupLoaded] = useState(false);
  const [submenu, setSubmenu] = useState([]);
  const { settings, blackTheme, curentLanguageId } = useContext(Context);
  const name = getTranslation(item, data, 'name', curentLanguageId);
  const { type, id, href, itemRef } = item;
  const submenuClasses = classNames(
    'submenu',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setData(newData);
    if (type === 'CATEGORY_GROUP') {
      getCategoryGroupItems(itemRef).then(res => {
        return res.json();
      }).then(el => {
        setSubmenu(el.result);
        setCategoryGroupLoaded(true);
      });
    }
  }, []);

  const link = () => {
    if (type === 'CATEGORY_GROUP') {
      const slug = item.reference.slug;
      return `/category-group/${slug}`;
    } else if (type === 'INTERNAL' || type === 'EXTERNAL') {
      return `/${href}`;
    } else if (type === 'CATEGORY') {
      const slug = item.reference.slug;
      return `/category/${slug}`;
    }
  };

  return (
    <>
      <Link
        key={id}
        to={link()}
        target={type === 'EXTERNAL' ? '_blank' : null}
        onClick={() => openMobileMenu()}
      >
        {name}
      </Link>
      {
        type === 'CATEGORY_GROUP' && categoryGroupLoaded ?
          <ul className={submenuClasses}>
            {showShadow && submenu.map(el => {
              const { images, slug, id } = el;
              const newData = modifidedItemWithTranslationField(el);
              const name = getTranslation(el, newData, 'name', curentLanguageId);
              return (
                <li key={id}>
                  <Link to={`/category/${slug}`} onClick={() => setShowShadow(false)}>
                    <img src={`${imagesUrl}${images[0]}?v=${settings.frontendVersion}`} alt='submenu item img'/>
                    <div className='title'>
                      <p>{name}</p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
          :
          null
      }
    </>
  );
};

NavigationLink.propTypes = {
  item: PropTypes.object,
  openMobileMenu: PropTypes.func
};

export default NavigationLink;
