import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainButton from '../MainButton/MainButton';

import { useWindowSize } from '../../utils/useWindowSizeHook';

import { imagesUrl } from '../../utils/api';

import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';
import { Context } from '../../context';

function CollectionGroupItem({ item, buttonTitle }) {

  const { settings, curentLanguageId } = useContext(Context);
  const [ data, setData ] = useState(item);

  const MOBILE_WIDTH = 575;

  const title = getTranslation(item, data, 'title', curentLanguageId);
  const description = getTranslation(item, data, 'description', curentLanguageId);

  const { width } = useWindowSize();

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setData(newData);
  }, []);

  return (
    <div className='collection-item'>
      <div className='collection-item__text'>
        <h2 className='collection-item__title'>{title}</h2>
        { description &&
          <p className='collection-item__description'>{description}</p>
        }
        { width > MOBILE_WIDTH &&
          <div className='collection-item__button'>
            <MainButton
              href={`/collections/${item.slug}`}
              className='btns--main'
            >
              {buttonTitle}
            </MainButton>
          </div>
        }
      </div>
      <div className='collection-item__img'>
        <img className='img-fluid' src={`${imagesUrl}${item.images}?v=${settings.frontendVersion}`} alt={title}/>
      </div>
      { width <= MOBILE_WIDTH &&
          <div className='collection-item__button'>
            <MainButton
              className='btns--main'
              href={`/collections/${item.slug}`}
            >
              {buttonTitle}
            </MainButton>
          </div>
      }
    </div>
  );
}

export default CollectionGroupItem;