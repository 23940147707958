import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../context';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import YourData from '../../components/Forms/YourData/YourData';
import ChangePassword from '../../components/Forms/ChangePassword/ChangePassword';
import { ReactComponent as PrevIcon } from './../../assets/icons/previous-arrow.svg';
import { useLocation } from 'react-router-dom';
import './Profile.scss';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { scrollToTop } from '../../utils/scrollToTop';

function ProfilePersonal({ history }) {

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);

  const { userData, isLogined, staticLocalization, changeLoginationStatus, changeBasketItemsLength } = useContext(Context);
  const { personalOffice, personalOfficePersonalData } = staticLocalization;

  useEffect(() => {

    scrollToTop();

    if(!isLogined){
      history.push('/profile');
    }

    if (showMenu !== false) {
      setShowMenu(false);
    }


  }, [location.key]);

  const showMenuHandler = ()=> {
    setShowMenu(true);
  };

  const  accauntQuitHandler = () => {
    localStorage.removeItem('lmsh-token');
    sessionStorage.removeItem('lmsh-token');
    history.push('/');
    changeLoginationStatus(false);
    changeBasketItemsLength(0);
    console.log('accauntQuitHandler', userData);
  };

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='profile-personal'>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {personalOffice.mainTitle}
            </MainTitle>
          </Col>
        </Row>
        { width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous' onClick={showMenuHandler}>
                <PrevIcon className='profile__icon-previous'/>
                <span>{personalOffice.backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12} >
            { ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  && <>
              <h3 className='profile__subtitle'>{personalOfficePersonalData.yourData}</h3>
            </>
            }
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className='profile-personal__wrapper'>

              { ((width <= LAPTOP && showMenu ) || width > LAPTOP) && <>
                <div className='profile__menu-wrapper'>
                  <ProfileMenu />
                </div>
              </>
              }
              { ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  && <>
                <div className='profile-personal__content-wrapper'>
                  <YourData />
                  {(userData.networkType === 'REGULAR') && <>
                    <h3 className='profile__subtitle'>
                      {personalOfficePersonalData.changePassword}
                    </h3>
                    <ChangePassword isProfile={true}/>
                  </>}
                  { width <= LAPTOP && <>
                    <MainButton palette='secondary' className='profile__btns-quit' onClick={accauntQuitHandler}>
                      {personalOffice.accountQuitButtonTitle}
                    </MainButton>
                  </>
                  }
                </div>
              </>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>

  );
}

ProfilePersonal.proTypes = {
  history: PropTypes.object
};

export default withRouter(ProfilePersonal);
