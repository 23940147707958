import React, { useState, useContext } from 'react';
import './RecoverPassword.scss';
import PropTypes from 'prop-types';
import { Context } from '../../../context';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { resetPassword } from '../../../utils/api';

import MainBatton from '../../MainButton/MainButton';
import Loader from '../../Loader/Loader';

import { ReactComponent as CloseIcon } from '../../../assets/icons/modal-close-icon.svg';
import { ReactComponent as CloseIconLight } from '../../../assets/icons/modal-close-icon--light.svg';

const RecoverPassword = ({ showVerifyForm, showRecoverPasswordForm }) => {

  const [formIsSent, setFormIsSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { blackTheme, staticLocalization, setBodyUnscroll } = useContext(Context);
  const { recoverPassword } = staticLocalization;

  const recoverPasswordSchema = Yup.object().shape({
    telephone: Yup.string()
      .required(recoverPassword.telephoneError)
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, recoverPassword.telephoneRegexError),
  });

  const closeModal = () => {
    showRecoverPasswordForm(false);
    setBodyUnscroll(false);
  };
    
  return(
    <Formik
      initialValues={{
        telephone: '',
      }}
      validationSchema={recoverPasswordSchema}
      onSubmit={values => {

        setFormIsSent(true);
        setShowErrorMessage(false);

        resetPassword(values.telephone).then((res) => {
          setFormIsSent(false);
          if(res.ok){
            showRecoverPasswordForm(false);
            showVerifyForm(true, 'forgot-password');
          }else{
            setShowErrorMessage(true);
          }
        });
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <div className='form-wrapper'>
          <button type='button' onClick={() => closeModal()} className='close-modal'>
            { blackTheme ? <CloseIcon /> : <CloseIconLight /> }
          </button>
          <Form className='g-form-style recover-password-from'>
            <div className='title'>
              <h3>{recoverPassword.title}</h3>
              <p>{recoverPassword.subtitle}</p>
            </div>
            <label>
              <p className='required'>{recoverPassword.telephone}</p>
              <Field type='tel' name='telephone' placeholder={recoverPassword.telephonePlaceholder}/>
              <ErrorMessage name='telephone'>
                {
                  msg => <div className='error'>{msg}</div>
                }
              </ErrorMessage>
            </label>
            {   // Show loader until the form is sent
              formIsSent ? <div className='form-spiner'><Loader /></div> : null
            }
            {
              showErrorMessage ? <div className='error errorMessage'>{recoverPassword.errorMessage}</div> : null
            }
            <MainBatton type='submit' palette='primary' onMouseDown={handleSubmit}>
              {recoverPassword.btnText}
            </MainBatton>

          </Form>
        </div>
      )}
    </Formik>
  );
};

RecoverPassword.propTypes = {
  showVerifyForm: PropTypes.func,
  showRecoverPasswordForm: PropTypes.func
};

export default RecoverPassword;