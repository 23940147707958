export const customStyles = {
  indicatorsContainer: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    position: 'relative'
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'var(--header-btn-group-bg)',
    border: 'none',
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    boxShadow: 'none',
    cursor: 'pointer',
    fontSize: '1.375rem',
    letterSpacing: '0.15rem',
    fontWeight: '600',
    fontFamily: 'Berimbau',
    textTransform: 'lowercase',
    textAlign: 'center'
  }),
  placeholder: (provided) => ({
    ...provided,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginRight: 0,
    marginLeft: 0,
    color: 'var(--general-text-color)'
  }),
  singleValue: (provided) => ({
    ...provided,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginRight: 0,
    marginLeft: 0,
    color: 'var(--general-text-color)'
  }),
  input: (provided) => ({
    ...provided,
    opacity: 0
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
  option: (provided) => ({
    ...provided,
    padding: 0,
    backgroundColor: 'var(--header-btn-group-bg)',
    border: 'none',
    borderRadius: '50%',
    minWidth: '40px',
    minHeight: '40px',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--general-text-color)',
    marginBottom: '8px',
    cursor: 'pointer',
    fontSize: '1.375rem',
    letterSpacing: '0.15rem',
    fontWeight: '600',
    fontFamily: 'Berimbau',
    textTransform: 'lowercase',
    textAlign: 'center'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none'
  })
};
