import { changeBasketItemQuantity, getGoodsForBasket } from './api';
import { getCategoryName, getTranslation, modifidedItemWithTranslationField } from './renderFieldWithTransition';
import { ERROR_VALUE, TYPES } from '../constants/app';

export const changeBasketItemsQuantity = (isLogined, changeBasketItemsLength, orderUserId) => {
  try {
    if (isLogined || orderUserId > 0) {
      getGoodsForBasket(orderUserId).then(res => {
        return res.json();
      }).then(el => {
        changeBasketItemsLength(el.count);
      });
    } else {
      const basketItemsLength = JSON.parse(localStorage.getItem('lmsh-basket')).length;
      changeBasketItemsLength(basketItemsLength);
    }
  } catch (e) {
    changeBasketItemsLength(0);
  }
};

// this removes all items that are not in the basketItems array
// items ids left in an array after successful order
// why?
// I don't know :)
export function removeItemsAfterSuccessOrder(isLogined, items, basketItemsFromDb) {
  let basketItems = JSON.parse(localStorage.getItem('lmsh-basket'));
  if (false === isLogined && basketItems && basketItems.length > 0) {
    basketItems = JSON.parse(localStorage.getItem('lmsh-basket'));
  } else {
    basketItems = basketItemsFromDb;
  }

  if (basketItems && basketItems.length > 0) {
    const ids = basketItems.map(item => item.id || item);

    items = Object.keys(items)
      .filter(key => ids.includes(Number(key)))
      .reduce((result, key) => {
        result[key] = items[key];
        return result;
      }, {});
  }

  return items;
}

export const changeItemCounterValueInBasket = (isLogined, element, counterValue, notRegisterCustomerId, lokalStorageItemId) => {
  if (isLogined) {
    changeBasketItemQuantity(element.itemEntity.id, counterValue, element.type, notRegisterCustomerId);
  } else if (notRegisterCustomerId > 0) {
    changeBasketItemQuantity(element.itemEntity.id, counterValue, element.type, notRegisterCustomerId);

    const basket = localStorage.getItem('lmsh-basket');
    const parsedBasket = JSON.parse(basket);

    const newBasket = parsedBasket.map(el => {
      return el.id === lokalStorageItemId ? { ...el, quantity: counterValue } : el;
    });

    localStorage.setItem('lmsh-basket', JSON.stringify(newBasket));

  } else {
    const basket = localStorage.getItem('lmsh-basket');
    const parsedBasket = JSON.parse(basket);

    const newBasket = parsedBasket.map(el => {
      return el.id === element.id ? { ...el, quantity: counterValue } : el;
    });

    localStorage.setItem('lmsh-basket', JSON.stringify(newBasket));
  }
};

export const getItemCategories = ({ item, categories, languageId, staticLocalization, type }) => {
  if (type === TYPES.GOODS) {
    return getCategoryName(item.category, categories, languageId);
  }

  if (type === TYPES.BOX_WITH_GOODS) {
    return staticLocalization.boxesLang.titleForBasket;
  }

  if (type === TYPES.INDIVIDUAL_ORDER) {
    return staticLocalization.individualLang.mainTitle;
  }
  if (type === TYPES.BOX) {
    return getTranslation(item, modifidedItemWithTranslationField(item), 'name', languageId);
  }

  return ERROR_VALUE;
};

export const getName = ({ item, data, languageId, type, isLoggedIn }) => {

  if (type === TYPES.GOODS) {
    return getTranslation(item, modifidedItemWithTranslationField(item), 'name', languageId);
  }

  if (type === TYPES.BOX_WITH_GOODS) {
    return '';
  }

  if (type === TYPES.INDIVIDUAL_ORDER) {
    const individualItem = isLoggedIn ? item.category : item;
    const modifiedData = modifidedItemWithTranslationField(individualItem);
    return getTranslation(individualItem, modifiedData, 'name', languageId);
  }

  if (type === TYPES.BOX) {
    return '';
  }

  return ERROR_VALUE;
};

export const getImages = ({ item, isLoggedIn, type, notRegisterCustomerId }) => {
  if (isLoggedIn || notRegisterCustomerId > 0) {
    try {
      if (type === TYPES.GOODS || type === TYPES.BOX) {
        if (item?.images?.length > 1) {
          const img = item.images.find(el => el.includes('technical'));
          return img ? img : item.images[0];
        }
        return item.images;
      }

      if (type === TYPES.BOX_WITH_GOODS) {
        return item.box.images;
      }

      if (type === TYPES.INDIVIDUAL_ORDER) {
        return item.category.images;
      }
    } catch (err) {
      console.log(err);
    }
  } else {
    if (Object.keys(TYPES).indexOf(item.type) > -1) {

      if (item.images.length > 1) {
        const img = item.images.find(el => el.includes('technical'));
        return img ? img : item.images[0];
      }
      return item.images;
    }
  }

  return ERROR_VALUE;
};