import React, { useState, useEffect, useContext } from 'react';
import './Filter.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from '../../components/Loader/Loader';
import Checkbox from '../CheckBox/CheckBox';
import MenuMask from '../MenuMask/MenuMask';
import MainButton from '../MainButton/MainButton';
import { ReactComponent as FilterArrow } from './../../assets/icons/filter-arow.svg';
import { Context } from '../../context';

import {
  getTranslation,
  getCategoryName,
  modifidedItemWithTranslationField
} from '../../utils/renderFieldWithTransition';

const OPTIONS_STRING_MULTILANGUAGE = 'OPTIONS_STRING_MULTILANGUAGE';

const FilterCategoryItem = ({ item, type, filterCategoryId }) => {
  const { curentLanguageId } = useContext(Context);

  const query = new URLSearchParams(window.location.search);
  const splitFitlerIds = query.get('fitlerIds') ? query.get('fitlerIds').split(',') : false;
  const splitFitlerValues = query.get('fitlerValues') ? query.get('fitlerValues').split(',') : false;
  const array = [];

  if (splitFitlerIds) {
    splitFitlerIds.map((el, index) => {
      array.push({
        filterCategoryId: Number(splitFitlerIds),
        filterCategoryItemId: Number(splitFitlerValues[index])
      });
    });
  }

  const _checked = array.length > 0 ? array.find(el => el.filterCategoryItemId === item.id) : false;

  const translatedObj = type === OPTIONS_STRING_MULTILANGUAGE ? modifidedItemWithTranslationField(item) : null;
  const name = type === OPTIONS_STRING_MULTILANGUAGE ? getTranslation(item, translatedObj, 'translatableValue', curentLanguageId) : item.value;

  return (
    <div className='filter-item'>
      <Checkbox name={filterCategoryId.toString()} value={String(item.id)} checked={_checked ? true : false}>
        <div className='filter-item__content'>
          <span>{name}</span>
          <span>{item.goodsCount}</span>
        </div>
      </Checkbox>
    </div>
  );
};

const FilterCategory = ({ item }) => {
  const { curentLanguageId } = useContext(Context);
  const [show, setShow] = useState(false);
  const translatedObj = modifidedItemWithTranslationField(item);
  let checked = null;

  const query = new URLSearchParams(window.location.search);
  const splitFitlerIds = query.get('fitlerIds') ? query.get('fitlerIds').split(',') : false;
  const splitFitlerValues = query.get('fitlerValues') ? query.get('fitlerValues').split(',') : false;

  if (splitFitlerIds) {
    splitFitlerIds.map(el => {
      if (el == item.id) {
        checked = true;
      }
    });
  }

  const list = classNames(
    'filter-category__list',
    { 'open': checked ?? show }
  );

  const arrow = classNames(
    'filter-category__arrow',
    { 'open': show }
  );

  const titleCliclHandler = () => {
    setShow(!show);
  };

  // DATA:
  const name = getTranslation(item, translatedObj, 'name', curentLanguageId);
  const option = item.options;

  return (
    <div className='filter-category'>
      <div className='filter-category__title' onClick={titleCliclHandler}>
        <MenuMask/>
        <div className='filter-category__wrapper'>
          <div className='filter-category__name'>
            {name}
          </div>
          <FilterArrow className={arrow}/>
        </div>
      </div>
      <div className={list}>
        {option.map(el => <FilterCategoryItem item={el}
          key={el.id}
          type={item.type}
          filterCategoryId={item.id}
        />)}
      </div>
    </div>
  );
};

function Filter({ data, loadDataWithFilters, filterBtnText, classNameCustom }) {
  const { staticLocalization } = useContext(Context);
  const { filters } = staticLocalization;

  const handler = (e) => {
    const formData = e.target;
    loadDataWithFilters(formData);

    e.preventDefault();
  };

  return (
    <>
      {data ?
        <>
          {data.length ?
            <form onSubmit={handler}>
              <div className={`filter mob-hide ${classNameCustom}`}>
                {data.map(el => <FilterCategory item={el} key={el.id}/>)}

              </div>
              <div className={`filter__submit-wrapper ${classNameCustom}`}>
                <MainButton palette='primary' type='submit'>
                  {filterBtnText}
                </MainButton>
              </div>
            </form>
            :
            <div className='filter--empty text-center'>{filters.emptyFilterMessage}</div>
          }
        </>
        : <Loader/>
      }
    </>
  );
}

FilterCategoryItem.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string,
  filterCategoryId: PropTypes.number
};

FilterCategory.propTypes = {
  item: PropTypes.object,
};
Filter.propTypes = {
  data: PropTypes.array,
  loadDataWithFilters: PropTypes.func,
  filterBtnText: PropTypes.string
};

export default Filter;
