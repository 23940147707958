import { createGlobalStyle } from 'styled-components';
import './generalConstants.scss';
import './fontsConstants.scss';

// Images
import footerBorder from '../assets/images/footer-border.svg';
import borderLight from '../assets/images/item-border--light.svg';
import formDropDownIcon from '../assets/icons/form-drop-down-icon.svg';
import formDropDownIconLight from '../assets/icons/form-drop-down-icon--light.svg';
// Images

// Sizes
const xxxxl = '1919.5px';
const xxxl = '1439.5px';
const xxl = '1365.5px';
const xl = '1199.5px';
const lg = '991.5px';
const md = '767.5px';
const sm = '575.5px';
const xs = '320.5px';
// Sizes

// Fonts
const berimbau = 'Berimbau, sans-serif';
const myriadLigth = 'Myriad-Pro Ligth, sans-serif';
const myriadRegular = 'Myriad-Pro Regular, sans-serif';
const myriadSemiBold = 'Myriad-Pro SemiBold, sans-serif';
const myriadBold = 'Myriad-Pro Bold, sans-serif';
// Fonts

const generalColor4 = '#E7DBBC';

export const GeneralCastomStyles = createGlobalStyle`

    body {
        background-color: var(--main-background-color);
        transition: var(--generalTransition);
        font-size: 16px;
        color: ${generalColor4};
        min-width: 320px;
        max-width: 1950px;
        margin: 0 auto;
    }

    .container-fluid {
        padding-right: 60px;
        padding-left: 60px;

        .row {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-right: 0px;
        padding-left: 0px;
    }

    h1, .g-like-h1 {
        font-size: 4.375rem;
        line-height: 100%;
        letter-spacing: 0.06em;
        font-family: ${berimbau};
        color: var(--general-text-color);
    }

    h2, .g-like-h2 {
        font-size: 3.125rem;
        line-height: 105%;
        letter-spacing: 0.1em;
        font-family: ${berimbau};
        color: var(--general-text-color);
    }

    h3, .g-like-h3 {
        font-size: 2.5rem;
        line-height: 100%;
        letter-spacing: 0.15em;
        font-family: ${berimbau};
        color: var(--general-text-color);
    }

    .g-like-h4 {
        font-size: 1.5rem;
        line-height: 100%;
        letter-spacing: 0.8em;
        font-family: ${berimbau};
        color: var(--general-text-color);
    }

    h4, .g-like-h5 {
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: 0.03em;
        font-family: ${myriadBold};
        color: var(--general-text-color);
    }

    .g-like-h6 {
        font-size: 1rem;
        line-height: 100%;
        letter-spacing: 0.03em;
        font-family: ${myriadSemiBold};
        color: var(--general-text-color);
    }

    .g-like-p16 {
        font-size: 1rem;
        line-height: 160%;
        font-family: ${myriadRegular};
        color: var(--general-text-color);
    }

    p, .g-like-p14 {
        font-size: 0.875rem;
        line-height: 130%;
        font-family: ${myriadRegular};
        color: var(--general-text-color);
    }

    .mt-5bg {
        position: relative;
        margin-top: -5px;
        background-color: var(--main-background-color);
        z-index: 1;
    }

    .g-template-style {
        .main-page-template {
            &__title-wrapper {
                h2 {
                    font-size: 70px;
                    font-weight: bold;
                }
            }

            &__content {
                display: grid;

                .main-item {
                    .add-to-cart {
                        .counter {
                            input[type=number] {
                                width: 38px;
                                height: 38px;
                                font-size: 0.875rem;
                            }
                        }
                    }
                }
            }
        }

        .baner {
            display: flex;
            border: 23px solid;
            border-image: ${props => (props.blackTheme ? `url(${footerBorder}) 28 repeat` : `url(${borderLight}) 28 repeat`)};
            padding: 0 75px;
            align-items: center;
            text-decoration: none;

            .text {
                width: 100%;
                max-width: 265px;
                margin-right: 60px;

                h4 {
                    font-weight: 500;
                    font-size: 3.125rem;
                    line-height: 105%;
                    font-family: 'Berimbau', sans-serif;
                    letter-spacing: 0.1em;
                    margin-bottom: 25px;
                    font-weight: 600;
                }

                p {
                    color: var(--main-card-description);
                    margin-bottom: 55px;
                    font-size: 14px;
                }

                a {
                    text-decoration: none !important;
                }

                a.btns {
                    max-width: 117px;
                    display: flex;
                    justify-content: center;
                }
            }

            picture {
                max-width: 600px;
                width: 100%;

                img {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .mobile-btn {
                display: none;
            }
        }
    }

    .g-form-style {
        display: flex;
        flex-direction: column;

        &.black-theme {
            .drop-down .react-select__control .react-select__indicator {
                background-image: url(${formDropDownIcon});
            }
        }

        &.light-theme {
            .drop-down .react-select__control .react-select__indicator {
                background-image: url(${formDropDownIconLight});
            }
        }

        input, textarea {
            width: 100%;
            border: 2px solid var(--form-input-border);
            box-sizing: border-box;
            border-radius: 4px;
            background-color: transparent !important;
            outline: none;
            font-size: 1rem;
            line-height: 150%;
            color: var(--general-text-color);
            font-family: ${myriadRegular};
            padding: 11px 34px;

            &::placeholder {
                font-size: inherit;
                line-height: inherit;
                font-family: inherit;
                color: var(--form-input-placefolder);
            }

            &:focus {
                border: 2px solid var(--form-input-border--focus);
            }

            &:not([value=""]) {
                border: 2px solid var(--form-input-border--focus);
            }

        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px var(--main-background-color) inset !important;
            -webkit-text-fill-color: var(--general-text-color);
        }

        input,
        input[type=text],
        input[type=email],
        input[type=password],
        textarea {
            -webkit-appearance: none;
        }

        .show-hide-password {
            input {
                padding-right: 60px;
            }
        }

        label {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            label {
                margin-bottom: 0;
                padding: 0;
            }

            p {
                color: var(--general-text-color);
                font-size: 1rem;
                line-height: 150%;
                margin-bottom: 10px;
                font-family: ${myriadRegular};

                &.required:after {
                    content: '*';
                    color: #E9524C;
                }

                &.success {
                    color: #D4A047;
                    font-family: ${myriadRegular};
                    font-size: 14px;
                    line-height: 130%;
                    margin-bottom: 0;
                }
            }
        }

        .error {
            font-size: .75rem;
            line-height: 150%;
            font-family: $myriadRegular;
            color: #E9524C;
            
            span.no-error {
                font-family: "Myriad-Pro Regular", sans-serif;
                font-size: 0.875rem;
                line-height: 130%;
                letter-spacing: 0.03em;
                color: #9A917F;
            }
        }

        ul.error-description {
            color: #9A917F;
            padding: 10px;
            font-size: .75rem;
            margin-top: -12px;

            li::marker {
                color: #E9524C;
            }

            li {
                margin-left: 7px;
            }
        }

        span.gold-text {
            color: #D4A047;
        }

        .g-form-forget {
            color: #E7DBBC;
            font-size: 1rem;
            line-height: 150%;
            margin-bottom: 10px;
            font-family: $myriadRegular;
            text-align: right;
        }

        .form-spiner {
            .loader {
                svg {
                    width: 30px;
                    height: 30px;
                    margin: 0 0 0 auto;
                }

                img {
                    width: 15px;
                    margin: 0 0 0 auto;
                }
            }
        }

        .drop-down {
            &.like-value {
                .react-select__control {
                    border: 2px solid var(--form-input-border--focus);

                    .react-select__placeholder {
                        color: var(--general-text-color);
                    }
                }
            }

            .react-select__menu {
                z-index: 2;
            }

            &.template-selected {
                .react-select__control {
                    .react-select__placeholder {
                        color: var(--general-text-color);
                    }
                }
            }

            .react-select__single-value,
            .react-select__input {
                color: var(--general-text-color)
            }

            .react-select__control {
                width: 100%;
                border: 2px solid var(--form-input-border);
                box-sizing: border-box;
                border-radius: 4px;
                background-color: transparent;
                outline: none;
                padding: 5px 20px 5px 34px;
                box-shadow: none;

                &--is-focused {
                    border: 2px solid var(--form-input-border--focus);
                    box-shadow: none;
                }

                .react-select__placeholder {
                    font-size: 1rem;
                    line-height: 150%;
                    color: var(--form-input-placefolder);
                    font-family: ${myriadRegular};
                }

                .react-select__indicator {
                    width: 16px;
                    height: 20px;

                    svg {
                        display: none;
                    }

                    &-separator {
                        display: none;
                    }
                }
            }

            .react-select__menu {
                .react-select__option {
                    color: var(--general-text-color);
                }
            }

            .react-select__menu-list {
                background-color: var(--main-background-color--transparent);

                .react-select__option--is-focused {
                    background-color: var(--main-background-color--dropdown);
                }

                .react-select__option--is-selected {
                    background-color: var(--main-background-color);
                }
            }
        }

        .react-datepicker {
            &-wrapper {
                input {
                    min-height: 47px;
                }
            }

            &__triangle {
                button {
                    outline: none;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &__navigation--next:hover {
                border-left-color: transparent;
            }
        }

        .g-form-style {
            display: flex;
            flex-direction: column;

            input {
                width: 100%;
                border: 2px solid var(--form-input-border);
                box-sizing: border-box;
                border-radius: 4px;
                background-color: transparent;
                outline: none;
                font-size: 1rem;
                line-height: 150%;
                color: var(--general-text-color);
                font-family: $myriadRegular;
                padding: 11px 34px;

                &::placeholder {
                    font-size: inherit;
                    line-height: inherit;
                    font-family: inherit;
                    color: var(--form-input-placefolder);
                }

                &:focus {
                    border: 2px solid var(--form-input-border--focus);
                }
            }

            .show-hide-password {
                input {
                    padding-right: 60px;
                }
            }

            label {
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;

                label {
                    margin-bottom: 0;
                    padding: 0;
                }

                p {
                    color: var(--general-text-color);
                    font-size: 1rem;
                    line-height: 150%;
                    margin-bottom: 10px;
                    font-family: $myriadRegular;

                    &.required:after {
                        content: '*';
                        color: #E9524C;
                    }
                }
            }

            .error {
                font-size: .75rem;
                line-height: 150%;
                font-family: ${myriadRegular};
                color: #E9524C;
            }

            .g-form-forget {
                color: #E7DBBC;
                font-size: 1rem;
                line-height: 150%;
                margin-bottom: 10px;
                font-family: ${myriadRegular};
                text-align: right;
            }

            .form-spiner {
                .loader {
                    svg {
                        width: 30px;
                        height: 30px;
                        margin: 0 0 0 auto;
                    }

                    img {
                        width: 15px;
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }

    #_x3C_text_x3E_ {
        fill: white;
    }

    .main-loader {
        position: fixed;
        z-index: 1021;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        transition: all .3s ease-out;
        opacity: 1;
        visibility: visible;

        .loader {
            background-color: #F3F0E5;

            img {
                max-height: 100%;
            }
        }

        &.hide {
            opacity: 0;
            visibility: hidden;
        }
    }

    .main-item-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        position: relative;

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            height: 100%;
            width: 4px;
            background-color: var(--main-background-color);
            z-index: 1;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            height: 4px;
            width: 100%;
            background-color: var(--main-background-color);
            z-index: 10;
        }
    }

    @media screen and (max-width: ${xxxl}) {
        html, body {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1700px) {
        .main-item-wrapper {
            grid-template-columns: repeat(4, 1fr);
        }

        .g-template-style {
            .baner {
                padding: 0 50px;
            }
        }
    }

    @media screen and (max-width: 1360px) {
        .main-item-wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: ${xl}) {
        html, body {
            font-size: 16px;
        }

        .container-fluid {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    @media screen and (max-width: ${lg}) {
        h1, .g-like-h1 {
            font-size: 2.25rem;
        }

        h2, .g-like-h2 {
            font-size: 1.25rem;
            font-weight: 600;

        }

        .g-form-style {
            input {
                font-size: 0.75rem;
                line-height: initial;
                min-height: 50px;
                padding: 11px 22px;
            }

            .drop-down {
                & > span {
                    display: none;
                }

                &.template-selected {
                    .react-select__control {
                        .react-select__placeholder {
                            color: var(--general-text-color);
                            font-size: 0.75rem;
                            line-height: 0.875rem;
                            min-height: 50px;
                            padding: 11px 22px;
                        }
                    }
                }

                .react-select__single-value,
                .react-select__input {
                    color: var(--general-text-color);
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                }

                .react-select__control {
                    padding: 0px 22px;

                    .react-select__placeholder {
                        font-size: 0.875rem;
                        line-height: 0.875rem;
                    }

                    .react-select__indicator {
                        width: 16px;
                        height: 20px;

                        svg {
                            display: none;
                        }

                        &-separator {
                            display: none;
                        }
                    }
                }

                .react-select__menu {
                    .react-select__option {
                        color: var(--general-text-color);
                        font-size: 0.875rem;
                        line-height: 0.875rem;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }

                .react-select__menu-list {
                    .react-select__option--is-focused {
                        background-color: var(--main-background-color);
                    }
                }

                .react-select__value-container {
                    padding: 0;

                    div:last-child {
                        height: 46px;
                        margin: 0px;
                        padding-bottom: 0px;
                        padding-top: 0px;
                    }

                    .react-select__input {
                        input {
                            min-height: initial;
                            height: inherit;
                        }
                    }
                }
            }

            label {
                margin-bottom: 30px;
                padding-left: 25px;
                padding-right: 25px;

                p {
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    margin-bottom: 7px;
                }
            }
        }

        .main-item-wrapper {
            grid-template-columns: repeat(2, 1fr);
            display: flex;
            flex-wrap: wrap;

            .main-item {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }

        .g-template-style {
            .main-page-template {
                &__title-wrapper {
                    h2 {
                        font-size: 2.125rem;
                        line-height: 100%;
                    }
                }
            }

            .baner-wrapper {
                .baner {
                    flex-direction: column;
                    padding: 18px 10px;

                    .text {
                        max-width: unset;
                        margin-right: 0;

                        h4 {
                            text-align: center;
                            letter-spacing: 0.075em;
                            margin-bottom: 0.5rem;
                            font-size: 1.5625rem;
                        }

                        &__wrapper {
                            p {
                                text-align: center;
                                margin-bottom: 1rem;
                                line-height: 150%;
                                font-size: 12px;
                            }
                        }

                        .desctop-btn {
                            display: none;
                        }
                    }

                    & > a {
                        display: inline-block;
                        margin-bottom: 15px;
                    }

                    .mobile-btn {
                        display: block;

                        a {
                            min-width: 164px;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            .main-item {
                .wrapper {
                    padding-bottom: 0px;
                    display: flex;
                    flex-direction: column;
                }

                .like-btn {
                    order: 1;
                }

                .img-wrapper {
                    order: 2;
                }

                .name-and-price {
                    order: 3;
                }
            }
        }
    }

    @media screen and (max-width: ${sm}) {
        .main-item-wrapper {
            grid-template-columns: repeat(auto-fill, 50%);
        }

        .g-form-style {
            label {
                padding: 0 30px;

                input,
                input::placeholder {
                    font-size: 0.875rem;
                }
            }

            .show-password {
                svg {
                    width: 22px;
                    height: 12px;
                }
            }
        }

        .g-template-style {
            .main-page-template {
                &__title-wrapper {
                    h2 {
                        font-size: 2.125rem;
                        font-weight: 600;
                        line-height: 100%;
                    }
                }
            }

            .main-item {
                .wrapper {
                    padding-bottom: 0px;
                    display: flex;
                    flex-direction: column;
                }

                .like-btn {
                    order: 1;
                }

                .img-wrapper {
                    order: 2;
                }

                .name-and-price {
                    order: 3;
                }
            }
        }
    }
`;
