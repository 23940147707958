import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../../context';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import {
  calculateDeliveryConst, logination,
  warehouse
} from '../../../../utils/api';

const PostDepartment = ({ setFieldValue, setDeliveryPrice, setFieldError, notRegisterCustomerId, ...props }) => {
  const [defaultValue, setDefaultValue] = useState();
  const { staticLocalization, userData, langKey } = useContext(Context);
  const { deliveryForm } = staticLocalization;
  const [options, setOptions] = useState([]);

  const handleChange = (val) => {
    setFieldValue('');
    setTimeout(() => {
      setFieldValue(val);
      setDefaultValue(val);

      const formValues = props.form.values;

      const getCalculatedDeliveryConst = new Promise((resolve, reject) => {
        calculateDeliveryConst(
          userData.id || notRegisterCustomerId,
          formValues.city.id,
          formValues.firstName,
          formValues.lastName,
          'midName',
          formValues.email,
          formValues.telephone,
          formValues.deliveryAndPaymentTypeData.type,
          formValues.deliveryAndPaymentTypeData.id,
          formValues.address,
          formValues.building,
          val.deliveryServiceDepartment || formValues.department.deliveryServiceDepartment
        ).then(response => {
          if (response.ok) {
            return resolve(response.json());
          }

          response.json().then(data => {
            setFieldError('department', data.error);
          });
        });
      });

      Promise.all([getCalculatedDeliveryConst]).then((res) => {
        const [calculatedDeliveryConst] = res;

        setDeliveryPrice(calculatedDeliveryConst);
      });
    });
  };

  const filter = (inputValue = null, newOptions) => {
    if (inputValue) {
      if (/^\d+$/.test(inputValue)) {
        return options.filter(i => i.label.includes(`№${inputValue}`));
      }
      return options.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
      
    }

    return newOptions;
  };

  const loadOptions = async (inputValue, callback) => {
    const cityRef = props.form.values.city.cityRef;

    // if a user types something in input and options are already loaded
    if (options.length > 0 && inputValue) {
      return filter(inputValue, options);
    }

    const newOptions = [];

    try {
      const warehouseResponse = await warehouse(cityRef, langKey);
      const warehouseData = await warehouseResponse.json();

      warehouseData.forEach((option) => {
        const val = option.description;

        newOptions.push({
          value: val,
          label: val,
          id: option.number,
          deliveryServiceDepartment: option.ref
        });
      });
    } catch (err) {
      setOptions([]);
    }

    setTimeout(() => {
      setOptions(newOptions);
      newOptions.forEach((item) => {
        if (
          item.deliveryServiceDepartment ===
          props.form.initialValues.deliveryServiceDepartment
        ) {
          setDefaultValue(item);
          setFieldValue(item);
        }
      });
      callback(filter(inputValue, newOptions));
    }, 1000);
  };

  useEffect(() => {
    const newDefaultValue = options.find(
      (item) => item.deliveryServiceDepartment === props.form.values.department.deliveryServiceDepartment
    );

    if (newDefaultValue) {
      setDefaultValue(newDefaultValue);
      setFieldValue(newDefaultValue);
    }
  }, [options]);

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      key={JSON.stringify(langKey)}
      defaultOptions
      value={defaultValue}
      name='PostDepartment'
      onChange={val => {
        const array = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
        array['deliveryServiceDepartment'] = val.deliveryServiceDepartment;
        localStorage.setItem('regestration', JSON.stringify(array));
        handleChange(val);
      }}
      placeholder={deliveryForm.postDepartmentPlaceholder}
      noOptionsMessage={({ inputValue: string }) => deliveryForm.postDepartmentNoOptionsMessage || ''}
      className='drop-down'
      classNamePrefix='react-select'
      indicatorsContainer={{
        display: 'none'
      }}
    />
  );
};
PostDepartment.propTypes = {
  cityRef: PropTypes.any,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setDeliveryPrice: PropTypes.func,
  form: PropTypes.object,
  notRegisterCustomerId: PropTypes.number
};

export default PostDepartment;
