import React, { useContext } from 'react';
import classNames from 'classnames';
import './Template_1.scss';
import PropTypes from 'prop-types';

import MainItem from '../../../../components/MainItem/MainItem';

import { modifidedItemWithTranslationField, getTranslation } from '../../../../utils/renderFieldWithTransition';
import { imagesUrl } from '../../../../utils/api';
import { Context } from '../../../../context';

import { ReactComponent as ButtonSide } from '../../../../assets/images/button-side.svg';
import { ReactComponent as ButtonBody } from '../../../../assets/images/button-body.svg';

const Baner = ({ data, banerIndex }) => {

  const { curentLanguageId, settings, blackTheme } = useContext(Context);

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  const { images, imagesMedium, imagesSmall, buttonUrl, url } = data;
  const name = getTranslation(data, modifidedItemWithTranslationField(data), 'name', curentLanguageId);
  const description = getTranslation(data, modifidedItemWithTranslationField(data), 'text', curentLanguageId);
  const btnText = getTranslation(data, modifidedItemWithTranslationField(data), 'buttonTitle', curentLanguageId);

  return (
    <span className={classNames('baner-wrapper', whatTheme, `baner-wrapper--${banerIndex}`)}>
      <div className='baner'>
        <div className='text'>
          <a href={url ? url : null} target='blank' rel='noopener noreferrer' className='text__wrapper'>
            <h4>
              {name}
            </h4>
            <p>
              {description || ''}
            </p>
          </a>
          {
            buttonUrl ?
              <a href={buttonUrl} className='btns btns--primary' target='_black' rel='noopener noreferrer'>
                {btnText}
                <div className='btns__buttons'>
                  <ButtonSide/>
                  <ButtonBody/>
                  <ButtonSide/>
                </div>
              </a>
              :
              null
          }
        </div>
        <a href={url ? url : null} target='blank' rel='noopener noreferrer'>
          <picture>
            <source media='(max-width: 1024px)' srcSet={`${imagesUrl}${imagesMedium}?v=${settings.frontendVersion}`} />
            <source media='(max-width: 545px)' srcSet={`${imagesUrl}${imagesSmall}?v=${settings.frontendVersion}`} />
            <img src={`${imagesUrl}${images}?v=${settings.frontendVersion}`} alt='Flowers'  />
          </picture>
        </a>
      </div>
    </span>
  );
};

const Template_1 = ({ data }) => {

  const { staticLocalization } = useContext(Context);
  let banerIndex = 0;

  data.sort((a, b) => a.templateItemId - b.templateItemId);

  return (
    <div className='g-template-style collection-template-1'>
      {data.map((el, index) => {
        try{
          const { templateItemValue } = el;
          if(templateItemValue.category){
            return <MainItem key={`element${index}`} item={templateItemValue} withCounter={true} withDescription={true} buttonTitle={staticLocalization.collectionLang.collectionBtnTitle || staticLocalization.collectionLang.collectionBtnTitle} buttonLink='/product/'/>;
          }
          banerIndex++;
          return <Baner key={`baner${index}`} data={templateItemValue} banerIndex={banerIndex}/>;
        }catch(e){

        }
      })}
    </div>
  );
};

Baner.propTypes = {
  data: PropTypes.object
};

Template_1.propTypes = {
  data: PropTypes.array
};

export default Template_1;
