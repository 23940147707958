export const dummyStateStepsNotLogined = ({ firstStepTitle, secondStepTitle, thirdStepTitle, fourthStepTitle, firstStepName, secondStepName, thirdStepName, fourthStepName }) => {
  return(
    [
      {
        id: 1,
        name: firstStepName,
        title: firstStepTitle,
        state: 'active'
      },
      {
        id: 2,
        name: secondStepName,
        title: secondStepTitle,
        state: 'next',
      },
      {
        id: 3,
        name: thirdStepName,
        title: thirdStepTitle,
        state: 'next',
      },
      {
        id: 4,
        name: fourthStepName,
        title: fourthStepTitle,
        state: 'next',
      }
    ]
  );
};

export const dummyStateStepsLogined = ({ firstStepTitle, secondStepTitle, thirdStepTitle, firstStepName, secondStepName, thirdStepName }) => {
  return(
    [
      {
        id: 1,
        name: firstStepName,
        title: firstStepTitle,
        state: 'active'
      },
      {
        id: 2,
        name: secondStepName,
        title: secondStepTitle,
        state: 'next',
      },
      {
        id: 3,
        name: thirdStepName,
        title: thirdStepTitle,
        state: 'next',
      }
    ]
  );
};