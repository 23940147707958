import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { imagesUrl } from '../../utils/api';
import { Context } from '../../context';


import './MainSlider.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import { ReactComponent as SliderArrow } from '../../assets/icons/form-drop-down-icon.svg';
import { ReactComponent as SliderArrowLight } from '../../assets/icons/form-drop-down-icon--light.svg';


import ErrorImg from '../../assets/images/candy-1--error.png';

const MainSlider = ({ data, category, name }) => {

  const { settings, blackTheme } = useContext(Context);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [clickedButton, setClickedButton] = useState(0);
  const [quantityOfVerticalSliderElement] = useState(3);
  const [images, setImages] = useState({ dark: [], light: []});

  useEffect(() => {
    const dark = [];
    const light = [];
    data.images.forEach((el) => {
      if (el.includes('dark')) {
        dark.push(el);
      } else if (el.includes('light')) {
        light.push(el);
      }
    });

    setImages({ dark, light });
  }, []);

  function Arrows(props) {
    const { className, onClick } = props;
    if(props.theme === 0) {
      return (
        <SliderArrow onClick={onClick} className={className} />
      );
    } else {
      return (
        <SliderArrowLight onClick={onClick} className={className}/>
      );
    }
  }

  const bigSliderSettings = (arrow) => {
    return {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <Arrows theme={arrow}/>,
      prevArrow: <Arrows theme={arrow}/>,
      draggable: false,
      fade: true,
      swipe: false
    };
  };

  const smallSliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: quantityOfVerticalSliderElement,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    vertical: window.innerWidth > 991,
    centerMode: false,
  };

  const Tag = images.length > quantityOfVerticalSliderElement ? 'div' : 'button';

  const handleClick = (e, index) => {
    setClickedButton(index);
    nav2.slickGoTo(index);
  };

  const handleClickIfSlider = (e, index) => {
    setClickedButton(index);
    nav1.slickGoTo(index);
    nav2.slickGoTo(index - 1);
  };

  const smallSliderClassNames = classNames(
    'small-slider',
    'slider-custom-style',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  return (
    <>
      <div xs={12} className='mobile-name'>
        {/* <p> */}
        <div>
          {category}
        </div>
        {name}
        {/* </p> */}
      </div>
      <div className='slider-wrapper'>
        <div className='big-slider slider-custom-style'>
          <Slider {...bigSliderSettings(blackTheme ? 0 : 1)} asNavFor={nav1} ref={slider2 => setNav2(slider2)}>
            {
              images[blackTheme ? 'dark' : 'light'].map((el, index) => {
                return (
                  <div className='wrapper-for-img' key={index}>
                    <img src={`${imagesUrl}${encodeURIComponent(el)}?v=${settings.frontendVersion}`} alt='a' onError={(e) => { e.target.onerror = null; e.target.src = ErrorImg; }} />
                  </div>
                );
              })
            }
          </Slider>
        </div>
        <div className={smallSliderClassNames}>
          {
            images[blackTheme ? 'dark' : 'light'].map((el, index) => {
              return (
                (index === clickedButton) ?
                  <button className='wrapper-for-img active' key={index} onClick={(e) => handleClick(e.target, index)}>
                    <img src={`${imagesUrl}${encodeURIComponent(el)}?v=${settings.frontendVersion}`} alt='a' onError={(e) => { e.target.onerror = null; e.target.src = ErrorImg; }} />
                  </button>
                  :
                  <button className='wrapper-for-img' key={index} onClick={(e) => handleClick(e.target, index)}>
                    <img src={`${imagesUrl}${encodeURIComponent(el)}?v=${settings.frontendVersion}`} alt='a' onError={(e) => { e.target.onerror = null; e.target.src = ErrorImg; }} />
                  </button>
              );
            })
          }
        </div>
      </div>
    </>
  );
};


export default MainSlider;
