import React, {  useState, useEffect, useContext  }  from 'react';

import Counter from '../Counter/Counter';
import MainButton from '../../components/MainButton/MainButton';

import { ReactComponent as CloseModalSVG } from './../../assets/icons/close.svg';
import Loader from '../../components/Loader/Loader';

import { imagesUrl, getIndividualOrderGoods, getIndividualOrderGoodsNew, deletGoodFromBasketById } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';
import { changeItemCounterValueInBasket, getItemCategories, getName, getImages } from '../../utils/basketItems';
import { TYPES } from '../../constants/app';
import { Context } from '../../context';

import { getCustomInscriptionAmount } from '../../utils/getCustomInscriptionValues';

const priceDecorator = (price) => {
  if (price > 0) { return `+${price}`;};
  return price;
};

const IndividualComponentView = ({ item, category, customInscriptionText, customDecorImages }) => {

  const { staticLocalization, curentLanguageId, settings } = useContext(Context);
  const [data, setData] = useState(item);

  useEffect(() => {
    setData(modifidedItemWithTranslationField(item));
  }, []);

  const { images, price: priceContent, isCustomInscription, isCustomDecor } = item;
  // check if price set in settings
  const checkPriceInSetings = item?.settings ? item.settings[0].price : priceContent;
  // check price in custom inscription
  const checkCustomInscriptionPrice = isCustomInscription ? getCustomInscriptionAmount(item.customSettings[0].ranges, customInscriptionText) : checkPriceInSetings;
  // check is shape to show without sign
  const price = item?.type === 'shape' ? checkCustomInscriptionPrice : priceDecorator(checkCustomInscriptionPrice);
  const name = getName({ item: item, data, languageId: curentLanguageId, type: TYPES.GOODS });
  // check is customDecorImages from localState (has base64string) or from server
  const _customDecorImages = isCustomDecor
    ? customDecorImages?.base64string
      ? customDecorImages.base64string
      : `${imagesUrl}${customDecorImages[0]}`
    : '';
  const imgUrl = isCustomDecor ? _customDecorImages : `${imagesUrl}${images[0]}?v=${settings.frontendVersion}`;

  return (
    <div className='basket-item'>
      <div className='img-wrapper'>
        <img src={imgUrl} alt={name}/>
      </div>
      <div className='text'>
        <div className='text__title'>
          { category }
        </div>
        <div className='text__name'>
          { name }
          {isCustomInscription && <p className='individual-order__custom-text'>{customInscriptionText}</p>}
        </div>
      </div>
      <div className='counter-and-price'>
        <div className='counter-and-price__price'>
          { price }
          <div className='currency'>
            {staticLocalization.general.currency}
          </div>
        </div>
      </div>
    </div>
  );
};

const IndividualAdditionComponentView = ({ item }) => {

  const { staticLocalization, curentLanguageId, settings } = useContext(Context);

  const { images } = item;
  const price = priceDecorator(item.settings[0].price);
  const currency = staticLocalization.general.currency;
  const name = getName({ item: item, item, languageId: curentLanguageId, type: TYPES.GOODS });

  return (
    <div className='individual-order__addition-item'>
      <div className='individual-order__addition-img'>
        <img src={`${imagesUrl}${images[0]}?v=${settings.frontendVersion}`} alt={name}/>
      </div>
      <div className='individual-order__addition-price'>
        {price}
        <small> {currency}</small>
      </div>
    </div>
  );
};

const IndividualAdditionComponent = ({ item }) => {

  const { staticLocalization } = useContext(Context);

  if (!item.items || item.items.length === 0) return null;

  return (
    <div className='basket-item addition'>
      <p className='individual-order__addition-title'>
        {staticLocalization.individualLang.addition}
      </p>
      <div className='individual-order__addition-wrapper'>
        {
          item.items.map(item => <IndividualAdditionComponentView item={item} key={item.id}/>)
        }
      </div>
    </div>
  );
};

const IndividualItemDetailView = ({ item }) => {

  const { staticLocalization }  = useContext(Context);
  const { individualLang } = staticLocalization;

  const customInscriptionText = item?.customInscriptionText;
  const customDecorImages = item?.customDecorImages;

  const INDIVIDUAL_COMPONENTS = ['shape', 'chocolateType', 'additions', 'inscription', 'decor', 'box', 'postcard'];
  const CATEGORY_TITLES = {
    shape: individualLang.stepName0,
    chocolateType: individualLang.stepName1,
    additions: individualLang.stepName2,
    inscription: individualLang.stepName3,
    box: individualLang.stepName4,
    postcard: individualLang.stepName5,
    decor: individualLang.stepName6,
  };

  const viewContent = [];

  INDIVIDUAL_COMPONENTS.forEach( component => {

    if (item[component]?.id) {
      const _component = {
        ...item[component],
        type: component
      };
      viewContent.push(_component);
    }
    if (component === 'additions') {
      const _component = {
        id: 'additions',
        items: item[component]
      };
      viewContent.push(_component);
    }
  });

  return (
    <>
      {
        viewContent.map(item => {
          if (item.id === 'additions') return <IndividualAdditionComponent key={`${item.type}-${item?.id}`} item={item} />;
          return <IndividualComponentView key={`${item.type}-${item?.id}`} item={item} customInscriptionText={customInscriptionText} customDecorImages={customDecorImages}
            category={CATEGORY_TITLES[item.type] || CATEGORY_TITLES[item.boxType]} />;
        })
      }
    </>
  );
};

const IndividualItemDetail = ({ content }) => {

  const { isLogined, staticLocalization, curentLanguageId, settings } = useContext(Context);

  const [item, setItem] = useState(null);
  const id = isLogined ? content.itemEntity.id : content.id;

  const checkAndAddField = (field, item) => {

    const newItems = field.reduce((acc, curr) => {
      if (item[curr]) return { ...acc, [curr]: item[curr] };
      return { ...acc };
    }, {});
    return {
      ...item.itemEntity,
      ...newItems,
    };
  };

  useEffect(() => {

    if(isLogined) {
      getIndividualOrderGoodsNew({ id: id }).then(res => {
        return res.json();
      }).then(item => {
        setItem(item);
      });
    } else {
      const _item = JSON.parse(localStorage.getItem('lmsh-basket')).find(el => el.id === id);
      if (_item) {
        const _itemNew = checkAndAddField(['customInscriptionText', 'customDecorImages'],  _item);
        setItem(_itemNew);
      }
    }

  }, []);

  return (
    <>
      {
        item
          ? <IndividualItemDetailView item={item}/>
          : <Loader />
      }
    </>
  );
};

const BasketIndividualDetail = ({
  detailCloseHandler,
  images,
  category,
  description,
  counter,
  setCounter,
  price,
  handleChangeGeneralPrice,
  changeItemCounterValueInBasket,
  isLogined,
  item,
  onClose,
  notRegisterCustomerId,
  isOrder
}) => {

  const { staticLocalization, curentLanguageId, settings } = useContext(Context);

  const [detailItemCounter, setDetailItemCounter] = useState(counter);
  const [itemAmount, setItemAmount] = useState(counter * +price);

  const detaildChangeQuantityHandler = () => {
    const newPrice = (detailItemCounter - counter) * +price;
    handleChangeGeneralPrice(+newPrice, true);
    if (notRegisterCustomerId) {
      changeItemCounterValueInBasket(isLogined, item, detailItemCounter, notRegisterCustomerId, notRegisterCustomerId > 0 ? item.itemEntity.id : null);
    } else {
      changeItemCounterValueInBasket(isLogined, item, detailItemCounter, 0);
    }
    setCounter(detailItemCounter);
    onClose();
  };

  const counterValueDetailChanged = (val, isIncrement, quantity) => {
    if (!(val === 0 && !isIncrement)) {
      setDetailItemCounter(val);
      setItemAmount(val * +price);
    }
  };

  return (
    <div className='basket-item__detail-wrapper'>
      <div className='border-wrapper'>
        <div className={`basket-item__detail ${isOrder ? 'basket-item__detail--isOrder': ''}`}>
          <div className='basket-item__detail-title'>
            <h3>{staticLocalization.individualLang.mainTitle}</h3>
            <CloseModalSVG onClick={detailCloseHandler}/>
          </div>
          <div className='basket-item complete'>
            <div className='wrapper'>
              <div className='img-wrapper'>
                <img src={`${imagesUrl}${images}?v=${settings.frontendVersion}`} alt='basket item'/>
              </div>
              <div className='text'>
                <div className='text__title'>
                  { category }
                </div>
                <div className='text__description'>
                  { description }
                </div>
              </div>
              <div className='counter-and-price'>
                <Counter counterValueChanged={ (val, isIncrement, counter) => counterValueDetailChanged(val, isIncrement, counter) } counterValue={ +counter } />
                <div className='counter-and-price__price'>
                  { price }
                  <div className='currency'>
                    {staticLocalization.general.currency}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IndividualItemDetail content={item} />
          <div className='individual-order__amount'>
            <span>{staticLocalization.basket.detailItemAmount || 'Разом'}</span>
            { itemAmount }<small>{staticLocalization.general.currency}</small>
          </div>
          <div className={`individual-order__button-group ${isOrder ? 'justify-content-end' : ''} `}>
            <MainButton  palette='secondary' onClick={detailCloseHandler}>{staticLocalization.basket?.cancelItemBtns}</MainButton>
            {!isOrder &&
                <MainButton  palette='primary' onClick={detaildChangeQuantityHandler}>{staticLocalization.basket?.saveItemBtns}</MainButton>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketIndividualDetail;
