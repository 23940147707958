import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import './CollectionGroup.scss';

import MainTitle from '../MainTitle/MainTitle';
import CollectionGroupItem from './CollectionGroupItem';
import MainButton from '../MainButton/MainButton';

import { ReactComponent as ArrowToLeftIcon } from './../../assets/icons/previous-arrow.svg';

import { useWindowSize } from '../../utils/useWindowSizeHook';

import { Context } from '../../context';

function CollectionGroup({ content, className }) {

  const { staticLocalization,  blackTheme } = useContext(Context);
  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';
  const {
    collecitonsGroupTitle,
    goBackButtonTitle,
    collecitonsGroupItemButtonTitle
  } = staticLocalization.collectionLang;

  const MOBILE_WIDTH = 575;

  const { width } = useWindowSize();

  const history = useHistory();

  const classes = classNames(
    'collection',
    whatTheme,
    className
  );

  const goBackHandler = () => {
    history.goBack();
  };

  return (
    <Container fluid >

      <Row>
        <Col xs={12}>
          <MainTitle>
            {collecitonsGroupTitle}
          </MainTitle>
        </Col>
      </Row>
      { width <= MOBILE_WIDTH && <Row>
        <Col xs={12}>
          <MainButton palette='secondary' className='h-42 sm-14' onClick={goBackHandler}>
            <ArrowToLeftIcon/>
            {goBackButtonTitle}
          </MainButton>
        </Col>
      </Row>
      }
      <Row>
        <Col xs={12}>
          <div className={classes}>
            { content.map(el => <CollectionGroupItem item={el} buttonTitle={collecitonsGroupItemButtonTitle} key={`collection-${el.id}`}/>) }
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CollectionGroup;