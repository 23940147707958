import React, { useContext, useEffect, useState } from 'react';
import './OrderBascket.scss';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Context } from '../../context';
import { getGeneralPrice } from '../../utils/getGeneralPrice';
import { getGoodsForBasket } from '../../utils/api';

import OrderBascketItem from './OrderBascketItem';
import Loader from '../Loader/Loader';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-btn.svg';
import { removeItemsAfterSuccessOrder } from '../../utils/basketItems';

const OrderBascket = ({
  history,
  showLocalPrice,
  deliveryPrice,
  orderBasketGeneralPrice,
  setOrderBasketGeneralPrice,
  orderBasketAdditionalProtection,
  setOrderBasketAdditionalProtection,
  additionalProtection,
  additionalProtectionPrice,
  notRegisterCustomerId,
  useLocalCard,
  bofonQuintity,
  rerender,
  setAdditionalProtection,
  giftBoxIsCheked
}) => {

  let orderItems = {};


  const {
    isLogined,
    blackTheme,
    generalPrice,
    basketItemsLength,
    changeGeneralPrice,
    staticLocalization
  } = useContext(Context);

  const [basketData, setBasketData] = useState([]);
  const [showBasketData, setShowBasketData] = useState({
    GOODS: [],
    BOX_WITH_GOODS: [],
    INDIVIDUAL_ORDER: [],
    SETS: []
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [orderBasketIsEmpty, setOrderBasketIsEmpty] = useState(false);

  const selectGoods = (el) => {
    // select items by type to show
    const _goods = [];
    const _boxWithGoods = [];
    const _individalOrder = [];
    // const _sets = [];
    // BOX shows in the same frame as goods
    el && el.map(item => {
      switch (item.type) {
      case 'GOODS':
        _goods.push(item);
        break;
      case 'BOX_WITH_GOODS':
        _boxWithGoods.push(item);
        break;
      case 'INDIVIDUAL_ORDER':
        _individalOrder.push(item);
        break;
      case 'BOX':
        _goods.push(item);
        break;
      default:
        console.log('good without type');
      }
    });
    setShowBasketData({
      GOODS: _goods,
      BOX_WITH_GOODS: _boxWithGoods,
      INDIVIDUAL_ORDER: _individalOrder,
      // SETS: _sets
    });
  };

  useEffect(() => {
    setIsLoaded(false);
    if (isLogined || notRegisterCustomerId > 0) {
      setTimeout(() => {
        getGoodsForBasket(notRegisterCustomerId).then(res => {
          return res.json();
        }).then(el => {
          if (el.count === 0) {
            history.push('/basket');
          } else {
            setBasketData(el.result); // TODO: REMOVE
            selectGoods(el.result);
            // const price = (+getGeneralPrice(true, el.result, notRegisterCustomerId));
            //setOrderBasketGeneralPrice(additionalProtection ? price + (+additionalProtectionPrice) : price);
            setIsLoaded(true);
          }
        });
      }, 500); // just magic

    } else {
      const basket = JSON.parse(localStorage.getItem('lmsh-basket'));
      if (basket?.length <= 0) {
        history.push('/basket');
      } else {
        setBasketData(basket); // TODO: REMOVE
        selectGoods(basket);

        setTimeout(() => {
          const price = +getGeneralPrice(false, basket);
          setOrderBasketGeneralPrice(additionalProtection ? price + (+additionalProtectionPrice) : price);
          setIsLoaded(true);
        }, 50);
      }
    }

  }, [isLogined, basketItemsLength, notRegisterCustomerId, rerender, additionalProtection, giftBoxIsCheked]);

  useEffect(() => {
    if (orderItems && Object.keys(orderItems).length > 0) {
      orderItems = removeItemsAfterSuccessOrder(isLogined, orderItems);

      let price = 0;
      const boxId = localStorage.getItem('lmsh-box-id');

      Object.entries(orderItems).map((el, key) => {
        if(el[1].type === 'BOX' && el[0] !== boxId) {
          delete orderItems[el[0]];
        } else {
          el[1] ? price += el[1].price : delete orderItems[el[0]];
        }
      });

      setOrderBasketGeneralPrice(price + orderBasketAdditionalProtection);
    }
  }, [[], orderItems]);

  const changeNewGeneralPrice = (price, increment) => {
    const newPrice = increment ? +orderBasketGeneralPrice + +price : +orderBasketGeneralPrice - +price;
    setOrderBasketGeneralPrice(+newPrice);
  };

  const generalLocalPriceClassNames = classNames(
    'general-local-price',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const removeAddProtection = () => {
    setAdditionalProtection(false);
    localStorage.setItem('lmsh-additionalProtection', false);
    setOrderBasketAdditionalProtection(0);
  };

  return (
    <>
      {
        isLoaded
          ? <div className='order-basckets__wrapper'>
            {
              ['INDIVIDUAL_ORDER', 'BOX_WITH_GOODS', 'GOODS'].map(
                (item) => {
                  const isComplex = (item === 'INDIVIDUAL_ORDER' || item === 'BOX_WITH_GOODS') ? 'complex' : '';
                  if (isComplex) {
                    if (showBasketData[item].length > 0) {
                      return (<>
                        {showBasketData[item].map((el) => (
                          <div className={`order-bascket__wrapper ${isComplex}`} key={`${item}-${el.id}`}>
                            <OrderBascketItem
                              item={el}
                              key={el.id}
                              setIsLoaded={(val) => setIsLoaded(val)}
                              setBasketData={(val) => setBasketData(val)}
                              setOrderBasketIsEmpty={(val) => setOrderBasketIsEmpty(val)}
                              handleChangeGeneralPrice={(price, increment) =>
                                changeNewGeneralPrice(price, increment)
                              }
                              orderItems={orderItems}
                              orderItemsCallback={(item) => {
                                if (item) {
                                  orderItems = { ...orderItems, ...item };
                                }
                              }}
                              notRegisterCustomerId={notRegisterCustomerId}
                            />
                          </div>
                        ))}
                      </>);
                    }
                  } else {
                    if (showBasketData[item].length > 0) {
                      return (
                        <div className={`order-bascket__wrapper ${isComplex}`} key={item}>
                          {showBasketData[item].map((el) => (
                            <OrderBascketItem
                              item={el}
                              key={el.id}
                              setIsLoaded={(val) => setIsLoaded(val)}
                              setBasketData={(val) => setBasketData(val)}
                              setOrderBasketIsEmpty={(val) => setOrderBasketIsEmpty(val)}
                              handleChangeGeneralPrice={(price, increment) =>
                                changeNewGeneralPrice(price, increment)
                              }
                              orderItems={orderItems}
                              orderItemsCallback={(item) => {
                                if (item) {
                                  orderItems = { ...orderItems, ...item };
                                }
                              }}
                              notRegisterCustomerId={notRegisterCustomerId}
                            />
                          ))}
                        </div>
                      );
                    }
                  }
                }
              )
            }
            {
              additionalProtection ?
                <div className={'order-bascket__wrapper complex additional-protection additional-protection__flex'}>
                  <h3>{staticLocalization.order.additionalBoxAddedMesssage}</h3>
                  <div className='price-and-close'>
                    <button className='remove-item-btn' onClick={removeAddProtection}>
                      <CloseIcon/>
                    </button>
                    <div className='price'>
                      {additionalProtectionPrice}
                      <div className='currency'>
                        {staticLocalization.general.currency}
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
          </div>
          : <Loader/>
      }
      {showLocalPrice ? (
        <div className={generalLocalPriceClassNames}>
          <ul>
            <li>
              <p>{staticLocalization.order.localPrice}</p>
              <div>
                {orderBasketGeneralPrice}
                <small>{staticLocalization.general.currency}</small>
              </div>
            </li>
            {
              useLocalCard ?
                <>
                  <li>
                    <p>{staticLocalization.order.bofonsWillBeWithdrawed}</p>
                    <div>
                      {bofonQuintity}
                    </div>
                  </li>
                  <li>
                    <p>{staticLocalization.order.creditedToTheAccount}</p>
                    <div>
                      {Math.floor((orderBasketGeneralPrice - bofonQuintity) / 10)}
                    </div>
                  </li>
                </>
                :
                null
            }
          </ul>
          <ul>
            <li>
              <p>{staticLocalization.order.deliveryCost}</p>
              {typeof deliveryPrice === 'number' ? (
                <div>
                  {deliveryPrice}
                  <small>{staticLocalization.general.currency}</small>
                </div>
              ) : (
                <div>
                  0
                  <small>{staticLocalization.general.currency}</small>
                </div>
              )}
            </li>
          </ul>
        </div>
      ) : null}

      <div className='general-price'>
        {orderBasketIsEmpty ? null : (
          <>
            <div className='general-price__wrapper'>
              <span>{staticLocalization.order.together}</span>
              {showLocalPrice
                ? orderBasketGeneralPrice - bofonQuintity > 0
                  ? orderBasketGeneralPrice -
                  bofonQuintity +
                  (typeof deliveryPrice === 'number' ? deliveryPrice : null)
                  : 0
                : orderBasketGeneralPrice}
              <span className='currency'>
                {staticLocalization.general.currency}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};


OrderBascket.propTypes = {
  history: PropTypes.object,
  showLocalPrice: PropTypes.bool,
  deliveryPrice: PropTypes.any,
  notRegisterCustomerId: PropTypes.number,
  setGiftBoxIsCheked: PropTypes.func,
  giftBoxIsCheked: PropTypes.bool,
};

export default withRouter(OrderBascket);
