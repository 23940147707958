export const defaultItemProps = {
  'quantity': 0,
  'id': 0,
  'category': 0,
  'name': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'weight': 0,
  'price': 0,
  'discountPrice': null,
  'description': {
    'translations': [
      {
        'text': '<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n<p><span style=\'white-space:  pre-wrap;\'>Something going wrong</span></p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n</body>\n</html>',
        'language': 1
      }
    ]
  },
  'shortDescription': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'expiration': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'storageConditions': {
    'translations': [
      {
        'text': '<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n<p><span style=\'white-space:  pre-wrap;\'>Something going wrong</span></p>\n</body>\n</html>',
        'language': 1
      }
    ]
  },
  'article': '1',
  'metaDescription': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'metaTitle': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'length': null,
  'width': 0,
  'height': 0,
  'allergens': {
    'translations': [
      {
        'text': 'Something going wrong',
        'language': 1
      }
    ]
  },
  'images': [
    'goods_13/Slivovi bomboni1591278546175.png',
    'goods_13/Slivovi bomboni1591278546180.png',
    'goods_13/Slivovi bomboni1591278546184.png'
  ],
  'tags': [],
  'gridItemWidth': 1,
  'isSet': false,
  'setGoods': [],
  'filterValues': [],
  'isInWishlist': false,
  'isPublished': true,
  'updatedAt': 1591278546000,
  'createdBy': 1,
  'groupGoods': [],
  'slug': null,
  'popularity': 0
};
