import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Context } from '../../context';
import './NotFound.scss';

function NotFound() {

  const { staticLocalization } = useContext(Context);

  return (
    <Container fluid className='not-found'>
      <Row className='h-100'>
        <Col className='h-100'>
          <div className='not-found__wrapper'>
            <p className='g-like-h1'>404</p>
            <p className='g-like-h5'>{staticLocalization._404Page.text}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
