import React, { useState, useEffect } from 'react';
import './Template_1.scss';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../../utils/useWindowSizeHook';
import { getEllementOffsetForTemplates } from '../../../utils/getEllementOffset';

import MostPopular from '../../MostPopular/MostPopular';
import Novelty from '../../Novelty/Novelty';

const Template_1 = ({ template }) => {

  const [mostPopularLimit, setMostPopularLimit] = useState(0);
  const [noveltyLimit, setNoveltyLimit] = useState(0);

  useEffect(() => {
    const getMostPopularLimit = getEllementOffsetForTemplates(window.innerWidth, 8, 7, 9, 7, 4);
    const getNoveltyLimit = getEllementOffsetForTemplates(window.innerWidth, 20, 16, 16, 14, 8);
    setMostPopularLimit(getMostPopularLimit);
    setNoveltyLimit(getNoveltyLimit);
  }, [useWindowSize()]);

  return(
    <div className='template-1'>
      {mostPopularLimit ? <MostPopular data={template[2]} elemetsLimit={mostPopularLimit}/> : null}
      {noveltyLimit ? <Novelty data={template[3]} elemetsLimit={noveltyLimit}/> : null}
    </div>
  );
};

Template_1.propTypes = {
  template: PropTypes.array
};

export default Template_1;