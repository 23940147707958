import React, { useState, useEffect, useRef, useContext } from 'react';
import './BasketAddingAnimation.scss';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { Context } from '../../context';

const BasketAddingAnimation = ({ parentRef, imageRef, sizes, showAnimation, product }) => {
  const [canvasSizes, setCanvasSizes] = useState({});
  const canvasRef = useRef();
  const { blackTheme } = useContext(Context);
  const windowWindth = useWindowSize().width;

  useEffect(() => {
    let magicAnimation;
    const getRandomIntFromMinToMax = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення
    };

    const getStartXPosition = () => {
      const min = (canvasSizes.canvWidth - canvasSizes.imageWidth);
      const max = canvasSizes.imageWidth;
      return getRandomIntFromMinToMax(min, max);
    };

    const getStartYPosition = () => {
      const min = 80;
      const max = canvasSizes.imageHeight;
      return getRandomIntFromMinToMax(min, max);
    };

    if (imageRef && imageRef.current) {
      imageRef.current.onload = function () {
        const imageWidth = imageRef?.current?.clientWidth;
        const imageHeight = imageRef?.current?.clientHeight;
        const canvWidth = parentRef?.current?.clientWidth;
        const canvHeight = windowWindth > 991 ? imageHeight + 80 : parentRef?.current?.clientHeight;

        if (canvasRef.current) {
          canvasRef.current.height = canvHeight;
          canvasRef.current.width = canvWidth;

          setCanvasSizes({
            canvWidth,
            canvHeight,
            imageWidth,
            imageHeight
          });
        }
      };
    } else {
      const canvWidth = 250;
      const canvHeight = 250;

      if (canvasRef.current) {
        canvasRef.current.height = canvHeight;
        canvasRef.current.width = canvWidth;

        setCanvasSizes({
          canvWidth,
          canvHeight,
          imageWidth: 125,
          imageHeight: 125
        });
      }
    }

    if (canvasRef.current.getContext) {
      const ctx = canvasRef.current.getContext('2d');
      const fireflieSetting = {
        firefliesQuintity: windowWindth > 991 ? 3000 : 1000,
        firefliesSpeed: 2,
        radius: 1,
        // color: blackTheme ? '254, 246, 130' : '245, 147, 101',
        color: blackTheme ? '254, 246, 130' : '214, 178, 69',
        anglespeed: getRandomIntFromMinToMax(1, 5) / 100,
        cancelAnimationSpeed: 6
      };

      function fireflie(x, y, radius, color, randomForX, randomForY, opacity, delay) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.opacity = opacity;
        this.makeItMoreTransparent = true;
        this.blinkingQuintity = 0;
        this.angle = 0;
        this.delay = delay;
        this.delayTimer = 0;

        this.dx = randomForX ? Math.random() + (fireflieSetting.firefliesSpeed / 10000) : -(Math.random() + (fireflieSetting.firefliesSpeed / 10000));
        this.dy = randomForY ? (Math.random() + (fireflieSetting.firefliesSpeed / 10000)) : -(Math.random() + (fireflieSetting.firefliesSpeed / 10000));

        this.draw = function () {
          ctx.beginPath();
          ctx.fillStyle = `rgba(${this.color}, ${this.opacity})`;
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
          ctx.fill();
        };

        this.animate = function () {

          if (this.opacity > 0) {
            this.opacity -= (Math.random() / 25);
            this.x += ((this.dx + Math.sin(this.angle)) * fireflieSetting.cancelAnimationSpeed);
            this.y += ((this.dy + Math.sin(this.angle)) * fireflieSetting.cancelAnimationSpeed);
            this.draw();
          }
        };
      };

      // Generate fireflies ---->
      let fireflies = [];

      const generateFireflies = () => {
        fireflies = [];

        for (let i = 1; i <= fireflieSetting.firefliesQuintity; i++) {
          const { color, radius } = fireflieSetting;
          const x = getStartXPosition();
          const y = getStartYPosition();
          // const x = (canvasSizes.width / 2);
          // const y = (canvasSizes.height / 2);
          const randomForX = i % 2 === 0;
          const randomForY = getRandomIntFromMinToMax(1, 3) % 2 === 0;
          const opacity = Math.random();
          const delay = getRandomIntFromMinToMax(0, 500);

          fireflies.push(new fireflie(x, y, radius, color, randomForX, randomForY, opacity, delay));
        }
      };
      // <---- Generate fireflies

      // Render fireflies ---->

      const Update = () => {
        ctx.clearRect(0, 0, 700, 700);
        fireflies.forEach(el => {
          el.animate();
        });
        magicAnimation = requestAnimationFrame(Update);
      };

      if (canvasSizes.canvWidth) {
        generateFireflies();
        Update();
      }

      return () => {
        cancelAnimationFrame(magicAnimation);
      };
    };

  }, [showAnimation]);

  const type = product ? 'button-animation-canvas' : 'animation-canvas';

  return (
    <canvas ref={canvasRef} className={type}></canvas>
  );
};

export default BasketAddingAnimation;