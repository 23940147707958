import React, { useState, useContext, useEffect, useRef } from 'react';
import './HeroBanner.scss';
import { Context } from '../../context';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import classNames from 'classnames';
import { imagesUrl } from '../../utils/api';

import Winter from './Animations/Winter/Winter';
import Autumn from './Animations/Autumn/Autumn';
import Spring from './Animations/Spring/Spring';

function HeroBanner({ banerData }) {

  // const [canvasSizing, setCanvasSizing] = useState({});

  const { blackTheme }  = useContext(Context);

  // const candyAnimation = useRef();
  // const candy = useRef();
  // const windowWidth = useWindowSize().width;

  // useEffect(() => {

  //   if(window.innerWidth > 991){
  //     const candyRef = candy.current;
      
  //     candyRef.onload = function(){
  
  //       const candyImgWidth = candyRef.clientWidth;
  //       const candyImgHeight = candyRef.clientHeight;

  //       setCanvasSizing({
  //         candyImgWidth,
  //         candyImgHeight
  //       })
  
  //     }
  //   }
    
  // }, [blackTheme])

  // useEffect(() => {

  //   const candyRef = candy.current;
  //   const candyAnimationRef = candyAnimation.current;
  //   const { candyImgWidth, candyImgHeight } = canvasSizing;
  //   let magicAnimation;
  
  //   const getRandomInt = (min, max) => {
  //     min = Math.ceil(min);
  //     max = Math.floor(max);
  //     return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення
  //   }

  //   const fireflieSetting = {
  //     firefliesQuintity: 3000,
  //     firefliesSpeed: 2,
  //     radius: 1,
  //     // color: blackTheme ? '254, 246, 130' : '245, 147, 101',
  //     color: blackTheme ? '254, 246, 130' : '214, 178, 69',
  //     anglespeed: getRandomInt(1, 5) / 100,
  //     cancelAnimationSpeed: 6
  //   }
    
  //   candyAnimationRef.width = windowWidth > 650 ? candyImgWidth + 300 : (windowWidth - 60);
  //   candyAnimationRef.height = candyImgHeight + 150;

  //   const getStartXPosition = (candyWidth) => {
  //     if(windowWidth > 650){
  //       return getRandomInt(160, (candyWidth + 140))
  //     }
  //     else{
  //       const possibleStartProsition =  (windowWidth / 2) - candyWidth + 30;
  //       const possibleEndProsition = (windowWidth / 2);
  //       return getRandomInt(possibleStartProsition, possibleEndProsition);
      
  //     }
  //   }

  //   const getStartYPosition = (candyHeight) => {
  //     if(windowWidth > 650){
  //       return getRandomInt(80, (candyHeight + 80))
  //     }
  //     else{
  //       const possibleStartProsition =  candyAnimationRef.height;
  //       const possibleEndProsition = candyAnimationRef.height - candyHeight;
  //       return getRandomInt(possibleStartProsition, possibleEndProsition)
  //     }
  //   }

  //   if(candyAnimationRef.getContext){
  //     const ctx = candyAnimationRef.getContext('2d');

  //     function fireflie (x, y, radius, color, randomForX, randomForY, opacity, delay) {
        
  //       this.x = x;
  //       this.y = y;
  //       this.radius = radius;
  //       this.color = color;
  //       this.opacity = opacity;
  //       this.makeItMoreTransparent = true;
  //       this.blinkingQuintity = 0;
  //       this.angle = 0;
  //       this.delay = delay;
  //       this.delayTimer = 0;

  //       this.dx = randomForX ? Math.random() + (fireflieSetting.firefliesSpeed / 10000) : -(Math.random() + (fireflieSetting.firefliesSpeed / 10000));
  //       this.dy = randomForY ? (Math.random() + (fireflieSetting.firefliesSpeed / 10000)) : -(Math.random() + (fireflieSetting.firefliesSpeed / 10000));

  //       this.draw = function(){
  //         ctx.beginPath();

  //         ctx.fillStyle = `rgba(${this.color}, ${this.opacity})`;
  //         ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
  //         ctx.fill();
  //       };

  //       // this.animate = function(){
  //       //   if(this.delayTimer >= this.delay){
  //       //     this.angle += fireflieSetting.anglespeed;

  //       //     this.x += (this.dx + Math.sin(this.angle));
  //       //     this.y += this.dy;

  //       //     if(this.blinkingQuintity <= 2){
  //       //       this.opacity -= (Math.random() / 50);
  //       //       if(this.opacity <= 0){
  //       //         this.opacity = 1;
  //       //         this.blinkingQuintity++
  //       //       }
  //       //     }else{
  //       //       this.opacity -= (Math.random() / 25);
  //       //     }

  //       //     this.draw();
  //       //   }else{
  //       //     this.delayTimer++
  //       //   }
  //       // }

  //       this.animate = function(){

  //         if(this.opacity > 0){

  //           this.opacity -= (Math.random() / 25);

  //           this.x += ((this.dx + Math.sin(this.angle)) * fireflieSetting.cancelAnimationSpeed);
  //           this.y += ((this.dy + Math.sin(this.angle)) * fireflieSetting.cancelAnimationSpeed);

  //           this.draw();
            
  //         }

  //       }

  //     };
      
  //     // Generate fireflies ---->
  //     let fireflies = [];
      
  //     const generateFireflies = (fireflieSetting) => {
  //       fireflies = [];

  //       for(let i = 1; i <= fireflieSetting.firefliesQuintity; i++){
        
  //         const { color, radius } = fireflieSetting;
  //         const x = getStartXPosition(candyImgWidth);
  //         const y = getStartYPosition(candyImgHeight);
  //         const randomForX = i % 2 === 0;
  //         const randomForY = getRandomInt(1, 3) % 2 === 0;

  //         const opacity = Math.random();
  //         const delay = getRandomInt(0, 500);

  //         fireflies.push(new fireflie(x, y, radius, color, randomForX, randomForY, opacity, delay));
  //       }
  //     }
  //     // <---- Generate fireflies

  //     // Render fireflies ---->

  //     const Update = () => {
  //       ctx.clearRect(0, 0, 700, 700);

  //       fireflies.forEach(el => {
  //         el.animate()
  //       })

  //       magicAnimation = requestAnimationFrame(Update)

  //     }

  //     function mouseOverkHendler () {
      
  //       // let isAnimationStart = true;

  //       // return function(){

  //       //   isAnimationStart && generateFireflies(fireflieSetting);

  //       //   return isAnimationStart = false;
  //       // }
  //       return generateFireflies(fireflieSetting)

  //     }

  //     function scrollkHendler () {
  //       let isFirstScroll = true;
  //       return function () {

  //         if(isFirstScroll){
  //           generateFireflies(fireflieSetting);
  //           // Update();
  //         }

  //         return isFirstScroll = false
  //       }
  //     }

  //     Update();

  //     // const candyMouseOverHendler = mouseOverkHendler();
  //     const candyScrollkHendler = scrollkHendler();

  //     candyRef.addEventListener("mouseover", mouseOverkHendler);
  //     document.addEventListener('scroll', candyScrollkHendler)
  //     // <---- Render fireflies
  //   };

  //   return () => {
  //     cancelAnimationFrame(magicAnimation);
  //   }

  // }, [canvasSizing, blackTheme])

  const heroBannerClassNames = classNames(
    'hero-banner',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const candyImage = () => {
    const candyImageType = banerData[1].templateItemValue.images;
    return blackTheme ? candyImageType.find(el => el.includes('dark')) : candyImageType.find(el => el.includes('light'));
  };

  // render season animation accordingly to the current month
  const getSeason = () => {
    const now = new Date();
    const month = now.getMonth() + 1; // +1 because month starts from 0

    if (month >= 12 || month <= 2) {
      return <Winter />;
    } else if ((month >= 3 && month <= 5) || (month >= 6 && month <= 8)) { //spring and summer === one season
      return <Spring />;
    }

    return <Autumn />;
  };

  const currentSeason = getSeason();

  return (
    <div className={heroBannerClassNames}>
      {currentSeason}
      <div className='candy'>
        {/* <canvas ref={candyAnimation}></canvas> */}
        {/*<img src={`${imagesUrl}${candyImage()}`} alt='candy' ref={candy}/>*/}
        <img src={`${imagesUrl}${candyImage()}`} alt='candy'/>
      </div>
    </div>
  );
}

export default HeroBanner;
