import React, { useState, useEffect, useContext } from 'react';
import './BasketItem.scss';
import './BasketItemDetail.scss';
import { Context } from '../../context';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Counter from '../Counter/Counter';
import MainButton from '../../components/MainButton/MainButton';
import Modal from '../../components/Modal/Modal';
import BasketBoxesDetail from './BasketBoxesDetail';
import BasketIndividualDetail from './BasketIndividualDetail';

import { ReactComponent as CloseSVG, ReactComponent as CloseModalSVG } from '../../assets/icons/close.svg';

import { imagesUrl, deletGoodFromBasketById } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';
import { getPrice } from '../../utils/getGeneralPrice';
import { TYPES } from '../../constants/app';
import { changeItemCounterValueInBasket, getItemCategories, getName, getImages } from '../../utils/basketItems';
import CustomInscription from '../Forms/CustomInscription/CustomInscription';
import { dummyStaticLocalization } from '../PageContainer/staticLocalization';
import { Form } from 'formik';
import ConfirmDeleteItem from './ConfirmDeleteItem';

const BasketItem = ({ item, handleChangeGeneralPrice, setIsLoaded, items, itemsCallback }) => {
  const { id, quantity } = item;
  const [data, setData] = useState(item);
  const [counter, setCounter] = useState(quantity);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailItemCounter, setDetailItemCounter] = useState(quantity);
  const [detailToShow, setDetailToShow] = useState(null);
  const {
    settings, isLogined, blackTheme, changeGeneralPrice, basketItemsLength,
    changeBasketItemsLength, staticLocalization, curentLanguageId,
    isOpenModal, setIsOpenModal, setGeneralPrice,
    ...getCategories
  } = useContext(Context);
  const _item = isLogined ? item.itemEntity : item;
  useEffect(() => {
    setData(modifidedItemWithTranslationField(_item));
  }, []);

  const category = getItemCategories({
    item: _item,
    categories: getCategories.categories,
    languageId: curentLanguageId,
    staticLocalization,
    type: item.type
  });
  const name = getName({ item: _item, data, languageId: curentLanguageId, type: item.type });
  const description = getTranslation(_item, data, 'shortDescription', curentLanguageId); // only for "GOODS"
  const { slug } = _item;
  const images = getImages({ item: _item, isLoggedIn: isLogined, type: item.type });
  const price = getPrice(_item, item.type, isLogined);
  const entityId = item?.itemEntity?.id || id;

  const [itemAmount, setItemAmount] = useState(+quantity * +price);

  useEffect(() => {
    itemsCallback({ [entityId]: (+counter * +price) });
  }, []);

  useEffect(() => {
    itemsCallback({ [entityId]: (+counter * +price) });
    setItemAmount(+counter * +price);
  }, [counter]);

  useEffect(() => {
    setGeneralPrice(itemAmount);
  }, [itemAmount]);

  const classes = classNames(
    'basket-item',
    { 'no-buttom-border': (item.type === TYPES.BOX_WITH_GOODS || item.type === TYPES.INDIVIDUAL_ORDER) }
  );

  const counterValueChanged = (val, isIncrement) => {
    if (!(counter === 1 && val === 1 && !isIncrement)) {
      handleChangeGeneralPrice(+price, isIncrement);
      changeItemCounterValueInBasket(isLogined, item, val, 0);
      setCounter(val);
    }
  };

  const toggleModal = (e, forcedEvent) => {
    // if ((e.target === e.currentTarget) || (forcedEvent)) { // for synthetic events
    setIsOpenModal(!isOpenModal);
  };

  const getOrder = () => {
    if (item.type === 'BOX_WITH_GOODS' || item.type === 'INDIVIDUAL_ORDER') {
      return 1;
    }
    return 2;
  };

  const deleteGoodFromBasket = () => {
    setIsModalOpen(false);
    if (isLogined) {
      setIsLoaded(false);
      deletGoodFromBasketById(item.id, 0).then(() => {
        changeBasketItemsLength(basketItemsLength - 1);
      });
    } else {
      const basket = localStorage.getItem('lmsh-basket');
      const parsedBasket = JSON.parse(basket);
      const newBasket = parsedBasket.filter(el => (el?.itemEntity?.id || el.id) !== entityId);
      changeBasketItemsLength(basketItemsLength - 1);
      localStorage.setItem('lmsh-basket', JSON.stringify(newBasket));
    }

    itemsCallback({ [entityId]: false });
  };

  const handleDeleteItemFromBasket = () => {
    setIsModalOpen(true);
  };

  const detailToShowHandler = (itemType) => {
    setDetailToShow(itemType);
    toggleModal();
  };

  const detailCloseHandler = () => {
    setDetailToShow(null);
    toggleModal();
  };

  return (
    <>
      <div className={classes} style={{ order: getOrder() }}>
        <div className='wrapper'>
          <div className='img-wrapper'>
            {item.type === TYPES.GOODS
              ? <Link to={`/product/${slug}`}>
                <img src={`${imagesUrl}${images}?v=${settings.frontendVersion}`} alt='basket item'/>
              </Link>
              : <img src={`${imagesUrl}${images}?v=${settings.frontendVersion}`} alt='basket item'/>
            }
          </div>
          <div className='text'>
            <div className='text__title'>
              {category}
            </div>
            <Link to={`/product/${slug}`} className='text__name'>
              {name}
            </Link>
            <div className='text__description'>
              {description}
            </div>
            {(item.type === TYPES.BOX_WITH_GOODS || item.type === TYPES.INDIVIDUAL_ORDER) && <>
              <MainButton palette='secondary' className='btns__order-basket-item'
                onClick={() => detailToShowHandler(item.type)}
              >
                {staticLocalization.basket?.showDetailBtn || 'Переглянути'}
              </MainButton>
            </>
            }
          </div>
          <div className='counter-and-price'>
            {
              item.type === TYPES.BOX ? null
                :
                <Counter price={price} counterValueChanged={(val, isIncrement) => counterValueChanged(val, isIncrement)}
                  counterValue={+counter}/>
            }
            <div className='counter-and-price__price'>
              {price * counter}
              <div className='currency'>
                {staticLocalization.general.currency}
              </div>
            </div>
          </div>
          <div className='del'>
            <button onClick={handleDeleteItemFromBasket}><CloseModalSVG/></button>
          </div>
        </div>
        {
          (isOpenModal && detailToShow === TYPES.INDIVIDUAL_ORDER) && <>
            <Modal className='align-items-start'>
              <BasketIndividualDetail
                detailCloseHandler={detailCloseHandler}
                images={images}
                category={category}
                description={description}
                counter={counter}
                setCounter={setCounter}
                price={price}
                handleChangeGeneralPrice={handleChangeGeneralPrice}
                changeItemCounterValueInBasket={changeItemCounterValueInBasket}
                isLogined={isLogined}
                onClose={detailCloseHandler}
                item={item}
              />
            </Modal>
          </>
        }
        {(isOpenModal && detailToShow === TYPES.BOX_WITH_GOODS) && <>
          <Modal className='align-items-start'>
            <BasketBoxesDetail
              item={item}
              onClose={detailCloseHandler}
              counter={counter}
              handleChangeGeneralPrice={handleChangeGeneralPrice}
              changeItemCounterValueInBasket={changeItemCounterValueInBasket}
              setCounter={setCounter}
              isLogined={isLogined}
              price={price}
            />
          </Modal>
        </>
        }
      </div>
      {isModalOpen && <Modal className='align-items-center'>
        <ConfirmDeleteItem setIsModalOpen={setIsModalOpen} deleteGoodFromBasket={deleteGoodFromBasket}/>
      </Modal>}
    </>
  );
};

BasketItem.propTypes = {
  item: PropTypes.object,
  handleChangeGeneralPrice: PropTypes.func,
  setItems: PropTypes.func,
  items: PropTypes.object,
  itemsCallback: PropTypes.func,
};

export default BasketItem;
