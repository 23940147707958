import React, { useState, useEffect, useContext } from 'react';
import './Candy.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MainTitle from '../../components/MainTitle/MainTitle';
import MainItem from '../../components/MainItem/MainItem';
import MainButton from '../../components/MainButton/MainButton';
import Pagination from '../../components/Pagination/Pagination';
import Filter from '../../components/Filter/Filter';
import Dropdown from '../../components/Dropdown/Dropdown';
import Loader from '../../components/Loader/Loader';

import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import {
  getGroupById,
  getGroupItems,
  getFiltersByGroupId,
  getCategoryId
} from '../../utils/api';
import { getTranslation, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { getEllementOffset } from '../../utils/getEllementOffset';
import { Context } from '../../context';
import { ReactComponent as ArrowToLeftIcon } from './../../assets/icons/previous-arrow.svg';
import { ReactComponent as FilterOpenIcon } from './../../assets/icons/filter-open.svg';
import { ReactComponent as CloseIcon } from './../../assets/icons/close.svg';

const Candy = ({ history }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const { width } = useWindowSize();
  const filterToggleHandler = () => {
    setOpenFilter(!openFilter);
  };
  const filterToggleClass = classNames(
    'sorting-bar-wrapper',
    { 'open': openFilter }
  );

  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [filters, setFilters] = useState('');
  const [metaData, setMetaData] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [smallLoader, setSmallLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(true);
  const [countOfElements, setCountOfElements] = useState(0);
  const [elementsOffset] = useState(getEllementOffset(width, 25, 20, 15, 20));
  const [loadMorePage, setLoadMorePage] = useState(1);
  const [canShowMore, setCanShowMore] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [categoryForFilter, setCategoryForFilter] = useState('');
  const [categoryForFilterItem, setCategoryForFilterItem] = useState('');
  const [sortValue, setSortValue] = useState('NEW');
  const [sortToCheapest, setSortToCheapest] = useState(false);
  const [initialPaginationPage, setInitialPaginationPage] = useState(true);
  const [locationId, setLocationId] = useState(false);
  const { staticLocalization, curentLanguageId } = useContext(Context);

  useEffect(() => {
    const slug = history.location.pathname.split('/')[2];
    getCategoryId(slug).then(res => {
      return res.json();
    }).then(el => {
      setLocationId(el.result.shift().id);
    });
  }, [[], history.location.pathname]);

  useEffect(() => {
    if (locationId) {
      setIsLoaded(false);
      const newGetGropItem = new Promise((resolve, reject) => {
        const query = new URLSearchParams(window.location.search);
        getGroupItems({
          groupId: locationId,
          limit: elementsOffset,
          offset: query.get('page') ?? 0,
          fitlerIds: query.get('fitlerIds') ?? 0,
          fitlerValues: query.get('fitlerValues') ?? 0,
          sort: sortValue,
          sortAsc: sortToCheapest,
          langId: curentLanguageId
        }).then((res) => { // Get all group items
          resolve(res.json());
        });
      });

      const newgetGroupById = new Promise((resolve, reject) => {
        getGroupById(locationId).then(res => {
          resolve(res.json());
        });
      });

      const newGetFiltersByGroup = new Promise((resolve, reject) => {
        getFiltersByGroupId(locationId).then(res => {
          resolve(res.json());
        });
      });

      Promise.all([newGetGropItem, newgetGroupById, newGetFiltersByGroup]).then(res => {
        const [newGetGropItem, newgetGroupById, newGetFiltersByGroup] = res;

        setData(newGetGropItem.result);
        setCountOfElements(newGetGropItem.count);

        const groupTitle = getTranslation(newgetGroupById, modifidedItemWithTranslationField(newgetGroupById), 'name', curentLanguageId);
        setTitle(groupTitle);
        const { metaDescription, metaTitle } = newgetGroupById;
        const getMetaData = {
          metaDescription,
          metaTitle,
        };

        setMetaData(getMetaData);

        const groupFilters = newGetFiltersByGroup.result;
        setFilters(groupFilters);

        if (newGetGropItem.count <= elementsOffset) {
          setCanShowMore(false);
          setShowPagination(false);
        } else {
          setCanShowMore(true);
          setShowPagination(true);
        }

        setIsLoaded(true);
      });
    }
  }, [locationId, curentLanguageId]);

  const loadDataWithFilters = (arr) => {
    setFilterLoader(false);

    const filterCategory = [];
    const filterCategoryItem = [];

    for (const item of arr) {
      if (item.checked === true) {
        filterCategory.push(item.name);
        filterCategoryItem.push(item.value);
      }
    }

    const fitlerIds = filterCategory.join(',');
    const fitlerValues = filterCategoryItem.join(',');
    const url = new URL(window.location);
    const id = url.searchParams.get('id');
    const historyFitlerIds = fitlerIds ? '&fitlerIds=' + fitlerIds : '';
    const historyFitlerValues = fitlerValues ? '&fitlerValues=' + fitlerValues : '';

    history.push({
      search: (id ? ('id=' + id) : '') + historyFitlerIds + historyFitlerValues,
    });

    getGroupItems({
      groupId: locationId,
      limit: elementsOffset,
      offset: 0,
      fitlerIds,
      fitlerValues,
      sort: sortValue,
      sortAsc: sortToCheapest,
      langId: curentLanguageId
    }).then(res => {
      return res.json();
    }).then(el => {
      setCategoryForFilter(fitlerIds);
      setCategoryForFilterItem(fitlerValues);
      setData(el.result);
      setCountOfElements(el.count);
      if (el.count <= elementsOffset) {
        setCanShowMore(false);
        setShowPagination(false);
      } else {
        setCanShowMore(true);
        setShowPagination(true);
      }
      // setCanShowMore(el.count >= elementsOffset);
      setFilterLoader(true);
    });
  };

  const paginationLogick = (page) => {
    setFilterLoader(false);
    const offset = elementsOffset * page;

    getGroupItems({
      groupId: locationId,
      limit: elementsOffset,
      offset,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: sortValue,
      sortAsc: sortToCheapest,
      langId: curentLanguageId
    }).then((res) => { // Get all group items
      return res.json();
    }).then((el) => {
      setCanShowMore(el.result.length >= elementsOffset); // add or remove showMore btn
      setLoadMorePage((page + 1));
      setData(el.result);
      setCountOfElements(el.count);
      setFilterLoader(true);
    });
  };

  const showMore = () => {
    setSmallLoader(true);
    const newPage = loadMorePage * elementsOffset;
    getGroupItems({
      groupId: locationId,
      limit: elementsOffset,
      offset: newPage,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: sortValue,
      sortAsc: sortToCheapest,
      langId: curentLanguageId
    }).then((res) => { // Get all group items
      return res.json();
    }).then((el) => {
      const newData = [...data, ...el.result];
      setCanShowMore(newData.length < el.count); // add or remove showMore btn
      setData(newData);
      setLoadMorePage((loadMorePage + 1));
      setSmallLoader(false);
    });
  };

  const sortingLogic = (val) => {
    setFilterLoader(false);
    let { value } = val;
    let sortAsc = false;

    const afterDataLoading = (el) => {
      setData(el.result);
      setFilterLoader(true);
    };

    setLoadMorePage(1);

    if (value === 'PRICE UP' || value === 'NAME DOWN') {
      sortAsc = true;
    }

    if (value === 'PRICE DOWN' || value === 'PRICE UP') {
      value = 'PRICE';
    }

    if (value === 'NAME DOWN' || value === 'NAME UP') {
      value = 'NAME';
    }

    return getGroupItems({
      groupId: locationId,
      limit: elementsOffset,
      offset: 0,
      fitlerIds: categoryForFilter,
      fitlerValues: categoryForFilterItem,
      sort: value,
      sortAsc,
      langId: curentLanguageId
    }).then((res) => { // Get all group items
      return res.json();
    }).then((el) => {
      setSortValue(value);
      setSortToCheapest(sortAsc);
      setCanShowMore(elementsOffset < el.count);
      afterDataLoading(el);
      setInitialPaginationPage(!initialPaginationPage);
    });
  };

  const metaTitle = getTranslation(metaData, modifidedItemWithTranslationField(metaData), 'metaTitle', curentLanguageId);
  const metaDescription = getTranslation(metaData, modifidedItemWithTranslationField(metaData), 'metaDescription', curentLanguageId);

  const renderPage = () => {
    if (isLoaded) {
      return (
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name='description' content={metaDescription} data-react-helmet='true'/>
          </Helmet>
          <Container fluid className='candy-container'>
            <Row>
              <Col xs={12}>
                <MainTitle>
                  {title}
                </MainTitle>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto mb-3'>
                <div className='title-submenu'>
                  <div className='title-submenu__desktop'>
                    <div className='dropdown-wrapper ml-auto'>
                      <Dropdown isSearchable={false} onChange={(val) => sortingLogic(val)}/>
                    </div>
                  </div>
                  <div className='title-submenu__mobile'>
                    {openFilter ?
                      <p className='title-submenu__filter-hint'>{staticLocalization.category.filteringHint}</p> :
                      <MainButton palette='secondary' onClick={() => history.goBack()}>
                        <ArrowToLeftIcon className='mr-3'/>
                        {staticLocalization.category.goBack}
                      </MainButton>
                    }
                    <MainButton palette='secondary' className='btns--square' onClick={filterToggleHandler}>
                      {openFilter ? <CloseIcon/> : <FilterOpenIcon/>}
                    </MainButton>
                    {
                      openFilter && <div className='sorting-bar-wrapper__mobile'>
                        <Filter data={filters} loadDataWithFilters={(arr) => {
                          loadDataWithFilters(arr);
                          setOpenFilter();
                        }} filterBtnText={staticLocalization.category.filterBtnText}/>
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='content-field'>
                <div
                  className={classNames('sorting-bar-wrapper', { 'sorting-bar-wrapper--no-filters': !filters.length })}>
                  <Filter data={filters} loadDataWithFilters={(arr) => {
                    loadDataWithFilters(arr);
                  }} filterBtnText={staticLocalization.category.filterBtnText}/>
                </div>
                <div className={classNames('cart-wrapper', { 'cart-wrapper--no-filters': !filters.length })}>
                  {
                    filterLoader ?
                      <>
                        <div className='main-item-wrapper'>
                          {data.map((el) => <MainItem key={el.id}
                            withCounter
                            withDescription
                            item={el}
                            buttonLink='/product/'
                            categoryId={Number(locationId)}
                            buttonTitle={staticLocalization.category.mainItemBtnText}/>)}
                        </div>
                        {smallLoader ? <Loader/> : null}
                      </>
                      :
                      <Loader/>
                  }
                </div>

                {
                  canShowMore ?
                    <MainButton className='btns--main btns--showmore' onClick={() => showMore()}>
                      {staticLocalization.category.showMoreBtnText}
                    </MainButton>
                    :
                    null
                }
              </Col>
              {
                showPagination ?
                  <Col xs={12} className='pagination-wrapper d-none d-lg-block'>
                    <Pagination
                      elementCount={countOfElements}
                      elementLimit={elementsOffset}
                      returnPageOffset={offset => paginationLogick(offset)}
                      initialOffset={initialPaginationPage}
                    />
                  </Col>
                  :
                  null
              }
            </Row>
          </Container>
        </>
      );
    }
    return (
      <Loader/>
    );
  };

  return (
    renderPage()
  );
};

Candy.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object
};

Candy.defaultProps = {
  title: 'Default page'
};

export default withRouter(Candy);
