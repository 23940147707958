import React, {useState, useContext, useEffect} from 'react';
import './PhoneVerification.scss';
import PropTypes from 'prop-types';
import { Context } from '../../../context';
import { Formik, Form, Field } from 'formik';
import {   } from 'react-router-dom'; 
import * as Yup from 'yup';

import { ReactComponent as CloseIcon } from '../../../assets/icons/modal-close-icon.svg';
import { ReactComponent as CloseIconLight } from '../../../assets/icons/modal-close-icon--light.svg';

import {verifyUserByTelephone, resetPasswordConfirmation, resendSMS} from '../../../utils/api';

import MainButton from '../../MainButton/MainButton';
import Loader from '../../Loader/Loader';

const PhoneVerification = ({ showVerifyForm, showRegisterForm, type, data }) => {

  const [formIsSent, setFormIsSent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showCongratulationsMessage, setShowCongratulationsMessage] = useState(false);
  const [isForgotPasswordCongratulationsMessage, setIsForgotPasswordCongratulationsMessage] = useState(false);

  const { blackTheme, staticLocalization, setBodyUnscroll } = useContext(Context);
  const { phoneVerificationForm } = staticLocalization;

  const [button, setButton] = useState(false);
  const [showSpan, setShowSpan] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [resendCounter, setResendCounter] = useState(0);
  const [resendCounterError, setResendCounterError] = useState(false);


  const PhoneVerification  = Yup.object().shape({
    code: Yup.string()
      .required(phoneVerificationForm.codePlaceholderError),
  });

  const closeModal = () => {
    setShowCongratulationsMessage(false);
    showVerifyForm(false, '');
    showRegisterForm();
    setBodyUnscroll(false);
    setFormIsSent(false);
  };

 const onClick = () => {
     if (resendCounter <= 3) {
         setResendCounter(+resendCounter +1);
         setShowSpan(true);
         setButton(false);
         setSeconds(60);
         resendSMS(data.id, data.phone);
     }
 }

    useEffect(() => {
        if (resendCounter <= 3) {
            if (seconds > 0) {
                setTimeout(() => setSeconds(seconds - 1), 1000);
            } else {
                setButton(true);
                setShowSpan(false);
            }
        } else {
            setResendCounterError(true);
            setButton(false);
            setShowSpan(false);
        }

    });

 const resetErrorMessage = ()=> {
   setShowErrorMessage(false);
 };

  const close = () => {
    showVerifyForm(false);
    setBodyUnscroll(false);
  };
    
  return(
    <>
      {
        showCongratulationsMessage ?
          <div className='congratulations-message'>
            <button onClick={() => closeModal()}>
              { blackTheme ? <CloseIcon /> : <CloseIconLight /> }
            </button>
            <div className="title">
              <h2>
                {
                  isForgotPasswordCongratulationsMessage ?
                    phoneVerificationForm.forgotPasswordCongratulationsTitle
                    :
                    phoneVerificationForm.registerCongratulationsTitle
                }
              </h2>
              <p>
              {
                  isForgotPasswordCongratulationsMessage ?
                    phoneVerificationForm.forgotPasswordCongratulationsSubtitle
                    :
                    phoneVerificationForm.registerCongratulationsSubtitle
                } 
              </p>
            </div>
            <div className="btn-group">
              <MainButton href="/" palette='secondary'>
                На головну
              </MainButton>
              <MainButton onClick={() => closeModal()}>
                Увійти
              </MainButton>
            </div>
          </div>
          :
          <Formik
            initialValues={{
              code: '',
            }}
            validationSchema={PhoneVerification}
            onSubmit={values => {

              setFormIsSent(true);
              setShowErrorMessage(false);

              switch (type){
              case 'registration':
                verifyUserByTelephone(values.code).then(res => {
                  if(res.ok){
                    res.json().then(() => {
                      setIsForgotPasswordCongratulationsMessage(false);
                      setShowCongratulationsMessage(true);
                    });
                  }else{
                    setShowErrorMessage(true);
                    setFormIsSent(false);
                  }
                });
                break;
              case 'forgot-password':
                resetPasswordConfirmation(values.code).then(res => {
                  if(res.ok){
                    setIsForgotPasswordCongratulationsMessage(true);
                    setShowCongratulationsMessage(true);
                  }else{
                    setShowErrorMessage(true);
                    setFormIsSent(false);
                  }
                });

                break;
              default: return null;
              }

            }}
          >
            {({ errors, touched, handleSubmit }) => (
              <Form className='g-form-style phone-verification-form'>
                <div className="wrapper">
                  <button type='button' onClick={() => close()} className='close-modal'>
                    { blackTheme ? <CloseIcon /> : <CloseIconLight /> }
                  </button>
                  <div className='title'>
                    <h3>{phoneVerificationForm.title}</h3>
                    <p>{phoneVerificationForm.subtitle}</p>
                  </div>
                  <label>
                    <p className='required'>{phoneVerificationForm.code}</p>
                    <Field type='code' name='code' placeholder={phoneVerificationForm.codePlaceholder} onKeyUp={resetErrorMessage}/>
                    {errors.code && touched.code ? ( // Error
                      <div className='error'>{errors.code}</div>
                    ) : null}
                  </label>
                  {   // Show loader until the form is sent
                    formIsSent ? <div className='form-spiner'><Loader /></div> : null
                  }
                  {
                    showErrorMessage ? <div className='error errorMessage'>{phoneVerificationForm.errorMessage}</div> : null
                  }
                  <div className='buttons'>
                  <MainButton type='submit' palette='primary' onMouseDown={handleSubmit}>
                    {phoneVerificationForm.btnText}
                  </MainButton>
                  <MainButton type='submit' palette='secondary'onClick={onClick} show={button}>
                    {phoneVerificationForm.btnResend}
                  </MainButton>
                  </div>
                  {showSpan ? <span className='countdown'> {phoneVerificationForm.ResendSMS} {seconds} </span> : null}
                  {resendCounterError ? <span className='countdown'> {phoneVerificationForm.ResendSMSError} </span> : null}
                </div>
              </Form>
            )}
          </Formik>
      }
    </>
  );
};

PhoneVerification.propTypes = {
  showVerifyForm: PropTypes.func,
  showRegisterForm: PropTypes.func,
  data: PropTypes.object,
  type: PropTypes.string
};

export default PhoneVerification;
