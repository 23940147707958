import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import './Profile.scss';
import classNames from 'classnames';
import { Context } from '../../context';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { getGeneralSetings, getUserOrdersById } from '../../utils/api';
import { scrollToTop } from '../../utils/scrollToTop';

import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import Loader from '../../components/Loader/Loader';
import OrderBascketItem from '../../components/OrderBasket/OrderBascketItem';


import { ReactComponent as PrevIcon } from './../../assets/icons/previous-arrow.svg';
import { ReactComponent as ButtonSide } from './../../assets/images/button-side.svg';
import { ReactComponent as ButtonBody } from './../../assets/images/button-body.svg';

function ProfileOrdersDetail(props) {

  const { staticLocalization, blackTheme, settings } = useContext(Context);
  const { personalOfficeMyOrders, personalOffice, deliveryForm, order } = staticLocalization;

  const { width } = useWindowSize();
  const LAPTOP = 991;
  const [showMenu, setShowMenu] = useState(false);
  const [dataAboutOrder, setDataAboutOrder] = useState([]);
  const [showBasketData, setShowBasketData] = useState({ GOODS: [], BOX_WITH_GOODS: [], INDIVIDUAL_ORDER: []});
  const [isLoaded, setIsLoaded] = useState(false);

  const slug = useParams();
  const subtitle = `${personalOfficeMyOrders.orderDetailsTitle} №${dataAboutOrder.orderNumber}`;
  const subtitle2 = personalOfficeMyOrders.basketTitle;
  const profileTitle1 = personalOfficeMyOrders.recipient;
  const profileTitle2 = personalOfficeMyOrders.telephone;
  const profileTitle3 = personalOfficeMyOrders.deliveryMethod;
  const profileTitle4 = 'Місто';
  const profileTitle5 = 'Відділення';
  const profileTitle6 = 'Адреса';

  const buttonTrackTitle = personalOfficeMyOrders.buttonTrackTitle;
  const accountQuitButtonTitle = personalOffice.accountQuitButtonTitle;
  const backButtonTitle = personalOffice.backButtonTitle;

  const location = useLocation();

  let additionalProtectionPrice = 0;
  if (dataAboutOrder.additionalProtection) {
    additionalProtectionPrice = settings.additionalProtectionPrice;
  }

  const selectGoods = (el) => {
    // select items by type to show
    const _goods = [];
    const _boxWithGoods = [];
    const _individuslOrder = [];
    // BOX shows in the same frame as goods
    el.map(item => {
      switch (item.type) {
      case 'GOODS':
        _goods.push(item);
        break;
      case 'BOX_WITH_GOODS':
        _boxWithGoods.push(item);
        break;
      case 'INDIVIDUAL_ORDER':
        _individuslOrder.push(item);
        break;
      case 'BOX':
        _goods.push(item);
        break;
      default:
      }
    });
    setShowBasketData({
      GOODS: _goods,
      BOX_WITH_GOODS: _boxWithGoods,
      INDIVIDUAL_ORDER: _individuslOrder
    });
  };

  // TODO: Дописати для інших типів
  const deliveryType = () => {
    switch (dataAboutOrder.recipient.deliveryType) {
    case 'SELF_PICKUP':
      return personalOfficeMyOrders.SELF_PICKUP;
    case 'COURIER':
      return deliveryForm.deliveryAndPaymentTypes_COURIER;
    case 'PROVIDER':
      return deliveryForm.deliveryAndPaymentTypes_PROVIDER;
    default:
      return;
    }
  };

  useEffect(() => {

    scrollToTop();

    if (showMenu !== false) {
      setShowMenu(false);
    }
  }, [location.key]);

  useEffect(() => {
    getUserOrdersById(slug.id).then(res => {
      return res.json();
    }).then(el => {
      setDataAboutOrder(el);
      selectGoods(el.basket);
      setIsLoaded(true);
    });
  }, []);

  const showMenuHandler = () => {
    setShowMenu(true);
  };

  const shippingCostClassNames = classNames(
    'shipping-cost',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  return (
    <>
      <Helmet>
        <title>
                    Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='profile-orders-detail'>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {personalOfficeMyOrders.mainTitle}
            </MainTitle>
          </Col>
        </Row>
        {width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous'
                onClick={showMenuHandler}>
                <PrevIcon className='profile__icon-previous'/>
                <span>{backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12}>
            {
              isLoaded ?
                <div className='profile-orders-detail__grid'>
                  <div className='profile-orders-detail__content'>
                    <h3 className='profile__subtitle profile__subtitle--detail'>{subtitle}</h3>
                    <div className='profile-orders-detail__grid-content'>
                      {((width <= LAPTOP && showMenu) || width > LAPTOP) && <>
                        <div className='profile__menu-wrapper'>
                          <ProfileMenu/>
                        </div>
                      </>
                      }
                      <div className='profile-orders-detail__description'>
                        <p>
                          <strong className='g-like-14'>{profileTitle1}</strong>
                          <span className='g-like-h6'>

                            {`${dataAboutOrder.recipient.firstName} ${dataAboutOrder.recipient.lastName}`}
                          </span>
                        </p>
                        <p>
                          <strong className='g-like-14'>{profileTitle2}</strong>
                          <span className='g-like-h6'>
                            {dataAboutOrder.recipient.phone}
                          </span>
                        </p>
                        <p>
                          <strong className='g-like-14'>{profileTitle3}</strong>
                          <span className='g-like-h6'>
                            {deliveryType()}
                          </span>
                        </p>
                        <p>
                          <strong className='g-like-14'>{profileTitle4}</strong>
                          <span className='g-like-h6'>
                            {`${dataAboutOrder.recipient.city.name}`}
                          </span>
                        </p>
                        {dataAboutOrder.recipient.npWarehouse &&
                          <p>
                            <strong className='g-like-14'>{profileTitle5}</strong>
                            <span className='g-like-h6'>
                              {`${dataAboutOrder.recipient.npWarehouse.description}`}
                            </span>
                          </p>
                        }
                        {dataAboutOrder.recipient.address &&
                          <p>
                            <strong className='g-like-14'>{profileTitle6}</strong>
                            <span className='g-like-h6'>
                              {`${dataAboutOrder.recipient.address}, ${dataAboutOrder.recipient.building || ''}`}
                            </span>
                          </p>
                        }
                        {dataAboutOrder.recipient.givingPoint.address &&
                          <p>
                            <strong className='g-like-14'>{profileTitle6}</strong>
                            <span className='g-like-h6'>
                              {`${dataAboutOrder.recipient.givingPoint.address}`}
                            </span>
                          </p>
                        }
                        {
                          dataAboutOrder.recipient.deliveryType === 'PROVIDER' && dataAboutOrder.status === 'RECEIVED' ?
                            <a href='https://novaposhta.ua/' target='_blank'
                              className='btns btns--primary profile__btns-track'>
                              {buttonTrackTitle}
                              <div className='btns__buttons'>
                                <ButtonSide/>
                                <ButtonBody/>
                                <ButtonSide/>
                              </div>
                            </a>
                            :
                            null
                        }
                        {width <= LAPTOP && <>
                          <MainButton palette='secondary' className='profile__btns-quit'>
                            {accountQuitButtonTitle}
                          </MainButton>
                        </>
                        }
                      </div>

                    </div>
                  </div>

                  <div className='profile-orders-detail__order-wrapper'>
                    <h3 className='profile__subtitle profile__subtitle--detail'>{subtitle2}</h3>

                    <div className='order-basckets__wrapper'>
                      {
                        ['INDIVIDUAL_ORDER', 'BOX_WITH_GOODS', 'GOODS'].map(
                          (item) => {
                            const isComplex = (item === 'INDIVIDUAL_ORDER' || item === 'BOX_WITH_GOODS') ? 'complex' : '';
                            if (isComplex) {
                              if (showBasketData[item].length > 0) {
                                return (<>
                                  {showBasketData[item].map((el) => (
                                    <div
                                      className={`order-bascket__wrapper ${isComplex}`}
                                      key={`${item}-${el.id}`}>
                                      <OrderBascketItem item={el} key={el.id}
                                        buyPrice={el.buyPrice}
                                        isOrder={true}/>
                                    </div>
                                  ))}
                                </>);
                              }
                            } else {
                              if (showBasketData[item].length > 0) {
                                return (
                                  <div
                                    className={`order-bascket__wrapper ${isComplex}`}
                                    key={item}>
                                    {showBasketData[item].map((el) => (
                                      <OrderBascketItem item={el} key={el.id}
                                        buyPrice={el.buyPrice}
                                        isOrder={true}/>
                                    ))}
                                  </div>
                                );
                              }
                            }
                          }
                        )
                      }
                    </div>
                    {
                      dataAboutOrder.additionalProtection ?
                        <div className={'order-bascket__wrapper complex additional-protection additional-protection__flex'}>
                          <h3>{staticLocalization.order.additionalBoxAddedMesssage}</h3>
                          <div className='price-and-close'>
                            <div className='price'>
                              {additionalProtectionPrice}
                              <div className='currency'>
                                {staticLocalization.general.currency}
                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    }

                    <div className='general-price'>
                      {
                        +dataAboutOrder.bofon > 0 ?
                          <ul className={shippingCostClassNames}>
                            <li>
                              <p>{staticLocalization.order.discount}</p>
                              <div>{dataAboutOrder.bofon}</div>
                            </li>
                            <li>
                              <p>{staticLocalization.order.bofonEarned}</p>
                              <div>{dataAboutOrder.lokalBalanceEarn}</div>
                            </li>
                          </ul>
                          :
                          null
                      }

                      {
                        +dataAboutOrder.shippingCost > 0 ?
                          <ul className={shippingCostClassNames}>
                            <li>
                              <p>{order.localPrice}</p>
                              <div>{(dataAboutOrder.totalPrice - +dataAboutOrder.shippingCost).toFixed(0)}</div>
                            </li>
                            <li>
                              <p>{order.deliveryCost}</p>
                              <div>{dataAboutOrder.shippingCost}</div>
                            </li>
                          </ul>
                          :
                          null
                      }

                      <div className='general-price__wrapper'>

                        <span>{staticLocalization.order.together}</span>
                        {dataAboutOrder.totalPrice}
                        <span className='currency'>
                          {staticLocalization.general.currency}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <Loader/>
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProfileOrdersDetail;
