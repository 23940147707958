import React, {useState, useCallback, useContext} from 'react'
import './DropZone.scss';
import {useDropzone} from 'react-dropzone';
import { Context } from '../../../context';

import MainButton from '../../MainButton/MainButton';

import {ReactComponent as CloseBtn} from '../../../assets/icons/modal-close-icon.svg';
import {ReactComponent as CloseBtnLight} from '../../../assets/icons/modal-close-icon--light.svg';

const DropZone = ({ onSubmit, content }) => {

    const { blackTheme, staticLocalization, setIsOpenModal, isOpenModal } = useContext(Context);
    const { dropZone } = staticLocalization;

    const [onError, setOnError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [imageFile, setImageFile] = useState({
        file: null,
        base64string: ''
    });

    const toggleModal = (e, forcedEvent) => {
        if ((e.target === e.currentTarget) || (forcedEvent)) {
            setIsOpenModal(!isOpenModal);
        }
    };

    const closeHandler = (e) => {
        toggleModal(e, true);
    };


    const onDrop = useCallback((acceptedFiles, rejectedFiles ) => {
        if(acceptedFiles.length > 0){
            setOnError(false);
            setImageFile(item => ({...item, file: acceptedFiles[0]}));
            //const linkToFile = URL.createObjectURL(acceptedFiles[0]);
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onloadend = () => {
                const _base64String = reader.result;
                setImageFile(item => ({...item, base64string: _base64String}));
            }
        }else{
            switch(rejectedFiles[0].errors[0].code){
                case 'file-invalid-type':
                    setErrorMessage(dropZone.errorInvalidType);
                    break;
                case 'file-too-large': 
                    setErrorMessage(dropZone.errorFileTooLarge);
                    break;
                default: setErrorMessage(dropZone.anotnerError);
            }
            setOnError(true);
        }
    }, [])

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png',
        maxSize: 5000000
    })

    const handleSendFile = () => {
        if (imageFile.file) {
            onSubmit(imageFile, content);
        }
    }

    return(
        <div className="drop-zone-inner">
            <div className="drop-zone-wrapper">
                <div className="close-btn">
                    <button onClick={closeHandler}>
                        {blackTheme ? <CloseBtn /> : <CloseBtnLight />}
                    </button>
                </div>
                <div className='text'>
                    <h3>{dropZone.title}</h3>
                    <div className="description">
                        <p>{dropZone.subtitle}</p>
                    </div>
                </div>

                <div {...getRootProps()} className='drop-zone'>
                    <input {...getInputProps()} />
                    <div className='title'>
                        {dropZone.dropZoneTitle}
                    </div>
                    <p>{dropZone.dropZoneSubTitle}</p>
                    <div className='file-name message'>
                        {acceptedFiles[0] ? acceptedFiles[0].name : '' }
                    </div>
                    {
                    onError ? 
                        <div className='error message'>
                            {errorMessage}
                        </div>
                        :
                        null
                    }
                </div>
                <div className="drop-zone-wrapper__image">
                    {imageFile.base64string && <img src={imageFile.base64string}/>}
                </div>
                
                <div className="btn-group">
                    <MainButton palette='secondary' onMouseDown={closeHandler}>{dropZone.cancel}</MainButton>
                    <MainButton onMouseDown={handleSendFile}>{dropZone.add}</MainButton>
                </div>
            </div>
        </div>
    );
};

export default DropZone;