import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './ChangePassword.scss';
import { Context } from '../../../context';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { changePasword } from '../../../utils/api';

import MainBatton from '../../MainButton/MainButton';
import ShowPassword from '../ShowPassword/ShowPassword';
import Loader from '../../Loader/Loader';

const ChangePasswordForm = ({ isProfile }) => {
  const [sendingIsSuccess, setSendingIsSuccess] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [formIsSent, setFormIsSent] = useState(false);

  const { staticLocalization } = useContext(Context);
  const { changePasswordForm } = staticLocalization;

  const ChangePasswordSchema  = Yup.object().shape({
    password: Yup.string()
      .required(changePasswordForm.oldPasswordError),
    newPassword: Yup.string()
      .required(changePasswordForm.passwordError)
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, changePasswordForm.passwordRegexError),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], changePasswordForm.passwordConfirmationError)
  });

  return(
    <Formik
      initialValues={{
        password: '',
        newPassword: '',
        confirmNewPassword: ''
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={(values, { resetForm }) => {
        // onSubmitHandler
        const { password, newPassword } = values;
        setFormIsSent(true);
        setShowMessage(false);
        changePasword(password, newPassword).then(res => {
          if(res.ok){
            resetForm({ values: '' });
          }
          setShowMessage(true);
          setSendingIsSuccess(res.ok);
          setFormIsSent(false);
        });
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Form className='g-form-style change-password-form'>

          <label>
            <p className='required'>{changePasswordForm.oldPassword}</p>
            <Field type='password' name='password' placeholder={changePasswordForm.oldPasswordPlaceholder} component={ShowPassword}/>
            {errors.password && touched.password ? ( // Error
              <div className='error'>{errors.password}</div>
            ) : null}
          </label>

          <label>
            <p className='required'>{changePasswordForm.password}</p>
            <Field type='password' name='newPassword' placeholder={changePasswordForm.passwordPlaceholder} component={ShowPassword}/>
            {errors.newPassword && touched.newPassword ? ( // Error
              <div className='error'>{errors.newPassword}</div>
            ) : null}
          </label>

          <label>
            <p className='required'>{changePasswordForm.passwordConfirmation}</p>
            <Field type='password' name='confirmNewPassword' placeholder={changePasswordForm.passwordConfirmationPlaceholder} component={ShowPassword}/>
            {errors.confirmNewPassword && touched.confirmNewPassword ? ( // Error
              <div className='error'>{errors.confirmNewPassword}</div>
            ) : null}
          </label>

          {   // Show loader until the form is sent
            formIsSent ? <div className='form-spiner'><Loader /></div> : null
          }

          {
            showMessage ?
              <label>
                <p className='success'>
                  {sendingIsSuccess ? changePasswordForm.showSuccessMessage : changePasswordForm.showErrorMessage}
                </p>
              </label>
              :
              null
          }

          <div className='additional-text'>
            <p>{changePasswordForm.additionalText}</p>
            {
              !isProfile &&
                <p>{changePasswordForm.userAgreement} <Link to='/'>{changePasswordForm.userAgreementLinkText}</Link></p>
            }
          </div>

          <MainBatton type='submit' palette='primary' onMouseDown={handleSubmit}>
            {changePasswordForm.save}
          </MainBatton>

        </Form>
      )}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  isProfile: PropTypes.bool.isRequired,
};

export default ChangePasswordForm;
