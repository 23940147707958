import React, { useState, useEffect } from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import './PageContainer.scss';
// import '../../../node_modules/bootstrap/scss/bootstrap.scss';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { changeBasketItemsQuantity } from '../../utils/basketItems';
import { ROUTES } from '../../constants/routes';
import { Context } from '../../context';
import { getCategory, getCurrentUser, getAccessToken, getMenu, getLanguages, getStaticLocalizationById, getSettings, deleteAllGoodsFromBasket } from '../../utils/api';
import { regexForGettingAccessToken } from '../../utils/regexForGettingId';
import { ThemeStyling } from '../../ThemeStyling';
import { GeneralCastomStyles } from '../../styles/GeneralCastomStyles';
import { setBasketFromLocalStorage } from '../../utils/setBasketFromLocalStorage';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PreFooterCityImage from '../PreFooterCityImage/PreFooterCityImage';
import MainLoader from '../MainLoader/MainLoader';

// TODO: Зробити прив'язку до беку;
import { dummyStaticLocalization } from './staticLocalization';

const PageContainer = () => {

  const [categories, setCategories] = useState([]);
  const [mainDataIsLoaded, setMainDataIsLoaded] = useState(false);
  const [showMainLoader, setShowMainLoader] = useState(true);
  const [blackTheme, setBlackTheme] = useState(true);
  const [isLogined, setIsLogined] = useState(false);
  const [userData, setUserData] = useState({});
  const [generalPrice, setGeneralPrice] = useState(0);
  const [basketItemsLength, setBasketItemsLength] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [navigation, setNavigation] = useState ([]);
  const [leanguageSwitcherData, setLeanguageSwitcherData] = useState([]);
  const [curentLanguageId, setCurentLanguageId] = useState(1);
  const [staticLocalization, setStaticLocalization] = useState(dummyStaticLocalization);
  const [settings, setSettings] = useState({});
  const [bodyUnscroll, setBodyUnscroll] = useState(false);
  const [langKey, setLangKey] = useState('uk');

  useEffect(() => {
    setLangKey(curentLanguageId === 1 ? 'uk' : 'en');
  }, [curentLanguageId]);
  const checkLogination = () => {

    const getFromSessionStorage = sessionStorage.getItem('lmsh-token');
    const getFromLocalStorage = localStorage.getItem('lmsh-token');
    const socialRegister = localStorage.getItem('social-register');

    console.log('CHECK LOGINATION');
    console.log((getFromSessionStorage !== null || getFromLocalStorage !== null || (socialRegister !==null && regexForGettingAccessToken())));

    return (getFromSessionStorage !== null || getFromLocalStorage !== null || (socialRegister !==null && regexForGettingAccessToken()));

  };

  const checkTheme = () => {
    if(sessionStorage.getItem('lms-theme', blackTheme)){
      const currentTheme = sessionStorage.getItem('lms-theme', blackTheme);
      setBlackTheme(JSON.parse(currentTheme));
    }else{
      const objDate = new Date();
      const hours = objDate.getHours();
      const minutes = objDate.getMinutes();
      const ifTime = (hours >= 9 && minutes >= 0 && hours <= 17  && minutes <= 59);
      setBlackTheme(!ifTime);
    }
  };

  const checkHotReload = (item) => {

    const currentSettings = localStorage.getItem('lms-settings');
    if ( currentSettings ) {
      const frontendVersion = JSON.parse(currentSettings);
      if (frontendVersion.frontendVersion !== item.frontendVersion) {
        document.location.reload(true);
      }
    }
    const newSettings = localStorage.setItem('lms-settings', JSON.stringify(item));
    setSettings(item);

  };

  useEffect(()=>{
    const newPrimiseFromGetCategory = new Promise ((resolve, reject) => {
      getCategory().then(response=>{
        resolve(response.json());
      });
    });

    const newPrimiseFromGetCarrentUser = new Promise ((resolve, reject) => {
      const accessToken = getAccessToken();
      const date = new Date();

      const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));

      if(localStorage.getItem('social-register') && regexForGettingAccessToken()){
        localStorage.setItem('lmsh-token', accessToken);
        localStorage.setItem('refresh-token', date.getTime());
        localStorage.removeItem('social-register');

        if(localBasket && localBasket.length > 0){
          return deleteAllGoodsFromBasket(0).then(() => {
            return setBasketFromLocalStorage(setBasketItemsLength, 0, false, setIsLogined).then(() => {
              window.location.assign('/order');
            });
          });
        }
        window.location.assign('/profile/personal');
      }

      if(accessToken !== undefined){
        if(
          (sessionStorage.getItem('refresh-token') && date - sessionStorage.getItem('refresh-token') < 86400000) ||
          (localStorage.getItem('refresh-token') && date - localStorage.getItem('refresh-token') < 86400000)
        )
        {
          getCurrentUser(accessToken).then(response => {
            resolve(response.json());
          });
        }
        else{
          sessionStorage.removeItem('refresh-token');
          sessionStorage.removeItem('lmsh-token');
          localStorage.removeItem('refresh-token');
          localStorage.removeItem('lmsh-token');
          resolve(null);
        }

      }else{
        resolve(null);
      }
    });

    const newGetMenu = new Promise ((resolve, reject) => {
      getMenu().then((res)=>{
        resolve(res.json());
      });
    });

    const newGetLanguageById = new Promise ((resolve, reject) => {
      const leanguageArr = [];
      getLanguages().then((response) => {
        return response.json();
      })
        .then((data) => {
          data.forEach(el=>{
            const formatedEl = {
              value: el.name,
              label: el.code,
              data: el.id
            };
            leanguageArr.push(formatedEl);
          });

          let currentId;

          if(localStorage.getItem('lmsh-curentLanguage') === null){
            const { id } = data[0];
            currentId = id;
            localStorage.setItem('lmsh-curentLanguage', id);
          }else{
            currentId = JSON.parse(localStorage.getItem('lmsh-curentLanguage'));
          }

          setCurentLanguageId(currentId);
          setLeanguageSwitcherData(leanguageArr);

          getStaticLocalizationById(currentId).then(res => {
            resolve(res.json());
          });
        });
    });

    const newSettings = new Promise ((resolve, reject) => {
      getSettings().then(response => {
        resolve(response.json());
      });
    });

    console.time('Promise.all time');
    Promise.all([newPrimiseFromGetCategory, newPrimiseFromGetCarrentUser, newGetLanguageById, newGetMenu, newSettings]).then((res)=>{
      const [GetCategory, GetCarrentUser, GetLanguageById, GetMenu, GetSettings] = res;


      checkHotReload(GetSettings);
      setUserData({ ...GetCarrentUser }); //data about current user
      setCategories(GetCategory.result);
      changeBasketItemsQuantity(checkLogination(), setBasketItemsLength);
      setIsLogined(checkLogination());
      setStaticLocalization(GetLanguageById);
      setNavigation(GetMenu);
      checkTheme();
      setMainDataIsLoaded(true);

      console.timeEnd('Promise.all time');
    });

  }, []);

  const renderPage = () => {
    return(
      <div className='full-page'>

        <Router>
          <LastLocationProvider>
            {
              mainDataIsLoaded && <>
                <Header />
                <div className='content'>
                  <Switch>
                    {ROUTES.map(
                      route => (
                        <Route
                          exact
                          key={route.path || 404}
                          path={route.path}
                          render={props => (<route.component { ...props } />)}
                        />
                      )
                    )}
                  </Switch>
                </div>
                <PreFooterCityImage />
                <Footer />
              </>
            }
            {/*TODO: temporary animation off*/}
            {/*{showMainLoader && <MainLoader />}*/}
          </LastLocationProvider>
        </Router>

      </div>
    );
  };

  return (
    <Context.Provider value={{
      isOpenModal, setIsOpenModal,
      categories: categories,
      mainDataIsLoaded: mainDataIsLoaded,
      blackTheme: blackTheme,
      isLogined: isLogined,
      userData: userData,
      setUserData: setUserData,
      generalPrice: generalPrice,
      setGeneralPrice: setGeneralPrice,
      basketItemsLength: basketItemsLength,
      navigation: navigation,
      staticLocalization: staticLocalization,
      leanguageSwitcherData: leanguageSwitcherData,
      curentLanguageId: curentLanguageId,
      langKey: langKey,
      settings,
      changeTheme(){
        sessionStorage.setItem('lms-theme', !blackTheme);
        return setBlackTheme(!blackTheme);
      },
      changeLoginationStatus(newStatus){
        return setIsLogined(newStatus);
      },
      setDataAboutUser(data){
        return setUserData(data);
      },
      changeGeneralPrice(price){
        return setGeneralPrice(price);
      },
      changeBasketItemsLength(length){
        return setBasketItemsLength(length);
      },
      changeStaticLocalization(localization){
        return setStaticLocalization(localization);
      },
      changeCurentLanguageId(id){
        return setCurentLanguageId(id);
      },
      setBodyUnscroll(val){
        return setBodyUnscroll(val);
      },
      toggleMainLoader(val){
        return setShowMainLoader(val);
      }
    }}>
      <GeneralCastomStyles blackTheme={blackTheme}/>
      <ThemeStyling blackTheme={blackTheme} bodyUnscroll={bodyUnscroll}/>
      {renderPage()}
    </Context.Provider>
  );
};

export default PageContainer;
