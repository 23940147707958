import React, { useState, useEffect, useContext } from 'react';
import './Order.scss';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Context } from '../../context';

import MainTitle from '../../components/MainTitle/MainTitle';
import Steps from '../../components/Steps/Steps';
import OrderBascket from '../../components/OrderBasket/OrderBascket';
import MainButton from '../../components/MainButton/MainButton';

import FirstStepTemplate from './FirstStepTemplate/FirstStepTemplate';
import SecondStepTemplate from './SecondStepTemplate/SecondStepTemplate';
import ThirdStepTemplate from './ThirdStepTemplate/ThirdStepTemplate';
import FourthStepTemplate from './FourthStepTemplate/FourthStepTemplate';

import { ReactComponent as ReturnBackIcon } from '../../assets/icons/return-back.svg';

import { dummyStateStepsLogined, dummyStateStepsNotLogined } from './OrderSteps';

import { getGeneralSetings, getGiftBoxes, getGoodsForBasket } from '../../utils/api';
import useScrollToHook from '../../utils/useScrollToHook';

const Order = () => {
  const {
    isLogined,
    staticLocalization,
    generalPrice,
    setBodyUnscroll,
    changeBasketItemsLength,
    basketItemsLength
  } = useContext(Context);

  const { order, orderSteps } = staticLocalization;

  const dummyStateSteps = isLogined ?
    dummyStateStepsLogined({
      firstStepTitle: orderSteps.firstStepTitle,
      secondStepTitle: orderSteps.thirdStepTitle,
      thirdStepTitle: orderSteps.fourthStepTitle,
      firstStepName: orderSteps.firstStepName,
      secondStepName: orderSteps.thirdStepName,
      thirdStepName: orderSteps.fourthStepName
    })
    :
    dummyStateStepsNotLogined({
      firstStepTitle: orderSteps.firstStepTitle,
      secondStepTitle: orderSteps.secondStepTitle,
      thirdStepTitle: orderSteps.thirdStepTitle,
      fourthStepTitle: orderSteps.fourthStepTitle,
      firstStepName: orderSteps.firstStepName,
      secondStepName: orderSteps.secondStepName,
      thirdStepName: orderSteps.thirdStepName,
      fourthStepName: orderSteps.fourthStepName
    });

  const [whichStep, setWhichStep] = useState(1);
  const [steps, setSteps] = useState({ step: whichStep - 1, content: dummyStateSteps });
  const [items, setItems] = useState(dummyStateSteps[whichStep - 1]);
  const [stepQuantity, setStepQuantity] = useState(4);
  const [departureAt, setDepartureAt] = useState(0);
  const [desiredDeliveryDate, setDesiredDeliveryDate] = useState(null);
  const [comment, setComment] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [additionalProtectionPrice, setAdditionalProtectionPrice] = useState(0);
  const [showLocalPrice, setShowLocalPrice] = useState(true);
  const [orderBasketGeneralPrice, setOrderBasketGeneralPrice] = useState(generalPrice);
  const [orderBasketAdditionalProtection, setOrderBasketAdditionalProtection] = useState(0);
  const [useLocalCard, setUseLocalCard] = useState(false);
  const [rerender, setRerender] = useState(false);

  // FIRST STEP DATA:
  const [additionalProtection, setAdditionalProtection] = useState(localStorage.getItem('lmsh-additionalProtection') === 'true');

  const [state, setState] = useState('standartBox');
  const [giftBoxIsCheked, setGiftBoxIsCheked] = useState(false);
  const [giftBoxIsLoaded, setGiftBoxIsLoaded] = useState(false);
  const [giftBoxData, setGiftBoxData] = useState([]);
  const [noGiftBoxes, setNoGiftBoxes] = useState(true);
  // SECOND STEP DATA:
  const [notRegisterCustomerId, setNotRegisterCustomerId] = useState(0);
  // THIRD STEP DATA:
  const [recipientId, setRecipientId] = useState(0);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  // FOURTH STEP DATA:
  const [showMessage, setShowMessage] = useState(false);
  const [orderIsSuccess, setOrderIsSuccess] = useState(false);
  const [showBlockedUserMessage, setShowBlockedUserMessage] = useState(false);
  const [bofonQuintity, setBofonQuintity] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('CASH');

  const [scrollToRef, setShouldScrollTo] = useScrollToHook();

  useEffect(() => {
    setShouldScrollTo(true);
  }, [steps]);

  const setPayment = (val) => {
    setPaymentMethod(val);
  };

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setStepQuantity(2);
    }

    getGeneralSetings().then(res => {
      return res.json();
    }).then(el => {
      setAdditionalProtectionPrice(el.additionalProtectionPrice);
    });

  }, []);

  useEffect(() => {  // getGiftBoxes

    setGiftBoxIsLoaded(false);

    const getGiftBoxesApi = (isLogined, dataForRequest) => {
      getGiftBoxes(isLogined, dataForRequest).then(res => {
        if (res.ok) {
          res.json().then(el => {
            setNoGiftBoxes(false);
            setGiftBoxData(el.result);
            setGiftBoxIsLoaded(true);

            if (el.result.length === 0) {
              setNoGiftBoxes(true);
              setGiftBoxIsLoaded(true);
            }
          });
        } else {
          setNoGiftBoxes(true);
          setGiftBoxIsLoaded(true);
        }
      });
    };

    if (state === 'giftBox') {
      if (isLogined) {
        getGoodsForBasket().then(res => {
          return res.json();
        }).then(el => {

          const dataForRequest = el.result.map(item => {
            const { type, quantity } = item;
            return { type, refId: item.itemEntity.id, quantity };
          });

          getGiftBoxesApi(isLogined, dataForRequest);

        });
      } else {
        const localData = JSON.parse(localStorage.getItem('lmsh-basket'));

        const filtredDataForRequest = localData.filter(el => el.type !== 'BOX');

        const dataForRequest = filtredDataForRequest.map(el => {

          const { type, quantity, id } = el;

          return { type, refId: id, quantity };
        });

        getGiftBoxesApi(isLogined, dataForRequest);

      }
    }

  }, [state]);

  useEffect(() => {
    setShowLocalPrice(isLogined ? (whichStep === 2 || whichStep === 3) : (whichStep === 3 || whichStep === 4));
  }, [whichStep]);

  const StepTitle = ({ step }) => {

    return (
      <div className='internal-title'>
        <h3>
          {dummyStateSteps.find(el => el.id === step).title || null}
        </h3>
      </div>
    );

  };

  StepTitle.propTypes = {
    step: PropTypes.number
  };

  const nextStepButtonHandler = () => {

    setItems(dummyStateSteps[steps.step + 1]);

    const _stepsContent = [...steps.content];
    _stepsContent[steps.step].state = 'passed';
    _stepsContent[steps.step + 1].state = 'active';
    setSteps(steps => {
      return {
        step: steps.step + 1,
        content: _stepsContent
      };
    });

  };

  const previousStepButtonHandler = () => {

    setItems(dummyStateSteps[steps.step - 1]);

    const _stepsContent = [...steps.content];
    _stepsContent[steps.step].state = 'next';
    _stepsContent[steps.step - 1].state = 'active';
    setSteps(steps => {
      return {
        step: steps.step - 1,
        content: _stepsContent
      };
    });
  };

  const handleChangeStap = (val) => {
    const thisStap = whichStep;
    if (val === 'next') {
      nextStepButtonHandler();
      return setWhichStep(thisStap + 1);
    }
    previousStepButtonHandler();
    return setWhichStep(thisStap - 1);
  };

  const addOrRemoveProtection = (val) => {
    localStorage.setItem('lmsh-additionalProtection', val);
    setAdditionalProtection(val);
  };

  const changeStepLookAfterLoading = () => {
    const _stepsContent = [...dummyStateStepsLogined({
      firstStepTitle: orderSteps.firstStepTitle,
      secondStepTitle: orderSteps.thirdStepTitle,
      thirdStepTitle: orderSteps.fourthStepTitle,
      firstStepName: orderSteps.firstStepName,
      secondStepName: orderSteps.thirdStepName,
      thirdStepName: orderSteps.fourthStepName
    })];

    _stepsContent[steps.step - 1].state = 'passed';
    _stepsContent[steps.step].state = 'active';

    setSteps(steps => {
      return {
        step: steps.step,
        content: _stepsContent
      };
    });
  };

  const StepTemplate = () => {
    if (whichStep === 1) {
      return <FirstStepTemplate
        handleChangeStap={val => handleChangeStap(val)}
        additionalProtection={additionalProtection}
        setAdditionalProtection={val => addOrRemoveProtection(val)}
        additionalProtectionPrice={additionalProtectionPrice}
        orderBasketGeneralPrice={orderBasketAdditionalProtection}
        setOrderBasketAdditionalProtection={(val) => setOrderBasketAdditionalProtection(val)}
        state={state}
        setState={val => setState(val)}
        giftBoxIsCheked={giftBoxIsCheked}
        setGiftBoxIsCheked={val => setGiftBoxIsCheked(val)}
        giftBoxIsLoaded={giftBoxIsLoaded}
        giftBoxData={giftBoxData}
        setGiftBoxData={val => setGiftBoxData(val)}
        noGiftBoxes={noGiftBoxes}
        setNoGiftBoxes={val => setNoGiftBoxes(val)}
        setRerender={() => setRerender(!rerender)}
      />;
    } else if (whichStep === 2) {

      if (isLogined) {
        return <ThirdStepTemplate
          handleChangeStap={(val) => handleChangeStap(val)}
          setPaymentTypes={val => setPaymentTypes(val)}
          notRegisterCustomerId={notRegisterCustomerId}
          setRecipientId={(val) => setRecipientId(val)}
          setDepartureAt={(val) => setDepartureAt(val)}
          setDesiredDeliveryDate={(val) => setDesiredDeliveryDate(val)}
          setComment={(val) => setComment(val)}
          setTemplateName={(val) => setTemplateName(val)}
          setDeliveryPrice={(val) => setDeliveryPrice(val)}
        />;
      }

      return <SecondStepTemplate
        handleChangeStap={(val) => handleChangeStap(val)}
        type='2'
        setNotRegisterCustomerId={val => setNotRegisterCustomerId(val)}
        changeStepLookAfterLoading={() => changeStepLookAfterLoading()}
      />;
    } else if (whichStep === 3) {

      if (isLogined) {
        return <FourthStepTemplate
          handleChangeStap={(val) => handleChangeStap(val)}
          notRegisterCustomerId={notRegisterCustomerId}
          recipientId={recipientId}
          additionalProtection={additionalProtection}
          paymentTypes={paymentTypes}
          setShowMessage={() => setShowMessage(true)}
          setOrderIsSuccess={val => setOrderIsSuccess(val)}
          departureAt={departureAt}
          desiredDeliveryDate={desiredDeliveryDate}
          comment={comment}
          setShowBlockedUserMessage={() => setShowBlockedUserMessage(true)}
          useLocalCard={useLocalCard}
          setUseLocalCard={val => setUseLocalCard(val)}
          setBofonQuintity={val => setBofonQuintity(val)}
          setPaymentMethod={(val) => setPayment(val)}
        />;
      }

      return <ThirdStepTemplate
        handleChangeStap={(val) => handleChangeStap(val)}
        setPaymentTypes={val => setPaymentTypes(val)}
        notRegisterCustomerId={notRegisterCustomerId}
        setRecipientId={(val) => setRecipientId(val)}
        setDepartureAt={(val) => setDepartureAt(val)}
        setDesiredDeliveryDate={(val) => setDesiredDeliveryDate(val)}
        setComment={(val) => setComment(val)}
        setTemplateName={(val) => setTemplateName(val)}
        setDeliveryPrice={(val) => setDeliveryPrice(val)}
      />;
    }

    return <FourthStepTemplate
      handleChangeStap={(val) => handleChangeStap(val)}
      notRegisterCustomerId={notRegisterCustomerId}
      recipientId={recipientId}
      additionalProtection={additionalProtection}
      paymentTypes={paymentTypes}
      setShowMessage={() => setShowMessage(true)}
      setOrderIsSuccess={val => setOrderIsSuccess(val)}
      departureAt={departureAt}
      desiredDeliveryDate={desiredDeliveryDate}
      comment={comment}
      setShowBlockedUserMessage={() => setShowBlockedUserMessage(true)}
      useLocalCard={useLocalCard}
      setUseLocalCard={val => setUseLocalCard(val)}
      setBofonQuintity={val => setBofonQuintity(val)}
      setPaymentMethod={(val) => setPayment(val)}
    />;
  };

  const BlockedUserMessage = () => {

    const { settings, setBodyUnscroll } = useContext(Context);

    useEffect(() => {
      setBodyUnscroll(true);
    }, []);

    function convertPhone(str) {
      return str.replace(/[^0-9\\.]+/g, '');
    }

    return (
      <div className='message message--biger'>
        <div className='message__wrapper'>
          <h2>
            {order.blokedUserMessage}
            <a href={`tel:${convertPhone(settings.callCenterPhone)}`}>
              {settings.callCenterPhone}
            </a>
          </h2>
          <div className='btn-group'>
            <MainButton onClick={() => {
              setBodyUnscroll(false);
            }}
            href='/'
            >
              {order.returnToHomePage}
            </MainButton>
          </div>
        </div>
      </div>
    );
  };

  const orderIsSuccessMessage = (order) => {
    // let message = order.orderErrorMessageTitle;
    let message = order.congratulationMessageTitle;
    if (orderIsSuccess && paymentMethod === 'BANK') {
      message = order.congratulationMessageTitle;
    } else if (orderIsSuccess && paymentMethod === 'CASH') {
      message = order.congratulationMessageCashTitle;
    }

    if (!orderIsSuccess) {
      message = order.orderErrorMessageTitle;
    }

    return message;
  };

  const orderIsSuccessSubtitleMessage = (order) => {
    // let message = order.orderErrorMessageSubtitle;
    let message = order.congratulationMessageSubtitle;
    if (orderIsSuccess && paymentMethod === 'BANK') {
      message = order.congratulationMessageSubtitle;
    } else if (orderIsSuccess && paymentMethod === 'CASH') {
      message = order.congratulationMessageCashSubtitle;
    }

    if (!orderIsSuccess) {
      message = order.orderErrorMessageSubtitle;
    }

    return message;
  };

  useEffect(() => {
    console.log('orderIsSuccess', orderIsSuccess);
  }, [orderIsSuccess]);

  return (
    <>
      <Helmet>
        <title>
          Набори цукерок ручної роботи - цукерки в коробках - Львівська Майстерні Шоколаду
        </title>
        <meta
          name='description'
          content='Купити подарунки з цукерок ❖ Подарункові коробки цукерок ❖ Справжня насолода в кожній коробочці ❖Львівська Майстерня Шоколаду - Інтернет-магазин цукерок, шоколаду та шоколадних фігурок.'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='order'>
        <Row>
          <Col xs={12} ref={scrollToRef}>
            <MainTitle>
              {order.mainTitle}
            </MainTitle>
          </Col>
          <Col xs={12} lg={7} xl={8} className='order__content'>
            <StepTitle step={whichStep} />
            <Steps content={steps.content} quantity={stepQuantity} page='order' />
            {StepTemplate()}
          </Col>
          <Col xs={12} className='return-to-basket'>
            {
              whichStep === 1 ?
                <MainButton palette='secondary' href='/basket'>
                  <ReturnBackIcon />
                  {order.returnToBasket}
                </MainButton>
                :
                <MainButton palette='secondary' onClick={() => handleChangeStap('prev')}>
                  <ReturnBackIcon />
                  {order.returnToPreviousStap}
                </MainButton>
            }
          </Col>
          <Col xs={12} lg={5} xl={4} className='order-bascket'>
            <div className='internal-title'>
              <h3>{order.yourBasket}</h3>
            </div>
            <OrderBascket
              showLocalPrice={showLocalPrice}
              deliveryPrice={deliveryPrice}
              orderBasketGeneralPrice={orderBasketGeneralPrice}
              setOrderBasketGeneralPrice={(val) => setOrderBasketGeneralPrice(val)}
              orderBasketAdditionalProtection={orderBasketAdditionalProtection}
              setOrderBasketAdditionalProtection={(val) => setOrderBasketAdditionalProtection(val)}
              additionalProtection={additionalProtection}
              setAdditionalProtection={setAdditionalProtection}
              additionalProtectionPrice={additionalProtectionPrice}
              notRegisterCustomerId={notRegisterCustomerId}
              useLocalCard={useLocalCard}
              bofonQuintity={bofonQuintity}
              rerender={rerender}
              setGiftBoxIsCheked={val => setGiftBoxIsCheked(val)}
              giftBoxIsCheked={giftBoxIsCheked}
            />
          </Col>
        </Row>
        {
          showMessage ?
            <div className='message'>
              <div className='message__wrapper'>
                <div className='title'>
                  <h2>
                    {
                      orderIsSuccessMessage(order)
                    }
                  </h2>
                  <p>
                    {
                      orderIsSuccessSubtitleMessage(order)
                    }
                  </p>
                </div>
                <div className='btn-group'>
                  <MainButton onClick={() => {
                    setBodyUnscroll(false);
                    if (orderIsSuccess) {
                      changeBasketItemsLength(0);
                    }
                  }} href='/' palette='secondary'>
                    {order.congratulationMessageBtn}
                  </MainButton>
                  {
                    isLogined ?
                      <MainButton onClick={() => {
                        setBodyUnscroll(false);
                        if (orderIsSuccess) {
                          changeBasketItemsLength(0);
                        }
                      }} href={orderIsSuccess ? '/profile/orders' : '/basket'}>
                        {
                          orderIsSuccess ?
                            order.congratulationMessageBtnLogined
                            :
                            order.orderErrorMessageBtn
                        }
                      </MainButton>
                      :
                      null
                  }
                </div>

              </div>
            </div>
            :
            null
        }
        {
          showBlockedUserMessage ?
            <BlockedUserMessage />
            :
            null
        }
      </Container>
    </>
  );
};


export default Order;
