import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './LogInForm.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Context } from '../../../context';

import ShowPassword from '../ShowPassword/ShowPassword';
import CheckBox from '../../CheckBox/CheckBox';
import MainBatton from '../../MainButton/MainButton';
import Loader from '../../Loader/Loader';

import { logination, getCurrentUser, deleteAllGoodsFromBasket, getGoodsForBasket } from '../../../utils/api';
import { setBasketFromLocalStorage } from '../../../utils/setBasketFromLocalStorage';
import { scrollToTop } from '../../../utils/scrollToTop';
import CaptchaWidget from '../Captcha/CaptchaWidget';

const LogInForm = ({
  changeStep,
  showRegisterForm,
  showRecoverPasswordForm,
  formID,
  history,
  changeStepLookAfterLoading
}) => {

  const [rememberMe, setRememberMe] = useState(false);
  const [loginationFailed, setLoginationFailed] = useState(false);
  const [formIsSent, setFormIsSent] = useState(false);

  const {
    changeLoginationStatus,
    setDataAboutUser,
    changeBasketItemsLength,
    staticLocalization,
    setBodyUnscroll
  } = useContext(Context);
  const { loginationForm } = staticLocalization;

  const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const phoneRegex = /^(\+380-|\+380|0)?\d{9}$/; // Change this regex based on requirement

  const phoneIntRegex = /^(\+-|\+|0)?\d{16}$/; // Change this regex based on requirement

  const [phone, setPhone] = useState(false);


  const LogInSchema = Yup.object().shape({
    login: Yup.string()
      .test('test', loginationForm.validationError,
        function(value) {
          const isValidEmail = emailRegex.test(value);
          const isValidPhone = phoneRegex.test(value);
          const isValidPhoneInt = phoneIntRegex.test(value);
          if (phoneRegex.test(value)) {
            setPhone(true);
          }

          if (!isValidEmail && !isValidPhone && !isValidPhoneInt) {
            return false;
          }
          return true;
        })

      .required(loginationForm.emailOrPhoneError),
    password: Yup.string()
      .required(loginationForm.passwordError)
  });

  return (
    <Formik
      initialValues={{
        login: '',
        password: ''
      }}
      validationSchema={LogInSchema}
      onSubmit={(values) => {
        const { login, password } = values;

        let modeLogin;
        if (phone && (login.split('')[0] == 0)) {
          modeLogin = '+38' + login;
        } else {
          modeLogin = login;
        }


        setFormIsSent(true); // Show spiner
        setLoginationFailed(false); // Remove error message

        logination(modeLogin, password).then(res => {
          setFormIsSent(false); // Remove spinner
          if (res.ok) { // Check if logination is successful
            setLoginationFailed(false);
            res.json().then(logInfo => {

              const { access_token } = logInfo;
              const date = new Date();

              localStorage.setItem('lmsh-token', access_token);
              localStorage.setItem('refresh-token', date.getTime());

              const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));
              getCurrentUser(access_token).then(response => {
                response.json().then(dataAboutUser => {

                  const { email } = dataAboutUser;

                  setDataAboutUser(dataAboutUser);

                  if (localBasket) {
                    if (localBasket.length > 0) {
                      deleteAllGoodsFromBasket(0).then(() => {
                        setBasketFromLocalStorage(changeBasketItemsLength, 0, false, changeLoginationStatus).then(() => {
                          if (formID === '1') { // Check which form we use (1 === form from personal office)
                            history.push('/profile/personal');
                          } else { // (2 == form from order)
                            changeStepLookAfterLoading();
                          }
                        });
                      });
                    } else {
                      changeLoginationStatus(true);
                      getGoodsForBasket().then(res => {
                        return res.json();
                      }).then(el => {
                        changeBasketItemsLength(el.count);
                        if (formID === '1') { // Check which form we use (1 === form from personal office)
                          history.push('/profile/personal');
                        } else { // (2 == form from order)
                          changeStepLookAfterLoading();
                        }
                      });
                    }
                  } else {
                    changeLoginationStatus(true);
                    getGoodsForBasket().then(res => {
                      return res.json();
                    }).then(el => {
                      changeBasketItemsLength(el.count);
                      if (formID === '1') { // Check which form we use (1 === form from personal office)
                        history.push('/profile/personal');
                      } else { // (2 == form from order)
                        changeStepLookAfterLoading();
                      }
                    });
                  }

                });
              });
              return;
            });
          } else {
            setLoginationFailed(true);
          }
        });
      }}
    >
      {({ handleSubmit, ...rest }) => {
        return (

          <Form className='g-form-style login-form'>

            <label>
              <p className='required'>{loginationForm.emailOrPhone}</p>
              <Field
                type='text'
                name='login'
                placeholder={loginationForm.emailOrPhonePlaceholder}
                className='filled'
              />
              <ErrorMessage name='login'>
                {
                  msg => <div className='error'>{msg}</div>
                }
              </ErrorMessage>
            </label>

            <label>
              <p className='required'>{loginationForm.password}</p>
              <Field
                name='password'
                placeholder={loginationForm.passwordPlaceholder}
                component={ShowPassword}
              />
              <ErrorMessage name='password'>
                {
                  msg => <div className='error'>{msg}</div>
                }
              </ErrorMessage>
              {   // Show loader until the form is sent
                formIsSent ? <div className='form-spiner'><Loader /></div> : null
              }
              {   // if logination failder show this message
                loginationFailed ? <div className='error'>{loginationForm.generalError}</div> : null
              }
            </label>

            <div className='logination-additional-btns'>
              <div className='remember-me'>
                <CheckBox isChecked={false} name='remember-me'
                  onChange={(name, value, checked) => setRememberMe(checked)}>
                  <span>{loginationForm.rememberMe}</span>
                </CheckBox>
              </div>
              <div className='forget-password'>
                <button type='button' onClick={() => {
                  showRecoverPasswordForm(true);
                  setBodyUnscroll(true);
                }}>
                  {loginationForm.forgetPassword}
                </button>
              </div>
            </div>

            <MainBatton type='submit' palette='primary' onMouseDown={handleSubmit}>
              {loginationForm.logIn}
            </MainBatton>

            <MainBatton type='button' palette='secondary' onClick={() => {
              showRegisterForm();
              scrollToTop();
            }}>
              {loginationForm.register}
            </MainBatton>

          </Form>
        );
      }}
    </Formik>
  );
};

LogInForm.propTypes = {
  changeStep: PropTypes.func,
  showRegisterForm: PropTypes.func,
  showRecoverPasswordForm: PropTypes.func,
  formID: PropTypes.string,
  history: PropTypes.object,
  changeStepLookAfterLoading: PropTypes.func
};

export default withRouter(LogInForm);
