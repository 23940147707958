import {
  getGoodsForBasket, addBoxedGoodsToBasket, addIndividualGoodsToBasketNew, addGiftBoxToBasket,
  addGoodsToBasket, deleteAllGoodsFromBasket, logination
} from './api';


export const setBasketFromLocalStorage = async (changeBasketItemsLength, orderUserId, dontREmoveItemsFromLS = false, changeLoginationStatus) => {

  console.log('setBasketFromLocalStorage');

  const localData = localStorage.getItem('lmsh-basket'); // get data from local storage
  if (localData) {

    const getedData = JSON.parse(localData);

    for (const el of getedData) { // add items to a basket from localStorage
      switch (el.type) {
      case 'GOODS':
        await addGoodsToBasket(true, el, el.quantity, () => {
        }, orderUserId);
        break;
      case 'BOX_WITH_GOODS':
        await addBoxedGoodsToBasket(true, el, el.quantity, () => {
        }, el.images, el.price, orderUserId);
        break;
      case 'INDIVIDUAL_ORDER':
        await addIndividualGoodsToBasketNew(true, el, el.quantity, () => {
        }, el.images, el.price, orderUserId, () => {
        });
        break;
      case 'BOX':
        await addGiftBoxToBasket(true, el, changeBasketItemsLength, orderUserId);
        break;
      default:
      }
    }

    if (!dontREmoveItemsFromLS) {
      console.log('if (!dontREmoveItemsFromLS) {');
      localStorage.removeItem('lmsh-basket');
      changeLoginationStatus(true);
    }
  }

  return new Promise((resolve, reject) => {
    getGoodsForBasket(orderUserId).then(res => {
      return res.json();
    }).then(el => {
      changeBasketItemsLength(el.count);
      resolve(true);
    });
  });
};