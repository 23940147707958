import React, { useContext, useState } from 'react';
import { Context } from '../../context';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import MainTitle from '../../components/MainTitle/MainTitle';
import SecondStepTemplate from '../Order/SecondStepTemplate/SecondStepTemplate';
import './Profile.scss';

function Profile({ history }) {

  const { staticLocalization } = useContext(Context);
  const { personalOffice } = staticLocalization;
  const [ isWhichForm, setIsWhichForm] = useState(true);

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid >
        <Row>
          <Col xs={12}>
            <MainTitle>
              {personalOffice.mainTitle}
            </MainTitle>
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <h3 className='profile__subtitle'>
              {
                isWhichForm
                  ? personalOffice.subTitleUnregistered
                  : personalOffice.subTitleRegistration
              }
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SecondStepTemplate
              type={'user'}
              setIsWhichForm={setIsWhichForm}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

Profile.propTypes = {
  history: PropTypes.object
};

export default withRouter(Profile);

