import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Context } from '../../context';
import { ReactComponent as ButtonSide } from './../../assets/images/button-side.svg';
import { ReactComponent as ButtonBody } from './../../assets/images/button-body.svg';

import './MainButton.scss';

const Button = ({
  children, onClick, className, disabled, show, active, palette, type, ...attrs
}) => {

  const MOBILE = 991.5;

  const bgAnimation = [{ visibility: 'visible' }, { visibility: 'hidden' }];

  const onClickAction = e => {
    if (disabled) {
      e.preventDefault();
    } else {
      e.persist();
      //return setTimeout(() =>  {onClick(e);}, 300);
      return onClick(e);
    }
  };

  const { blackTheme } = useContext(Context);

  const theme = blackTheme ? 'dark' : 'light';

  const classes = classNames(
    'btns',
    `btns--${palette}`,
    className,
    { active }
  );


  // `btns--${palette}-${theme}`,
    
  const LinkTo = () => {
    return (
      <Link to={attrs.href}
        className={classes}
        onClick={onClickAction}
      >
        {children}

        <div className='btns__buttons'>
          <ButtonSide/>
          <ButtonBody/>
          <ButtonSide/>
          {/* <div className='button-body'></div> */}
        </div>

      </Link>
    );
  };

  const Button = () => {
    return (
      show ? <button
        className={classes}
        disabled={disabled}
        onClick={onClickAction}
        type={type}
        {...attrs}
      >
        {children}

        <div className='btns__buttons'>
          <ButtonSide/>
          <ButtonBody/>
          <ButtonSide/>
          {/* <div className='button-side'></div>
          <div className='button-body'></div>
          <div className='button-side'></div> */}
        </div>

      </button> : ''
    );
  };

  const Tag = attrs.href
    ? LinkTo
    : Button;

  return (
    <Tag />
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  active: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  palette: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  children: 'Default button',
  onClick: () => {},
  className: '',
  disabled: false,
  show: true,
  active: false,
  theme: 'dark',
  palette: 'primary',
  type: 'button'
};

export default Button;
