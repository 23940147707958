import React, { useEffect, useContext, useRef } from 'react';
import './Autumn.scss';
import classNames from 'classnames';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../utils/useWindowSizeHook';

import Parallax from 'parallax-js'; // Now published on NPM

// import LeafTexture from '../../../../assets/images/leaf.png';
import LeafTexture_1Dark from '../../../../assets/images/leafs/leaf_dark_1.svg';
import LeafTexture_2Dark from '../../../../assets/images/leafs/leaf_dark_2.svg';
import LeafTexture_3Dark from '../../../../assets/images/leafs/leaf_dark_3.svg';

import LeafTexture_1Light from '../../../../assets/images/leafs/leaf_light_1.svg';
import LeafTexture_2Light from '../../../../assets/images/leafs/leaf_light_2.svg';
import LeafTexture_3Light from '../../../../assets/images/leafs/leaf_light_3.svg';


const Autumn = () => {
  const { blackTheme, staticLocalization } = useContext(Context);
  const { general } = staticLocalization;

  const scene = useRef();
  const starsCanv = useRef();
  const leafsCanv = useRef();

  const windowWidth = useWindowSize().width;

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const getRandomIntFromMinToMax = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення
  };

  useEffect(() => {

    let leafsAnimationFrame;

    if (windowWidth > 575) {
      const leafsCanvas = leafsCanv.current;

      var parallaxInstance = new Parallax(scene.current, {
        relativeInput: true,
        hoverOnly: true
      });

      if (leafsCanvas) {
        if (leafsCanvas.getContext) {
          var ctx3 = leafsCanvas.getContext('2d');

          // state
          const leafsQuintuty = 13;
          const speed = 1;

          leafsCanvas.width = scene.current.clientWidth;
          leafsCanvas.height = scene.current.clientHeight;

          var leafImg_1 = new Image();
          var leafImg_2 = new Image();
          var leafImg_3 = new Image();

          const whichImageToDraw = (variant) => {
            if (variant === 1) {
              return leafImg_1;
            } else if (variant === 2) {
              return leafImg_2;
            }
            return leafImg_3;
            
          };

          function Leaf(x, y, rotate, random, imageVariant) {
            this.x = x;
            this.y = y;
            this.rotate = rotate;
            this.sinusoid = random;
            this.imageWidth = getRandomIntFromMinToMax(30, 45);
            this.imageVariant = imageVariant;

            var x_fix = this.x;
            var angle = 0;
            var anglespeed = getRandomIntFromMinToMax(1, 9) / 100;
            var range = getRandomIntFromMinToMax(10, 30);

            this.dy = Math.random() + speed;
            this.dx = Math.random() > 0.5 ? Math.random() + speed : -(Math.random() + speed);
            this.drotate = Math.random() > 0.5 ? Math.random() / 20 : -(Math.random() / 20);

            leafImg_1.src = blackTheme ? LeafTexture_1Dark : LeafTexture_1Light;
            leafImg_2.src = blackTheme ? LeafTexture_2Dark : LeafTexture_2Light;
            leafImg_3.src = blackTheme ? LeafTexture_3Dark : LeafTexture_3Light;

            this.init = function() {
              this.draw();
            };

            this.animate = function() {
              if (this.y > leafsCanvas.height) {
                this.y = 0;
                this.x = getRandomIntFromMinToMax(0, leafsCanvas.width);
              } else {

                angle += anglespeed;

                this.y += this.dy;
                this.x = this.sinusoid ? x_fix + Math.sin(angle) * range : this.x + this.dx;
                this.rotate += this.drotate;
              }

              this.init();

            };

            this.draw = function() {

              ctx3.globalCompositeOperation = 'destination-over';

              ctx3.fillStyle = 'rgba(0, 0, 0, 0.4)';
              ctx3.strokeStyle = 'rgba(0, 153, 255, 0.4)';
              ctx3.save();
              ctx3.translate(0, 0);

              ctx3.translate(this.x, this.y);
              ctx3.rotate(this.rotate);

              ctx3.drawImage(whichImageToDraw(this.imageVariant), 0, 0, (this.imageWidth * 0.6), this.imageWidth);

              ctx3.restore();
            };

          }

          const leafs = [];

          for (let i = 1; i <= leafsQuintuty; i++) {
            const x = getRandomIntFromMinToMax(0, leafsCanvas.width);
            const y = getRandomIntFromMinToMax(0, leafsCanvas.height);
            const rotate = getRandomIntFromMinToMax(0, 360);
            const random = Math.random() >= 0.5;
            const imageVariant = getRandomIntFromMinToMax(1, 4);

            leafs.push(new Leaf(x, y, rotate, random, imageVariant));
          }

          function Update() {
            try {
              ctx3.clearRect(0, 0, (scene.current.clientWidth + 100), (scene.current.clientHeight + 100));

              leafs.forEach((el) => {
                el.animate();
              });

              leafsAnimationFrame = requestAnimationFrame(Update);
            } catch (err) {
              console.log(err);
            }
          }

          Update();

        }
      }

    }

    return () => {
      cancelAnimationFrame(leafsAnimationFrame);
    };

  }, [blackTheme, windowWidth]);

  useEffect(() => {

    if (windowWidth > 575) {
      const starsCanvas = blackTheme ? starsCanv.current : null;

      const windowWidth = window.innerWidth;
      const windowInnerHeight = window.innerHeight;

      if (blackTheme) {
        if (starsCanvas.getContext) {
          const ctx = starsCanvas.getContext('2d');

          const starsCanvasWidth = windowWidth - 120;
          const starsCanvasHeight = windowInnerHeight * 0.7;
          const starsQuintity = 150;

          starsCanvas.width = starsCanvasWidth;
          starsCanvas.height = starsCanvasHeight;

          for (let i = 0; i <= starsQuintity; i++) {
            ctx.beginPath();
            var x = getRandomInt(starsCanvasWidth); // x coordinate
            var y = getRandomInt(starsCanvasHeight); // y coordinate
            var radius = 2; // Arc radius
            var anticlockwise = false; // clockwise or anticlockwise
            const fillOpacity = getRandomIntFromMinToMax(6, 11) / 10;

            // ctx.fillStyle = `rgba(247, 201, 101, ${Math.random()})`;
            ctx.fillStyle = `rgba(247, 201, 101, ${fillOpacity})`;
            ctx.arc(x, y, radius, 0, Math.PI * 2, anticlockwise);

            ctx.fill();
          }

        }
      }
    }

  }, [blackTheme, windowWidth]);


  const autumnClassNames = classNames(
    'scene',
    'autumn',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const autumnMobileClassNames = classNames(
    'mobile-plug',
    'mobile-plug--autumn',
    blackTheme ? 'black-theme' : 'light-theme'
  );


  return (
    <>
      {
        windowWidth > 575 ?
          <div ref={scene} className={autumnClassNames}>
            <div data-depth='0.21' className='layer layer-5'></div>
            {
              blackTheme ?
                <div data-depth='0.21' className='layer stars'>
                  <canvas ref={starsCanv}></canvas>
                </div>
                :
                null
            }
            <div data-depth='0.19' className='layer layer-4'></div>
            <div data-depth='0.16' className='layer layer-3'></div>
            <div data-depth='0.13' className='layer layer-6'></div>
            <div data-depth='0.1' className='layer layer-2'></div>
            <div data-depth='0.13' className='layer leafs'>
              <canvas ref={leafsCanv}></canvas>
            </div>
            <div data-depth='0.13' className='layer layer-1'></div>
            <div data-depth='0' className='layer text'>
              <h1>{general.name}</h1>
            </div>
          </div>
          :
          <div className={autumnMobileClassNames}>
            <h1>{general.name}</h1>
          </div>
      }
    </>
  );
};

export default Autumn;