import React, { useState, useEffect, useContext } from 'react';
import './CategoryGroup.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import MainTitle from '../MainTitle/MainTitle';
import MainButton from '../MainButton/MainButton';
import CategoryGroupItem from './CategoryGroupItem';
import Loader from '../Loader/Loader';

import { getCategoryGroupItems, getCategoryGroupById, getCategoryGroupsId } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';
import { Context } from '../../context';

import { ReactComponent as ArrowToLeftIcon } from './../../assets/icons/previous-arrow.svg';

function CategoryGroup({ history }) {
  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [locationId, setLocationId] = useState(false);

  useEffect(() => {
    const slug = history.location.pathname.split('/')[2];
    getCategoryGroupsId(slug).then(res => {
      return res.json();
    }).then(el => {
      setLocationId(el.result.shift().id);
    });
  }, [[], history.location.pathname]);


  const { blackTheme, staticLocalization, curentLanguageId } = useContext(Context);

  const classes = classNames(
    'category-group',
    blackTheme ? 'black-theme' : 'ligth-theme'
  );

  useEffect(() => {
    if (locationId) {
      setIsLoaded(false);

      const getCategoryGroupNew = new Promise((resolve, reject) => {
        getCategoryGroupById(locationId).then(res => {
          resolve(res.json());
        });
      });

      const getCategoryGroupItemsNew = new Promise((resolve, reject) => {
        getCategoryGroupItems(locationId).then(res => {
          resolve(res.json());
        });
      });

      Promise.all([getCategoryGroupNew, getCategoryGroupItemsNew]).then(res => {
        const [categoryGroup, categoryGroupItems] = res;
        setCategoryData(categoryGroup);
        setData([...categoryGroupItems.result]);
        setIsLoaded(true);
      });
    }
  }, [locationId]);

  // DATA:
  const title = getTranslation(categoryData, modifidedItemWithTranslationField(categoryData), 'name', curentLanguageId);
  const metaTitle = getTranslation(categoryData, modifidedItemWithTranslationField(categoryData), 'metaName', curentLanguageId);

  const Content = () => {
    if (isLoaded) {
      return (
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content="Helmet application" data-react-helmet="true"/>
          </Helmet>
          <Row>
            <Col xs={12}>
              <MainTitle>
                {title}
              </MainTitle>
            </Col>
          </Row>
          {
            window.innerWidth <= 991 ?
              <Row>
                <Col className="ml-auto">
                  <div className="title-submenu">
                    <div className="title-submenu__mobile mb-3">
                      <MainButton palette="secondary" onClick={() => history.goBack()}>
                        <ArrowToLeftIcon className="mr-3"/>
                        {staticLocalization.categoryGroup.goBack}
                      </MainButton>
                    </div>
                  </div>
                </Col>
              </Row>
              :
              null
          }
          <Row>
            <Col xs={12}>
              <div className={classes}>
                {data.map(el => <CategoryGroupItem
                  item={el}
                  key={el.id}
                  buttonText={staticLocalization.categoryGroup.review}
                />)}
              </div>
            </Col>
          </Row>
        </>
      );
    }
    ;
    return (
      <Loader/>
    );
  };

  return (
    <Container fluid>
      {Content()}
    </Container>
  );
}

CategoryGroup.propTypes = {
  history: PropTypes.object
};

export default withRouter(CategoryGroup);
