import React, { useState, useContext, useEffect } from 'react';

import { ReactComponent as CloseSVG } from './../../assets/icons/close.svg';
import MainButton from '../../components/MainButton/MainButton';
import Counter from '../Counter/Counter';

import { imagesUrl, getGroupItems, getFiltersForBox, addBoxedGoodsToBasket } from '../../utils/api';
import { getItemFieldWithTranslation } from '../../utils/renderFieldWithTransition';

import { Context } from '../../context';

const BasketBoxesDetail = ({
  item,
  onClose,
  counter,
  handleChangeGeneralPrice,
  changeItemCounterValueInBasket,
  setCounter,
  isLogined,
  price,
  notRegisterCustomerId,
  isOrder
}) => {

  const { box, goods } = item.itemEntity;
  const newGoods = goods.reduce((acc, curr) => {
    const arr = [];
    for (let i = 0; i < curr.quantity; i++) {
      arr.push(curr.goods);
    }
    return acc = [...acc, ...arr];
  }, []);

  const { staticLocalization, blackTheme, settings } = useContext(Context);
  const { general, fillingLang } = staticLocalization;
  const [detailItemCounter, setDetailItemCounter] = useState(counter);
  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';
  const { curentLanguageId } = useContext(Context);
  const packingTitle = getItemFieldWithTranslation(box, 'name', curentLanguageId);
  const packingImg = `${imagesUrl}${box.images[0]}?v=${settings.frontendVersion}`;
  //const packingDescription = `${packingTitle}`;
  const goodsPrice = price;

  const [itemAmount, setItemAmount] = useState(counter * +goodsPrice);

  const counterValueDetailChanged = (val, isIncrement, quantity) => {
    if (!(val === 0 && !isIncrement)) {
      setDetailItemCounter(val);
      setItemAmount(val * +goodsPrice);
    }
  };

  const detaildChangeQuantityHandler = () => {
    const newPrice = (detailItemCounter - counter) * +goodsPrice;
    handleChangeGeneralPrice(+newPrice, true);
    if (notRegisterCustomerId) {
      changeItemCounterValueInBasket(isLogined, item, detailItemCounter, notRegisterCustomerId, notRegisterCustomerId > 0 ? item.itemEntity.id : null);
    } else {
      changeItemCounterValueInBasket(isLogined, item, detailItemCounter, 0);
    }
    setCounter(detailItemCounter);
    onClose();
  };

  const ModalPanelItem = ({ content }) => {

    const goods = content;
    const checkTechnocalImage = goods.images.find(el => el.includes('technical')) || goods.images[0];
    const modalPanelItemImg = `${imagesUrl}${checkTechnocalImage}?v=${settings.frontendVersion}`;
    const modalPanelItemTitle = getItemFieldWithTranslation(goods, 'name', curentLanguageId);

    return (
      <div className={`boxes-modal__item ${whatTheme}`}>
        <img className='boxes-modal__item-img' src={modalPanelItemImg} alt={modalPanelItemTitle} />
        <p className='boxes-modal__item-description'>{modalPanelItemTitle}</p>
        {/* <div className='boxes-modal__item-price'>
          {goods.price}
          <small>{general.currency}</small>
        </div> */}
      </div>
    );
  };

  return (
    <div className='basket-item__detail-wrapper'>
      <div className='border-wrapper'>
        <div className={`basket-item__detail ${isOrder ? 'basket-item__detail--isOrder' : ''}`}>
          <div className='basket-item__detail-title'>
            <h3 className='boxes-modal__title'>
              {staticLocalization.fillingLang.boxTitle}
              <small>({newGoods.length}
                <span>{fillingLang.boxTitleNumberPreposition}</span> {box.goodsQuantity})</small>
            </h3>

            <CloseSVG onClick={onClose} />
          </div>

          <div className={`boxes-modal ${whatTheme}`}>
            <div className='boxes-modal__packing-wrapper'>
              <img className='boxes-modal__packing-img' src={packingImg} alt={packingTitle} />
              <div className='boxes-modal__packing-text'>
                <p className='boxes-modal__packing-description g-like-p16'>
                  <small>
                    {fillingLang.packingType}
                  </small>
                  {/* {packingDescription} */}
                </p>
                <div className='boxes-modal__packing-price-wrapper'>
                  <Counter
                    counterValueChanged={(val, isIncrement, counter) => counterValueDetailChanged(val, isIncrement, counter)}
                    counterValue={+counter} />
                  <div className='boxes-modal__packing-amount'>
                    {goodsPrice}
                    <small>{general.currency}</small>
                  </div>
                </div>
              </div>
            </div>
            <div className='boxes-modal__items'>
              {
                newGoods.map((item, index) => <ModalPanelItem
                  key={`modal-${item.id}-${index}`}
                  content={item}
                />
                )
              }
            </div>
            <div className='boxes-modal__amount'>
              <span>{fillingLang.boxAmountTitle}</span>
              {itemAmount}
              <small>{general.currency}</small>
            </div>
            <div className={`individual-order__button-group ${isOrder ? 'justify-content-end' : ''} `}>
              <MainButton palette='secondary' onClick={onClose}>
                {staticLocalization.basket?.deleteItemBtns || 'Закрити'}
              </MainButton>
              {!isOrder &&
                <MainButton palette='primary' onClick={detaildChangeQuantityHandler}>
                  {staticLocalization.basket?.saveItemBtns || 'Зберегти'}
                </MainButton>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );

};

export default BasketBoxesDetail;
