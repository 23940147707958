import InputMask from 'react-input-mask';
import React from 'react';
import PropTypes from 'prop-types';

const PhoneMask = ({ handleChange, handleBlur, value, readOnly, ...props }) => {
  return (
    <>
      <InputMask
        mask='+3\8\0 99 999 99 99'
        maskChar='_'
        permanent={[0, 1, 2]}
        placeholder={props.placeholder || ''}
        id='telephone'
        name='telephone'
        type='tel'
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    </>
  );
};

PhoneMask.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool
};

export default PhoneMask;
