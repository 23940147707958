import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import './SliderContainer.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { Context } from '../../context';
import { defaultItemProps } from '../../utils/defaultItemProps';
import { modifidedItemWithTranslationField, getTranslation, getCategoryName } from '../../utils/renderFieldWithTransition';
import { clearHtml } from '../../utils/clearHtml';
import { addGoodsToBasket } from '../../utils/api';
import { dotToСoma, isStringEmpty } from '../../utils/string';

import MainSlider from '../../components/MainSlider/MainSlider';
import MainButton from '../../components/MainButton/MainButton';
import Like from '../../components/Like/Like';
import Counter from '../../components/Counter/Counter';

import { ReactComponent as WeightIcon } from './../../assets/icons/weight-icon.svg';
import { ReactComponent as SizingIcon } from './../../assets/icons/sizing-icon.svg';
import { ReactComponent as BasketIcon } from '../../assets/icons/basket-icon.svg';
import { ReactComponent as ShowMoreArrow } from './../../assets/icons/filter-arow.svg';
import BasketAddingAnimation from '../BasketAddingAnimation/BasketAddingAnimation';
import ErrorImg from '../../assets/images/candy-1--error.png';

const SliderContainer = ({ data, onClick, buttonTitle, otherContent }) => {

  const { ...getCategories } = useContext(Context);
  const { isLogined, changeBasketItemsLength, staticLocalization, curentLanguageId } = useContext(Context);

  const [item, setItem] = useState(data);
  const [showMore, setShowMore] = useState(false);
  const [quintity, setQuintity] = useState(1);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(true)

  const parentRef = useRef();
  const imageRef = useRef();
  const additionalInfoRef = useRef(null);

  const additionalInformation = classNames(
    'additional-information',
    showMore ? 'show-more' : ''
  );

  const arrowClassName = classNames('expand-text', { 'open': showMore });

  // DATA:
  const category = getCategoryName(data.category, getCategories.categories, curentLanguageId);
  const name = getTranslation(data, item, 'name', curentLanguageId);
  const description = getTranslation(data, item, 'description', curentLanguageId);
  const clearDescription = clearHtml(description);
  const expiration = getTranslation(data, item, 'expiration', curentLanguageId);
  const storageConditions = getTranslation(data, item, 'storageConditions', curentLanguageId);
  const clearStorageConditions = clearHtml(storageConditions);
  const allergens = getTranslation(data, item, 'allergens', curentLanguageId);
  const { isInWishlist, weight, width, height, length, price } = data;
  const { individualGood } = staticLocalization;

  const showAdditionalInfo = useMemo(() => {
    return {
      expiration: isStringEmpty(expiration),
      clearStorageConditions: isStringEmpty(clearStorageConditions),
      allergens: isStringEmpty(allergens),
    }
  }, [expiration, clearStorageConditions, allergens]);

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setItem(newData);
  }, []);

  useEffect(() => {
    setShowMoreButton(additionalInfoRef.current.scrollHeight > additionalInfoRef.current.clientHeight);
  }, [additionalInfoRef])


  const onClickHandler = () => {
    if (onClick) {
      onClick(data);
    } else {
      addGoodsToBasket(isLogined, data, quintity, changeBasketItemsLength)
    }

    setShowAnimation(!showAnimation);
  }

  const isOtherContent = otherContent ? otherContent === 'fillingBox' : false;

  return(
    <Container fluid ref={parentRef}>
      <Row>
        <Col lg={12} xl={8} className='slider-and-description'>
          <MainSlider data={data} category={category} name={name}/>
          <div className='product-description'>
            <div className='head'>
              <div className='type'>
                {category}
              </div>
              <h2>
                {name}
              </h2>
            </div>
            <div className='price'>
              {price}
              <span className='currency'>{staticLocalization.general.currency}</span>
            </div>

            <div className='buttons'>
              { !isOtherContent &&  <Counter counterValueChanged={(val, vals) => setQuintity(val)}/> }
              <div className='relative'>
                <MainButton className='btns--main' onClick={onClickHandler}>
                  <BasketIcon /> {buttonTitle ? buttonTitle : individualGood.mainItemBtnText}
                </MainButton>
                <BasketAddingAnimation parentRef={parentRef} showAnimation={showAnimation} product={true}/>
              </div>
              <div className='beloved'>
                <Like goodsId={data.id} isInWishlist={isInWishlist}/>
              </div>
            </div>
            <div className='sizing'>
              <div>
                <WeightIcon />
                <span>{dotToСoma(curentLanguageId, weight)}{staticLocalization.general.grams}</span>
              </div>
              <div className='height-weight-length'>
                <div className='height-and-icon'>
                  <span>{height} {staticLocalization.general.millimeters}</span>
                  <SizingIcon />
                </div>
                <div className='width-and-length'>
                  <span>{width} {staticLocalization.general.millimeters}</span>
                  <span>{length} {staticLocalization.general.millimeters}</span>
                </div>
              </div>
            </div>
            <small className='additional-text'>{individualGood.additionalText}</small>
          </div>
          <div className={additionalInformation} ref={additionalInfoRef}>
            <div className='text' dangerouslySetInnerHTML={{ __html: clearDescription }}></div>
            {Object.values(showAdditionalInfo).some(Boolean) &&
              <ul className='about-product'>
                {showAdditionalInfo.expiration &&
                  <li>
                    <div className='title'>{individualGood.expirationDate}</div>
                    {expiration}
                  </li>
                }
                {clearStorageConditions &&
                  <li>
                    <div className='title'>{individualGood.storageConditions}</div>
                    <div dangerouslySetInnerHTML={{__html: clearStorageConditions}}></div>
                  </li>
                }
                {allergens &&
                  <li>
                    <div className='title'>{individualGood.allergens}</div>
                    {allergens}
                  </li>
                }
              </ul>
            }
          </div>
          {showMoreButton &&
            <div className='show-more-btn'>
              <MainButton onClick={() => setShowMore(!showMore)} palette='secondary'>
                {showMore ? individualGood.showLessBtnText : individualGood.showMoreBtnText}
                <ShowMoreArrow className={arrowClassName}/>
              </MainButton>
            </div>
          }
        </Col>
      </Row>
    </Container>
  );
};

SliderContainer.propTypes = {
  data: PropTypes.object
};
SliderContainer.defaultProps = {
  data: defaultItemProps
};

export default SliderContainer;
