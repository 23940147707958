import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context } from '../../../context';
import { ReactComponent as PurchaseIcon } from './../../../assets/icons/purchase.svg';
// import { ReactComponent as PurchaseTestIcon } from './../../../assets/icons/purchase-test.svg';

const Purchase = ({ closeMobileMenu, setHardCloseSerchBar }) => {

  const { basketItemsLength } = useContext(Context);
  return(
    <div className='purchase'>
      <Link to='/basket' onClick={() => {closeMobileMenu(); setHardCloseSerchBar();}}>
        <PurchaseIcon />
        <span>
          {basketItemsLength}
        </span>
      </Link>
    </div>
  );
};

Purchase.propTypes = {
  closeMobileMenu: PropTypes.func
};

export default Purchase;