import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../context';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './Profile.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { getWihsList } from '../../utils/api';

import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import Pagination from '../../components/Pagination/Pagination';
import MainItem from '../../components/MainItem/MainItem';
import Loader from '../../components/Loader/Loader';
import { ReactComponent as PrevIcon } from './../../assets/icons/previous-arrow.svg';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';

import { useWindowSize } from '../../utils/useWindowSizeHook';

function ProfileLiked ({ history }) {

  const [data, setData] = useState([]);
  const [isLiked, setIsLiked] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false);
  const [countOfElements, setCountOfElements] = useState(0);
  const [elementsOffset, setElementsOffset] = useState(10);
  const [elementsPage, setElementsPage] = useState(0);
  const [pageOffset, setPageOffset] = useState(1);
  const [isEmpty, setIsEmpty] = useState(true);
  const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false);

  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [showPagination, setShowPagination] = useState(false);

  const { isLogined, staticLocalization } = useContext(Context);
  const { personalOffice, personalOfficeLiked } = staticLocalization;


  const title = personalOffice.mainTitle;
  const subtitle = personalOfficeLiked.mainTitle;
  const moreButtonTitle = personalOfficeLiked.showMore;
  const backButtonTitle = personalOffice.backButtonTitle;

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {

    scrollToTop();

    if(!isLogined){
      history.push('/profile');
    }

    if (showMenu !== false) {
      setShowMenu(false);
    }
  }, [location.key]);

  const showMenuHandler = ()=> {
    setShowMenu(true);
  };

  useEffect(()=>{
    getWihsList(elementsOffset, 0).then(res => {
      return res.json();
    }).then(wihsList => {
      setCountOfElements(wihsList.count);
      setIsEmpty(wihsList.count === 0);
      setData(wihsList.result || []);
      setShowPagination(wihsList.count > elementsOffset);
      setHideShowMoreBtn(wihsList.count <= elementsOffset);
      setIsLoaded(true);
    });
  }, [isLiked]);

  const paginationLogick = (page) => {
    setIsLoaded(false);
    getWihsList(elementsOffset, (elementsOffset * page)).then((res)=>{ // Get all group items
      return res.json();
    }).then((el)=>{
      setPageOffset(page + 1);
      setData(el.result);
      setHideShowMoreBtn(el.result.length < elementsOffset);
      setCountOfElements(el.count);
      setShowMoreBtn((elementsOffset * page) + el.result.length < el.count);
      setIsLoaded(true);
    });
  };

  const loadMore = () => {
    setIsLoaded(false);
    const elCount = elementsOffset * (elementsPage + 1);
    getWihsList(elementsOffset, elCount).then(res => {
      return res.json();
    }).then(wihsList => {
      setData([...data, ...wihsList.result]);
      setElementsPage(elementsPage + 1);
      setHideShowMoreBtn(elCount + elementsOffset >=  wihsList.count);
      setIsLoaded(true);
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='profile-liked'>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {title}
            </MainTitle>
          </Col>
        </Row>
        { width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous' onClick={showMenuHandler}>
                <PrevIcon className='profile__icon-previous'/>
                <span>{backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12} >
            { ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  && <>
              <h3 className='profile__subtitle'>{subtitle}</h3>
            </>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='profile-liked__wrapper'>
              { ((width <= LAPTOP && showMenu ) || width > LAPTOP) && <>
                <div className='profile__menu-wrapper'>
                  <ProfileMenu />
                </div>
              </>
              }
              {
                isLoaded ?
                  isEmpty ?
                    ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  && <>
                      <Row>
                        <Col xs={12} className='empty-wishlist'>
                          <h3>{personalOfficeLiked.emptyWishlist}</h3>
                        </Col>
                      </Row>
                    </>
                    :
                    ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  &&
                    <div className='cart-wrapper'>
                      <div className='main-item-wrapper'>
                        {data.map((el) => <MainItem
                            key={el.id}
                            withCounter
                            withDescription
                            item={el}
                            isLikedProp={isLiked}
                            setIsLikedProp={setIsLiked}
                            buttonLink='/product/'/> )}
                      </div>
                      { showMoreLoader && <Loader /> }
                    </div>
                  :
                  <Loader />
              }
            </div>
          </Col>
        </Row>
        {
          isEmpty ?
            null
            :
            <>
              { width > LAPTOP && <>
                <Row>
                  <Col>
                    <div className='profile-liked__show-more'>
                      {
                        hideShowMoreBtn ?
                          null
                          :
                          <MainButton className='btns--main' onClick={() => loadMore()}>
                            {moreButtonTitle}
                          </MainButton>
                      }
                    </div>
                  </Col>
                </Row>
              </>
              }
              { ((width <= LAPTOP && !showMenu )) && <>
                <Row>
                  <Col>
                    <div className='profile-liked__show-more'>
                      {
                        hideShowMoreBtn ?
                          null
                          :
                          <MainButton className='profile-liked__btns-more' palette='secondary'  onClick={() => loadMore()}>
                            {moreButtonTitle} {`(${elementsPage + 1}/${Math.ceil(countOfElements / elementsOffset)})`}
                          </MainButton>
                      }
                    </div>
                  </Col>
                </Row>
              </>
              }
              { (width > LAPTOP) && (countOfElements > elementsOffset) && <>
                <Row>
                  <Col xs={12}>
                    <Pagination
                      elementCount={countOfElements}
                      elementLimit={elementsOffset}
                      returnPageOffset={offset=>paginationLogick(offset)}
                    />
                  </Col>
                </Row>
              </>
              }
            </>
        }
      </Container>
    </>
  );
}

ProfileLiked.propTypes = {
  history: PropTypes.object
};

export default withRouter(ProfileLiked);
