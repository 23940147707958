import React, { useState } from 'react';
import './HeaderButtonGroup.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SearchBar from './SearchBar/SearchBar';
import LeanguageSwitcher from './LeanguageSwitcher/LeanguageSwitcher';
import PersonalOffise from './PersonalOffise/PersonalOffise';
import ThemeSwitcher from './ThemeSwitcher/ThemeSwitcher';
import Beloved from './Beloved/Beloved';
import Purchase from './Purchase/Purchase';

import { useWindowSize } from '../../utils/useWindowSizeHook';

import { ReactComponent as BurgerLine } from './../../assets/icons/burger-line.svg';

const HeaderButtonGroup = ({ openMobileMenu, mobileMenuIsOpen, showShadow  }) => {

  const [hardCloseSerchBar, setHardCloseSerchBar] = useState(false);

  const { width } = useWindowSize();
  const MOBILE = 1200;

  const mobileMenuClassNames = classNames(
    'burger',
    mobileMenuIsOpen ? 'open' : null
  );

  const burgerButtonClockHandler = () => {
    openMobileMenu(!mobileMenuIsOpen)
    showShadow(shadow => !shadow);
  }

  const closeMobileMenuHandler = () => {

    showShadow(false);
    openMobileMenu(false);

  }

  return(
    <div className='header-button-group'>
      <SearchBar mobileMenuIsOpen={mobileMenuIsOpen} handleChangeStatusOfMobileMenu={closeMobileMenuHandler} hardCloseSerchBar={hardCloseSerchBar} />
      <ThemeSwitcher setHardCloseSerchBar={setHardCloseSerchBar}/>
      <LeanguageSwitcher setHardCloseSerchBar={() => setHardCloseSerchBar(!hardCloseSerchBar)}/>
      <PersonalOffise closeMobileMenu={closeMobileMenuHandler} setHardCloseSerchBar={() => setHardCloseSerchBar(!hardCloseSerchBar)}/>
      <Beloved closeMobileMenu={closeMobileMenuHandler} setHardCloseSerchBar={() => setHardCloseSerchBar(!hardCloseSerchBar)}/>
      <Purchase closeMobileMenu={closeMobileMenuHandler} setHardCloseSerchBar={() => setHardCloseSerchBar(!hardCloseSerchBar)}/>
      <button type='button' className={mobileMenuClassNames} onClick={burgerButtonClockHandler}>
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </button>
    </div>
  );
};

HeaderButtonGroup.propTypes = {
  openMobileMenu: PropTypes.func,
  mobileMenuIsOpen: PropTypes.bool
};

export default HeaderButtonGroup;