import React, { useState, useEffect, useContext } from 'react';
import './FourthStepTemplate.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Context } from '../../../context';

import RadioButton from '../../../components/RadioButton/RadioButton';
import LocalForm from '../../../components/Forms/LocalForm/LocalForm';
import LocalNumberConfirmationPopup
from '../../../components/Forms/LocalNumberConfirmationPopup/LocalNumberConfirmationPopup';

import MainButton from '../../../components/MainButton/MainButton';
import CheckBox from '../../../components/CheckBox/CheckBox';
import LocalFromGetBofons from '../../../components/Forms/LocalForm/LocalFromGetBofons';
import Loader from '../../../components/Loader/Loader';

import { checkCard, createOrder, liqPay } from '../../../utils/api';
import { clearStorage } from '../../../utils/clearStorage';

const FourthStepTemplate = ({
  handleChangeStap,
  notRegisterCustomerId,
  recipientId,
  additionalProtection,
  paymentTypes,
  setShowMessage,
  setOrderIsSuccess,
  departureAt,
  desiredDeliveryDate,
  comment,
  setShowBlockedUserMessage,
  useLocalCard,
  setUseLocalCard,
  setBofonQuintity,
  setPaymentMethod
}) => {

  const [state, setState] = useState({ paymentMethod: 'CASH' });
  const [showLoginedLocalForm, setShowLoginedLocalForm] = useState(false);
  const [localCardIsChecked, setLocalCardIsChecked] = useState(true);
  const [errorQuintity, setErrorQuintity] = useState(0);

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(0);
  const [showLocalNumberConfirmationPopup, setShowLocalNumberConfirmationPopup] = useState(false);
  const [localCardNumber, setLocalCardNumber] = useState(0);
  const [proposeBurnAmount, setProposeBurnAmount] = useState(0);
  const [isItPossibleToWithdraw, setIsItPossibleToWithdraw] = useState(true);
  const [howMuchcanWithdraw, setHowMuchcanWithdraw] = useState(0);

  const { setBodyUnscroll, userData, blackTheme, staticLocalization, changeBasketItemsLength, isLogined } = useContext(Context);
  const { fourthOrderStep } = staticLocalization;
  const { localForm, order } = staticLocalization;

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setShowLoginedLocalForm(!!userData.localCard);
  }, [userData.localCard]);


  useEffect(() => {
    if (paymentTypes.length === 1) {
      setState({ paymentMethod: 'BANK' });
    }
  }, []
  );

  useEffect(() => {
    setPaymentMethod(state.paymentMethod);
  }, [setPaymentMethod, state.paymentMethod]);


  const onChangeHandler = (name, value) => {
    setState({ [name]: value });
  };

  const classes = classNames(
    'fourth-step',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const noWithdrawPossibilityClasses = classNames(
    'no-withdraw-possibility',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const paymentMethodRadiobuttons = () => {
    return paymentTypes.map(el => {
      if (el === 'CASH') {
        return (
          <RadioButton name='paymentMethod' value='CASH' key={el}
            checkedValue={
              state.paymentMethod
            }
            onChange={onChangeHandler}>
            <div className='title'>
              {fourthOrderStep.paymentUponReceiptOfTheOrder}
            </div>
          </RadioButton>
        );
      } else if (el === 'BANK') {
        return (
          <RadioButton name='paymentMethod' value='BANK' key={el}
            checkedValue={
              state.paymentMethod
            }
            onChange={onChangeHandler}>
            <div className='title'>
              LIQPAY
            </div>
          </RadioButton>
        );
      }
    });
  };

  const makeOrder = () => {
    if (userData.status === 'E_BLOCKED') {
      setShowBlockedUserMessage();
    } else {
      setIsButtonClicked(true);
      createOrder(notRegisterCustomerId, recipientId, additionalProtection, state.paymentMethod, true, departureAt, desiredDeliveryDate, comment).then(res => {
        if (res.ok) {
          res.json().then(el => {
            if (state.paymentMethod === 'BANK') {
              liqPay(el.id, notRegisterCustomerId).then(res => {
                return res.text();
              }).then(el => {

                const doc = new DOMParser().parseFromString(el, 'text/html');

                document.getElementsByClassName('liqpay-wrapper')[0].appendChild(doc.childNodes[0]);
                document.getElementsByClassName('liqpay-wrapper')[0].getElementsByTagName('form')[0].submit();

              });
            } else {
              setBodyUnscroll(true);
              setShowMessage();
              setOrderIsSuccess(res.ok);
              // clear basket local storage after order
              localStorage.setItem('lmsh-basket', JSON.stringify([]));
              // changeBasketItemsLength(0);
            }
          });
        } else {
          setBodyUnscroll(true);
          setShowMessage();
          setOrderIsSuccess(res.ok);
          changeBasketItemsLength(0);
          setIsButtonClicked(false);
        }
      });
      
      clearStorage();
    }

  };

  return (
    <div className={classes}>

      <div className='payment-method'>

        <div className='payment-method__wrapper'>

          {paymentMethodRadiobuttons()}

          <div className='liqpay-wrapper'></div>

        </div>

      </div>
      {
        localCardIsChecked ?
          <div className='local-card'>
            <div className='checkbox-wrapper'>
              <CheckBox
                isChecked={false}
                name='use-local-card'
                onChange={(name, value, checked) => {
                  setUseLocalCard(checked);
                  if (checked && isLogined && userData.localCard) {
                    checkCard(userData.localCard).then(res => {
                      if (res.ok) {
                        res.json().then(data => {
                          setIsItPossibleToWithdraw(data.balanceAfter);
                          setHowMuchcanWithdraw(data.proposeBurnAmount);
                          setProposeBurnAmount(data.proposeBurnAmount);
                          // setLocalCardNumber(data.cardNo);
                          // setShowLoginedLocalForm();
                        });
                      }
                    });
                  }
                }}
              >
                <div className='text'>
                  <div className='title'>
                    {localForm.checkBoxTitle}
                  </div>
                  <div className='description'>
                    {localForm.checkBoxDescription}
                  </div>
                </div>
              </CheckBox>
            </div>
            {
              useLocalCard ?
                showLoginedLocalForm ?
                  isItPossibleToWithdraw ?
                    <LocalFromGetBofons
                      makeOrder={() => makeOrder()}
                      useLocalCard={useLocalCard}
                      setErrorQuintity={() => setErrorQuintity(errorQuintity + 1)}
                      orederUserId={notRegisterCustomerId}
                      localCardNumber={localCardNumber}
                      proposeBurnAmount={proposeBurnAmount}
                      howMuchcanWithdraw={howMuchcanWithdraw}
                      changeBofonQuintityForWithdraw={val => setBofonQuintity(val)}
                    />
                    :
                    <div className={noWithdrawPossibilityClasses}>
                      <h3>{localForm.noWithdraw}</h3>
                    </div>
                  :
                  <LocalForm
                    changeStep={val => handleChangeStap(val)}
                    formLocation='ORDER_FOURTH_STEP'
                    makeOrder={() => makeOrder()}
                    useLocalCard={useLocalCard}
                    setShowLoginedLocalForm={val => setShowLoginedLocalForm(val)}
                    setShowLocalNumberConfirmationPopup={(val) => setShowLocalNumberConfirmationPopup(val)}
                    setErrorQuintity={() => setErrorQuintity(errorQuintity + 1)}
                    showVerificationPopup={() => setShowLocalNumberConfirmationPopup(true)}
                    setCustomerPhoneNumber={number => setCustomerPhoneNumber(number)}
                  />
                :
                null
            }
            <div className='foot-btn-group'>
              <MainButton type='button' palette='secondary' onClick={() => handleChangeStap('prev')}>
                {order.goBack}
              </MainButton>
              <div className='submit'>
                {
                  errorQuintity >= 3 ?
                    <MainButton type='button' palette='secondary'
                      onClick={() => makeOrder()} disabled={isButtonClicked}>{localForm.withoutCard}</MainButton>
                    :
                    null
                }
                <MainButton type='button' onClick={() => makeOrder()} disabled={isButtonClicked}>{localForm.toOrder}</MainButton>
              </div>
            </div>
            {
              showLocalNumberConfirmationPopup ?
                <LocalNumberConfirmationPopup
                  type='LOCAL_LOGIN_PAGE'
                  formLocation='ORDER_FOURTH_STEP'
                  hideVerificationPopup={() => setShowLocalNumberConfirmationPopup(false)}
                  customerPhoneNumber={customerPhoneNumber}
                  setLocalCardNumber={(number) => setLocalCardNumber(number)}
                  orederUserId={notRegisterCustomerId}
                  setProposeBurnAmount={amount => setProposeBurnAmount(amount)}
                  setHowMuchcanWithdraw={quintity => setHowMuchcanWithdraw(quintity)}
                  setIsItPossibleToWithdraw={val => setIsItPossibleToWithdraw(val)}
                  setShowLoginedLocalForm={() => setShowLoginedLocalForm(true)}
                />
                :
                null
            }
          </div>
          :
          <Loader />
      }
    </div>
  );
};

FourthStepTemplate.propTypes = {
  handleChangeStap: PropTypes.func,
  notRegisterCustomerId: PropTypes.any,
  recipientId: PropTypes.number,
  additionalProtection: PropTypes.bool,
  paymentTypes: PropTypes.array,
  setShowMessage: PropTypes.func,
  setOrderIsSuccess: PropTypes.func,
  departureAt: PropTypes.number,
  desiredDeliveryDate: PropTypes.instanceOf(Date),
  comment: PropTypes.string,
  setShowBlockedUserMessage: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  useLocalCard: PropTypes.bool,
  setUseLocalCard: PropTypes.func,
  setBofonQuintity: PropTypes.func,
};

export default FourthStepTemplate;