import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context } from '../../context';
import MainButton from '../MainButton/MainButton';
import { imagesUrl } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';

function CategoryItem({ item, buttonText }) {
  const [data, setData] = useState(item);
  const { settings, curentLanguageId } = useContext(Context);

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setData(newData);
  }, []);

  const img = `${imagesUrl}${item.images}?v=${settings.frontendVersion}`;
  const title = getTranslation(item, data, 'name', curentLanguageId);
  const description = getTranslation(item, data, 'metaDescription', curentLanguageId);

  return (
    <div className='category-group-item'>
      <Link to={`/category/${item.slug || 'group'}`} className='general-link'></Link>
      <div className='background'></div>
      <div className='content'>
        <h2 className='category-group-item__title'>{title}</h2>
        <div className='category-group-item__text'>
          <p className='category-group-item__description'>{description}</p>
          <div className='category-group-item__button'>
            <MainButton
              className='btns--main'
              href={`/category/${item.slug || 'group'}`}
            >
              {buttonText}
            </MainButton>
          </div>
        </div>
        <div className='category-group-item__img'>
          <img className='img-fluid' src={img} alt={item.goodsAlt}/>
        </div>
        <div className='category-group-item__button category-group-item__button--mobile'>
          <MainButton
            className='btns--main'
            href={`/category/${item.slug || 'group'}`}
          >
            {buttonText}
          </MainButton>
        </div>
      </div>
    </div>
  );
}

CategoryItem.propTypes = {
  item: PropTypes.object,
  buttonText: PropTypes.string
};

export default CategoryItem;