import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import './Dropdown.scss';

import { ReactComponent as ArrowIcon } from './../../assets/icons/filter-arow.svg';
import { ReactComponent as ButtonSide } from './../../assets/images/button-side.svg';
import { ReactComponent as ButtonBody } from './../../assets/images/button-body.svg';

import { getTranslation, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';

import { Context } from '../../context';

const Dropdown = ({
  name, onChange, className, isSearchable, placeholder, options, classNamePrefix,
  defaultValue, addAllOption
}) => {

  const { staticLocalization, curentLanguageId } = useContext(Context);
  const { dropdownLang } = staticLocalization;

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [value, setValue] = useState(null);

  const defaultOptions = [
    {
      'id': 'NEW',
      name: dropdownLang.new
    },
    {
      'id': 'NAME DOWN',
      name: dropdownLang.nameDown
    },
    {
      'id': 'NAME UP',
      name: dropdownLang.nameUp
    },
    {
      'id': 'POPULARITY',
      name: dropdownLang.popularity
    },
    {
      'id': 'PRICE UP',
      name: dropdownLang.priceUp
    },
    {
      'id': 'PRICE DOWN',
      name: dropdownLang.priceDown
    }
  ];

  const darkTheme = useContext(Context);
  const theme = darkTheme ? 'dark' : 'light';

  useEffect(()=> {

    const optionsProps = options.map(item => {
      return {
        id: item.id,
        name: item?.translatableValue ? getTranslation(null, modifidedItemWithTranslationField(item), 'translatableValue', curentLanguageId) : getTranslation(null, modifidedItemWithTranslationField(item), 'name', curentLanguageId)
      };
    });

    const _options = options.length === 0 ?  defaultOptions : optionsProps;

    // set select options
    if (_options && _options.length > 0) {
      setDropdownOptions( () => {
        const firstOption = addAllOption ? [{ value: null, label: `...` }] : [];
        const _selectOptions = firstOption.concat(
          _options.map(item => (
          { value: item.id, label: item.name }
        )));

        // set defaultValue
        const _defaultValue = dropdownOptions.findIndex(item => item.value === value.value);
        setValue(_selectOptions[_defaultValue < 0 ? 0 : _defaultValue]);

        return [..._selectOptions];
      });
    }


  }, [curentLanguageId, options]);

  const classes = classNames(
    'dropdown',
    `dropdown--${theme}`,
    className
  );

  const onChangeHandler = (selectedOption) => {
    onChange(selectedOption);
    setValue({ ...selectedOption });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowIcon />
      </components.DropdownIndicator>
    );
  };

  const ControlComponent = props => (
    <>
      <div className='btns__buttons'>
        <ButtonSide/>
        <ButtonBody/>
        <ButtonSide/>
      </div>
      <components.Control {...props} />
    </>
  );

  return (
    <>
      {
        dropdownOptions.length ?
          <Select
            components={{
              DropdownIndicator,
              Control: ControlComponent,
              IndicatorSeparator: () => null,
            }}
            name={name}
            onChange={onChangeHandler}
            className={classes}
            isSearchable={isSearchable}
            placeholder={placeholder}
            options={dropdownOptions}
            classNamePrefix={classNamePrefix}
            defaultValue={{ label: dropdownOptions[0].label }}
          />
          :
          null
      }
    </>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  classNamePrefix: PropTypes.string,
  defaultValue: PropTypes.number,
  theme: PropTypes.oneOf(['dark', 'light']),
};

Dropdown.defaultProps = {
  name: '',
  onChange: () => {},
  className: '',
  isSearchable: true,
  placeholder: '',
  options: [],
  classNamePrefix: 'dropdown-container',
  defaultValue: 0,
  theme: 'dark',
};

export default Dropdown;
