import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Context } from '../../context';
import { getMainPageTempalte } from '../../utils/api';

import HeroBanner from './../../components/HeroBanner/HeroBanner';
import Template_1 from '../../components/Templates/Template_1/Template_1';
import Loader from '../../components/Loader/Loader';
import { useIsMountedRef } from '../../utils/useCustomRefHook';

const Home = () => {

  const [template, setTemplate] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    getMainPageTempalte().then(res => {
      return res.json();
    }).then(el => {
      if (isMountedRef.current) {
        // check mounted for browser back
        setTemplate(el.templateItemValue);
        setIsLoaded(true);
      }

    }).catch(err => {
    });
  }, []);

  return (
    <Container fluid className='g-template-style'>
      {
        isLoaded ?
          <>
            <HeroBanner banerData={template}/>
            <Template_1 template={template} />
          </>
          :
          <Loader />
      }
    </Container>
  );
};

export default Home;

