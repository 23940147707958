import React, { useState, useEffect, useContext } from 'react';
import './Kit.scss';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { getKits } from '../../utils/api';
import { Context } from '../../context';

import MainTitle from '../../components/MainTitle/MainTitle';
import KitItem from './KitItem';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import MainButton from '../../components/MainButton/MainButton';
import Dropdown from '../../components/Dropdown/Dropdown';

import { ReactComponent as ArrowToLeftIcon } from './../../assets/icons/previous-arrow.svg';

const Kit = ({ history }) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [getedData, setGetedData] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [paginationLimit] = useState(6);
  const [kitsQuintity, setKitsQuintity] = useState(0);
  const [hideShowMoreBtn, setHideShowMoreBtn] = useState(true);
  const [sortValue, setSortValue] = useState('NEW');
  const [sortToCheapest, setSortToCheapest] = useState('');
  const [initialPaginationPage, setInitialPaginationPage] = useState(true);
  const [showPagination, setShowPagination] = useState(false);

  const { blackTheme, staticLocalization, curentLanguageId } = useContext(Context);
  const { kits } = staticLocalization;
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const getData = (getAllElement, offset, sort, sortAsc) => {
    getKits(offset, paginationLimit, sort, sortAsc, curentLanguageId).then((response) => {
      setIsLoaded(false);
      return response.json();
    })
      .then((data) => {
        setKitsQuintity(data.count);
        getAllElement ? setGetedData(data.result) : setGetedData([...getedData, ...data.result]);
        setHideShowMoreBtn((data.count - paginationLimit) <= offset);
        setShowPagination(data.result.length < data.count);
        setIsLoaded(true);
      });
  };

  useEffect(()=>{
    getData(true, pageOffset);
  }, []);

  const handleLoadItems = (val, callback) => {
    callback(val);
  };

  const paginationLogick = (val) => {
    const newPageOffset = val * paginationLimit;
    setPageOffset(newPageOffset);
    getData(true, newPageOffset, sortValue, sortToCheapest);
  };

  const loadMoreLogick = (val) => {
    const newPageOffset = val + paginationLimit;
    setPageOffset(newPageOffset);
    getData(false, newPageOffset, sortValue, sortToCheapest);
  };

  const sortingLogick = (selectedOption) => {
    const { value } = selectedOption;
    setPageOffset(0);
    setInitialPaginationPage(!initialPaginationPage);

    if(value === 'PRICE DOWN'){
      setSortValue('PRICE');
      setSortToCheapest(false);
      return getData(true, 0, 'PRICE', false);
    }else if(value === 'PRICE UP'){
      setSortValue('PRICE');
      setSortToCheapest(true);
      return getData(true, 0, 'PRICE', true);
    }
    else if(value === 'NAME DOWN'){
      setSortValue('NAME');
      setSortToCheapest(false);
      return getData(true, 0, 'NAME', true);
    }
    else if(value === 'NAME UP'){
      setSortValue('NAME');
      setSortToCheapest(false);
      return getData(true, 0, 'NAME', false);
    }
    else if(value === 'POPULARITY'){
      setSortValue('POPULARITY');
      setSortToCheapest(true);
      return getData(true, 0, value, false);
    }
    setSortValue(value);
    return getData(true, 0, value);
  };

  return(
    <Container fluid className='kit'>
      <Helmet>
        <title>
          Комплекти з шоколаду, фігурок, тафель, цукерок, кави — солодкий подарунок від Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Купити подарунковий комплект солодощів від Львівської майстерні шоколаду ❖ Подарунок на день народження ❖ Подарунок на весілля ❖ Презент на ювілей ❖ Шоколадний дарунок для всіх ❖ Подарунок коханому ❖ Готовий шоколадний подарунок'
          data-react-helmet='true'
        />
      </Helmet>
      <Row>
        <Col xs={12}>
          <MainTitle>
            {kits.mainTitle}
          </MainTitle>
        </Col>
        <Col className='kit__dropdown-wrapper'>
          <div className='mibile-go-back'>
            <MainButton palette='secondary' onClick={() => history.goBack()} >
              <ArrowToLeftIcon className='mr-3'/>
              {staticLocalization.category.goBack}
            </MainButton>
          </div>
          <Dropdown isSearchable={false} onChange={(selectedOption) => sortingLogick(selectedOption)}/>
        </Col>
        <Col xs={12} className={`kit__items-wrapper ${getTheme}`}>

          {isLoaded ?
            getedData.map((itemData)=>{
              return <KitItem item={itemData} buttonTitle={`${kits.btnsToBasket}`} removeArrow addCounter addLikeButton key={itemData.id} href={`/product/${itemData.slug}`} />;
            })
            :
            <Loader />
          }
        </Col>
        {
          hideShowMoreBtn
            ?
            null
            :
            <Col xs={12} className='show-more-btn-wrapper'>
              <MainButton className='btns--showmore' onClick={()=>handleLoadItems(pageOffset, loadMoreLogick)}>{kits.showMoreBtnText}</MainButton>
            </Col>
        }
        <Col>
          {
            showPagination && <>
              <Pagination
                elementCount={kitsQuintity}
                elementLimit={paginationLimit}
                returnPageOffset={offset=>handleLoadItems(offset, paginationLogick)}
                initialOffset={initialPaginationPage}
              />
            </>
          }
        </Col>
      </Row>
    </Container>
  );
};

Kit.propTypes = {
  history: PropTypes.object
};

export default withRouter(Kit);