import React from 'react';
import CategoryGroup from '../../components/CategoryGroup/CategoryGroup';

function CategoryGroupPage() {

  return (
    <CategoryGroup />
  );
}

export default CategoryGroupPage;