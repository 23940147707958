const setEmailToLocalStorage = (email) => {
    return localStorage.setItem('lmsh-email', email);
};

const getEmailFromLocalStorage = () => {
    return localStorage.getItem('lmsh-email');
};

const removeEmailFromLocalStorage = () => {
    return localStorage.removeItem('lmsh-email');
};

export {setEmailToLocalStorage, getEmailFromLocalStorage, removeEmailFromLocalStorage};