import React from 'react';
import './ThirdStepTemplate.scss';
import PropTypes from 'prop-types';
import DeliveryForm from '../../../components/Forms/DeliveryForm/DeliveryForm';

const ThirdStepTemplate = ({
  handleChangeStap,
  setPaymentTypes,
  notRegisterCustomerId,
  setRecipientId,
  setDepartureAt,
  setDesiredDeliveryDate,
  setComment,
  setTemplateName,
  setDeliveryPrice
}) => {
  return (
    <div className='third-step'>
      <DeliveryForm
        changeStep={(val) => handleChangeStap(val)}
        setPaymentTypes={val => setPaymentTypes(val)}
        notRegisterCustomerId={notRegisterCustomerId}
        setRecipientId={(val) => setRecipientId(val)}
        setDepartureAt={(val) => setDepartureAt(val)}
        setDesiredDeliveryDate={(val) => setDesiredDeliveryDate(val)}
        setComment={(val) => setComment(val)}
        setTemplateName={(val) => setTemplateName(val)}
        setDeliveryPrice={(val) => setDeliveryPrice(val)}
      />
    </div>
  );
};


ThirdStepTemplate.propTypes = {
  handleChangeStap: PropTypes.func,
  setPaymentTypes: PropTypes.func,
  notRegisterCustomerId: PropTypes.number,
  setRecipientId: PropTypes.func,
  setDepartureAt: PropTypes.func,
  setDesiredDeliveryDate: PropTypes.func,
  setComment: PropTypes.func,
  setTemplateName: PropTypes.func,
  setDeliveryPrice: PropTypes.func
};

export default ThirdStepTemplate;
