import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import PageContainer from './components/PageContainer/PageContainer';
import 'core-js/features/number/is-nan';
import 'core-js/es/';
import 'raf/polyfill';

ReactDOM.render(
  <PageContainer />,
  document.getElementById('root')
);

serviceWorker.unregister();
