import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PreviousArrowIcon } from './../../assets/icons/previous-arrow.svg';
import { ReactComponent as NextArrowIcon } from './../../assets/icons/next-arrow.svg';
import './Pagination.scss';
import { withRouter } from 'react-router-dom';

import { scrollToTop } from '../../utils/scrollToTop';

const Pagination = ({ elementCount, elementLimit, returnPageOffset, initialOffset, history }) => {
  const [pageOffset, setPageOffset] = useState(0);
  const [elementsArray, setElementsArray] = useState([]);

  useEffect(() => { // create a arr for loop
    const newArr = [];
    const quintityOfPagesButton = Math.ceil(elementCount / elementLimit);

    for (let i = 0; i < quintityOfPagesButton; i++) {
      newArr.push(i);
    }

    setElementsArray(newArr);

  }, [elementCount]);

  useEffect(() => {
    setPageOffset(0);
  }, [initialOffset]);

  const PageLimit = 7;
  const elementsArrayAmount = elementsArray.length;

  const handleClickPaginationBtn = (elementNum) => {
    setPageOffset(elementNum);
    returnPageOffset(elementNum);
    scrollToTop(0, 'auto');
  };

  const renderButton = (elementNum, isActive) => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get('page') ? query.get('page') - 1 : 0;
    const classsName = (page === elementNum) ? 'active' : '';

    const id = query.get('id') ?? false ;
    const fitlerIds = query.get('fitlerIds') ? '&fitlerIds=' + query.get('fitlerIds').split(',') : '';
    const fitlerValues = query.get('fitlerValues') ? '&fitlerValues=' + query.get('fitlerValues').split(',') : '';

    return (
      <button key={elementNum} onClick={() => {
        handleClickPaginationBtn(elementNum);
        history.push({
          search: (id ? ('id=' + id) : '') + fitlerIds + fitlerValues + '&page=' + (elementNum + 1),
        });
      }}
              className={classsName}>
        {elementNum + 1}

      </button>
    );
  };

  const choseActiveButton = (elementNum) => {
    return renderButton(elementNum, elementNum === pageOffset);
  };

  const paginationButtonLogick = (elementNum) => {
    if ((elementsArrayAmount - PageLimit) > pageOffset) {  // if left more then 7 pages
      if (elementNum < (pageOffset + PageLimit) && elementNum > (pageOffset - 1)) {  // Render elemen what we need
        return choseActiveButton(elementNum);
      } else if (elementsArrayAmount - 1 === elementNum) { // Render last element
        return renderButton(elementNum, false);
      } else if ((elementNum - PageLimit) === pageOffset) { // Render dots
        return <span key={ elementNum }>...</span>;
      }
    } else {  // if left less then 7 pages
      if ((elementNum + (PageLimit + 1)) > elementsArrayAmount) {
        return choseActiveButton(elementNum);
      }
    }

  };

  const prevButtonHandleClick = () => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get('page') ?? 0;

    if (page > 1) {
      const fitlerIds = query.get('fitlerIds') ? '&fitlerIds='+ query.get('fitlerIds') : '';
      const fitlerValues = query.get('fitlerValues') ? '&fitlerValues='+ query.get('fitlerValues') : '';
      const id = query.get('id') ?? 1;
      const newPageOffset = Number(page) - 1;

      setPageOffset(newPageOffset);
      returnPageOffset(newPageOffset);
      scrollToTop(0, 'auto');
      history.push({
        search: (id ? ('id=' + id) : '') + fitlerIds + fitlerValues + '&page=' + newPageOffset,
      });
    }
    return null;
  };

  const nextButtonHandleClick = () => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get('page') ?? 1;

    if (elementsArray.length > Number(page)) {
      const fitlerIds = query.get('fitlerIds') ? '&fitlerIds='+ query.get('fitlerIds') : '';
      const fitlerValues = query.get('fitlerValues') ? '&fitlerValues='+ query.get('fitlerValues') : '';
      const id = query.get('id');

      const newPageOffset = (Number(page) + 1);
      setPageOffset(newPageOffset);
      returnPageOffset(newPageOffset);
      scrollToTop(0, 'auto');
      history.push({
        search: (id ? ('id=' + id) : '') + fitlerIds + fitlerValues + '&page=' + newPageOffset,
      });
    }
    return null;
  };

  return (
    <div className="pagination">
      <div className="prev-btn">
        <button onClick={() => prevButtonHandleClick()}>
          <PreviousArrowIcon/>
        </button>
      </div>

      <div className="control-btn">
        {elementsArray.map((el, index) => paginationButtonLogick(index))}
      </div>

      <div className="next-btn">
        <button onClick={() => nextButtonHandleClick()}>
          <NextArrowIcon/>
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  elementCount: PropTypes.number,
  elementLimit: PropTypes.number,
  returnPageOffset: PropTypes.func,
  initialOffset: PropTypes.bool
};

export default withRouter(Pagination);