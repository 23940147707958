import React from 'react';
import IndividualGroup from '../../components/IndividualGroup/IndividualGroup';
import { Helmet } from 'react-helmet';

const Individual = () => {
  return (
    <>
      <Helmet>
        <title>
          Індивідуальне замовлення тафлі, фігурки, картини з шоколаду у Львівській майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Замовити шоколадні тафлі з індивідуальним наповенням ❖ Шоколадні вироби на замовлення ❖ Шоколадні фігурки на замовлення ❖ Картини з шоколаду ❖ Свій напис на шоколаді ❖ Подарунок на весілля ❖ індивідуальні замовлення у Львівській майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <IndividualGroup className='individual'/>
    </>
  );
};

export default Individual;