import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import IndividualStepsItem from '../../components/IndividualItem/IndividualStepsItem';
import Loader from '../../components/Loader/Loader';
import MainButton from '../../components/MainButton/MainButton';
import Pagination from '../../components/Pagination/Pagination';

import { useWindowSize } from '../../utils/useWindowSizeHook';
import { scrollToTop } from '../../utils/scrollToTop';

import { getBoxes } from '../../utils/api';

import { Context } from '../../context';

const PASSED_STEP_NAME = 'passed';
const ACTIVE_STEP_NAME = 'active';
const NEXT_STEP_NAME = 'next';

function Packing({ steps, setContent, setSteps }) {

  const { blackTheme, staticLocalization } = useContext(Context);
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const { boxesLang, packingLang } = staticLocalization;
 
  const { width } = useWindowSize();
  const MOBILE = 575;
  const LAPTOP = 991;

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState([]);

  const  paginationLimit = 6;  // goods to show
  const [pageOffset, setPageOffset] = useState(0);
  const [itemsQuintity, setItemsQuintity] = useState(0);
  const [initialPaginationPage, setInitialPaginationPage] = useState(false);

  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [showPagination, setShowPagination] = useState(false);

  useEffect( ()=> {
    getData({ limit: paginationLimit, offset: pageOffset });
  }, []);

  const getData = ({ type = 'GOODS', limit, offset, isAllItems = true }) => {

    try {
      isAllItems ? setIsLoaded(false) : setShowMoreLoader(true);
      getBoxes({ type, limit,  offset }).then((res)=>{
        return res.json();
      }).then((items)=>{
        isAllItems ? setData(items.result) : setData([...data, ...items.result]);
        setItemsQuintity(items.count);
        setShowMoreBtn((items.count - paginationLimit) > offset);
        setShowPagination(items.result.length < items.count);
        isAllItems ? setIsLoaded(true) : setShowMoreLoader(false);
      });
    } catch (err) {
      console.log(err);
    }

  };

  const paginationLogick = (val) => {
    setShowMoreBtn(false);
    const offset = val * paginationLimit;
    setPageOffset(offset);
    getData({ limit: paginationLimit, offset, isAllItems: true });
  };

  const loadMoreLogick = (val) => {
    const offset = val + paginationLimit;
    setPageOffset(offset);
    getData({ limit: paginationLimit, offset, isAllItems: false });
  };

  const onShowMoreClick = () => {
    loadMoreLogick(pageOffset);
  };

  const boxesItemButtonHandler = (content, step, itemType) => {
    setContent(content);

    const _stepsContent = steps.content.map((item, index) => {
      if (index === steps.step) {
        return {
          ...item,
          state: PASSED_STEP_NAME
        };
      }
      if (index === steps.step + 1) {
        return {
          ...item,
          state: ACTIVE_STEP_NAME
        };
      }
      return { ...item };
    });

    setSteps(steps => ({
      step: steps.step + 1,
      content: _stepsContent
    }));

    scrollToTop();
  };

  return (
    <>
      <Row>
        <Col xs={12} >
          <h3 className='boxes__subtitle'>{packingLang.subtitle}</h3>
          <p className='boxes__description'>{packingLang.subtitleDescr}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} >
          { isLoaded
            ? <>
              <div className={`boxes__items boxes__items--${steps.step} ${getTheme}`}>
                { data.map(
                  item => <IndividualStepsItem
                    key={`boxes-${item.id}`}
                    content={item}
                    step={steps.step}
                    buttonTitle={packingLang.buttonItemTitle}
                    itemType=''
                    category=''
                    onClick={boxesItemButtonHandler}/>
                )
                }
              </div>
              { showMoreLoader && <Loader /> }
            </>
            : <Loader />
          }
        </Col>
      </Row>
      {
        (width > MOBILE && showPagination) && <>
          <Row>
            <Col xs={12} >
              { showMoreBtn && <>
                <MainButton palette='primary' className='boxes__btns-more boxes__btns-more--desktop btns--showmore' onClick={onShowMoreClick}>
                  {boxesLang.showMoreBtnText}
                </MainButton>
              </>
              }
              <Pagination
                elementCount={itemsQuintity}
                elementLimit={paginationLimit}
                returnPageOffset={paginationLogick}
                initialOffset={initialPaginationPage}
              />
            </Col>
          </Row>
        </>
      }
      {
        (width <= MOBILE && showMoreBtn) && <>
          <Row>
            <Col xs={12} >
              <MainButton palette='secondary' className='boxes__btns-more boxes__btns-more--mobile' onClick={onShowMoreClick}>
                {boxesLang.showMoreBtnText}
              </MainButton>
            </Col>
          </Row>
        </>
      }
    </>
  );
}

Packing.propTypes = {
  steps: PropTypes.object,
  setContent: PropTypes.func,
  setSteps: PropTypes.func,
};

export default Packing;
