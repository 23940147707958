import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './CustomInscription.scss';

import { ReactComponent as CloseSVG } from '../../../assets/icons/close.svg';
import { Context } from '../../../context';

import {getCustomInscriptionAmount} from '../../../utils/getCustomInscriptionValues'

import MainButton from '../../MainButton/MainButton';

const CustomInscription = ({ content, onSubmit }) => {

  const customSettings = content ? content.customSettings[0].ranges : null;
  const initInscriptionPrice = customSettings ? customSettings[0].price : 0;

  //const [price, setPrice] = useState(initInscriptionPrice);
  const [fullPrice, setFullPrice] = useState(0);
  
  const { userData, staticLocalization, setIsOpenModal, isOpenModal } = useContext(Context);
  const { inscriptionForm, general } = staticLocalization;
  
  const setFieldValueHandler = (e, text) => {
    
    const _text = e?.target ? e.target.value : text;
    // const _price = getCustomInscriptionPrice(customSettings, _text);

    //setPrice(_price);
    //setFullPrice(getCustomInscriptionFullPrice(_price, _text));
    setFullPrice(getCustomInscriptionAmount(customSettings, _text));
    return e?.target ? e.target.value : '';
  }

  const toggleModal = (e, forcedEvent) => {
    if ((e.target === e.currentTarget) || (forcedEvent)) {
      setIsOpenModal(!isOpenModal);
    }
  
  };

  const onResetHandler = (resetForm) => {
    resetForm();
    setFieldValueHandler(null, '');
  }
  
  const closeHandler = (e) => {
    toggleModal(e, true);
  };

  const CustomInscriptionSchema  = Yup.object().shape({
    text: Yup.string()
      .required(inscriptionForm.textError)
  });
    
  return (
    <Formik
      initialValues={
        {
          text: ''
        }
      }
      validationSchema={CustomInscriptionSchema}
      onSubmit = {(values) => {
        onSubmit(values.text.trim(), fullPrice);
      }}
    >
      {({ errors, touched, handleSubmit, setFieldValue, setFieldTouched, resetForm }) => (
        <>
          <div className='custom-inscription-form__wrapper'>
            <div className='border-wrapper'>

              <div className='custom-inscription-form__title-wrapper'>
                <h3 className='custom-inscription-form__title'>{inscriptionForm.title}</h3>
                <CloseSVG onClick={closeHandler}/>
              </div>

              <Form className='g-form-style custom-inscription-form'>

                <label>
                  <p className='required'>{inscriptionForm.text}</p>
                  <Field
                    type='text'
                    name='text'
                    placeholder={inscriptionForm.textPlaceholder}
                    onChange={(e) => setFieldValue('text', setFieldValueHandler(e))}/>
                  {errors.text && touched.text ? ( // Error
                    <div className='error'>{errors.text}</div>
                  ) : null}
                </label>
                
                <div className="custom-inscription-form__price-field-wrapper">
                  {/* <p className="custom-inscription-form__price"><span>{inscriptionForm.perSymbolText}</span> {price}<small>{general.currency}</small></p>  */}
                  <p className="custom-inscription-form__price"><span>{inscriptionForm.amountText}</span> {fullPrice}<small>{general.currency}</small></p>
                </div>
                <div className='foot-btn-group'>
                  <MainButton type='reset' palette='secondary' onMouseDown={() => onResetHandler(resetForm)}>
                    {inscriptionForm.clear}
                  </MainButton>
                  <MainButton type='submit' palette='primary' onMouseDown={handleSubmit}>
                    {inscriptionForm.submit}
                  </MainButton>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
      
  );
};

export default CustomInscription;