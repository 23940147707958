import React, { useEffect, useState, useContext } from 'react';
import './SingleCollection.scss';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

import MainTitle from '../../components/MainTitle/MainTitle';
import Loader from '../../components/Loader/Loader';
import Template_1 from './Templates/Template_1/Template_1';

import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';
import { scrollToTop } from '../../utils/scrollToTop';

import { getCollections, getCollectionsId } from '../../utils/api';
import { Context } from '../../context';
import { useHistory } from 'react-router-dom';

function SingleCollection() {
  const history = useHistory();
  const { curentLanguageId } = useContext(Context);
  const [content, setContent] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [locationId, setLocationId] = useState(false);
  const title = getTranslation(null, content, 'name', curentLanguageId);

  useEffect(() => {
    const slug = history.location.pathname.split('/')[2];
    getCollectionsId(slug).then(res => {
      return res.json();
    }).then(el => {
      setLocationId(el.result.shift().id);
    });
  }, [[], history.location.pathname]);

  useEffect(() => {
    if (locationId) {
      scrollToTop(0, 'auto');

      try {
        setIsLoaded(false);
        getCollections(locationId).then((res) => {
          return res.json();
        }).then((item) => {
          setContent(modifidedItemWithTranslationField(item));
          setIsLoaded(true);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [locationId]);

  return (
    <>
      {
        isLoaded
          ? <>
            <Helmet>
              <title>
                {title} Львівської майстерні шоколаду — купити шоколад одної тематики
              </title>
              <meta
                name="description"
                content={`${title} — тематичний шоколад, фігурки, тафлі, цукерки та набори цукерок, плитки шоколаду ❖ Підбірка шоколаду для свята та подарунку ❖ Купити шоколад до свята ❖ Замовляй шоколадний подарунок`}
                data-react-helmet="true"
              />
            </Helmet>
            <Container fluid>
              <Row>
                <Col>
                  <MainTitle>
                    {title}
                  </MainTitle>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Template_1 data={content.templateItemValue}/>
                </Col>
              </Row>
            </Container>
          </>
          : <Loader/>
      }
    </>
  );
}

export default SingleCollection;