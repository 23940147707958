import React, { useState, useContext } from 'react';
import './YourData.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Context } from '../../../context';

import { updateUserData } from '../../../utils/api';

import MainButton from '../../MainButton/MainButton';
import Loader from '../../Loader/Loader';
import PhoneMask from '../TelephoneWithMask/PhoneMask';

const YourData = () => {

  const [sendingIsSuccess, setSendingIsSuccess] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [formIsSent, setFormIsSent] = useState(false);

  const { userData, setUserData, staticLocalization } = useContext(Context);
  const { yourDataForm } = staticLocalization;

  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [phone, setPhone] = useState(false);
  const phoneRegex = /^\+(?:[0-9] ?){11,14}[0-9]$/; // Change this regex based on requirement
  const phoneIntRegex = /^(\+-|\+|0)?\d{16}$/; // Change this regex based on requirement

  const YourDataSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(yourDataForm.nameError),
    lastName: Yup.string()
      .required(yourDataForm.lastNameError),
    email: Yup.string()
      .email(yourDataForm.emailErrorInvalidEmail)
      .required(yourDataForm.emailError),
    telephone: Yup.string()
      .required(yourDataForm.telephoneError).nullable()
      .test('test', yourDataForm.telephoneRegexError,
        function(value) {
          const isValidPhone = phoneRegex.test(value);
          const isValidPhoneInt = phoneIntRegex.test(value);
          if (phoneRegex.test(value)) {
            setPhone(true);
          }

          if (!isValidPhone && !isValidPhoneInt) {
            return false;
          }
          return true;
        })
  });

  return (
    <Formik
      initialValues={
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          telephone: userData.phone
        }
      }
      validationSchema={YourDataSchema}
      onSubmit={(values) => {
        const { firstName, lastName, email, telephone } = values;
        let modeLogin;
        if (phone && (telephone.split('')[0] == 0)) {
          modeLogin = '+38' + telephone;
        } else {
          modeLogin = telephone;
        }

        setFormIsSent(true);
        setShowMessage(false);

        updateUserData(firstName, lastName, email, modeLogin).then(res => {
          if (!res.ok) { // if some server error set his message
            res.json().then(err => {
              if (err.message === 'Phone already exists') {
                setPhoneError(true);
              } else if (err.message === 'User already exists') {
                setEmailError(true);
              }
            }
            );
          } else {
            setPhoneError(false);
            setEmailError(false);

            setUserData({ firstName, lastName, email, phone: modeLogin });
          }
          setSendingIsSuccess(res.ok);
          setFormIsSent(false);
          setShowMessage(true);
        });
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values }) => (
        <Form className='g-form-style your-data-form'>

          <label>
            <p className='required'>{yourDataForm.name}</p>
            <Field type='text' name='firstName' placeholder={yourDataForm.namePlaceholder} />
            <ErrorMessage name='firstName'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{yourDataForm.lastName}</p>
            <Field type='text' name='lastName' placeholder={yourDataForm.lastNamePlaceholder} />
            <ErrorMessage name='lastName'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{yourDataForm.email}</p>
            <Field type='email' name='email' placeholder={yourDataForm.emailPlaceholder} />
            <ErrorMessage name='email'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          <label>
            <p className='required'>{yourDataForm.telephone}</p>
            <PhoneMask handleChange={handleChange} handleBlur={handleBlur}
              placeholder={yourDataForm.telephonePlaceholder} value={values.telephone}>
            </PhoneMask>
            <ErrorMessage name='telephone'>
              {
                msg => <div className='error'>{msg}</div>
              }
            </ErrorMessage>
          </label>

          {   // Show loader until the form is sent
            formIsSent ? <div className='form-spiner'><Loader /></div> : null
          }

          {
            showMessage ?
              <label className={'message'}>
                <p className={sendingIsSuccess ? 'success' : 'error'}>
                  {sendingIsSuccess ? yourDataForm.successMessage : null}
                  {phoneError ? yourDataForm.telephoneUpdateError : null}
                  {emailError ? yourDataForm.emailUpdateError : null}
                </p>
              </label>
              :
              null
          }

          <MainButton type='submit' palette='primary' onMouseDown={handleSubmit}>
            {yourDataForm.save}
          </MainButton>
        </Form>
      )}
    </Formik>
  );
};

export default YourData;
