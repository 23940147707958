import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import MainTitle from '../../components/MainTitle/MainTitle';
import Steps from '../../components/Steps/Steps';
import Filling from './Filling';
import Packing from './Packing';
import './Boxes.scss';

import { Context } from '../../context';

function Boxes() {

  const { staticLocalization, isOpenModal } = useContext(Context);
  const { boxesLang } = staticLocalization;

  const stepsState = [
    {
      id: 'step1',
      name: boxesLang.stepName1,
      state: 'active'
    },
    {
      id: 'step2',
      name: boxesLang.stepName2,
      state: 'next',
    }
  ];

  const [steps, setSteps] = useState({ step: 0, content: stepsState });
  const [content, setContent] = useState(null);

  return (
    <Container fluid className='boxes'>
      <Helmet>
        <title>
          Власний набір цукерок ручної роботи — створи свою коробку цукерок з усього асортименту Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Збери свою коробку цукерок ручної роботи ❖ Власний набір цукерок для ідеального подарунку ❖ Створи найкращий набір цукерок для подарунку ❖ Купити цукерки ❖ Подарункові набори цукерок ❖ Львівська майстерня шоколаду — інтернет-крамниця цукерок та шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Row>
        <Col xs={12}>
          <MainTitle>
            {boxesLang.title}
          </MainTitle>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Steps content={steps.content} type='long' page='boxes'/>
        </Col>
      </Row>
      {
        steps.step === 0 && <>
          <Packing steps={steps} setContent={setContent} setSteps={setSteps}/>
        </>
      }
      {
        steps.step === 1 && <>
          <Filling steps={steps} setSteps={setSteps} content={content}/>
        </>
      }
    </Container>
  );
}

export default Boxes;