import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../components/IndividualItem/IndividualItem.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getTranslation, getCategoryName, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';
import { imagesUrl, addGoodsToBasket } from '../../utils/api';
import { Context } from '../../context';
 
import MainButton from '../../components/MainButton/MainButton';
import Counter from '../../components/Counter/Counter';
import Like from '../../components/Like/Like';
import BasketAddingAnimation from '../../components/BasketAddingAnimation/BasketAddingAnimation';

import { ReactComponent as RightArrow } from './../../assets/icons/arrow-to-right.svg';
import ErrorImg from '../../assets/images/candy-1--error.png';

const KitItem = ({
  item,
  buttonTitle,
  removeArrow,
  addCounter,
  href,
}) => {
    
  const [data, setData] = useState(item);
  const [counterValue, setCounterValue] = useState(1);
  const [showAnimation, setShowAnimation] = useState(false);

  const { settings, isLogined, changeBasketItemsLength, curentLanguageId, staticLocalization, blackTheme, ...getCategories } = useContext(Context);
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const parentRef = useRef();
  const imageRef = useRef();

  useEffect(() => {

    const newData = modifidedItemWithTranslationField(item);
    setData(newData);

  }, []);

  const RemoveArrow = removeArrow ? null : <RightArrow className='ml-3'/>;

  // TODO: Добавити логіку каунтера на сторінці комплектів
  const RenderCounter =  addCounter ? <Counter counterValueChanged={(val)=>setCounterValue(val)}/> : null;

  const getTechnicalImg = () => {
    return item.images.find(el => el.includes('technical')) || item.images[0]     
  }

  const imgUrl = `${imagesUrl}${getTechnicalImg()}?v=${settings.frontendVersion}`;
  const categoryName = getCategoryName(data.category, getCategories.categories, curentLanguageId);
  const name = getTranslation(item, data, 'name', curentLanguageId);
  const price = data.price;
  const description = getTranslation(item, data, 'shortDescription', curentLanguageId);
  const currency = staticLocalization.general.currency;

  return (
    <div className={`individual-item ${getTheme}`} ref={parentRef}>
      <div className='border-wrapper'></div>
      <div className='wrapper'>
        <div className='like-btn'>
          <Like goodsId={item.id} isInWishlist={item.isInWishlist}/>
        </div>
        <Link className='img-wrapper' to={{
          pathname: href,
          state: item
        }}>
          <img src={imgUrl} ref={imageRef} onError={(e)=>{e.target.onerror = null; e.target.src=ErrorImg;}}/>
          <BasketAddingAnimation parentRef={parentRef} imageRef={imageRef} showAnimation={showAnimation}/>
        </Link>
        <div className='next-and-counter'>
          {RenderCounter}
          <div className='next'>
            <MainButton palette='primary' onClick={() => {addGoodsToBasket(isLogined, item, counterValue, changeBasketItemsLength); setShowAnimation(!showAnimation)}}>
              {buttonTitle}
            </MainButton>
          </div>
        </div>
        <div className='name-and-price'>
          <div className='name'>
            <p>
              <small>
                {categoryName}
              </small>
              {name}
            </p>
          </div>
          <div className='price'>
            <p>
              {price}
              <small>{currency}</small>
            </p>
          </div>
        </div>
        <div className='description'>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

KitItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  removeArrow: PropTypes.bool,
  addCounter: PropTypes.bool,
  addLikeButton: PropTypes.bool,
  href: PropTypes.string
};

export default KitItem;