import React, { useContext, useEffect } from 'react';
import Portal from './Portal';
import './Modal.scss';

import { Context } from '../../context';

function Modal(props) {

  const { setBodyUnscroll, blackTheme } = useContext(Context);

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  useEffect(() => {
    setBodyUnscroll(true);
    return () => setBodyUnscroll(false);
  }, []);

  const closeHandler = (e) => {
    if  (e.target?.className === 'modals') { props.close() };
    return;
  };

  return (
    <Portal>
      <div className={`modals ${whatTheme} ${props?.className ? props.className : ''}`} onClick={closeHandler}>
        {React.cloneElement(props.children, {
          close: props.close
        })}
      </div>
    </Portal>
  );
}

export default Modal;