import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Counter.scss';

import { ReactComponent as CounterIncrease } from '../../assets/icons/counter-increase.svg';
import { ReactComponent as CounterDecrease } from '../../assets/icons/counter-decrease.svg';

const Counter = ({ counterValueChanged, className, counterValue }) => {
  const [counter, setCounter] = useState(+counterValue);

  useEffect(() => {
    const value = +counterValue > 0 ? +counterValue : 1;
    setCounter(+value);
  }, [counterValue]);


  const handleCount = (increase) => {
    if (increase) {
      if (counter + 1 > 500) {
        setCounter(500);
        counterValueChanged(500);
      } else {
        setCounter(counter + 1);
        counterValueChanged(+counter + 1);
      }
    } else {
      setCounter((+counter == 1) ? 1 : counter - 1);
      counterValueChanged((+counter == 1) ? 1 : counter - 1);
    }


    // const counterValue = increase ? +counter + 1 : +counter - 1;
    // counterValueChanged(+counterValue || 1, increase);
    // setCounter(+counterValue || 1);
  };

  const classes = classNames(
    'counter',
    className
  );

  const handleSetCounter = (e) => {
    if (e.value > Number(e.max)) {
      setCounter(e.max);
      counterValueChanged(e.max || 1);
    } else {
      setCounter(Math.round(e.value));
      counterValueChanged(+e.value || 1);
    }

  };
  const blur = (value) => {
    if (value <= 0) {
      setCounter(1);
    }
  };

  return (
    <div className={classes}>
      <button onClick={() => handleCount(false)}>
        <CounterDecrease/>
      </button>
      <input
        type="text"
        pattern="[0-9]*"
        min="1"
        max="500"
        step="1"
        value={counter}
        onChange={(e) => handleSetCounter(e.target)}
        onBlur={(e) => blur(e.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <button onClick={() => handleCount(true)}>
        <CounterIncrease/>
      </button>
    </div>
  );
};

Counter.propTypes = {
  counterValueChanged: PropTypes.func,
  className: PropTypes.string,
  counterValue: PropTypes.number
};

Counter.defaultProps = {
  counterValueChanged: () => {
  },
  className: '',
  counterValue: 1
};

export default Counter;
