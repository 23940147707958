const currantYear = new Date().getFullYear();
export const dummyStaticLocalization = {
  general: {
    currency: 'грн', // staticLocalization.general.currency
    grams: 'г', // staticLocalization.general.grams
    millimeters: 'мм', // staticLocalization.general.millimeters
    name: 'Львівська майстерня шоколаду',
    copyright: `© Львівська майстерня шоколаду, 2009-${currantYear}`
  },
  filters: {
    emptyFilterMessage: 'Для даної категорії товарів фільтри відсутні'
  },
  header: {
    login: 'Увійти'
  },
  footer: {
    followTheNews: 'Слідкуйте за новинами',
    formText: 'Дізнавайтесь першими про нові продукти, акції та спеціальні пропозиції. Підпишіться на e-mail розсилку',
    formPlaceholder: 'Ваш E-mail',
    contacts: 'Контакти',
    candy: 'ЦУКЕРКИ',
    subscribe: 'ПІДПИСУЙТЕСЬ',
    subscribeText: 'Дізнавайтесь першими про нові продукти, акції та спеціальні пропозиції. Підпишіться на e-mail розсилку',
    mainOffice: 'Головний офіс',
    RoundTheClockHotline: 'Цілодобова гаряча лінія'
  },
  homePage: {
    mostPopular: 'Улюблені смаколики',
    novelty: 'Прем’єра',
    mainItemBtnText: 'В кошик'
  },
  _404Page: {
    text: 'Сторінку не знайденно'
  },
  categoryGroup: {
    review: 'Переглянути',
    goBack: 'Назад'
  },
  category: {
    filteringHint: 'Вибрати фільтри',
    filterBtnText: 'Застосувати',
    mainItemBtnText: 'В кошик',
    showMoreBtnText: 'Показати більше',
    goBack: 'Назад'
  },
  individualGood: {
    mainItemBtnText: 'В кошик',
    additionalText: '* Пряма доставка з виробництва у Львові',
    expirationDate: 'Строк придатності',
    storageConditions: 'Умови зберігання',
    allergens: 'Алергени',
    showMoreBtnText: 'Показати більше',
    showLessBtnText: 'Показати менше',
    fillingTheBox: 'Наповнення коробки',
    youMightLikeIt: 'Не встидайся, купуй ще!',
    moreCandy: 'Більше цукерок'
  },
  kits: {
    mainTitle: 'Комплекти',
    showMoreBtnText: 'Показати більше',
    btnsToBasket: 'В кошик'
  },
  basket: {
    mainTitle: 'Ваш кошик',
    cleanTheBasket: 'Очистити кошик',
    generalPrice: 'Загальна вартість',
    continueShopping: 'Продовжити покупки',
    makingAnOrder: 'Оформлення замовлення',
    emptyBasket: 'Ви ще не oбрали жодної цукерки',
    сontinueShopping: 'Продовжити покупки',
    chooseGoods: 'Обрати товари',
    deleteItemBtns: 'Видалити',
    saveItemBtns: 'Зберегти',
    detailItemAmount: 'Разом',
    showDetailBtn: 'Переглянути',
    deleteItemText: 'Ви бажаєте видалити товар?'
  },
  order: {
    mainTitle: 'Оформлення замовлення',
    yourBasket: 'Ваш кошик',
    returnToBasket: 'Повернутися до кошика',
    returnToPreviousStap: 'Повернутися назад',
    additionalBoxAddedMesssage: '+ Додатковий захист від спеки',
    goBack: 'Назад',
    nextStep: 'Наступний крок',
    together: 'Разом',

    congratulationMessageTitle: 'Успішна оплата',
    congratulationMessageSubtitle: 'Дякуємо, ви успішно оплатили ваше замовлення.',
    congratulationMessageCashTitle: 'Замовлення прийнято',
    congratulationMessageCashSubtitle: 'Дякуємо, ваше замовлення прийнято.',
    congratulationMessageBtn: 'На головну',
    congratulationMessageBtnLogined: 'Статус замовлення',

    orderErrorMessageTitle: 'Виникла проблема',
    orderErrorMessageSubtitle: 'Виникла проблема з оплатою вашого замовлення. Спробуйте ще раз',
    orderErrorMessageCashSubtitle: 'Виникла проблема з оплатою вашого замовлення. Спробуйте ще раз',
    orderErrorMessageBtn: 'Спробувати ще раз',

    noGoodsselected: 'Ви ще не oбрали жодної цукерки',
    localPrice: 'Товарів на суму',
    bofonsWillBeWithdrawed: 'Буде списано бофонів',
    creditedToTheAccount: 'Буде нараховано бофонів',
    deliveryCost: 'Вартість доставки',
    deliveryCostError: 'Щось пішло не так при розрахунку',
    blokedUserMessage: 'Ваш акаунт заблоковано. Будь ласка, зверніться на нашу цілодобову гарячу лінію:',
    returnToHomePage: 'Повернутись на головну',
    showDetailBtn: 'Переглянути'
  },
  orderSteps: {
    firstStepTitle: 'Оберіть упакування',
    secondStepTitle: 'Контактні дані',
    thirdStepTitle: 'Куди доставити ?',
    fourthStepTitle: 'Оплата',
    firstStepName: 'Упакування',
    secondStepName: 'Контактні дані',
    thirdStepName: 'Доставка',
    fourthStepName: 'Оплата'
  },
  firstOrderStep: {
    standardPackagingTitle: 'Стандартне',
    standardPackagingDescription: 'Обов’язкове для транспортування. Пакується в залежності від обраного товару',
    giftPackagingTitle: 'Подарункові коробки',
    giftPackagingDescription: 'Рекомендовані для обраних товарів',
    additionalBoxTitle: 'Додатковий захист від спеки',
    additionalBoxDescription: 'Спеціальне упакування, щоб продукція була доставлена в ідеальному стані',
    giftBoxNotFind: 'Твоє замовлення настільки велике, що в одну коробку не вміститься, пропонуємо обрати коробки у розділі',
    packaging: 'Упаковка'
  },
  secondOrderStep: {
    regularCustomer: 'Я постійний клієнт',
    regularCustomerRegestration: 'реєстрація',
    buyWithoutRegistration: 'Купити без реєстрації',
    logInThroughSocialNetworks: 'Увійти через соц.мережі',
    mobileLogInThroughSocialNetworks: 'Увійти через'
  },
  fourthOrderStep: {
    paymentUponReceiptOfTheOrder: 'Оплата при отриманні замовлення'
  },
  loginationForm: {
    emailOrPhone: 'E-mail або телефон',
    emailOrPhonePlaceholder: 'Введіть ваш E-mail або телефон',
    emailOrPhoneError: 'Поле обов’язкове',
    password: 'Пароль',
    passwordPlaceholder: 'Введіть ваш пароль',
    passwordError: 'Поле обов’язкове',
    generalError: 'Неправелиний E-mail/телефон або пароль',
    rememberMe: 'Запам’ятати мене',
    forgetPassword: 'Забули пароль ?',
    logIn: 'Увійти',
    register: 'Зареєструватися'
  },
  registrationForm: {
    name: 'Ім’я',
    namePlaceholder: 'Введіть ваше ім’я',
    nameError: 'Поле обов’язкове',
    lastName: 'Прізвище',
    lastNamePlaceholder: 'Введіть ваше прізвище',
    lastNameError: 'Поле обов’язкове',
    email: 'E-mail',
    emailPlaceholder: 'Введіть ваш E-mail',
    emailError: 'Поле обов’язкове',
    emailErrorInvalidEmail: 'Неправильний формат',
    telephone: 'Ваш номер телефону',
    telephonePlaceholder: 'Ваш номер телефону',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    password: 'Придумайте пароль',
    passwordPlaceholder: 'Введіть ваш пароль',
    passwordError: 'Поле обов’язкове',
    passwordRegexError: 'Пароль повинен складатись з не менш ніж 8 символів, містити цифри та латинські літери, у тому числі великі',
    passwordErrorLikeName: 'Пароль збігається з ім’ям',
    passwordErrorLikeEmail: 'Пароль збігається з поштою',
    passwordConfirmation: 'Повторіть ваш пароль',
    passwordConfirmationPlaceholder: 'Введіть ваш пароль знову',
    passwordConfirmationError: 'Паролі не співпадають',
    additionalText: 'Пароль повинен складатись з не менш ніж 8 символів, містити цифри та латинські літери, у тому числі великі, і не повинен збігатися з ім’ям та ел. поштою',
    userAgreement: 'Реєструючись, ви погоджуєтеся з',
    userAgreementLinkText: 'угодою користувача',
    logIn: 'Я вже зареєстрований',
    gender: '(-на)',
    register: 'Зареєструватися',
  },
  phoneVerificationForm: {
    title: 'Введіть ваш код з смс', //!
    subtitle: 'Будь ласка, введіть ваш номер телефону. Ви отримаєте СМС з кодом підтвердження', //!
    code: 'Код з СМС',
    codePlaceholder: 'Введіть код', //!
    codePlaceholderError: 'Поле обов’язкове',
    btnText: 'Продовжити',
    errorMessage: 'Ви ввели не правельний код',
    forgotPasswordCongratulationsTitle: 'Вітаємо!', //!
    registerCongratulationsTitle: 'Вітаємо!', //!
    forgotPasswordCongratulationsSubtitle: 'На ваш номер телефону прийде смс з новим паролем', //!
    registerCongratulationsSubtitle: 'Ви успішно зареєструвалися' //!
  },
  recoverPassword: {
    title: 'Забули пароль ?', //!
    subtitle: 'Будь ласка, введіть код, який прийшов вам у смс', //!
    telephone: 'Ваш номер телефону',
    telephonePlaceholder: 'Ваш номер телефону',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    btnText: 'Продовжити',
    errorMessage: 'За таким номером не знайденно користувача'
  },
  withoutRegestrationForm: {
    name: 'Ім’я',
    namePlaceholder: 'Введіть ваше ім’я',
    nameError: 'Поле обов’язкове',
    lastName: 'Прізвище',
    lastNamePlaceholder: 'Введіть ваше прізвище',
    lastNameError: 'Поле обов’язкове',
    email: 'E-mail',
    emailPlaceholder: 'Введіть ваш E-mail',
    emailError: 'Поле обов’язкове',
    emailErrorInvalidEmail: 'Неправельний формат',
    telephone: 'Телефон Ваш номер телефону',
    telephonePlaceholder: 'Ваш номер телефону',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    telephoneCreateError: 'Користувач з таким номером телефону вже існує. Спробуйте увійти, використовуючи цей номер.',
  },
  deliveryForm: {
    template: 'Шаблон',
    deliveryTemplates: 'Оберіть шаблон відправки',
    deliveryTemplatesPlaceholder: 'Оберіть шаблон доставки',
    deliveryTemplatesNoOptionsMessage: 'У вас ще немає шаблонів для відправлення',
    name: 'Ім’я отримувача',
    namePlaceholder: 'Введіть ім’я отримувача',
    nameError: 'Поле обов’язкове',
    lastName: 'Прізвище отримувача',
    lastNamePlaceholder: 'Введіть прізвище отримувача',
    lastNameError: 'Поле обов’язкове',
    email: 'E-mail',
    emailPlaceholder: 'Введіть ваш E-mail',
    emailError: 'Поле обов’язкове',
    emailErrorInvalidEmail: 'Неправельний формат',
    telephone: 'Телефон отримувача',
    telephonePlaceholder: 'Введіть номер телефону отримувача',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    city: 'Оберіть місто отримувача',
    toOrder: 'Оформити замовлення',
    addToTemplates: 'Додати до шаблонів',
    deliveryAndPaymentTypesPlaceholder: 'Оберіть варіант доставки',
    deliveryAndPaymentTypesNoOptionsMessage: 'Оберіть варіант доставки',
    deliveryAndPaymentTypes_SELF_PICKUP: 'Самовивіз з магазину на вулиці',
    deliveryAndPaymentTypes_COURIER: 'Доставка кур`єром',
    deliveryAndPaymentTypes_PROVIDER: 'Самовивіз з відділення Нової Пошти',
    date: 'Оберіть дату доставки',
    postDepartmentPlaceholder: 'Оберіть варіант доставки',
    postDepartmentNoOptionsMessage: 'Нажаль, доступних відділень не найденно',
    adress: 'Адреса отримувача',
    adressPlaceholder: 'Вулиця',
    building: 'Будинок',
    department: 'Оберіть відділення',
    cargo: 'Вантажне'
  },
  localForm: {
    checkBoxTitle: 'Використати картку локаль',
    checkBoxDescription: 'Оплата і нарахування бофонів LOKAL для учасників програми лояльності',
    howMuchCanWithdraw: 'Можна списати', //!
    balance: 'Баланс',
    bofon: 'Бофон',
    bofons: 'бофонів',
    oneBofonTo: '10',
    login: 'Ваш логін',
    loginPlaceholder: 'Введіть ваш логін',
    loginError: 'Поле обов’язкове',
    password: 'Ваш пароль',
    passwordPlaceholder: 'Введіть ваш пароль',
    passwordError: 'Поле обов’язкове',
    toOrder: 'Оформити замовлення',
    tieACard: 'Прив’язати карту локаль',
    forgotYourPassword: 'Забули пароль?',
    withoutCard: 'Оформити без картки LOKAL',
    generalError: 'Неправелиний логін або пароль',
    howMarchToWithdraw: 'Вкажіть скільки списати',
    howMarchToWithdrawPlaceholder: 'Введіть кількість',
    bofonErrors: 'Щось пішло не так',
    logIn: 'Прив’язати карту',
    noWithdraw: 'На жаль, за дані товари розрахунок бофонами неможливий.', //!
    withdraw: 'Списати'
  },
  footerForm: {
    placeholder: 'Ваш E-Mail',
    successMessage: 'Успіх'
  },
  dropZone: {
    title: 'Завантажити декор',
    subtitle: 'Тільки в форматах .png, .jpg. Розмір до 8мб',
    dropZoneTitle: 'Оберіть файл',
    dropZoneSubTitle: 'або перетягніть його сюди',
    errorInvalidType: 'Ви вибрали неправельний тип файлу',
    errorFileTooLarge: 'Ви вибрали занадто велийкий файл',
    anotnerError: 'Щось піішло не так, попробуйте знову',
    cancel: 'Скасувати',
    add: 'Додати декор'
  },
  personalOffice: {
    mainTitle: 'Особистий кабінет',
    subTitle: 'Контактні дані',
    subTitleUnregistered: 'Вхід',
    subTitleRegistration: 'Реєстрація',
    logInThroughSocialNetworks: 'Увійти через соц.мережі',
    registerThroughSocialNetworks: 'Увійти через соц.мережі',
    accountQuitButtonTitle: 'Вийти з акаунту',
    backButtonTitle: 'Назад до кабінету',
    orderIsEmpty: 'Нажаль, ви ще нічого не купляли',
    emptyBasket: 'У вас ще немає шаблонів',
    createTemplate: 'Створити шаблон',
    edit: 'Редагувати',
    editTemplate: 'Редагувати шаблон',
    templateName: 'Назва шаблону', //!
    templateSubName: 'Шаблон №', //!
    name: 'Ім’я отримувача',
    lastName: 'Прізвище отримувача',
    phone: 'Телефон отримувача',
    goBack: 'Назад',
    emptyOrderList: 'Ви ще нічого не замовили'
  },
  personalOfficeMenu: {
    personalData: 'Особисті дані',
    myOrders: 'Мої замовлення',
    localCard: 'Картка локаль',
    picking: 'Обране',
    templatesToSend: 'Шаблони для відправлення',
    accountQuitButtonTitle: 'Вийти з акаунту',
    accountQuitButtonTitleHeader: 'Вийти'
  },
  personalOfficePersonalData: {
    yourData: 'Ваші дані',
    changePassword: 'Змінити пароль',
  },
  yourDataForm: {
    name: 'Ім’я',
    namePlaceholder: 'Введіть ваше ім’я',
    nameError: 'Поле обов’язкове',
    lastName: 'Прізвище',
    lastNamePlaceholder: 'Введіть ваше прізвище',
    lastNameError: 'Поле обов’язкове',
    email: 'E-mail',
    emailPlaceholder: 'Введіть ваш E-mail',
    emailError: 'Поле обов’язкове',
    emailErrorInvalidEmail: 'Неправельний формат',
    telephone: 'Телефон',
    telephonePlaceholder: 'Ваш номер телефону',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    save: 'Зберегти',
    errorMessage: 'Щось пішло не так',
    successMessage: 'Ваші данні успішно оновлені'
  },
  changePasswordForm: {
    oldPassword: 'Старий пароль',
    oldPasswordPlaceholder: 'Введіть ваш старий пароль',
    oldPasswordError: 'Поле обов’язкове',
    password: 'Новий пароль',
    passwordPlaceholder: 'Введіть ваш новий пароль',
    passwordError: 'Поле обов’язкове',
    passwordRegexError: 'Пароль повинен складатись з не менш ніж 8 символів, містити цифри та латинські літери, у тому числі великі',
    passwordConfirmation: 'Повторіть ваш новий пароль',
    passwordConfirmationPlaceholder: 'Введіть ваш пароль знову',
    passwordConfirmationError: 'Паролі не співпадають',
    additionalText: 'Пароль повинен складатись з не менш ніж 6 символів, містити цифри та латинські літери, у тому числі великі, і не повинен збігатися з ім’ям та ел. поштою',
    userAgreement: 'Реєструючись, ви погоджуєтеся з',
    userAgreementLinkText: 'угодою користувача',
    save: 'Зберегти',
    showSuccessMessage: 'Пароль змінений успішно',
    showErrorMessage: 'Щось пішло не так'
  },
  personalOfficeMyOrders: {
    mainTitle: 'Мої замовлення',
    subTitle: 'Мої замовлення',
    backButton: 'Назад до кабінету',
    orderDetailsTitle: 'Замовлення',
    basketTitle: 'Ваш кошик',
    recipient: 'Отримувач',
    telephone: 'Телефон',
    deliveryMethod: 'Спосіб доставки',
    buttonTrackTitle: 'Відстежити замовлення',
    readMore: 'Детальніше',
    order: 'Замовлення',
    SELF_PICKUP: 'Самовивіз',
    statusNEW: 'Нове',
    statusPENDING_PAYMENT: 'Очікується оплати',
    statusCANCELED: 'Скасовано',
    statusACCEPTED: 'Прийнято та формається',
    statusAWAITING_RECEIVING: 'Очікує видачі',
    statusSENT: 'Отримано',
    statusRECEIVED: 'Відправленно',
    statusREFUSED: 'Відмова',
    statusFINISHED: 'Завершено',

  },
  personalOfficeLocalCard: {
    mainTitle: 'Картка локаль',
    localCardNumber: 'Номер картки',
    balance: 'Баланс бофонів',
    attachAnotherCard: 'Прив’язати іншу картку'
  },
  personalOfficeLiked: {
    mainTitle: 'Обране',
    showMore: 'Показати більше',
    emptyWishlist: 'Ви ще не oбрали жодної цукерки'
  },
  personalOfficeTemplate: {
    mainTitle: 'Шаблони для відправлення',
  },
  deliveryFormTemplate: {
    templateName: 'Назва шаблону',
    templateNamePlaceholder: 'Назва шаблону',
    templateNameError: 'Поле обов’язкове',
    name: 'Ім’я отримувача',
    namePlaceholder: 'Введіть ім’я отримувача',
    nameError: 'Поле обов’язкове',
    lastName: 'Прізвище отримувача',
    lastNamePlaceholder: 'Введіть прізвище отримувача',
    lastNameError: 'Поле обов’язкове',
    city: 'Місто отримувача',
    cityPlaceholder: 'Оберіть місто отримувача',
    cityError: 'Поле обов’язкове',
    telephone: 'Телефон отримувача',
    telephonePlaceholder: 'Введіть номер телефону отримувача',
    telephoneError: 'Поле обов’язкове',
    telephoneRegexError: 'Номер повинен бути формату +380#########',
    street: 'Вулиця',
    streetPlaceholder: 'Введіть назву вулиці',
    streetError: 'Поле обов’язкове',
    house: 'Будинок',
    housePlaceholder: '№',
    houseError: 'Поле обов’язкове',
    btnText: 'Створити шаблон',
    editTemplate: 'Зберегти',
    saveTemplate: 'Створити шаблон',
    cancel: 'Скасувати',
    error: 'Шаблон відправки з таким номером вже існує. Ви можете проредагувати існуючий шаблон'
  },
  deliveryFormTemplateAssign: {
    name: 'Ім’я отримувача',
    namePlaceholder: 'Введіть ім’я отримувача',
    lastName: 'Прізвище отримувача',
    lastNamePlaceholder: 'Введіть прізвище отримувача',
    telephone: 'Телефон отримувача',
    telephonePlaceholder: 'Введіть номер телефону отримувача',
    delete: 'Видалити',
    save: 'Зберегти',
    templateNameError: 'Поле обов’язкове',
  },
  individualLang: {
    mainTitle: 'Індивідуальне замовлення',
    titleForBasket: 'Індивідуальне замовлення',
    individualItemButtonTitle: 'Створити замовлення',
    stepName0: 'Форма',
    stepTitle0: 'Оберіть форму тафлі',
    stepTypeTitle0: 'Форма',
    stepButtonTitle0: 'Обрати форму',
    stepName1: 'Шоколад',
    stepTitle1: 'Оберіть шоколад',
    stepTypeTitle1: 'Шоколад',
    stepButtonTitle1: 'Обрати шоколад',
    stepName2: 'Додатки',
    stepTitle2: 'Оберіть додатки',
    stepTypeTitle2: 'Додатки',
    stepButtonTitle2: 'Обрати додаток',
    stepName3: 'Напис',
    stepTitle3: 'Оберіть напис',
    stepTypeTitle3: 'Напис',
    stepButtonTitle3: 'Обрати напис',
    stepName4: 'Упаковка',
    stepTitle4: 'Оберіть упаковку',
    stepTypeTitle4: 'Упаковка',
    stepButtonTitle4: 'Обрати упаковку',
    stepName5: 'Листівка',
    stepTitle5: 'Оберіть листівку',
    stepTypeTitle5: 'Листівка',
    stepButtonTitle5: 'Обрати листівку',
    stepName6: 'Декор',
    stepTitle6: 'Оберіть декор',
    stepTypeTitle6: 'Декор',
    stepButtonTitle6: 'Обрати декор',
    stepNameLast: 'Підтвердження',
    stepTitleLast: 'Підтвердження',
    orderBoxTitle: 'Ваше замовлення',
    orderBoxAmount: 'Разом',
    orderBoxAmountComplete: 'Загальна вартість',
    addition: 'Додатки',
    btnsBack: 'Назад',
    btnsNextStep: 'Наступний крок',
    btnsShowMore: 'Паказати більше',
    btnsShowOrder: 'Переглянути замовлення',
    btnsClear: 'Очистити',
    btnsAddToBasket: 'Додати в кошик',
    nextStep: 'Наступний крок',
    continueOrder: 'Продовжити замовлення',
    mostPopularTitle: 'Галерея', //!
    mostPopularButtonTitle: 'Переглянути' //!
  },
  boxesLang: {
    title: 'Зібрати коробку',
    titleForBasket: 'Коробка з цукерками',
    stepName1: 'Коробка',
    stepName2: 'Цукерки',
    showMoreBtnText: 'Показати більше',
    btnsBack: 'Назад',
  },
  packingLang: {
    subtitle: 'Виберіть подарункову коробку для цукерок',
    subtitleDescr: 'Картонні або дерев’яні коробки різного розміру, форми та дизайну, які потрібно наповнити цукерками',
    buttonItemTitle: 'Обрати коробку',
  },
  fillingLang: {
    subtitle: 'Заповніть коробку цукерками',
    subtitleDescr: 'Зліва на екрані ви бачите коробку з комірками, яка поступово заповнюється вибраними вами цукекрами. Кількість комірок відповідає кількості цукерок, які поміщаються в коробку',
    btnsAddToBasketTitle: 'Додати коробку в кошик',
    btnsEditTitle: 'Редагувати',
    btnsClearTitle: 'Очистити',
    boxTitle: 'Коробка',
    filterBtnText: 'Застосувати',
    mainItemButtonTitle: 'В коробку',
    boxTitleNumberPreposition: 'з',
    boxAmountTitle: 'Разом',
    packingType: 'Коробка з цукерками',
  },
  collectionLang: {
    collecitonsGroupTitle: 'Колекції',
    goBackButtonTitle: 'Назад',
    collecitonsGroupItemButtonTitle: 'Переглянути',
    collectionBtnTitle: 'В кошик'
  },
  dropdownLang: {
    new: 'Найновіше',
    nameDown: 'За ім`ям. А-Я',
    nameUp: 'За ім`ям. Я-А',
    popularity: 'Найпопулярніше',
    priceDown: 'Від найдорожчого',
    priceUp: 'Від найдешевшого'
  },
  inscriptionForm: {
    title: 'Введіть ваш текст',
    text: 'Введіть ваш текст',
    textError: 'Поле обов’язкове',
    textPlaceholder: 'Введіть ваш текст',
    clear: 'Очистити',
    submit: 'Зберегти'
  },
  searchBar: {
    text: 'Нема результатів за вашим запитом'
  },
  individualLangEmptyModal: {
    title: 'Бажаєте ще додатки ?',
    message: 'У вас залашились вільні комірки для додатків, бажаєте їх заповнити ?',
    btnsCancel: 'Обрати додатки',
    btnsOk: 'Наступний крок',
  },
  individualMaxAdditionExceededModal: {
    title: 'Відсутнє місце для додатків',
    message: 'Необхідно звільнити комірки, щоби вмістити обраний новий додаток',
    btnsCancel: 'Скасувати',
    btnsOk: 'Зберегти',
  },
  localNumberConfirmationPopup: { //!
    title: 'Введіть ваш код з смс',
    subtitle: 'Будь ласка, введіть ваш код з смс, по вказаному номеру телефону.',
    code: 'Код з СМС',
    codePlaceholder: 'Введіть код',
    btnText: 'Продовжити',
    errorMessage: 'Поле обов’язкове',
    generalError: 'Ви ввели неправильний код підтвердження'
  },
  mostInterestingIndividualPage: {
    title: 'Найцікавіші індивідуальні замовлення' //!
  }
};
