import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../context';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { getDeliveryAndPaymentTypes, calculateDeliveryConst } from '../../../../utils/api';

const DeliveryAndPaymentTypes = ({
  setDeliveryAndPaymentTypeData,
  setDeliveryPrice,
  setFieldValue,
  notRegisterCustomerId,
  setStartOfDeliveryPeriodDays,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [defaultValue, setDefaultValue] = useState();

  const { staticLocalization, userData, langKey } = useContext(Context);
  const { deliveryForm } = staticLocalization;

  useEffect(() => {
    setOptions([]);
    const selectedCityId = props.form.values.city.id;
    getDeliveryAndPaymentTypes(selectedCityId, langKey).then(res => {
      return res.json();
    }).then(el => {
      const getedOptions = [];
      try {
        el && el.map(deliveryMethod => {
          const { deliveryType, paymentTypes, entities } = deliveryMethod;

          switch (deliveryType) {
          case 'SELF_PICKUP':
            entities.forEach(el => {
              const { address, id, regionWarehouseDelivery } = el;
              getedOptions.push({
                value: `${deliveryForm.deliveryAndPaymentTypes_SELF_PICKUP} ${address}`,
                label: `${deliveryForm.deliveryAndPaymentTypes_SELF_PICKUP} ${address}`,
                type: deliveryType,
                id,
                paymentTypes,
                regionWarehouseDelivery
              });
            });
            break;
          case 'COURIER':
            entities.forEach(el => {
              const { name, id, regionWarehouseDelivery } = el;
              getedOptions.push({
                value: `${deliveryType} ${id}`,
                type: deliveryType,
                label: `${deliveryForm.deliveryAndPaymentTypes_COURIER} ${name}`,
                id,
                paymentTypes,
                regionWarehouseDelivery
              });
            });
            break;
          case 'PROVIDER':
            entities.forEach(el => {
              const { id, regionWarehouseDelivery } = el;
              getedOptions.push({
                value: `${deliveryType} ${id}`,
                type: deliveryType,
                label: deliveryForm.deliveryAndPaymentTypes_PROVIDER,
                id,
                paymentTypes,
                regionWarehouseDelivery
              });
            });
            break;
          default:
            return null;
          }
        });
      } catch (err) {
        console.log(err);
        setOptions([]);
      }

      setOptions(getedOptions);
    });
  }, [props.form.values.city, langKey]);

  useEffect(() => {
    if (options.length > 0) {
      const selectedOption = options.find(item => item.value === props.form.values.deliveryAndPaymentTypeData?.value);
      setDefaultValue(selectedOption || null);
    }
  }, [options, langKey]);

  const handleChange = (val) => {
    setStartOfDeliveryPeriodDaysFunc(val);

    setFieldValue(props.field.name, val);
    setDefaultValue(val);
    setDeliveryAndPaymentTypeData(val);
    const formValues = props.form.values;

    if (val.type !== 'COURIER') {
      setFieldValue('address', '');
      setFieldValue('building', '');
    }

    if (val.type === 'PROVIDER' && !formValues.department.deliveryServiceDepartment) {
      return;
    }

    if (val.type === 'COURIER' && (!formValues.address || !formValues.building)) {
      return;
    }

    const getCalculatedDeliveryConst = new Promise((resolve, reject) => {
      calculateDeliveryConst(
        userData.id || notRegisterCustomerId,
        formValues.city.id,
        formValues.firstName,
        formValues.lastName,
        'midName',
        formValues.email,
        formValues.telephone,
        val.type,
        val.id,
        formValues.address,
        formValues.building,
        formValues.department.deliveryServiceDepartment
      ).then(response => {
        response.ok ? resolve(response.json()) : resolve(null);
      });
    });

    Promise.all([getCalculatedDeliveryConst]).then((res) => {
      const [calculatedDeliveryConst] = res;

      setDeliveryPrice(calculatedDeliveryConst);
    });
  };

  const setStartOfDeliveryPeriodDaysFunc = (deliveryAndPaymentTypeData) => {
    if (!deliveryAndPaymentTypeData.regionWarehouseDelivery) {
      setStartOfDeliveryPeriodDays(null); // default value
      return;
    }

    const regionWarehouseDelivery = deliveryAndPaymentTypeData.regionWarehouseDelivery;
    let startOfDeliveryPeriodDaysNum;
    if (deliveryAndPaymentTypeData.type === 'COURIER') {
      startOfDeliveryPeriodDaysNum = regionWarehouseDelivery.deliveryPeriodCourierDays;
    } else {
      startOfDeliveryPeriodDaysNum = regionWarehouseDelivery.deliveryPeriodDays;
    }

    if (!startOfDeliveryPeriodDaysNum) {
      setStartOfDeliveryPeriodDays(null);
      return;
    }

    setStartOfDeliveryPeriodDays(startOfDeliveryPeriodDaysNum);
  };

  useEffect(() => {
    options.map(item => {
      if (item.type === props.form.values.deliveryAndPaymentTypeData) {
        setDefaultValue(item);
        setFieldValue(props.field.name, item);
      }
    });
  }, [options]);

  return (
    <>
      <Select
        name='deliveryAndPaymentTypeData'
        onChange={val => {
          handleChange(val);
          const array = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
          array['deliveryAndPaymentTypeData'] = val;
          localStorage.setItem('regestration', JSON.stringify(array));
        }}
        placeholder={deliveryForm.deliveryAndPaymentTypesPlaceholder}
        noOptionsMessage={({ inputValue: string }) => deliveryForm.deliveryAndPaymentTypesNoOptionsMessage}
        className='drop-down'
        classNamePrefix='react-select'
        indicatorsContainer={{
          display: 'none'
        }}
        options={options}
        value={defaultValue}
      />
    </>
  );
};

DeliveryAndPaymentTypes.propTypes = {
  form: PropTypes.any,
  setDeliveryAndPaymentTypeData: PropTypes.func,
  type: PropTypes.string,
  setFieldValue: PropTypes.func,
  setDeliveryPrice: PropTypes.func,
  notRegisterCustomerId: PropTypes.number,
  setStartOfDeliveryPeriodDays: PropTypes.func
};

export default DeliveryAndPaymentTypes;