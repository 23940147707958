import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../context';
import { customStyles } from './customStyles';
import Select from 'react-select';
import { getStaticLocalizationById } from '../../../utils/api';

const LeanguageSwitcher = ({ setHardCloseSerchBar }) => {

  const [leanguageData, setLeanguageData] = useState([]);
  const [curentLanguage, setCurentLanguage] = useState('');

  const { leanguageSwitcherData, changeStaticLocalization, curentLanguageId, changeCurentLanguageId } = useContext(Context);
  const handleChange = (selectedOption) => {
    setCurentLanguage(selectedOption);
    const { data } = selectedOption;
    localStorage.setItem('lmsh-curentLanguage', data);
    changeCurentLanguageId(data);
    const leanguageData = leanguageSwitcherData.filter(lang => lang.label !== selectedOption.label)
    setLeanguageData(leanguageData);
    getStaticLocalizationById(data).then(res => {
      return res.json();
    }).then(el => {
      changeStaticLocalization(el);
    });
  };

  useEffect(() => {
    const getedCurrentLanguage = leanguageSwitcherData.find(el => el.data === curentLanguageId);
    const leanguageData = leanguageSwitcherData.filter(lang => lang.label !== getedCurrentLanguage.label)
    setLeanguageData(leanguageData);
    try {
      setCurentLanguage(getedCurrentLanguage.label);
    } catch (e) {

    }

  }, []);

  return (
      <div className='header-button-group__item leanguage-switcher'>
        <Select
            styles={customStyles}
            name='form-field-name'
            value={curentLanguage}
            onFocus={() => setHardCloseSerchBar()}
            onChange={(val) => handleChange(val)}
            placeholder={curentLanguage}
            classNamePrefix='leanguage-switcher'
            readOnly={true}
            isSearchable={false}
            indicatorsContainer={{
              display: 'none'
            }}
            options={leanguageData}
        />
      </div>
  );
};

export default LeanguageSwitcher;


