import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainButton from '../MainButton/MainButton';

import { getTranslation, getCategoryName, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';

import { imagesUrl } from '../../utils/api';

import { Context } from '../../context';

function IndividualGroupItem({ item }) {

  const { settings, staticLocalization, curentLanguageId } = useContext(Context);
  const { individualLang } = staticLocalization;

  const [data, setData] = useState(item);

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setData(newData);
  }, []);

  const img = `${imagesUrl}${item.images}?v=${settings.frontendVersion}`;
  const title = getTranslation(item, data, 'name', curentLanguageId);
  const description = getTranslation(item, data, 'metaDescription', curentLanguageId);
  const buttonTitle = individualLang.individualItemButtonTitle;

  return (
    <div className='individual-group-item'>
      <div className='background'></div>
      <div className='content'>
        <h2 className='individual-group-item__title'>{title}</h2>
        <div className='individual-group-item__text'>
          <p className='individual-group-item__description'>{description}</p>
          <div className='individual-group-item__button'>
            <Link to={`/individual/${item.slug || 'group'}`}
              className='individual-group-item__link'
            >
              <MainButton
                className='btns--main'
              >
                {buttonTitle}
              </MainButton>
            </Link>
          </div>
        </div>
        <div className='individual-group-item__img'>
          <img className='img-fluid' src={img} alt={title}/>
        </div>
        <div className='individual-group-item__button individual-group-item__button--mobile'>
          <Link to={`/individual/${item.slug || 'group'}`}
            className='individual-group-item__link'
          >
            <MainButton
              className='btns--main'
            >
              {buttonTitle}
            </MainButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

IndividualGroupItem.propTypes = {
  item: PropTypes.object
};

export default IndividualGroupItem;