import React from 'react';
import './Loader.scss';
import { ReactComponent as LoaderImg } from '../../assets/icons/spiner.svg';
// import LoaderImg from '../../assets/images/candy-loader.svg';

const Loader = ()=>{
  return(
    <div className='loader'>
      <LoaderImg />
      {/* <img src={LoaderImg} alt=""/> */}
    </div>
  );
};

export default Loader;
