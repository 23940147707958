import React, { useState, useEffect, useContext } from 'react';
import './IndividualGroup.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IndividualGroupItem from './IndividualGroupItem';
import MainTitle from '../MainTitle/MainTitle';
import Loader from '../Loader/Loader';
import MainButton from '../MainButton/MainButton';

import MostInterestingImg from '../../assets/images/individual-gallery.png'

import { getIndividualCategories, getIndividualCategoriesNew } from '../../utils/api';

import { Context } from '../../context';

function IndividualGroup() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const { staticLocalization, blackTheme } = useContext(Context);
  const { individualLang } = staticLocalization;
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const collecitonsGroupTitle = individualLang.mainTitle;
  
  useEffect(()=>{
    try{

      getIndividualCategoriesNew().then((res)=>{
        return res.json();
      }).then((category)=>{
        const arr = category.result;
        setData(arr);
        setIsLoaded(true);
      });
    }catch(err){
      console.log(err);
    }

  }, []);

  const Content = () => {
    if(isLoaded){
      return(
        <>
          <Row>
            <Col xs={12}>
              <MainTitle>
                {collecitonsGroupTitle}
              </MainTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className={`individual-group ${getTheme}`}>
                { data.map(el => <IndividualGroupItem item={el} key={`individual-${el.id}`}/>) }

                {/* Individual gallery item ---> */}                
                <div className='individual-group-item'>
                  <div className='background'></div>
                  <div className='content'>
                    <div className='individual-group-item__text'>
                      <h2 className='individual-group-item__title'>{individualLang.mostPopularTitle || 'Галерея'}</h2>
                      <div className='individual-group-item__button'>
                        <Link to='/most-interesting'
                          className='individual-group-item__link'
                        >
                          <MainButton
                            className='btns--main'
                          >
                            {individualLang.mostPopularButtonTitle || 'Переглянути'}
                          </MainButton>
                        </Link>
                      </div>
                    </div>
                    <div className='individual-group-item__img'>
                      <img className='img-fluid' src={MostInterestingImg} alt='Individual gallery' />
                    </div>
                    <div className='individual-group-item__button individual-group-item__button--mobile'>
                      <Link to='/most-interesting'
                        className='individual-group-item__link'
                      >
                        <MainButton
                          className='btns--main'
                        >
                          {individualLang.mostPopularButtonTitle || 'Переглянути'}
                        </MainButton>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <--- Individual gallery item */}    

              </div>
            </Col>
          </Row>
        </>
      );
    }
    return(
      <Loader />
    );
  };

  return (
    <Container fluid >
      <Content />
    </Container>
  );
}

export default IndividualGroup;
