import React, { useState, useContext } from 'react';
import './LocalForm.scss';
import classNames from 'classnames';
import { Context } from '../../../context';
import PropTypes from 'prop-types';
import MainButton from '../../../components/MainButton/MainButton';
import Loader from '../../../components/Loader/Loader';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  getLokalDiscount,
  getLokalDiscountForAnonymous,
} from '../../../utils/api';


const LocalFromGetBofons = ({
  useLocalCard,
  setErrorQuintity,
  orederUserId,
  localCardNumber,
  proposeBurnAmount,
  howMuchcanWithdraw,
  changeBofonQuintityForWithdraw
}) => {

  const [checkLocalCard, setCheckLocalCard] = useState(true);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [backendError, setBackendError] = useState(false);
  const [formIsSent, setFormIsSent] = useState(false);
  const [notEnoughBofons, setNotEnoughBofons] = useState(false);

  const { blackTheme, staticLocalization, isLogined } = useContext(Context);
  const { localForm, order } = staticLocalization;

  const localFormClassNames = classNames(
    'local-card-form',
    'g-form-style',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const FormIfPersonHaveLocalCadSchema = Yup.object().shape(
    {
      howMuchToWithdraw: Yup.string()
        .required(localForm.loginError)
    }
  );

  return (
    <Formik
      initialValues={{
        howMuchToWithdraw: ''
      }}
      validationSchema={useLocalCard ? FormIfPersonHaveLocalCadSchema : null}
      onSubmit={values => {

        const { howMuchToWithdraw } = values;

        setFormIsSent(true);
        setShowGeneralError(false);
        if (+howMuchToWithdraw <= howMuchcanWithdraw) {

          const discountFunctions = {
            getLokalDiscountForAnonymous: getLokalDiscountForAnonymous,
            getLokalDiscount: getLokalDiscount
          };

          const functionName = isLogined ? 'getLokalDiscount' : 'getLokalDiscountForAnonymous';

          discountFunctions[functionName](localCardNumber, +howMuchToWithdraw, orederUserId).then(res => {
            if (res.ok) {
              res.json().then(() => {
                setFormIsSent(false);
                changeBofonQuintityForWithdraw(+howMuchToWithdraw);
              });
            } else {
              res.json().then((error) => {
                setFormIsSent(false);
                if (error.message) {
                  setBackendError(error.message);
                } else {
                  setShowGeneralError(true);
                  setErrorQuintity();
                }
              });

            }
          });
        } else {
          setNotEnoughBofons(true);
        }
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Form className={localFormClassNames}>

          <div className='inputs-wrapper'>
            {
              checkLocalCard ?
                <div className='balance'>
                  <div className='wrapper'>
                    <div className='info'>
                      <div className='info__item'>
                        <small>{localForm.balance}:</small>
                        <div><span>{proposeBurnAmount}</span> {localForm.bofons}</div>
                      </div>
                      <div className='info__item'>
                        <small>{localForm.howMuchCanWithdraw}:</small>
                        <div><span>{howMuchcanWithdraw}</span> {localForm.bofons}</div>
                      </div>
                    </div>
                  </div>
                  <p>1 {localForm.bofon} = {localForm.oneBofonTo} {staticLocalization.general.currency}</p>
                </div>
                :
                null
            }
            <label>
              <p className='required'>{localForm.howMuchToWithdraw}</p>
              <Field
                type='text'
                name='howMuchToWithdraw'
                placeholder={localForm.howMuchToWithdrawPlaceholder}
              />
              {errors.howMuchToWithdraw && touched.howMuchToWithdraw ? ( // Error
                <div className='error'>{errors.howMuchToWithdraw}</div>
              ) : null}
            </label>
            {
              showGeneralError ?
                <div className='error general-error'>{localForm.generalError}</div>
                :
                null
            }

            {
              backendError ?
                <div className='error general-error'>{backendError}</div>
                :
                null
            }
            {
              notEnoughBofons ?
                <div className='error general-error'>{localForm.notEnoughBofons}</div>
                :
                null
            }
            <MainButton type='submit' onMouseDown={handleSubmit}>{localForm.withdraw}</MainButton>
            {formIsSent ? <div className='form-spiner'><Loader /></div> : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

LocalFromGetBofons.propTypes = {
  changeStep: PropTypes.func,
  makeOrder: PropTypes.func,
  useLocalCard: PropTypes.bool,
  setErrorQuintity: PropTypes.func
};

export default LocalFromGetBofons;