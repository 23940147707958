import React, { useState, useEffect, useContext } from 'react';
import './Profile.scss';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ClassNames from 'classnames';
import { Context } from '../../context';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import DeliveryFormTemplate from '../../components/Forms/DeliveryForm/DeliveryFormTemplate';
import Loader from '../../components/Loader/Loader';

import { ReactComponent as PrevIcon } from '../../assets/icons/previous-arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/modal-close-icon.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/modal-close-icon--light.svg';
import { ReactComponent as SliderArrow } from '../../assets/icons/form-drop-down-icon.svg';
import { ReactComponent as SliderArrowLight } from '../../assets/icons/form-drop-down-icon--light.svg';

import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../utils/useWindowSizeHook';
import { getEllementOffsetForPersonalOffiseTemplates } from '../../utils/getEllementOffset';

import { getAllRecipients, getRecipientId, removeRecipientTemplate } from '../../utils/api';
import { scrollToTop } from '../../utils/scrollToTop';

const EditTemplate = ({ editedTemplatePhone, closeModalWindow, sliderItemIndex }) => {

  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getRecipientId(editedTemplatePhone, 0).then(res => {
      return res.json();
    }).then(el => {

      setData(el.result[0] || {});
      setIsLoaded(true);
    });
  }, []);

  return (
    isLoaded ?
      <DeliveryFormTemplate initVal={data} type='editTemplate' closeModalWindow={() => closeModalWindow()}
        sliderItemIndex={sliderItemIndex} />
      :
      null
  );

};

const CreateTemplate = ({ closeModalWindow }) => {
  return (
    <DeliveryFormTemplate type='createTemplate' closeModalWindow={() => closeModalWindow()} />
  );
};

const SliderItem = ({
  index,
  item,
  additionalClassname,
  setOpenModal,
  setEditTemplate,
  setEditedTemplatePhone,
  setSliderItemIndex,
  setSliderData,
  sliderData
}) => {

  const { staticLocalization, setBodyUnscroll } = useContext(Context);
  const { personalOffice } = staticLocalization;

  const handleClick = () => {
    setEditTemplate();
    setEditedTemplatePhone(item.phone);
    setSliderItemIndex(index);
    setOpenModal();
    setBodyUnscroll(true);
  };

  const handleTemplateDelete = (recipientId) => {
    removeRecipientTemplate(recipientId).then(() => {
      setSliderData(sliderData.filter(sliderItem => sliderItem.id !== item.id));
    });
  };

  const templateSliderItemClass = ClassNames(
    'template-slider-item',
    additionalClassname || ''
  );

  return (
    <div className={templateSliderItemClass}>
      <div>
        {personalOffice.templateName || 'Назва шаблону'}
        <p>{item.templateName || `${personalOffice.templateSubName || 'Шаблон №'}${index + 1}`}</p>
      </div>
      <div>
        {personalOffice.name}
        <p>{item.firstName}</p>
      </div>
      <div>
        {personalOffice.lastName}
        <p>{item.lastName}</p>
      </div>
      <div>
        {personalOffice.phone}
        <p>{item.phone}</p>
      </div>
      <div>
        {'Місто'}
        <p>{item.city.name}</p>
      </div>

      {(() => {
        switch (item.deliveryType) {
        case 'SELF_PICKUP':
          return (
            <div>
              {'Варіант доставки'}
              <p>Самовивіз з {item.givingPoint.address}</p>
            </div>
          );
        case 'COURIER':
          return (
            <div>
              {'Варіант доставки'}
              <p>{item.deliveryService.name}</p>
              {'Адреса'}
              <p>{item.address + ' ' + item.building}</p>
            </div>
          );
        case 'PROVIDER':
          return (
            <div>
              {'Варіант доставки'}
              <p>{item.deliveryService.name}</p>
              {'Відділення'}
              <p>{item.npWarehouse.description}</p>
            </div>
          );
        default:
          return null;
        }
      })()}

      {/*<MainButton palette='secondary' type='button' onClick={() => handleClick()}>*/}
      {/*  {personalOffice.edit}*/}
      {/*</MainButton>*/}
      <MainButton palette='primary'
        type='button'
        className='profile-template-delete'
        onClick={() => handleTemplateDelete(item.id)}>
        {'Видалити'}
      </MainButton>
    </div>
  );
};

const sliderSettings = (prevArrow, nextArrow) => {
  return (
    {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      vertical: false,
      focusOnSelect: false,
      nextArrow: nextArrow,
      prevArrow: prevArrow,
      responsive: [
        {
          breakpoint: 1399.5,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1199.5,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 991.5,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  );
};

function ProfileDeliveryTemplate({ history }) {
  const { isLogined, staticLocalization, blackTheme, setBodyUnscroll } = useContext(Context);
  const { personalOffice, personalOfficeTemplate } = staticLocalization;

  const title = personalOffice.mainTitle;
  const subtitle1 = personalOfficeTemplate.mainTitle;
  const backButtonTitle = personalOffice.backButtonTitle;

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [haveTemplates, setHaveTemplates] = useState(false);
  const [initSlider, setInitSlider] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [editedTemplatePhone, setEditedTemplatePhone] = useState('');
  const [sliderItemIndex, setSliderItemIndex] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {

    getAllRecipients().then(res => {
      return res.json();
    }).then(templates => {
      const elementsLimit = getEllementOffsetForPersonalOffiseTemplates(width, 4, 3, 2, 1);
      setHaveTemplates(templates.count > 0);
      setInitSlider(templates.count > elementsLimit);
      setSliderData(templates.result);
      setIsLoaded(true);
    });

  }, [refreshKey]);

  useEffect(() => {
    scrollToTop();

    if (!isLogined) {
      history.push('/profile');
    }
    if (showMenu !== false) {
      setShowMenu(false);
    }
  }, [location.key]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {blackTheme ? <SliderArrow /> : <SliderArrowLight />}
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {blackTheme ? <SliderArrow /> : <SliderArrowLight />}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className='profile-templates'>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {title}
            </MainTitle>
          </Col>
        </Row>
        {width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous' onClick={() => setShowMenu(true)}>
                <PrevIcon className='profile__icon-previous' />
                <span>{backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12}>
            {((width <= LAPTOP && !showMenu) || width > LAPTOP) && <>
              <h3 className='profile__subtitle'>{subtitle1}</h3>
            </>
            }
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className='profile-templates__wrapper'>

              {((width <= LAPTOP && showMenu) || width > LAPTOP) && <>
                <div className='profile__menu-wrapper'>
                  <ProfileMenu />
                </div>
              </>
              }
              {((width <= LAPTOP && !showMenu) || width > LAPTOP) && <>
                <div className='profile-templates__content-wrapper'>
                  {
                    isLoaded ?
                      haveTemplates ?
                        <div className='templates-slider-wrapper'>
                          <div className='slider-wrapper__slider'>
                            {
                              initSlider ?
                                <div>
                                  <Slider {...sliderSettings(<SamplePrevArrow />, <SampleNextArrow />)}>
                                    {
                                      sliderData.map((el, id) => {
                                        return <SliderItem
                                          index={id}
                                          item={el}
                                          key={el.id}
                                          additionalClassname={''}
                                          setOpenModal={() => {
                                            setOpenModal(true);
                                            setBodyUnscroll(true);
                                          }}
                                          setSliderData={setSliderData}
                                          sliderData={sliderData}
                                          setEditTemplate={() => setEditTemplate(true)}
                                          setEditedTemplatePhone={(val) => setEditedTemplatePhone(val.replace('+', ''))}
                                          setSliderItemIndex={(index) => setSliderItemIndex(index)}
                                        />;
                                      })
                                    }
                                  </Slider>
                                </div>
                                :
                                <div
                                  className={`withoutSlider ${sliderData.length === 1 ? 'justify-content-center' : ''}`}>
                                  {
                                    sliderData.map((el, id) => {
                                      return <SliderItem
                                        index={id}
                                        item={el}
                                        key={el.id}
                                        additionalClassname='template-slider-item--no-slider'
                                        setOpenModal={() => {
                                          setOpenModal(true);
                                          setBodyUnscroll(true);
                                        }}
                                        setSliderData={setSliderData}
                                        sliderData={sliderData}
                                        setEditTemplate={() => setEditTemplate(true)}
                                        setEditedTemplatePhone={(val) => setEditedTemplatePhone(val.replace('+', ''))}
                                        setSliderItemIndex={(index) => setSliderItemIndex(index)}
                                      />;
                                    })
                                  }
                                </div>
                            }

                          </div>
                          {/*<MainButton onClick={() => {*/}
                          {/*  setEditTemplate(false);*/}
                          {/*  setOpenModal(true);*/}
                          {/*  setBodyUnscroll(true);*/}
                          {/*}}>*/}
                          {/*  {personalOffice.createTemplate}*/}
                          {/*</MainButton>*/}
                        </div>
                        :
                        <div className='empty'>
                          <h3>
                            {personalOffice.emptyBasket}
                          </h3>
                          {/*<MainButton onClick={() => {*/}
                          {/*  setEditTemplate(false);*/}
                          {/*  setOpenModal(true);*/}
                          {/*  setBodyUnscroll(true);*/}
                          {/*}}>*/}
                          {/*  {personalOffice.createTemplate}*/}
                          {/*</MainButton>*/}
                        </div>
                      :
                      <Loader />
                  }
                </div>
              </>
              }
            </div>
          </Col>
        </Row>
        {
          openModal ?
            <div className='modal-window'>
              <div className='modal-window__wrapper'>
                <div className='head'>
                  <h3>
                    {
                      editTemplate ?
                        personalOffice.editTemplate
                        :
                        personalOffice.createTemplate
                    }
                  </h3>
                  <button onClick={() => {
                    setOpenModal(false);
                    setEditTemplate(false);
                    setBodyUnscroll(false);
                  }}>
                    {blackTheme ? <CloseIcon /> : <CloseIconLight />}
                  </button>
                </div>
                {
                  // editTemplate ?
                  //   <EditTemplate
                  //     editedTemplatePhone={editedTemplatePhone}
                  //     closeModalWindow={() => {
                  //       setOpenModal(false);
                  //       setBodyUnscroll(false);
                  //       setRefreshKey(oldKey => oldKey + 1);
                  //     }}
                  //     sliderItemIndex={sliderItemIndex}
                  //   />
                  //   :
                  // <CreateTemplate
                  //   closeModalWindow={() => {
                  //     setOpenModal(false);
                  //     setBodyUnscroll(false);
                  //   }}
                  // />
                }
              </div>
            </div>
            :
            null
        }
      </Container>
    </>
  );
}

SliderItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  additionalClassname: PropTypes.string,
  setOpenModal: PropTypes.func,
  setEditTemplate: PropTypes.func,
  setEditedTemplatePhone: PropTypes.func,
  setSliderItemIndex: PropTypes.func,
  setSliderData: PropTypes.func,
  sliderData: PropTypes.array
};

ProfileDeliveryTemplate.propTypes = {
  history: PropTypes.object
};

export default withRouter(ProfileDeliveryTemplate);