import { changeBasketItemsQuantity } from './basketItems';
import { regexForGettingAccessToken } from './regexForGettingId';

// const url = 'http://localhost:3001';
const url = 'https://demo-chocolate.luxnet.io/api';



export const imagesUrl = 'https://demo-chocolate.luxnet.io/images/';

const controller = new AbortController();
const signal = controller.signal;
const arrToString = (arr, keyWord) => arr.map(arrElem => `${keyWord}[]=${arrElem}&`).join('');

export const getLanguages = () => {
  return fetch(`${url}/languages`);
};

export const getStaticLocalizationById = (id) => {
  return fetch(`${url}/static-localization/site/language/${id}`);
};

export const getKits = (setOffset, setLimit, sort, sortAsc = false, langId = '') => {
  return fetch(`${url}/goods/search?isSet=true&offset=${setOffset}&limit=${setLimit}&sort=${sort ? sort : 'NEW'}&sortAsc=${sortAsc}&langId=${langId}`, { signal });
};

export const getCategory = () => {
  console.time(`${url}/categories/search`);
  console.log(new Date());
  return fetch(`${url}/categories/search`)
    .then(response => {
      console.log(new Date());
      console.timeEnd(`${url}/categories/search`);
      return response;
    });
};

export const getCategoryGroupById = (categoryGroupId) => {
  return fetch(`${url}/category-groups/${categoryGroupId}`);
};

export const getAllCategoryGroupItems = () => {
  return fetch(`${url}/categories`);
};

export const getCategoryId = (slug) => {
  return fetch(`${url}/categories/search?groupSlug=${slug}`);
};

export const getCategoryGroupsId = (slug) => {
  return fetch(`${url}/category-groups/site/search?groupSlug=${slug}`);
};

export const getCollectionsId = (slug) => {
  return fetch(`${url}/collections/site?groupSlug=${slug}`);
};

export const getCategoryGroupItems = (id) => {
  if (id === 7) {
    console.time(`/categories/search?groupId=${id}`);
    console.info(new Date());
  }

  return fetch(`${url}/categories/search?groupId=${id}`)
    .then(response => {
      if (id === 7) {
        console.info(new Date());
        console.timeEnd(`/categories/search?groupId=${id}`);
      }
      return response;
    });
};

export const getGroupById = (groupId) => {
  return fetch(`${url}/categories/site/${groupId}`);
};

export const getGroupItems = ({
  groupId = '',
  limit,
  offset = '',
  fitlerIds = 0,
  fitlerValues = 0,
  sort = 'NEW',
  sortAsc,
  forBox = '',
  langId = ''
}) => {
  // console.log('groupId - ',  groupId);
  // console.log('limit - ',  limit);
  // // console.log('offset - ',  offset);
  // console.log('fitlerIds - ',  fitlerIds);
  // console.log('fitlerValues - ',  fitlerValues);
  // console.log('sort - ',  sort);
  // console.log('sortAsc - ',  sortAsc);

  return fetch(`${url}/goods/search?categories=${groupId}&limit=${limit}&offset=${offset}&fitlerIds=${fitlerIds}&fitlerValues=${fitlerValues}&sort=${sort ? sort : 'NEW'}&sortAsc=${sortAsc}&forBox=${forBox}&langId=${langId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getItemsBySerchBar = (text) => {
  return fetch(`${url}/goods/search?limit=20&search=${text}`);
};

export const getMainPageTempalte = () => {
  return fetch(`${url}/main-page-template-value/active`);
};

export const getGoodsById = (ids) => {
  const queryParams = ids.map(id => `goodsArray[]=${id}`).join('&');
  return fetch(`${url}/goods/site/fetch-by-ids?${queryParams}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getGoodsBySlug = (slug) => {
  return fetch(`${url}/goods/site/fetch-by-ids?slug=${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getMenu = () => {
  return fetch(`${url}/menu/site`);
};

export const register = (firstName, lastName, email, phone, phoneNumber, password) => {

  const data = { firstName, lastName, email, phone, phoneNumber, password };

  return fetch(`${url}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const buyWithoutRegistration = (userFirstName, userLastName, userEmail, userPhone) => {

  const data = {
    userFirstName,
    userLastName,
    userEmail,
    userPhone
  };

  return fetch(`${url}/order-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const geWithoutRegistrationtUserId = (phone) => {
  return fetch(`${url}/order-user?phone=${phone}`);
};

export const rewriteBuyWithoutRegistration = (id, userFirstName, userLastName, userEmail, userPhone) => {
  const data = {
    userFirstName,
    userLastName,
    userEmail,
    userPhone
  };

  return fetch(`${url}/order-user/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const verifyUserByTelephone = (code) => {
  return fetch(`${url}/users/register-confirmation/${code}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const resendSMS = (userId, userPhone) => {
  const data = {
    userId,
    userPhone
  };

  return fetch(`${url}/users/resend-register-confirmation`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const getUserSocialData = () => {
  return fetch(`${url}/users/social-data`);
};

export const updateUserData = (firstName = '', lastName = '', email = '', phone = '') => {
  const data = { firstName, lastName, email, phone };

  return fetch(`${url}/users/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const facebookCallback = (token) => {

  return fetch(`${url}/users/facebook/callback?access_token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    mode: 'no-cors'
  }).then((response) => {
    console.log(response.json());
  });
};

export const googleCallback = (token) => {
  return fetch(`${url}/users/google/callback?access_token=${token}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
};

export const logination = (emailOrPhone, password) => {
  const data = {
    'emailOrPhone': `${emailOrPhone}`,
    'password': `${password}`
  };
  return fetch(`${url}/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const getAccessToken = () => {
  if (localStorage.getItem('social-register') && regexForGettingAccessToken()) {
    return regexForGettingAccessToken();
  }
  if (sessionStorage.getItem('lmsh-token')) { // check session storage and whrite accessToken
    return sessionStorage.getItem('lmsh-token');
  }
  if (localStorage.getItem('lmsh-token')) { // check local storage if session storage is empty and whrite accessToken
    return localStorage.getItem('lmsh-token');
  }
};

export const getCurrentUser = (token) => {
  return fetch(`${url}/users/current`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};

export const setWishlistItem = (id) => {
  return fetch(`${url}/wishlist/goods/${id}/set`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const resetPassword = (phone) => {

  const data = {
    phone
  };

  return fetch(`${url}/users/reset-password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const resetPasswordConfirmation = (code) => {
  return fetch(`${url}/users/reset-password-confirmation/${code}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const changePasword = (oldPassword, newPassword) => {

  const data = {
    oldPassword,
    newPassword
  };

  return fetch(`${url}/users/update-password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });

};

export const getWihsList = (limit, offset) => {
  return fetch(`${url}/wishlist/site?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getAllCollections = () => {
  return fetch(`${url}/collections/site?onlyActive=1`);
};

export const addGoodsToBasket = (isLogined, item, counterValue, changeBasketItemsLength, orderUserId = '') => {

  const { id } = item;

  if (isLogined) { // For logined person

    const data = {
      orderUserId,
      items: [
        {
          'quantity': counterValue,
          'type': 'GOODS',
          'refId': id
        }
      ]
    };

    return fetch(`${url}/basket/items`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify(data)
    }).then(el => {
      if (el.ok) {
        changeBasketItemsQuantity(true, changeBasketItemsLength, orderUserId);
      } else {
        // console.log('test');
      }
    });

    // eslint-disable-next-line no-else-return
  } else { // For not logined person

    if (localStorage.getItem('lmsh-basket')) {
      const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));

      // Check if item was added previously;
      localBasket.forEach(item => {
        if (item.id === id) {
          item.quantity += counterValue;
        }
      });
      if (localBasket.filter(el => el.id === id).length === 0) {
        localBasket.push({ quantity: counterValue, type: 'GOODS', ...item });
      }

      const newLocalBasket = JSON.stringify([...new Set(localBasket)]);

      localStorage.setItem('lmsh-basket', [newLocalBasket]);
    } else {
      const basketItem = JSON.stringify([{ quantity: counterValue, type: 'GOODS', ...item }]);
      localStorage.setItem('lmsh-basket', basketItem);
    }
    changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
  }
};

export const addBoxedGoodsToBasket = (isLogined, item, counterValue, changeBasketItemsLength, images, amount, orderUserId = '', callback) => {

  const data = {
    orderUserId,
    ...item
  };

  if (isLogined) { // For logined person
    const setBoxedGoods = async (data) => {
      await fetch(`${url}/basket/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data)
      }).then(el => {
        changeBasketItemsQuantity(true, changeBasketItemsLength, orderUserId);
        callback();
      }).catch(er => {
        console.log(er);
      });
    };

    return fetch(`${url}/boxes/reference`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify(data)
    }).then(res => {
      return res.json();
    }).then(el => {

      const data = {
        orderUserId,
        items: [
          {
            quantity: counterValue,
            type: 'BOX_WITH_GOODS',
            refId: el.id
          }
        ]
      };

      setBoxedGoods(data);

    }).catch(er => {
      console.log(er);
    });
    // eslint-disable-next-line no-else-return
  } else { // For not logined person

    if (localStorage.getItem('lmsh-basket')) {

      const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));

      localBasket.push(
        {
          quantity: counterValue,
          type: 'BOX_WITH_GOODS',
          id: Date.now(),
          images: images,
          price: amount,
          ...item
        });

      const newLocalBasket = JSON.stringify([...new Set(localBasket)]);
      localStorage.setItem('lmsh-basket', [newLocalBasket]);

    } else {

      const basketItem = JSON.stringify([
        {
          quantity: counterValue,
          type: 'BOX_WITH_GOODS',
          id: Date.now(),
          images,
          price: amount,
          ...item
        }]);
      localStorage.setItem('lmsh-basket', basketItem);

    }
    changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
    callback();
  }

};

export const addIndividualGoodsToBasket = (isLogined, item, counterValue, changeBasketItemsLength, images, amount, orderUserId = '', callback) => {

  const data = {
    orderUserId,
    ...item
  };

  if (isLogined) { // For logined person

    const setIndividualGoods = async (data) => {
      await fetch(`${url}/basket/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data)
      }).then(() => {
        callback();
        changeBasketItemsQuantity(true, changeBasketItemsLength, orderUserId);
      });
    };

    return fetch(`${url}/ind_order_step_items/reference`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify(data)

    }).then(res => {
      return res.json();
    }).then(el => {

      const data = {
        orderUserId,
        items: [
          {
            quantity: counterValue,
            type: 'INDIVIDUAL_ORDER',
            refId: el.id
          }
        ]
      };
      setIndividualGoods(data);

    }).catch(er => {
      console.log(er);
    });

  }
  // eslint-disable-next-line no-else-return
  else {
    const forLocalStorage = {};
    if (localStorage.getItem('lmsh-basket')) {
      const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));
      localBasket.push(
        {
          quantity: counterValue,
          type: 'INDIVIDUAL_ORDER',
          id: Date.now(),
          images,
          price: amount,
          ...item
        });
      const newLocalBasket = JSON.stringify([...new Set(localBasket)]);
      localStorage.setItem('lmsh-basket', [newLocalBasket]);
    } else {

      const basketItem = JSON.stringify([
        {
          quantity: counterValue,
          type: 'INDIVIDUAL_ORDER',
          id: Date.now(),
          images,
          price: amount,
          ...item
        }]);
      localStorage.setItem('lmsh-basket', basketItem);
    }
    changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
    callback();
  }

};

const convertBase64ToBlob = (file, name) => {
  const parts = file.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  return new File([new Blob([uInt8Array], { type: imageType })], name);
};

export const addIndividualGoodsToBasketNew = (isLogined, item, counterValue, changeBasketItemsLength, images, amount, orderUserId = '', callback) => {

  const data = {
    orderUserId,
    ...item
  };


  if (isLogined) { // For logined person
    const formData = new FormData();
    for (const key in data) {
      if (key === 'additionIds') {
        if (data[key].length > 0) {
          data[key].forEach(item => formData.append('additionIds[]', item));
        } else {
          formData.append('additionIds[]', null);
        }
      } else if (key === 'customDecorImages') {
        // check if customDecorImages not null
        if (data[key]) {
          if (data[key].file?.size) {
            formData.append(key, data[key].file);
          } else {
            const _item = convertBase64ToBlob(data[key].base64string, data[key].file?.path);
            formData.append(key, _item);
          }
        }
      } else {
        formData.append(key, data[key]);
      }
    }


    const setIndividualGoods = async (data) => {
      await fetch(`${url}/basket/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data)
      }).then(() => {
        callback();
        changeBasketItemsQuantity(true, changeBasketItemsLength, orderUserId);
      });
    };

    return fetch(`${url}/i-o-references`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: formData

    }).then(res => {
      return res.json();
    }).then(el => {

      const data = {
        orderUserId,
        items: [
          {
            quantity: counterValue,
            type: 'INDIVIDUAL_ORDER',
            refId: el.id
          }
        ]
      };
      setIndividualGoods(data);

    }).catch(er => {
      console.log(er);
    });

  }
  // eslint-disable-next-line no-else-return
  else {
    const forLocalStorage = {};
    if (localStorage.getItem('lmsh-basket')) {
      const localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));
      localBasket.push(
        {
          quantity: counterValue,
          type: 'INDIVIDUAL_ORDER',
          id: Date.now(),
          images,
          price: amount,
          ...item
        });
      const newLocalBasket = JSON.stringify([...new Set(localBasket)]);
      localStorage.setItem('lmsh-basket', [newLocalBasket]);
    } else {

      const basketItem = JSON.stringify([
        {
          quantity: counterValue,
          type: 'INDIVIDUAL_ORDER',
          id: Date.now(),
          images,
          price: amount,
          ...item
        }]);
      localStorage.setItem('lmsh-basket', basketItem);
    }
    changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
    callback();
  }

};

export const addGiftBoxToBasket = (isLogined, item, changeBasketItemsLength, orderUserId = '') => {
  const { id } = item;

  if (isLogined) { // For logined person

    const data = {
      orderUserId,
      items: [
        {
          'quantity': 1,
          'type': 'BOX',
          'refId': id
        }
      ]
    };

    const addBox = () => {
      return fetch(`${url}/basket/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data)
      });
    };

    return getGoodsForBasket(0).then(res => {
      return res.json();
    }).then(el => {
      const _box = el.result.find(el => el.type === 'BOX');
      if (_box) {
        return deletGoodFromBasketById(_box.id).then(res => {
          return addBox();
        });
      }
      return addBox().then(el => {
        if (el.ok) {
          changeBasketItemsQuantity(true, changeBasketItemsLength, orderUserId);
        }
      });

    });


    // eslint-disable-next-line no-else-return
  } else { // For not logined person

    if (localStorage.getItem('lmsh-basket')) {

      let localBasket = JSON.parse(localStorage.getItem('lmsh-basket'));

      if (localBasket.filter(el => el.type === 'BOX').length) {
        localBasket = localBasket.map(el => {
          if (el.type === 'BOX') {
            return { quantity: 1, type: 'BOX', ...item };
          }
          return el;
        });
      } else {
        localBasket.push({ quantity: 1, type: 'BOX', ...item });
      }

      const newLocalBasket = JSON.stringify([...new Set(localBasket)]);
      localStorage.setItem('lmsh-basket', [newLocalBasket]);
    } else {
      const basketItem = JSON.stringify([{ quantity: 1, type: 'BOX', ...item }]);
      localStorage.setItem('lmsh-basket', basketItem);
    }
    changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
  }
};

export const getGoodsForBasket = (orderUserId) => {

  return fetch(`${url}/basket${orderUserId ? `?orderUserId=${orderUserId}` : ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const deleteAllGoodsFromBasket = (orderUserId = '') => {
  return fetch(`${url}/basket?orderUserId=${orderUserId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const deletGoodFromBasketById = (id, orderUserId) => {
  return fetch(`${url}/basket/item/${id}?orderUserId=${orderUserId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const methodForDeletingOneItemFromBasket = (
  isLogined,
  itemId,
  changeLoaderStatus,
  setBasketData,
  getGeneralPriceFunc,
  changeGeneralPriceFunc,
  changeBasketItemsLengthFunc,
  orderUserId,
  lokalStorageItemId
) => {

  if (isLogined) {
    deletGoodFromBasketById(itemId, orderUserId).then(() => {
      changeLoaderStatus(false); // show loader
      getGoodsForBasket().then(res => { // get all basket item after deleting
        return res.json();
      }).then(el => {
        setBasketData(el.result); // set all basket item after deleting
        const newGeneralPrice = getGeneralPriceFunc(isLogined, el.result); // get new general price
        changeGeneralPriceFunc(newGeneralPrice); // change new general price
        changeBasketItemsLengthFunc(el.count);
        changeLoaderStatus(true); // remove loader
      });
    });
  } else if (orderUserId > 0) {
    deletGoodFromBasketById(itemId, orderUserId).then(() => {
      changeLoaderStatus(false); // show loader
      getGoodsForBasket(orderUserId).then(res => { // get all basket item after deleting
        return res.json();
      }).then(el => {
        setBasketData(el.result); // set all basket item after deleting
        const newGeneralPrice = getGeneralPriceFunc(isLogined, el.result); // get new general price
        changeGeneralPriceFunc(newGeneralPrice); // change new general price
        changeBasketItemsLengthFunc(el.count);
        changeLoaderStatus(true); // remove loader
      });
    });
    const basket = localStorage.getItem('lmsh-basket');
    const parsedBasket = JSON.parse(basket);
    const newBasket = parsedBasket.filter(el => el.id !== lokalStorageItemId);
    changeBasketItemsLengthFunc(newBasket.length);
    localStorage.setItem('lmsh-basket', JSON.stringify(newBasket));
    setBasketData(newBasket); // set all basket item after deleting
    const newGeneralPrice = getGeneralPriceFunc(isLogined, newBasket); // get new general price
    changeGeneralPriceFunc(newGeneralPrice);
  } else {
    const basket = localStorage.getItem('lmsh-basket');
    const parsedBasket = JSON.parse(basket);
    const newBasket = parsedBasket.filter(el => el.id !== itemId);
    changeBasketItemsLengthFunc(newBasket.length);
    localStorage.setItem('lmsh-basket', JSON.stringify(newBasket));
    setBasketData(newBasket); // set all basket item after deleting
    const newGeneralPrice = getGeneralPriceFunc(isLogined, newBasket); // get new general price
    changeGeneralPriceFunc(newGeneralPrice);
  }
};


// TODO rewrite
export const changeBasketItemQuantity = (id, quantity, type = '', notRegisterCustomerId) => {

  const data = { orderUserId: notRegisterCustomerId, quantity, type };

  return fetch(`${url}/basket/item/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const getFiltersByGroupId = (groupId) => {
  return fetch(`${url}/filters/search?categoryId=${groupId}`);
};

export const getFiltersForBox = () => {
  return fetch(`${url}/filters/for-box`);
};

export const getYouMayLikedList = (limit, offset, categoryId, tags) => {
  return fetch(`${url}/goods/search-for-liked?limit=${limit}&offset=${offset}&categoryId=${categoryId}&tags=${tags}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getStaticPages = () => {
  return fetch(`${url}/static-pages/site`);
};

export const getGeneralSetings = () => {
  return fetch(`${url}/settings`);
};

export const getCollections = (id) => {
  return fetch(`${url}/collections/${id}/site`);
};
// TODO: remove after new functional will have released
export const getIndividualCategories = () => {
  return fetch(`${url}/categories/search?type=INDIVIDUAL_ORDER`);
};

// TODO: remove after new functional will have released
export const getIndividualStepItems = ({ type, id, limit, offset }) => {
  return fetch(`${url}/ind_order_step_items/site?type=${type}&categoryId=${id}&limit=${limit}&offset=${offset}`);
};

// --- new individual orders
export const getIndividualCategoriesNew = () => {
  return fetch(`${url}/i-o-categories/site`);
};

export const getIndividualCategoryNew = ({ id }) => {
  return fetch(`${url}/i-o-categories/${id}`);
};
export const getIndividualStepItemsNew = ({ apiLink, categoryId, shapeId, limit, offset, filterIds, filterValues }) => {
  // console.log('apiLink - ',  apiLink);
  // console.log('categoryId - ',  categoryId);
  // console.log('shapeId - ',  shapeId);
  // console.log('limit - ',  limit);
  // console.log('offset - ',  offset);
  // console.log('filterIds - ',  filterIds);
  // console.log('filterValues - ',  filterValues);
  return fetch(`${url}/${apiLink}/site?categoryId=${categoryId}&shapeId=${shapeId}&limit=${limit}&offset=${offset}&filterIds=${filterIds}&filterValues=${filterValues}`);
};

export const getBoxes = ({ type, limit, offset }) => {
  return fetch(`${url}/boxes/site?type=${type}&limit=${limit}&offset=${offset}`);
};

export const getGiftBoxesCapacity = (capacity) => {

  return fetch(`${url}/basket/items/capacity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(capacity)
  });

};

export const getGiftBoxes = (isLogined, capacity) => {
  if (isLogined) {
    return fetch(`${url}/boxes/gift`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
  }
  return getGiftBoxesCapacity(capacity).then(res => {

    return res.json().then(el => {
      return fetch(`${url}/boxes/gift?capacity=${el}`);
    });

  });
};

export const subscribe = (userData, email) => {

  const setUserDataId = () => {
    try {
      if (userData.id) {
        return { userId: userData.id };
      }
      return null;
    } catch (err) {
      return null;
    }

  };

  const data = {
    ...setUserDataId(),
    email
  };
  return fetch(`${url}/subscriptions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const recipient = (
  isLogined,
  orderUserId = orderUserId || '',
  cityId,
  firstName,
  lastName,
  midName,
  email,
  phone,
  serveAsTemplate,
  deliveryType,
  deliveryEntityId,
  address,
  building,
  deliveryServiceDepartment,
  templateName,
  captchaToken
) => {

  const data = {
    orderUserId,
    cityId,
    firstName,
    lastName,
    midName,
    email,
    phone,
    serveAsTemplate,
    deliveryType,
    deliveryEntityId,
    address,
    building,
    deliveryServiceDepartment,
    templateName,
    captchaToken
  };

  data.npCityStreetId = address.id ?? null;
  console.log('RECIPIENT data - ', data);
  if (isLogined) {
    return fetch(`${url}/recipient`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify(data)
    });
  }
  return fetch(`${url}/recipient`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const removeRecipientTemplate = (recipientId) => {
  return fetch(`${url}/v2/recipient/template/${recipientId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getRecipientId = (phone, orderUserId) => {
  return fetch(`${url}/recipient?phone=${phone}&orderUserId=${orderUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const rewriteRecipient = (
  id,
  isLogined,
  orderUserId = orderUserId || '',
  cityId,
  firstName,
  lastName,
  midName,
  email,
  phone,
  serveAsTemplate,
  deliveryType,
  deliveryEntityId,
  address,
  building,
  deliveryServiceDepartment,
  templateName = ''
) => {
  const checkOrderUserId = orderUserId === 0 ? null : { orderUserId };
  const data = {
    ...checkOrderUserId,
    cityId,
    firstName,
    lastName,
    midName,
    email,
    phone,
    serveAsTemplate,
    deliveryType,
    deliveryEntityId,
    address,
    building,
    deliveryServiceDepartment,
    templateName
  };
  if (isLogined) {
    return fetch(`${url}/recipient/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`
      },
      body: JSON.stringify(data)
    });
  }
  return fetch(`${url}/recipient/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

export const calculateDeliveryConst = (
  orderUserId = '',
  cityId,
  firstName,
  lastName,
  midName,
  email,
  phone,
  deliveryType,
  deliveryEntityId,
  address,
  building,
  deliveryServiceDepartment = ''
) => {

  console.log('calculateDeliveryConst', orderUserId, cityId, firstName);

  return fetch(`
    ${url}/recipient/delivery-cost?orderUserId=${orderUserId}&cityId=${cityId}&deliveryType=${deliveryType}&deliveryEntityId=${deliveryEntityId}&deliveryServiceDepartment=${deliveryServiceDepartment}&address=${address}&building=${building}&firstName=${firstName}&lastName=${lastName}&phone=${phone}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};


export const getAllRecipients = (orderUserId) => {
  return fetch(`${url}/recipient?orderUserId=${orderUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const createOrder = (orderUserId, recipientId, additionalProtection, paymentType, isCallNeeded, departureAt, desiredDeliveryDate, comment) => {

  const data = {
    orderUserId,
    recipientId,
    additionalProtection,
    paymentType,
    departureAt,
    desiredDeliveryDate,
    comment
  };

  return fetch(`${url}/order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });

};

export const getUserOrders = () => {
  return fetch(`${url}/order`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getUserOrdersById = (id) => {
  return fetch(`${url}/order/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const getUserOrderStatusById = (id) => {
  return fetch(`${url}/order/status/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const searchRecipients = (phone) => {
  return fetch(`${url}/recipient?phone=+${phone}`);
};
// NovaPoshta

export const novaPoshtaGetCities = (city, langKey) => {
  return fetch(`${url}/v2/nova-poshta/cities?&name=${city}&limit=20&lang=${langKey}`);
};

export const novaPoshtaGetStreets = (cityId, streetName, ref, langKey) => {
  return fetch(`${url}/v2/nova-poshta/streets?&cityId=${cityId}&name=${streetName}&limit=20&ref=${ref}&lang=${langKey}`);
};

export const getDeliveryAndPaymentTypes = (cityId, langKey) => {
  return fetch(`${url}/recipient/delivery-and-payment-types?cityId=${cityId}&lang=${langKey}`);
};

export const warehouseTypes = (cityRef) => {
  return fetch(`${url}/nova-poshta/warehouse-types?cityRef=${cityRef}&page=1`);
};

export const warehouse = (cityRef, langKey) => {
  return fetch(`${url}/v2/nova-poshta/warehouses?cityRef=${cityRef}&lang=${langKey}`);
};

// LiqPay
export const liqPay = (orderId, orderUserId) => {
  return fetch(`${url}/payment/order/${orderId}/liqpay/checkout-from?orderUserId=${orderUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/html',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

// Local
export const localLogIn = (login, password) => {

  const data = { login, password };

  return fetch(`${url}/users/local/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const localLogOut = () => {

  return fetch(`${url}/users/local/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });

};

export const lokalGetBalance = () => {
  return fetch(`${url}/lokal/wallet/balance`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
};

export const lokalCardsByMobile = (phoneNumber, authCode = 0) => {

  const data = { phoneNumber, authCode };

  return fetch(`${url}/users/local/cardsByMobile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const lokalLoginOrRegister = (phone, authCode = 0, captchaToken = null, formLocation = null, isLogined = false) => {
  const nextRequestType = JSON.parse(sessionStorage.getItem('lokal-login-or-registration'))?.nextRequestType ?? null;

  return fetch(`${url}/lokal/login-or-register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify({ phone, authCode, nextRequestType, captchaToken, formLocation, isLogined })
  });
};

export const bindLocalCardsByMobile = (phoneNumber, authCode = '') => {

  const data = { phoneNumber, authCode };

  return fetch(`${url}/users/local/bind-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const localLoginyCardAuthCode = (cardNo, authCode) => {

  const data = { cardNo, authCode };

  return fetch(`${url}/users/local/login-by-card-auth-code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const localGetDiscount = (cardNo, paidByPoints, orderUserId = 0) => {

  const data = { cardNo, paidByPoints, orderUserId };

  return fetch(`${url}/users/local/get-discount`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(data)
  });
};

export const getLokalDiscountForAnonymous = (cardNo, paidByPoints, orderUserId) => {

  return fetch(`${url}/lokal/get-discount-for-anonymous`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ cardNo, paidByPoints, orderUserId })
  });
};

export const getLokalDiscount = (cardNo, paidByPoints, orderUserId) => {

  return fetch(`${url}/lokal/get-discount`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify({ cardNo, paidByPoints })
  });
};

export const checkCard = (cardNo) => {

  return fetch(`${url}/lokal/check-card`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify({ cardNo })
  });
};

export const getSettings = () => {
  return fetch(`${url}/settings`);
};


// Remove after set new individual order
export const getIndividualOrderGoods = ({ id, orderUserId }) => {

  return fetch(`${url}/ind_order_step_items/reference/${id}?orderUserId=${orderUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });

};

export const getIndividualOrderGoodsNew = ({ id, orderUserId }) => {

  return fetch(`${url}/i-o-references/${id}?orderUserId=${orderUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`
    }
  });

};

export const getPhotoGallery = (limit = 6, offset = 0) => {
  return fetch(`${url}/photo-gallery?limit=${limit}&offset=${offset}`);
};
