import { createGlobalStyle } from 'styled-components';

const mainBackgroundColor__Black = '#262223';
const mainBackgroundColor__Light = '#F3F0E5';

const counterBgColor__Black = 'rgba(246, 245, 241, 0.04);';
const counterBgColor__Light = 'rgba(186, 174, 163, 0.2)';

const headerBtnGroupBg__Black = '#302B2C';
const headerBtnGroupBg__Light = 'rgba(233, 228, 210, 1)';

const mainColor_1 = '#BAAEA3';
const mainColor_2 = '#634C41';
const mainColor_3 = '#E9524C';
const mainColor_4 = '#E7DBBC';
const mainColor_5 = '#8E7D72';
// const mainColor_6 = '#DCD6CA';
const mainColor_7 = '#D4A047';
const mainColor_8 = '#9A917F';
const mainColor_9 = '#504643';
const mainColor_10 = '#595451';
const mainColor_11 = '#3B3839';
const mainColor_12 = '#CFC9BB';
const mainColor_13 = '#E4AC4C';

export const ThemeStyling = createGlobalStyle`
    html{
        overflow: ${props => (props.bodyUnscroll ? 'hidden' : 'initial')};
    }
    body{
        overflow: ${props => (props.bodyUnscroll ? 'hidden' : 'initial')};
    }
    :root{
        --generalTransition: all .2s ease-out;

        --main-background-color: ${props => (props.blackTheme ? mainBackgroundColor__Black : mainBackgroundColor__Light)};
        --main-background-color--transparent: ${props => (props.blackTheme ? 'rgba(38, 34, 35, 0.9)' : 'rgba(243, 240, 229, 0.9)')};
        --main-background-color--popup: ${props => (props.blackTheme ? 'rgba(38, 34, 35, 0.7)' : 'rgba(243, 240, 229, 0.7)')};
        --main-background-color--popup-shadow: ${props => (props.blackTheme ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.2)')};
        --main-background-color--dropdown: ${props => (props.blackTheme ? mainBackgroundColor__Black : mainColor_8)};

        --general-text-color: ${props => (props.blackTheme ? mainColor_4 : mainColor_2)};

        --main-card-description: ${props => (props.blackTheme ? mainColor_8 : mainColor_5)};

        --counter-bg: ${props => (props.blackTheme ? counterBgColor__Black : counterBgColor__Light)};

        --header-btn-group-bg: ${props => (props.blackTheme ? headerBtnGroupBg__Black : headerBtnGroupBg__Light)};

        --footer-social-links: ${props => (props.blackTheme ? '#887C73' : mainColor_8)};
        --footer-social-links--hover: ${props => (props.blackTheme ? mainColor_4 : mainColor_2)};

        --pagination-non-active: ${props => (props.blackTheme ? mainColor_8 : mainColor_1)};

        --slider-mobile-category: ${props => (props.blackTheme ? 'rgba(231, 219, 188, 0.5)' : mainColor_7)};
        --slider-additional-text: ${props => (props.blackTheme ? mainColor_8 : 'rgba(99, 76, 65, 0.6)')};

        --check-button-checked: ${props => (props.blackTheme ? mainColor_4 : mainColor_2)};
        --check-button-border: ${props => (props.blackTheme ? mainColor_5 : mainColor_1)};

        --menu-mask: ${props => (props.blackTheme ? 'transparent' : '#ECE7D7')};
        --menu-mask-hover: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        
        --profile-menu-mask: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        --profile-menu-mask-hover: ${props => (props.blackTheme ? '#262223' : '#ECE7D7')};

        --dropdown-mask: ${props => (props.blackTheme ? '#242020' : '#ECE7D7')};
        --dropdown-mask-focus: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        --dropdown-option: ${props => (props.blackTheme ? mainColor_10 : mainColor_1)};

        --button-primary: ${mainColor_4};
        --button-primary-bg: ${mainColor_3};
        --button-primary-bg-hover: #C53C36;

        --button-secondary: ${props => (props.blackTheme ? mainColor_4 : mainColor_2)};
        --button-secondary-bg: ${props => (props.blackTheme ? '#242020' : '#ECE7D7')};
        --button-secondary-bg-hover: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        --button-secondary-icon: ${props => (props.blackTheme ? mainColor_4 : '#634C41')};

        --step-item-active: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        --step-item-prev: ${props => (props.blackTheme ? '#242020' : '#ECE7D7')};
        --step-item-next: ${props => (props.blackTheme ? '#302B2C' : '#E0DACA')};
        
        --form-toogler: ${props => (props.blackTheme ? '#595451' : mainColor_12)};
        --form-input-border: ${props => (props.blackTheme ? mainColor_9 : mainColor_12)};
        --form-input-border--focus: ${props => (props.blackTheme ? mainColor_5  : mainColor_1)};
        --form-input-placefolder: ${props => (props.blackTheme ? mainColor_10 : mainColor_12)};

        --additional-order-border: ${props => (props.blackTheme ? mainColor_11 : mainColor_12)};

        --signUp-form-userAgreement: ${props => (props.blackTheme ? 'white' : 'black')};

        --boxes-description ${props => (props.blackTheme ? mainColor_8 : mainColor_8)};

        --menu-item-hover ${props => (props.blackTheme ? mainColor_13 : mainColor_13)};
    }

`;
