import React, { useContext, useState } from 'react';
import './WithoutRegestration.scss';
import PropTypes from 'prop-types';
import { Context } from '../../../context';
import { Formik, Form, Field } from 'formik';
import MainButton from '../../MainButton/MainButton';
import * as Yup from 'yup';

import {
  buyWithoutRegistration,
  geWithoutRegistrationtUserId,
  rewriteBuyWithoutRegistration,
  deleteAllGoodsFromBasket
} from '../../../utils/api';
import { setEmailToLocalStorage } from '../../../utils/emailToLocalStorage';
import { setBasketFromLocalStorage } from '../../../utils/setBasketFromLocalStorage';
import PhoneMask from "../TelephoneWithMask/PhoneMask";

const WithoutRegestration = ({ changeStep, setNotRegisterCustomerId }) => {

  const { staticLocalization, changeBasketItemsLength, changeLoginationStatus } = useContext(Context);
  const { withoutRegestrationForm, order } = staticLocalization;
  const [phone, setPhone] = useState(false);
  const phoneRegex = /^\+(?:[0-9] ?){11,14}[0-9]$/; // Change this regex based on requirement
  const phoneIntRegex = /^(\+-|\+|0)?\d{16}$/; // Change this regex based on requirement
  const [phoneError, setPhoneError] = useState(false);

  const WithoutRegestrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(withoutRegestrationForm.nameError),
    lastName: Yup.string()
      .required(withoutRegestrationForm.lastNameError),
    email: Yup.string()
      .email(withoutRegestrationForm.emailErrorInvalidEmail)
      .required(withoutRegestrationForm.emailError),
    telephone: Yup.string()
      .required(withoutRegestrationForm.telephoneError)
      .test('test', withoutRegestrationForm.telephoneRegexError,
        function (value) {
          let isValidPhone = phoneRegex.test(value);
          let isValidPhoneInt = phoneIntRegex.test(value);
          if (phoneRegex.test(value)) {
            setPhone(true);
          }

          if (!isValidPhone && !isValidPhoneInt) {
            return false;
          }
          return true;
        }),
  });

  const localWithoutRegestration = localStorage.getItem('without-regestration') ? JSON.parse(localStorage.getItem('without-regestration')) : {};

  const handleChange = (event) => {
    const array = localWithoutRegestration;
    array[event.target.name] = event.target.value;
    // console.log(array);
    // console.log(JSON.stringify(array));

    localStorage.setItem('without-regestration', JSON.stringify(array));
  };

  return (
    <Formik
      initialValues={{
        firstName: localWithoutRegestration?.firstName || '',
        lastName: localWithoutRegestration?.lastName || '',
        email: localWithoutRegestration?.email || '',
        telephone: localWithoutRegestration?.telephone || '',
      }}
      validationSchema={WithoutRegestrationSchema}
      onSubmit={values => {

        const { email, firstName, lastName, telephone } = values;
        let modeLogin;
        if (phone && (telephone.split('')[0] == 0)) {
          modeLogin = '+38' + telephone;
        } else {
          modeLogin = telephone;
        }

        setEmailToLocalStorage(email);

        buyWithoutRegistration(firstName, lastName, email, modeLogin).then(res => {
          if (res.ok) {
            res.json().then(el => {
              deleteAllGoodsFromBasket(el.id).then(() => {
                setBasketFromLocalStorage(changeBasketItemsLength, el.id, true, changeLoginationStatus).then(() => {
                  setNotRegisterCustomerId(el.id);
                  changeStep('next');
                });
              });
            });

          } else {
            // TODO: Поміняти цю функцію на ось цю після того як Саша зробить фікс
            // geWithoutRegistrationtUserId(telephone).then(res => {
            geWithoutRegistrationtUserId(telephone.substr(1)).then(res => {
              return res.json();
            }).then(el => {
              rewriteBuyWithoutRegistration(el.result[0].id, firstName, lastName, email, telephone).then(res => {
                return res.json();
              }).then(el => {
                if (el.message && el.message === 'userPhone must be a valid phone number') {
                  setPhoneError(true);
                } else {
                  deleteAllGoodsFromBasket(el.id).then(() => {
                    setBasketFromLocalStorage(changeBasketItemsLength, el.id, true, changeLoginationStatus).then(() => {
                      changeStep('next');
                      setNotRegisterCustomerId(el.id);
                    });
                  });
                }
              });
            });
          }
        });
      }}
    >
      {({ errors, touched, handleSubmit, handleChange, handleBlur, ...props }) => (
        <Form className="g-form-style without-regestration-form">

          <label>
            <p className="required">{withoutRegestrationForm.name}</p>
            <Field type="text" name="firstName" placeholder={withoutRegestrationForm.namePlaceholder}
                   onKeyUp={handleChange}/>
            {errors.firstName && touched.firstName ? ( // Error
              <div className="error">{errors.firstName}</div>
            ) : null}
          </label>

          <label>
            <p className="required">{withoutRegestrationForm.lastName}</p>
            <Field type="text" name="lastName" placeholder={withoutRegestrationForm.lastNamePlaceholder}
                   onKeyUp={handleChange}/>
            {errors.lastName && touched.lastName ? ( // Error
              <div className="error">{errors.lastName}</div>
            ) : null}
          </label>

          <label>
            <p className="required">{withoutRegestrationForm.email}</p>
            <Field type="email" name="email" placeholder={withoutRegestrationForm.emailPlaceholder}
                   onKeyUp={handleChange}/>
            {errors.email && touched.email ? ( // Error
              <div className="error">{errors.email}</div>
            ) : null}
          </label>

          <label>
            <p className="required">{withoutRegestrationForm.telephone}</p>
            <PhoneMask handleChange={handleChange} handleBlur={handleBlur} placeholder={withoutRegestrationForm.telephonePlaceholder} value={props.values.telephone}>
            </PhoneMask>
            {errors.telephone && touched.telephone ? ( // Error
              <div className="error">{errors.telephone}</div>
            ) : null}
            {phoneError ? <div className="error">{withoutRegestrationForm.telephoneCreateError}</div> : null}
          </label>

          <div className="foot-btn-group">
            <MainButton palette="secondary" onClick={() => changeStep('prev')}>
              {order.goBack}
            </MainButton>

            <MainButton type="submit" palette="primary" onMouseDown={handleSubmit}>
              {order.nextStep}
            </MainButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

WithoutRegestration.propTypes = {
  changeStep: PropTypes.func,
  setNotRegisterCustomerId: PropTypes.func
};

export default WithoutRegestration;
