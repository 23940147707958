import React, {useState, useEffect, useContext, useRef} from 'react';
import './MostInterestingIndividual.scss';
import Slider from 'react-slick';

import { Context } from '../../context';
import { scrollToTop } from '../../utils/scrollToTop';


import { imagesUrl, getPhotoGallery } from '../../utils/api';

import MainTitle from '../../components/MainTitle/MainTitle';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';

import { ReactComponent as SliderArrow } from '../../assets/icons/form-drop-down-icon.svg';
import { ReactComponent as SliderArrowLight } from '../../assets/icons/form-drop-down-icon--light.svg';

const MostInterestingPopup = ({ whichSliderToOpen, closePopup, sliderRef }) => {

  const [popupIsLoaded, setPopupIsLoaded] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [showItems, setShowItems] = useState(false);

  const slider = useRef();

  useEffect(() => {

    getPhotoGallery(100, 0).then(res => {
      return res.json();
    }).then(el => {
      setPopupData(el.result);
      setPopupIsLoaded(true);
      slider.current.slickGoTo(whichSliderToOpen);

      const handleWindowClick = (e) => {
              
        if(!e.target.closest('.most-interesting-individual__popup .wrapper')){
          closePopup();
          window.removeEventListener("click", handleWindowClick);
        }
        
      }
      window.addEventListener('click', handleWindowClick)

    })
  }, [])

  const { blackTheme } = useContext(Context);

  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: true,
      nextArrow: <SliderArrow />,
      prevArrow: <SliderArrow />,
      onInit: () => {
        setShowItems(true);
      }
  };

  return(
    <div className='most-interesting-individual__popup'>
      <div className='wrapper'>
        {
          popupIsLoaded ?
            <Slider ref={slider} {...settings}>
              {popupData.map(el => {

                const filtredImages = () => {
                  
                  const obj = {
                    dark: '',
                    light: ''
                  }
                  
                  el.images.forEach(img => {
                    img.includes('dark') ? obj.dark = img : obj.light = img 
                  })

                  return obj;
                }

                return(
                  <div className={`${showItems ? 'show' : ''} popup-item`} key={el.id}>
                    <img src={`${imagesUrl}${filtredImages()[blackTheme ? 'dark' : 'light']}`} key={el.id} ></img>
                  </div>
                )

              })} 
            </Slider>
            :
            <Loader />
        }
      </div>
    </div>
  )
};

const MostInterestingIndividualItem = ({elementData, setShowPopup, order}) => {

  const [images, setImages] = useState({dark: '', light: ''});
  const { blackTheme } = useContext(Context); 
  
  useEffect(() => {

    const formatedImages = {dark: '', light: ''};

    elementData.images.forEach(img => {
      if(img.includes('dark')){
        formatedImages.dark = img; 
      }
      else if(img.includes('light')){
        formatedImages.light = img; 
      }  
    })

    setImages(formatedImages);
  }, [])

  const handleClick = () => {
    setShowPopup();
  }
  
  return(
    <div className="item" style={{order: order}}>
      <button onClick={handleClick}>
        <img src={`${imagesUrl}${images[blackTheme ? 'dark' : 'light']}`} alt="Item-image"/>
      </button>
    </div>
  )
};

const MostInterestingIndividual = () => {

  const [data, setData] = useState([]); 
  const [isLoaded, setIsLoaded] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [whichSliderToOpen, setWhichSliderToOpen] = useState(1);

  const [limit] = useState(6);
  const [getedElementCount, setGetedElementCount] = useState(0);
  const [showPagination, setShowPagination] = useState(true);

  const { staticLocalization, setBodyUnscroll } = useContext(Context); 
  const { mostInterestingIndividualPage } = staticLocalization;

  useEffect(() => {
    getPhotoGallery(limit).then(res => {
      return res.json();
    }).then(el => {
      setData(el.result);
      setShowPagination(el.count > limit)
      setGetedElementCount(el.count);
      scrollToTop(0, 'auto');
      setIsLoaded(true);
    })
  }, [])

  const handlePaginationClick = (page) => {
    
    setIsLoaded(false);

    getPhotoGallery(limit, (page * limit)).then(res => {
      return res.json();
    }).then(el => {
      setData(el.result);
      setIsLoaded(true);
    })
  } 

  const handleShowPopup = (sliderNumber) => {
    setWhichSliderToOpen(sliderNumber - 1);
    setBodyUnscroll(true);
    setShowPopup(true);
  } 

  return (
    <>
      <div className='most-interesting-individual'>
        <div className='container-fluid'>
          <div className="row">
            <div className="col-12">
              <MainTitle>
                {mostInterestingIndividualPage.title}
              </MainTitle>
            </div>
          </div>
          
          {
            isLoaded ?
            <>
              <div className="row content">
                <div className='content-wrapper'>
                  {data.map(el => <MostInterestingIndividualItem key={el.id} elementData={el} setShowPopup={() => handleShowPopup(el.positionNumber)} key={el.positionNumber} order={el.positionNumber}/>)}
                </div>
              </div>

            </>
            :
            <Loader />
          }

          <div className="row" style={{display: isLoaded ? 'block' : 'none'}}>
            {
              showPagination ?
                <Pagination elementCount={getedElementCount} elementLimit={limit} returnPageOffset={offset => handlePaginationClick(offset)} />
                :
                null
            }
          </div>
        
        </div>
      </div>
      {
        showPopup ?
          <MostInterestingPopup whichSliderToOpen={whichSliderToOpen} closePopup={() => {setShowPopup(false); setBodyUnscroll(false)}} />
          :
          null
      }
    </>
  )
};

export default MostInterestingIndividual;