import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import './IndividualSteps.scss';

import MainTitle from '../../components/MainTitle/MainTitle';
import Steps from '../../components/Steps/Steps' ;
import MainButton from '../../components/MainButton/MainButton';
import IndividualStepsItem from '../../components/IndividualItem/IndividualStepsItem';
import Counter from '../../components/Counter/Counter';
import Dropdown from '../../components/Dropdown/Dropdown';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import Modal from '../../components/Modal/Modal';
import CustomInscriptionForm from '../../components/Forms/CustomInscription/CustomInscription';
import DropZone from '../../components/Forms/DropZone/DropZone';

import { ReactComponent as CloseSVG } from './../../assets/icons/close.svg';
import { ReactComponent as BorderAdditionalOrderSVG } from './../../assets/images/border-additional-order.svg';
import { ReactComponent as PreviousArrowSVG } from './../../assets/icons/previous-arrow.svg';
import { ReactComponent as NextArrowSVG } from './../../assets/icons/next-arrow.svg';

import { scrollToTop } from '../../utils/scrollToTop';
import { regexForGettingId } from '../../utils/regexForGettingId';

import { getTranslation, getCategoryName, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';

import { useWindowSize } from '../../utils/useWindowSizeHook';
import { getIndividualStepItems, imagesUrl, getBoxes, getIndividualCategories, addIndividualGoodsToBasket,
  getIndividualCategoryNew, getIndividualStepItemsNew, addIndividualGoodsToBasketNew, getFiltersByGroupId
} from '../../utils/api';
import { getEllementOffset } from '../../utils/getEllementOffset';

import { Context } from '../../context';

const SHAPE_TYPE_NAME = 'SHAPE';
const ADDITION_TYPE_NAME = 'ADDITION';
const ADDITION_TYPE_STEP = 2;
const ADDITION_STEP_INDEX = 2;
const CHOCOLATE_TYPE_NAME = 'CHOCOLATE_TYPE';
const INSCRIPTION_TYPE_NAME = 'INSCRIPTION';
const DECOR_TYPE_NAME = 'DECOR';
const BOXES_TYPE_NAME = 'BOX';
const PASSED_STEP_NAME = 'passed';
const ACTIVE_STEP_NAME = 'active';
const NEXT_STEP_NAME = 'next';
const TABLET_WIDTH = 991;
const MOBILE_WIDTH = 575;

const priceDecorator = (price) => {
  if (price > 0) { return `+${price}`};
  return price;
}

const EmptyAdditionModal = ({ onClick }) => {

  const {
    staticLocalization, isOpenModal, setIsOpenModal
  } = useContext(Context);
  const { individualLangEmptyModal } = staticLocalization;

  const toggleModal = (e, forcedEvent) => {
    console.log('isOpenModal',isOpenModal)
    setIsOpenModal(!isOpenModal);
  };

  const closeHandler = (e) => {
    toggleModal(e, true);
  };

  const nextStepHandler = () => {
    onClick();
    closeHandler();
  }

  return (
    <div className="individual-modal">
      <div className='border-wrapper'>

        <div className='individual-modal__title-wrapper'>
          <h3 className='individual-modal__title'>{individualLangEmptyModal.title}</h3>

        </div>
        <p className='g-like-p16 individual-modal__message'>{individualLangEmptyModal.message}</p>
        <div className='foot-btn-group'>
          <MainButton palette='secondary' onClick={closeHandler}>
            {individualLangEmptyModal.btnsCancel}
          </MainButton>
          <MainButton palette='primary' onClick={nextStepHandler}>
            {individualLangEmptyModal.btnsOk}
          </MainButton>
        </div>
      </div>

    </div>
  );
}

const IndividualOrderAdditionalItem = ({ content, onDelete }) => {

  const { staticLocalization, settings } = useContext(Context);
  const { general } = staticLocalization;

  const price = priceDecorator(content.price);
  const imgUrl = `${imagesUrl}${content.images[0]}?v=${settings.frontendVersion}`;
  const currency = general.currency;

  return (
    <div className='individual-order__addition-item'>
      <CloseSVG onClick={()=>onDelete(content, ADDITION_TYPE_NAME)} />
      <div className='individual-order__addition-img'>
        <img src={imgUrl}/>
      </div>
      <div className='individual-order__addition-price'>
        {price}
        <small> {currency}</small>
      </div>
    </div>
  );
};

const IndividualOrderComplete = ({ type, price, onChange, category, onClose }) => {

  const { staticLocalization, curentLanguageId, settings } = useContext(Context);
  const { general } = staticLocalization;

  const data =  modifidedItemWithTranslationField(category);

  const currency = general.currency;
  const imgUrl = `${imagesUrl}${category.images[0]}?v=${settings.frontendVersion}`;
  const name = getTranslation(category, data, 'name', curentLanguageId);

  const TextWrapper = ({ children }) => {

    const { width } = useWindowSize();

    if (width > TABLET_WIDTH) {
      return (
        <div className='individual-order__text-wrapper'>
          { children }
        </div>
      );
    }

    return (
      <>
        { children }
      </>
    );
  };

  return (
    <div className='item individual-order__item'>
      <CloseSVG onClick={onClose}/>
      <div className='individual-order__item-top'>
        <div className='individual-order__img'>
          <img src={imgUrl}/>
        </div>
        <div className='individual-order__name'>
          <small>{type}</small>
          {name}
        </div>

      </div>
      <div className='individual-order__item-bottom'>
        <Counter className='mr-5' counterValueChanged={onChange}/>
        <div className='individual-order__price'>
          {price}
          <small>{currency}</small>
        </div>

      </div>
    </div>
  );
};

const IndividualOrderItem = ({ content, onDelete, steps, forComplete, maxAdditionsValue, additionsLength, customInscriptionText, customDecorImage }) => {

  const { staticLocalization, curentLanguageId, settings } = useContext(Context);
  const { general, individualLang } = staticLocalization;
  const { width } = useWindowSize();

  const [ flang, setFlang] = useState(content);

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(content.items);
    setFlang(newData);
  }, []);

  const STEP_TO_SHOW_DELETE_ORDER_ITEM_FROM = maxAdditionsValue > 0 ? 2 : 1;

  const { id, data, isCustomInscription, isCustomDecor } = content.items;
  const imgUrl = data ? null : `${imagesUrl}${content.items.images[0]}?v=${settings.frontendVersion}`;
  const name = data ? null : getTranslation(content.items, flang, 'name', curentLanguageId);
  const type = data ? null : steps.content[content.step].typeTitle;
  const price = data ? null : content.step > 0 ? priceDecorator(content.items.price) : content.items.price;
  const currency = general.currency;
  // check is step > additions and box not standart
  const isShowCloseSVG = content.step > STEP_TO_SHOW_DELETE_ORDER_ITEM_FROM
  ? content.items?.serveAsDefault === true
    ? false
    : true
  : false

  if (id === ADDITION_TYPE_NAME && maxAdditionsValue === 0) return null;

  if (id === ADDITION_TYPE_NAME) {

    const emptyFieldsNumber = maxAdditionsValue - additionsLength;

    const EmptyFields = () => {

      const EmptyFieldsItem = (
        <div className='individual-order__addition-item'>
          <div className='individual-order__addition-img'>
            <BorderAdditionalOrderSVG />
          </div>
        </div>
      );
      const emptyFields = [];
      for (let i = 0; i < emptyFieldsNumber; i++) {
        emptyFields.push(EmptyFieldsItem);
      }
      return (
        React.Children.toArray(emptyFields)
      );
    };

    return (
      <div className='item individual-order__addition'>
        <p className='individual-order__addition-title'>
          {individualLang.addition}
        </p>
        <div className='individual-order__addition-wrapper'>
          { (data?.length > 0) &&
            data.map(item => <IndividualOrderAdditionalItem key={`order-addition-${item.cellsElementId}`} content={item} onDelete={onDelete}/>)
          }
          { emptyFieldsNumber > 0 && <EmptyFields />}
        </div>

      </div>
    );
  }
  // for last step
  if (forComplete) {
    return (
      <div className='item individual-order__item'>
        { isShowCloseSVG && <CloseSVG onClick={()=>onDelete(content)}/> }
        <div className='individual-order__item-top'>
          <div className='individual-order__img'>
          <img src={isCustomDecor ? customDecorImage : imgUrl}/>
          </div>
          <div className='individual-order__name'>
            <small>{type}</small>
            {name}
            {isCustomInscription && <p className='individual-order__custom-text'>{customInscriptionText}</p>}
          </div>
        </div>
        <div className='individual-order__item-bottom'>
          <div className='individual-order__price'>
            {price}
            <small>{currency}</small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='item individual-order__item'>
      { isShowCloseSVG && <CloseSVG onClick={()=>onDelete(content)}/> }
      <div className='individual-order__img'>
        <img src={isCustomDecor ? customDecorImage : imgUrl}/>
      </div>
      <div className='individual-order__text-wrapper'>
        <div className='individual-order__name'>
          <small>{type}</small>
          {name}
          {isCustomInscription && <p className='individual-order__custom-text'>{customInscriptionText}</p>}
        </div>
        <div className='individual-order__price'>
          {price}
          <small>{currency}</small>
        </div>
      </div>
    </div>
  );
};

const IndividualSteps = () => {

  const { blackTheme, isLogined, changeBasketItemsLength, staticLocalization, curentLanguageId,
    isOpenModal, setIsOpenModal
  } = useContext(Context);
  const { general, individualLang } = staticLocalization;
  const history = useHistory();

  // stepsInitState - initial state for steps data
  // type INDIVIDUAL_ORDER - boxes
  const stepsInitState = [
    {
      id: 'individual-step-0',
      idNumber: 0,
      name: individualLang.stepName0,
      title: individualLang.stepTitle0,
      state: 'active',
      type: 'SHAPE',
      typeTitle: individualLang.stepTypeTitle0,
      buttonTitle: individualLang.stepButtonTitle0,
      apiLink: 'i-o-shapes',
    },
    {
      id: 'individual-step-1',
      idNumber: 1,
      name: individualLang.stepName1,
      title: individualLang.stepTitle1,
      state: 'next',
      type: 'CHOCOLATE_TYPE',
      typeTitle: individualLang.stepTypeTitle1,
      buttonTitle: individualLang.stepButtonTitle1,
      apiLink: 'i-o-chocolate-types',
    },
    {
      id: 'individual-step-2',
      idNumber: 2,
      name: individualLang.stepName2,
      title: individualLang.stepTitle2,
      state: 'next',
      type: 'ADDITION',
      typeTitle: individualLang.stepTypeTitle2,
      buttonTitle: individualLang.stepButtonTitle2,
      apiLink: 'i-o-additions',
    },
    {
      id: 'individual-step-3-1',
      idNumber: 3,
      name: individualLang.stepName3,
      title: individualLang.stepTitle3,
      state: 'next',
      type: 'INSCRIPTION',
      typeTitle: individualLang.stepTypeTitle3,
      buttonTitle: individualLang.stepButtonTitle3,
      apiLink: 'i-o-inscriptions',
    },
    {
      id: 'individual-step-3-2',
      idNumber: 6,
      name: individualLang.stepName6,
      title: individualLang.stepTitle6,
      state: 'next',
      type: 'DECOR',
      typeTitle: individualLang.stepTypeTitle6,
      buttonTitle: individualLang.stepButtonTitle6,
      apiLink: 'i-o-decors',
    },
    {
      id: 'individual-step-4',
      idNumber: 4,
      name: individualLang.stepName4,
      title: individualLang.stepTitle4,
      state: 'next',
      type: 'BOX',
      typeTitle: individualLang.stepTypeTitle4,
      buttonTitle: individualLang.stepButtonTitle4,
      apiLink: 'i-o-boxes',
    },
    {
      id: 'individual-step-5',
      idNumber: 5,
      name: individualLang.stepName5,
      title: individualLang.stepTitle5,
      state: 'next',
      type: 'POSTCARD',
      typeTitle: individualLang.stepTypeTitle5,
      buttonTitle: individualLang.stepButtonTitle5,
      apiLink: 'i-o-postcards',
    },
    {
      id: 'last',
      name: individualLang.stepNameLast,
      title: individualLang.stepTitleLast,
      state: 'next',
    }
  ];

  // current step and all steps data
  const [steps, setSteps] = useState({ step: -1, content: [] });
  // steps init state after sreps reset
  const [stepsContentStateForReset, setStepsContentStateForReset] = useState([]);
  // current step data
  const [stepContent, setStepContent] = useState(null);
  // selected category data
  const [category, setCategory] = useState(null);
  // selected shape data
  const [shape, setShape] = useState([]);
  // goods data for selected step
  const [data, setData] = useState([]);
  // max additional cells value (get in shape item)
  const [maxAdditionsValue, setMaxAdditionsValue] = useState(0);
  // current length of filled additions cells in order
  const [additionsLength, setAdditionsLength] = useState(0);
  // additian cells value of selected addition for exceeded Modal
  const [selectedAdditionValue, setSelectedAdditionValue] = useState(0);
  // content of box step with standadrt box content for insert to order on delete item
  const [standartBoxStepContent, setStandartBoxStepContent] = useState(null);

  const [filters, setFilters] = useState(null);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [order, setOrder] = useState([]);
  const [orderAmount, setOrderAmount] = useState(0);         // amount * orderAmountMultiplier
  const [itemOrderAmount, setItemOrderAmount] = useState(0); // amount for 1 set
  const [orderAmountMultiplier, setOrderAmountMultiplier] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showOrderMobile, setShowOrderMobile] = useState(false);

  const [customInscriptionText, setCustomInscriptionText] = useState('');
  const [itemForCustomInscription, setItemForCustomInscription] = useState(null);

  const [itemForCustomDecor, setItemForCustomDecor] = useState(null);
  const [customDecorImage, setCustomDecorImage] = useState({file: null, base64string: ''});

  const [additionCellsElementId, setAdditionCellsElementId] = useState(1);  // UniqueId for addutuinal elements
  const [whichModalShow, setWhichModalShow] = useState(null);
  const { width } = useWindowSize();

  const [filterIds, setFilterIds] = useState(null);
  const [filterValues, setFilterValues] = useState(null);

  const [showMoreBtn, setShowMoreBtn] = useState(false);
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [pageOffset, setPageOffset] = useState(1);
  const [elementsOffset] = useState(getEllementOffset(width, 12, 12, 6, 6));
  const [showPagination, setShowPagination] = useState(false);

  const [countOfElements, setCountOfElements] = useState(0);
  const [initialPaginationPage, setInitialPaginationPage] = useState(false);

  // steps start from 0
  const STEP_TO_USE_NEXT_STEP_FUNCTION_FOR_ITEM_BUTTON = 2;
  const STEP_TO_SHOW_TOP_PREV_STEP_BUTTON = 1;
  const STEP_TO_SHOW_TOP_NEXT_STEP_BUTTON = 2;
  const STEP_TO_SHOW_BUTTONS_UNDER_ORDER_BOX = 1;
  const ORDER_LENGTH_TO_SHOW_BUTTONS_UNDER_ORDER_BOX = 2;

  const existFilterForStep = () => {
    // check if exist filters && stepContent.type
    if (filters && stepContent?.type) {
      // check if exist filter for current step
      const stepFilter = filters[stepContent.type];
      if (stepFilter) {
        return true;
      }
    }
    return false
  }

  const TABLET_STEPS = 3;
  const MOBILE_STEPS = 2;

  const locationId = regexForGettingId(history);

  //const [additionalId, setAdditionalId] = useState(locationId);  TODO: remove

  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';
  const classes = classNames(
    'individual__content',
    getTheme
  );

  const checkBoxStepAndSetStandartBoxStepContent = (content) => {
    // check boxes step and set standartBoxStep content
    if (steps.content[steps.step].type === BOXES_TYPE_NAME) {
      if (order.findIndex(item => item?.type === BOXES_TYPE_NAME )) {
        // get standart box content
        const standartBox = content.find(item => item.serveAsDefault === true);
        if (standartBox) {
          // add price for selected item - need for individual steps after shape
          standartBox.price = standartBox.settings[0].price;
          //set setStandartBoxStep content
          setStandartBoxStepContent({
            step: steps.step,
            type: stepContent.type,
            items: standartBox
          });
        }
      }
    }
  }

  // get step data
  useEffect(() => {

    // check if steps get from server
    if (steps.step < 0) return;
    if (stepContent.id === 'last') return;

    setInitialPaginationPage(!initialPaginationPage);

    // TODO: get dropdown
    var _item = [];

    try{
      setIsLoaded(false);
      setData([]);
      getIndividualStepItemsNew({
        apiLink: steps.content[steps.step].apiLink,
        categoryId: locationId,
        shapeId: shape?.id,
        limit: elementsOffset,
        offset: 0,
      }).then((res)=>{
        return res.json();
      }).then((item)=>{
        setData(item.result);
        setCountOfElements(item.count);
        setShowMoreBtn(item.result.length < item.count);
        setShowPagination(item.result.length < item.count);
        setIsLoaded(true);
        // check box step and set standart box step content
        checkBoxStepAndSetStandartBoxStepContent(item.result);
        // reset filters
        setFilterIds(null);
        setFilterValues(null);
      });

    }catch(err){
      console.log(err);
    }

  }, [steps.step]);

  //set filters from category data
  useEffect(() => {

    if (category?.filters && category?.filters.length > 0) {
      const filters = category.filters.reduce((acc, curr) => {
        const _curr = { [curr.typeFor]: curr }
        return {...acc, ..._curr}
      }, {})
      setFilters(filters);
    }

  }, [category])

  // get category
  // set steps obj
  // set stepContent arr
  useEffect(()=>{

    try{

      getIndividualCategoryNew({id: locationId}).then((res)=>{
        return res.json();
      }).then((category)=>{

        // set category content
        setCategory(category);

        // filter decoration and inscription steps
        const _stepsContent = stepsInitState.filter(item => {
          if (!category.isDecorExists && item.apiLink === 'i-o-decors') return false;
          if (!category.isInscriptionExists && item.apiLink === 'i-o-inscriptions') return false;
          return item
        });

        // set steps content state for reset
        setStepsContentStateForReset(_stepsContent);
        // set first stepContent state
        setStepContent(_stepsContent[0])

        // set steps state {step: 0, content: Array(n)}
        const _steps = {
          step: 0,
          content: _stepsContent,
        }
        setSteps(_steps);

      });
    }catch(err){
      console.log(err);
    }

  }, []);

  const onDropdownChange = (val) => {

    setPageOffset(1);
    const { value, label } = val;

    setInitialPaginationPage(!initialPaginationPage);

    setFilterIds(filters[stepContent.type].filter.id);
    setFilterValues(value);

    try {
      setIsLoaded(false);
      setData([]);
      getIndividualStepItemsNew({
        apiLink: steps.content[steps.step].apiLink,
        categoryId: locationId,
        shapeId: shape?.id,
        filterIds: filters[stepContent.type].filter.id,
        filterValues: value,
        limit: elementsOffset,
        offset: 0,
      }).then((res)=>{
        return res.json();
      }).then((item)=>{
        setData(item.result);
        setShowMoreBtn(item.result.length < item.count);
        setShowPagination(item.result.length < item.count);
        setIsLoaded(true);
      });

    } catch (err) {
      console.log(err);
    }

  };

  // get index of additional elements in order array
  const getAdditionIndex = () => {

    return order.findIndex(item => {
      if (item && item.items) {
        return item.items.id === ADDITION_TYPE_NAME;
      }
      return false;
    });
  };

  const orderWithoutNulls = () => {
    return order.filter(item => item);
  };

  useEffect(() => {
    const _orderWithoutNulls = orderWithoutNulls();
    if (_orderWithoutNulls.length > 0) {
      const _orderAmount = _orderWithoutNulls.reduce((acc, curr) => {
        if (curr.items.id === ADDITION_TYPE_NAME) return acc;
        return acc + +curr.items.price;
      }, 0);
      let _additionAmount = 0;
      const additionIndex = getAdditionIndex();
      if (additionIndex > -1  && order[additionIndex].items.data.length > 0) {
        _additionAmount = order[additionIndex].items.data.reduce((acc, curr) => {
          return acc + +curr.price;
        }, 0);
      }

      setItemOrderAmount(_orderAmount + _additionAmount);

      const _totalAmount = (_orderAmount + _additionAmount) * orderAmountMultiplier;
      setOrderAmount(_totalAmount);
    }
  }, [order, orderAmountMultiplier]);

  const onShowMoreClick = () => {
    setShowMoreLoader(true);
    const newPage = pageOffset * elementsOffset;

    try{
      getIndividualStepItemsNew({
        apiLink: steps.content[steps.step].apiLink,
        categoryId: locationId,
        shapeId: shape?.id,
        filterIds: filterIds,
        filterValues: filterValues,
        limit: elementsOffset,
        offset: newPage,
      }).then((res)=>{
        return res.json();
      }).then((item)=>{
        const newData = [...data, ...item.result];

        setShowMoreBtn((newPage + elementsOffset) < item.count);
        setData(newData);
        setPageOffset(pageOffset + 1);
        setShowMoreLoader(false);
      });

    }catch(err){
      console.log(err);
    }

  };

  const paginationLogick = (page) => {

    setShowMoreBtn(false);

    const offset = elementsOffset * page;

    try{
      setIsLoaded(false);
      setData([]);
      getIndividualStepItemsNew({
        apiLink: steps.content[steps.step].apiLink,
        categoryId: locationId,
        shapeId: shape?.id,
        filterIds: filterIds,
        filterValues: filterValues,
        limit: elementsOffset,
        offset
      }).then((res)=>{
        return res.json();
      }).then((item)=>{
        setShowMoreBtn(offset + item.result.length < item.count);
        setPageOffset(page + 1);
        setData(item.result);
        setIsLoaded(true);
      });

    }catch(err){
      console.log(err);
    }

  };

  const goToStartHandler = () => {
    // reset steps content - must be before reset steps!
    setStepContent(steps.content[0]);
    // reset steps
    setSteps({step: 0, content: stepsContentStateForReset});
    // reset order
    setOrder([]);
    // reset order amount counter
    setOrderAmount(0);
    if (width <= TABLET_WIDTH) setShowOrderMobile(false);
    scrollToTop();
  };

  const toggleModal = (e, forcedEvent) => {
    // if ((e.target === e.currentTarget) || (forcedEvent)) { // for synthetic events
    setIsOpenModal(!isOpenModal);
  };

  const individualItemButtonHandler = (content, step, itemType) => {

    // create tmp order
    const _order = [...order];
    // sameShape initialization
    let sameShape = false;

    let _additionsLength = additionsLength;

    // check item type and make new order
    if (itemType && itemType === ADDITION_TYPE_NAME) {

      _additionsLength = _additionsLength + content.settings[0].cellAmount;
      // get additions index in order array
      const additionIndex = getAdditionIndex();
      // get additions from order array
      const _addition = additionIndex > -1 ? [...order[additionIndex].items.data] : [];
      // check maxAddition value and show Modal
      if (_additionsLength > maxAdditionsValue) {
        setSelectedAdditionValue(content.settings[0].cellAmount);
        setWhichModalShow('maxAdditionValueExceeded');
        setIsOpenModal(true);
        return
      }
      // add UniqueId for selected addition item
      const _content = {...content, cellsElementId: additionCellsElementId};
      // add price for selected addition item - need for individual steps after shape
      if (content?.settings) {
        _content.price = _content.settings[0].price;
      }
      // increase addition item UniqueId
      setAdditionCellsElementId(additionCellsElementId => additionCellsElementId + 1);
      // add selected addition item to additions
      _addition.push(_content);
      // create tmp order with new additions
      _order[steps.step] = {
        step: steps.step,
        type: stepContent.type,
        items: {
          id: itemType,
          data: _addition
        }
      };

      setAdditionsLength(_additionsLength);
    }

    else if (itemType && itemType === SHAPE_TYPE_NAME) {

      // set sameShape
      sameShape = (order.length > 0 && order[0].items.id == content.id);

      if (!sameShape) {

        // set shape
        setShape(content);

        // set MaxAdditionsValue
        const _maxAdditionsValue = content.additionsAmount;
        setMaxAdditionsValue(_maxAdditionsValue);

        // reset tmp order
        _order.length = 0;
        // create tmp content
        const _content = {...content}
        // create tmp order with new shape content
        _order[steps.step] = {
          step: steps.step,
          type: stepContent.type,
          items: _content
        };

        // reset additions length
        setAdditionsLength(0);

        }

    }
    // check chocolate step and add addition empty content
    else if (itemType && itemType === CHOCOLATE_TYPE_NAME) {

      // create tmp content
      const _content = {...content}
      // add price for selected item - need for individual steps after shape
      if (content?.settings) {
        _content.price = _content.settings[0].price;
      }
      // create tmp order with new content
      _order[steps.step] = {
        step: steps.step,
        type: stepContent.type,
        items: _content
      };
      // if order hasn't additions in order - add empty
      if (!_order[ADDITION_TYPE_STEP] && maxAdditionsValue > 0) {
        _order[ADDITION_TYPE_STEP] = {
          step: ADDITION_TYPE_STEP,
          type: 'ADDITION',
          items: {
            id: ADDITION_TYPE_NAME,
            data: []
          }
        };
      }
    }

    else { // --- else types

      // check CustomInscription item and show Modal
      if (itemType && itemType === INSCRIPTION_TYPE_NAME) {
        if (content.isCustomInscription) {
          setItemForCustomInscription(content);
          setWhichModalShow(INSCRIPTION_TYPE_NAME);
          setIsOpenModal(true);
          return;
        }
      }

      // check CustomInscription item and show Modal
      if (itemType && itemType === DECOR_TYPE_NAME) {
        //if (content.isCustomDecor) {
        if (content.isCustomDecor === true) { // temporary
          // create tmp content
          const _content = {...content}
          // add price for selected item - need for individual steps after shape
          if (content?.settings) {
            _content.price = _content.settings[0].price;
          }
          setItemForCustomDecor(_content);
          setWhichModalShow(DECOR_TYPE_NAME);
          setIsOpenModal(true);
          return;
        }
      }

      // create tmp content
      const _content = {...content}
      // add price for selected item - need for individual steps after shape
      if (content?.settings) {
        _content.price = _content.settings[0].price;
      }
      // create tmp order with new content
      _order[steps.step] = {
        step: steps.step,
        type: stepContent.type,
        items: _content
      };
    }

    // set new order
    setOrder(_order);

    if (steps.step < STEP_TO_USE_NEXT_STEP_FUNCTION_FOR_ITEM_BUTTON) {
      nextStepButtonHandler({selectedItem: content, sameShape, _additionsLength});
    }
  };

  const deleteFromOrder = (content, type) => {

    const _order = [...order];
    const additionIndex = getAdditionIndex();

    if (type === ADDITION_TYPE_NAME) {
      setAdditionsLength(additionsLength => additionsLength - content.settings[0].cellAmount)
      const _data = order[additionIndex].items.data.filter(item => item.cellsElementId !== content.cellsElementId);
      _order[additionIndex].items.data = _data;
    } else {
      // if deleted item is BOX and is standartBoxStepContent - check and add to order standart box
      if (content.type === BOXES_TYPE_NAME && standartBoxStepContent) {
        _order[content.step] = standartBoxStepContent;
      } else {
        _order[content.step] = null;
      }
    }

    setOrder(_order);
  };

  const goToNextStep = (content) => {

    const stepsContent = content ? content : steps.content;

    setStepContent(stepsContent[steps.step + 1]);

    const _stepsContent = stepsContent.map((item, index) => {
      if (index === steps.step) {
        return {
          ...item,
          state: PASSED_STEP_NAME
        };
      }
      if (index === steps.step + 1) {
        return {
          ...item,
          state: ACTIVE_STEP_NAME
        };
      }
      return { ...item };
    });

    setSteps(steps => {
      return {
        step: steps.step + 1,
        content: _stepsContent
      };
    });

    scrollToTop();

  }

  const nextStepButtonHandler = ({selectedItem, sameShape, _additionsLength: contentAdditionsLength}) => {

    let _stepsContent = [...steps.content];
    const _additionsLength = contentAdditionsLength ? contentAdditionsLength : additionsLength;

    // check addition cells value < max addition Value and show modal
    if (steps.content[steps.step].type === ADDITION_TYPE_NAME) {
      if (_additionsLength < maxAdditionsValue) {
        setWhichModalShow('hasEmptyAddition');
        setIsOpenModal(true);
        return
      }
    }

    // check boxes step selected item or add standard box
    if (steps.content[steps.step].type === BOXES_TYPE_NAME) {
      if (order.findIndex(item => item?.type === BOXES_TYPE_NAME ) === -1) {
        // create tmp order
        const _order = [...order];
        // check is set standartBoxStepContent
        if (standartBoxStepContent) {
          _order[steps.step] = standartBoxStepContent;
          setOrder(_order);
        }
      }

    }

    if (steps.content[steps.step].type === SHAPE_TYPE_NAME && !sameShape && selectedItem) {

      // remove or add addition steps from/to steps
      if (selectedItem.additionsAmount === 0) {
        // remove addition step from steps
        _stepsContent = _stepsContent.filter(item => item.id !== "individual-step-2");
      } else if (_stepsContent[ADDITION_STEP_INDEX].id !== "individual-step-2") {
        // add additions step to steps
        _stepsContent = [..._stepsContent.slice(0, ADDITION_STEP_INDEX), stepsInitState[ADDITION_STEP_INDEX], ..._stepsContent.slice(ADDITION_STEP_INDEX, _stepsContent.length)];
      }

    }

    goToNextStep(_stepsContent);

  };

  const previousStepButtonHandler = () => {

    setStepContent(steps.content[steps.step - 1]);

    const _stepsContent = steps.content.map((item, index) => {
      if (index === steps.step) {
        return {
          ...item,
          state: NEXT_STEP_NAME
        };
      }
      if (index === steps.step - 1) {
        return {
          ...item,
          state: ACTIVE_STEP_NAME
        };
      }
      return { ...item };
    });

    setSteps(steps => {
      return {
        step: steps.step - 1,
        content: _stepsContent
      };
    });

    scrollToTop();
  };

  const changeCompleteAmount = (multiplier) => {
    setOrderAmountMultiplier(multiplier);
  };

  const addToBasket = () => {

    const goodsObj = orderWithoutNulls().reduce( (acc, curr) => {
      acc[curr.type] = curr;
      return acc;
    }, {});


    const addition = goodsObj?.ADDITION?.items?.data && goodsObj?.ADDITION?.items?.data.length > 0
      ? goodsObj.ADDITION.items.data.map(item => item.id)
      : [];

    // 'SHAPE', 'CHOCOLATE_TYPE', 'ADDITION', 'INSCRIPTION', 'DECOR', 'BOX', 'POSTCARD'
    //
    const goods = {
      categoryId: category.id,
      shapeId: goodsObj.SHAPE.items.id,
      chocolateTypeId: goodsObj.CHOCOLATE_TYPE.items.id,
      additionIds: addition,
      inscriptionId: goodsObj.INSCRIPTION?.items.id || null,
      customInscriptionText: goodsObj.INSCRIPTION?.items.isCustomInscription ? customInscriptionText : null,
      decorId: goodsObj.DECOR?.items.id || null,
      customDecorImages: goodsObj.DECOR?.items.isCustomDecor ? customDecorImage : null,
      boxId: goodsObj.BOX?.items.serveAsDefault ? null : goodsObj.BOX?.items.id,
      postcardId: goodsObj.POSTCARD?.items.id || null,
      itemEntity: {
        category: category,
        shape: goodsObj.SHAPE.items,
        chocolateType: goodsObj.CHOCOLATE_TYPE.items,
        additions: goodsObj.ADDITION?.items.data,
        inscription: goodsObj.INSCRIPTION?.items,
        decor: goodsObj.DECOR?.items,
        box: goodsObj.BOX?.items,
        postcard: goodsObj.POSTCARD?.items,
      }
    };

    addIndividualGoodsToBasketNew(isLogined, goods, orderAmountMultiplier, changeBasketItemsLength, category.images, itemOrderAmount, null, ()=>{
      history.push('/basket');
    });

    scrollToTop();

  };

  const getStepQuantity = () => {
    if (width <= MOBILE_WIDTH ) return MOBILE_STEPS;
    if (width <= TABLET_WIDTH ) return TABLET_STEPS;
    return 0;
  };

  const onSubsciptionSubmit = (text, price) => {
    setCustomInscriptionText(text);
    setIsOpenModal(!isOpenModal);

    const _order = [...order];

    const _itemForCustomInscription = {
      ...itemForCustomInscription,
      price: price
    };

    _order[steps.step] = {
      step: steps.step,
      type: stepContent.type,
      items: _itemForCustomInscription
    };

    setOrder(_order);
    if (steps.step < STEP_TO_USE_NEXT_STEP_FUNCTION_FOR_ITEM_BUTTON) {
      nextStepButtonHandler();
    }
  };

  const showOrderMobileHandler = () => {
    setShowOrderMobile(!showOrderMobile);
    scrollToTop();
  };

  const onCustomDecorSubmit = (file, content) => {
    setCustomDecorImage(file);
    setIsOpenModal(!isOpenModal);

    const _order = [...order];

    _order[steps.step] = {
      step: steps.step,
      type: stepContent.type,
      items: {...itemForCustomDecor}
    };

    setOrder(_order);
    if (steps.step < STEP_TO_USE_NEXT_STEP_FUNCTION_FOR_ITEM_BUTTON) {
      nextStepButtonHandler();
    }
  }

  const MaxAdditionExceededModal = ({ onClick }) => {

    const {
      staticLocalization, isOpenModal, setIsOpenModal
    } = useContext(Context);
    const { individualMaxAdditionExceededModal } = staticLocalization;
    const [additionModal, setAdditionalModal] = useState(null);
    const additionIndex = getAdditionIndex();
    const [additionsModalLength, setAdditionsModalLength] = useState(additionsLength);

    useEffect( () => {
      setAdditionalModal(
        {
          ...order[additionIndex],
          items: {
            id: order[additionIndex].items.id,
            data: [...order[additionIndex].items.data]
          }
        });
    }, []);

    const toggleModal = (e, forcedEvent) => {
      setIsOpenModal(!isOpenModal);
    };

    const closeHandler = (e) => {
      toggleModal(e, true);
    };

    const acceptHandler = () => {

      const _order = [...order];
      _order[additionIndex].items.data = additionModal.items.data;
      _order[additionIndex].type = 'ADDITION';
      setOrder(_order);

      const _additionsLength = additionModal.items.data.reduce((acc, curr) => {
        return acc + curr.settings[0].cellAmount
      }, 0)

      setAdditionsLength(_additionsLength);

      closeHandler();

    }

    const deleteHandler = (content) => {
      const _data = additionModal.items.data.filter(item => item.cellsElementId !== content.cellsElementId);
      const _additionModal = {
        additionModal,
        items: {
          id: additionModal.items.id,
          data: _data
        }
      }

      const _additionsLength = _data.reduce((acc, curr) => {
        return acc + curr.settings[0].cellAmount
      }, 0)

      setAdditionalModal(_additionModal);
      setAdditionsModalLength(_additionsLength);
    };

    return (
      <div className="individual-modal">
        <div className='border-wrapper'>

          <div className='individual-modal__title-wrapper'>
            <h3 className='individual-modal__title'>{individualMaxAdditionExceededModal.title}</h3>

          </div>
          <p className='g-like-p16 individual-modal__message'>{`${individualMaxAdditionExceededModal.message}[${selectedAdditionValue}].`}</p>
          { additionModal &&
            <>
              <IndividualOrderItem
                content={additionModal}
                onDelete={deleteHandler}
                steps={steps}
                forComplete={true}
                maxAdditionsValue={maxAdditionsValue}
                additionsLength={additionsModalLength}
              />
            </>
          }
          <div className='foot-btn-group'>
            <MainButton palette='secondary' onClick={closeHandler}>
              {individualMaxAdditionExceededModal.btnsCancel}
            </MainButton>
            <MainButton palette='primary' onClick={acceptHandler}>
              {individualMaxAdditionExceededModal.btnsOk}
            </MainButton>
          </div>
        </div>

      </div>
    );
  }

  const IndividualOrder = () => (
    <div className='individual-order aside'>
      <div className='individual-order__caption'>
        <h3>{individualLang.orderBoxTitle}</h3>
      </div>
      <div className='individual-order__content'>
        <div className='border-wrapper'>
          {
            orderWithoutNulls()
              .map(item => <IndividualOrderItem
                key={`order-${item.step}-${item.items.id}`}
                content={item}
                onDelete={deleteFromOrder}
                steps={steps}
                maxAdditionsValue={maxAdditionsValue}
                additionsLength={additionsLength}
                customInscriptionText={customInscriptionText}
                customDecorImage={customDecorImage.base64string}
              />)
          }
        </div>
      </div>
      <div className='individual-order__amount'>
        <span>{individualLang.orderBoxAmount}</span>
        {orderAmount}<small>{general.currency}</small>
      </div>
      { (steps.step > STEP_TO_SHOW_BUTTONS_UNDER_ORDER_BOX || order.length >= ORDER_LENGTH_TO_SHOW_BUTTONS_UNDER_ORDER_BOX || width <= TABLET_WIDTH) &&
        <div className='individual-order__button-group'>
          <MainButton  palette='secondary' onClick={goToStartHandler}>{individualLang.btnsClear}</MainButton>
          {
            width > TABLET_WIDTH
              ? <MainButton  palette='primary' onClick={nextStepButtonHandler}>{individualLang.nextStep}</MainButton>
              : <MainButton  palette='primary' onClick={showOrderMobileHandler}>{individualLang.continueOrder}</MainButton>
          }
        </div>
      }
    </div>
  );

  return (
    <Container fluid className={`individual ${getTheme}`}>
      <Row>
        <Col>
          <MainTitle>
            {individualLang.mainTitle}
          </MainTitle>
        </Col>
      </Row>
      {
        steps.step > -1 ? <>
        <Row>
          <Col>
            <Steps content={steps.content} quantity={getStepQuantity()} page='individual'/>
          </Col>
        </Row>

        { steps.content[steps.step].type
          ? <>
            <Row>
              <Col>
                { (width > TABLET_WIDTH || (width <= TABLET_WIDTH && showOrderMobile === false))
                  ? <>
                    <div className={classes}>
                      <div className='individual__caption'>
                        <Row>
                          {
                            steps.step >= STEP_TO_SHOW_TOP_PREV_STEP_BUTTON && width > TABLET_WIDTH && <Col>
                              <MainButton  className='individual__btns-back' palette='secondary' onClick={previousStepButtonHandler}>
                                <span>{individualLang.btnsBack}</span>
                              </MainButton>
                            </Col>
                          }
                          <Col>
                            <h3>{stepContent.title}</h3>
                          </Col>
                        </Row>
                        { steps.step >= STEP_TO_SHOW_TOP_PREV_STEP_BUTTON && width <= TABLET_WIDTH && <Row>
                          <Col>
                            <div className='individual__btns-header-mobile'>
                              <MainButton  palette='secondary' className='btns--prev' onClick={previousStepButtonHandler}>
                                <PreviousArrowSVG />
                                <span>{individualLang.btnsBack}</span>
                              </MainButton>
                            </div>
                          </Col>
                        </Row>
                        }
                      </div>
                      {
                        existFilterForStep() && <>
                          <div className='dropdown-wrapper'>
                            <Dropdown
                              isSearchable={false}
                              options={filters[stepContent.type].filter.options}
                              onChange={onDropdownChange}
                              addAllOption={true}
                            />
                          </div>
                        </>
                      }

                      { (data?.length > 0 || isLoaded)
                        ? <>
                          <div className='individual-items__wrapper'>
                            <div className={`individual__items individual__items--${stepContent.idNumber}`}>
                              { data.map(
                                item => <IndividualStepsItem
                                  key={`${stepContent.type}-${item.id}`}
                                  content={item}
                                  step={steps.step}
                                  buttonTitle={steps.content[steps.step].buttonTitle}
                                  itemType={steps.content[steps.step].type}
                                  category={steps.content[steps.step].typeTitle}
                                  onClick={individualItemButtonHandler}/>
                              )}
                            </div>

                            { showMoreLoader && <Loader /> }

                            { (width <= TABLET_WIDTH && showMoreBtn)&&
                              <div className='individual__btns-footer-mobile'>
                                {
                                  showMoreBtn && <>
                                    <MainButton palette='secondary' className='individual__show-more' onClick={onShowMoreClick}>
                                      {individualLang.btnsShowMore}
                                    </MainButton>
                                  </>
                                }
                              </div>
                            }
                            { (width <= TABLET_WIDTH && (order.length > 0 || steps.step >= STEP_TO_SHOW_TOP_NEXT_STEP_BUTTON))&&
                              <div className='individual__btns-footer-mobile individual__btns-footer-mobile--sticky'>
                                { order.length > 0 &&
                                  <MainButton
                                    palette='secondary'
                                    className='individual__show-order'
                                    onClick={showOrderMobileHandler}
                                  >
                                    {individualLang.btnsShowOrder}
                                  </MainButton>
                                }
                                { steps.step >= STEP_TO_SHOW_TOP_NEXT_STEP_BUTTON && <>
                                <MainButton  palette='secondary' className='btns--next' onClick={nextStepButtonHandler}>
                                  <span>{individualLang.btnsNextStep}</span>
                                  <NextArrowSVG />
                                </MainButton>
                              </>
                              }
                              </div>
                            }
                          </div>
                        </>
                        : <Loader />
                      }

                    </div>

                    {
                      isOpenModal && <>
                        <Modal close={toggleModal}>
                          <>
                          {
                            whichModalShow === INSCRIPTION_TYPE_NAME && <>
                              <CustomInscriptionForm onSubmit={onSubsciptionSubmit} content={itemForCustomInscription}/>
                            </>
                          }
                          {
                            whichModalShow === 'hasEmptyAddition' && <>
                              <EmptyAdditionModal onClick={goToNextStep}/>
                            </>
                          }
                          {
                            whichModalShow === 'maxAdditionValueExceeded' && <>
                              <MaxAdditionExceededModal onClick={goToNextStep} content={order[getAdditionIndex()].items.data}/>
                            </>
                          }
                          {
                            whichModalShow === DECOR_TYPE_NAME && <>
                              <DropZone onSubmit={onCustomDecorSubmit} content={itemForCustomDecor}/>
                            </>
                          }
                          </>
                        </Modal>
                      </>
                    }
                  </>
                  : <IndividualOrder />
                }
              </Col>
              {
                // OrderBox
                ( order.length > 0 && steps.content[steps.step] !== undefined && width > TABLET_WIDTH ) && <>
                  <Col xs={4}>
                    <IndividualOrder />
                  </Col>
                </>
              }
            </Row>
            {
              (width > TABLET_WIDTH && showPagination) && <>
                <Row>
                  <Col xs={12} >
                    { showMoreBtn && <>
                      <MainButton palette='primary' className='individual__btns-more' onClick={onShowMoreClick}>
                        {individualLang.btnsShowMore}
                      </MainButton>
                    </>
                    }
                    <Pagination
                      elementCount={countOfElements}
                      elementLimit={elementsOffset}
                      returnPageOffset={paginationLogick}
                      initialOffset={initialPaginationPage}
                    />
                  </Col>
                </Row>
              </>
            }
          </>
          : <>
            <Row>
              <Col>
                <div className='individual__content'>
                  <div className='individual__caption'>
                    <Row>
                      {
                        width > TABLET_WIDTH && <>
                          <Col xs={2}>
                            <MainButton  className='individual__btns-back' palette='secondary' onClick={previousStepButtonHandler}>
                              {individualLang.btnsBack}
                            </MainButton>
                          </Col>
                        </>
                      }
                      <Col xs={12} lg={8} className='text-center'>
                        <h3>{stepContent.title}</h3>
                      </Col>
                      {
                        width <= TABLET_WIDTH && <>
                          <Col xs={12}>
                            <div className='individual__btns-header-mobile'>
                              <MainButton  palette='secondary' className='btns--prev' onClick={previousStepButtonHandler}>
                                <PreviousArrowSVG />
                                <span>{individualLang.btnsBack}</span>
                              </MainButton>
                            </div>
                          </Col>
                        </>
                      }
                    </Row>
                  </div>
                  <Row className='justify-content-center'>
                    <Col xs={12} lg={8}>
                      <div className='individual-order complete'>
                        <div className='individual-order__complete'>
                          <IndividualOrderComplete
                            imgUrl = {category.images[0]}
                            type = {individualLang.mainTitle}
                            price = {orderAmount}
                            onChange = {changeCompleteAmount}
                            category={category}
                            onClose = {goToStartHandler}
                          />
                        </div>
                        <div className='individual-order__content'>
                          {orderWithoutNulls().map(item => <IndividualOrderItem
                            key={`order-${item.step}-${item.items.id}`}
                            content={item}
                            onDelete={deleteFromOrder}
                            steps={steps}
                            forComplete={true}
                            maxAdditionsValue={maxAdditionsValue}
                            additionsLength={additionsLength}
                            customInscriptionText={customInscriptionText}
                            customDecorImage={customDecorImage.base64string}
                          />)
                          }
                        </div>
                        <div className='individual-order__amount'>
                          { width <= TABLET_WIDTH &&
                            <span>{individualLang.orderBoxAmountComplete}</span>
                          }
                          {orderAmount}<small>{general.currency}</small>
                        </div>
                        <div className='individual-order__button-group'>
                          <MainButton  palette='secondary' onClick={goToStartHandler}>{individualLang.btnsClear}</MainButton>
                          <MainButton  palette='primary' onClick={addToBasket}>{individualLang.btnsAddToBasket}</MainButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        }
      </>
      : <Loader />
    }
    </Container>
  );
};

export default IndividualSteps;
