import React, { useState, useEffect, useContext } from 'react';
import './DeliveryForm.scss';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk'; // register it with the name you want
import classNames from 'classnames';

import { Context } from '../../../context';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import CheckBox from '../../CheckBox/CheckBox';
import MainButton from '../../../components/MainButton/MainButton';
import City from './NovaPoshta/City';
import DeliveryAndPaymentTypes from './NovaPoshta/DeliveryAndPaymentTypes';
import PostDepartment from './NovaPoshta/PostDepartment';
import { ReactComponent as RadioButtonSVG } from '../../../assets/icons/button-radio.svg';

import {
  recipient,
  getRecipientId,
  rewriteRecipient,
  getAllRecipients,
  calculateDeliveryConst,
} from '../../../utils/api';
import { getEmailFromLocalStorage, removeEmailFromLocalStorage } from '../../../utils/emailToLocalStorage';
import Street from './NovaPoshta/Street';
import PhoneMask from '../TelephoneWithMask/PhoneMask';
import CaptchaWidget from '../Captcha/CaptchaWidget';
registerLocale('uk', uk);

const DeliveryForm = ({
  changeStep,
  setPaymentTypes,
  notRegisterCustomerId,
  setRecipientId,
  setDepartureAt,
  setDesiredDeliveryDate,
  setComment,
  setTemplateName,
  setDeliveryPrice
}) => {
  const localRegestration = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
  const deliveryTemplates = localStorage.getItem('deliveryTemplates') ? JSON.parse(localStorage.getItem('deliveryTemplates')) : {};
  const localWithoutRegestration = localStorage.getItem('without-regestration') ? JSON.parse(localStorage.getItem('without-regestration')) : {};
  const { isLogined, blackTheme, staticLocalization, userData, langKey } = useContext(Context);
  const [isCaptchaValid, setCaptchaValid] = useState(false);

  const [formFieldsData, setFormFieldsData] = useState({
    firstName: localRegestration?.firstName ||
      (isLogined ? userData.firstName : (localWithoutRegestration.firstName || '')),
    lastName: localRegestration?.lastName ||
      (isLogined ? userData.lastName : (localWithoutRegestration.lastName || '')),
    // email: '',
    telephone: localRegestration?.telephone ||
      (isLogined ? userData.phone : (localWithoutRegestration.telephone || '')),
    address: localRegestration?.address || '',
    building: localRegestration?.building || '',
    city: localRegestration?.city || '',
    deliveryAndPaymentTypeData: localRegestration?.deliveryAndPaymentTypeData || '',
    department: localRegestration?.department || '',
    departureAt: new Date(),
    deliveryDateType: localStorage.getItem('deliveryDateType') || 'DEFAULT',
    desiredDeliveryDate: localStorage.getItem('desiredDeliveryDate') || null,
    comment: '',
    saveToTemplates: false,
    templateName: '',
    captchaToken: ''
  });

  const [saveToTemplates, setSaveToTemplates] = useState(false);
  const [deliveryAndPaymentTypeData, setDeliveryAndPaymentTypeData] = useState({});
  const [deliveryTemplatesOptions, setDeliveryTemplatesOptions] = useState([]);
  const [deliveryDateType, setDeliveryDateType] = useState('DEFAULT');
  const [captcha, setCaptcha] = useState('');

  const { deliveryForm, order, orders } = staticLocalization;
  const [phone, setPhone] = useState(false);
  const phoneRegex = /^\+(?:[0-9] ?){11,14}[0-9]$/; // Change this regex based on requirement
  const phoneIntRegex = /^(\+-|\+|0)?\d{16}$/; // Change this regex based on requirement

  useEffect(() => {
    getAllRecipients(notRegisterCustomerId).then(res => {
      return res.json();
    }).then(el => {
      const mapedDeliveryTemplatesOptions = [];
      const newDeliveryTemplatesOptions = el.result.map((item, id) => {
        return {
          value: `${deliveryForm.template} ${item.id}`,
          label: item.templateName || `${deliveryForm.template} №${id + 1}`,
          data: item
        };
      });
      mapedDeliveryTemplatesOptions.push(newDeliveryTemplatesOptions);
      setDeliveryTemplatesOptions(...mapedDeliveryTemplatesOptions);
    });

    if (deliveryTemplates?.data) {
      deliveryTemplateIsSelected(deliveryTemplates?.data, false, true);
    }
  }, []);


  // period of delivery in days num ({2}-3 days)
  const [startOfDeliveryPeriodDays, setStartOfDeliveryPeriodDays] = useState(null);

  // Load the value from localStorage
  const savedStartOfDeliveryPeriodDays = localStorage.getItem('startOfDeliveryPeriodDays');
  useEffect(() => {
    if (savedStartOfDeliveryPeriodDays) {
      setStartOfDeliveryPeriodDays(parseInt(savedStartOfDeliveryPeriodDays, 10));
    }
  }, []);

  // period of delivery in days string ({2-3 days})
  const [startOfDeliveryPeriodDaysString, setStartOfDeliveryPeriodDaysString] = useState(null);
  const [deliveryDesiredDate, setDeliveryDesiredDate] = useState(null);
  const [minDeliveryDesiredDate, setMinDeliveryDesiredDate] = useState(null);

  useEffect(() => {
    if (startOfDeliveryPeriodDays > 0) {
      generateDeliveryPeriodString(startOfDeliveryPeriodDays);
      generateDeliveryPeriodDaysDate(startOfDeliveryPeriodDays);

      // Save the value to localStorage
      localStorage.setItem('startOfDeliveryPeriodDays', startOfDeliveryPeriodDays.toString());
    }
  }, [startOfDeliveryPeriodDays]);

  const generateDeliveryPeriodString = (startOfDeliveryPeriodDays) => {
    setStartOfDeliveryPeriodDaysString(`${startOfDeliveryPeriodDays}-${startOfDeliveryPeriodDays + 1} ${deliveryForm.days}`);
  };

  const generateDeliveryPeriodDaysDate = (startOfDeliveryPeriodDays) => {
    const date = new Date();
    date.setDate(date.getDate() + startOfDeliveryPeriodDays + 1);
    setMinDeliveryDesiredDate(date);

    if (deliveryDesiredDate === null) {
      setDeliveryDesiredDate(date);
    }
  };

  const setDeliveryPeriodDaysDate = (date) => {
    setDeliveryDesiredDate(date);
  };

  const deliveryTemplateIsSelected = (selectedOption, setFieldValue, load) => {
    if (!load) {
      setFieldValue('city', '');
    }

    const { firstName, lastName, phone, address, building, deliveryType, deliveryServiceDepartment } = selectedOption;

    const { name, id, cityRef } = selectedOption.city;

    const city = {
      value: name,
      label: name,
      id,
      cityRef
    };

    const registration = localStorage.getItem('regestration') ? JSON.parse(localStorage.getItem('regestration')) : {};
    registration.city = city;
    localStorage.setItem('regestration', JSON.stringify(registration));
    console.log('load', load);
    console.log('selectedOption', selectedOption);
    console.log('localRegestration', localRegestration);

    const newFormFieldsData = {
      firstName: load && firstName ? firstName : localRegestration.firstName,
      lastName: load && lastName ? lastName : localRegestration.lastName,
      telephone: load && phone ? phone : localRegestration.phone,
      address: load && address ? address: localRegestration.address || '',
      building: load && building ? building : localRegestration.building || '',
      city: load && city ? city : localRegestration.city,
      deliveryAndPaymentTypeData: load && deliveryType ? deliveryType : localRegestration.deliveryAndPaymentTypeData || '',
      deliveryServiceDepartment: load && deliveryServiceDepartment ? deliveryServiceDepartment : localRegestration.deliveryServiceDepartment || '',
      department: '',
      departureAt: new Date(),
      desiredDeliveryDate: null,
      comment: '',
      saveToTemplates: false,
      templateName: '',
      captchaToken: ''
    };

    setFormFieldsData(newFormFieldsData);

    const deliveryTemplatesData = JSON.parse(localStorage.getItem('deliveryTemplates')).data;
    const getCalculatedDeliveryConst = new Promise((resolve, reject) => {
      calculateDeliveryConst(
        userData.id || notRegisterCustomerId,
        deliveryTemplatesData.city.id,
        deliveryTemplatesData.firstName,
        deliveryTemplatesData.lastName,
        'midName',
        deliveryTemplatesData.email,
        deliveryTemplatesData.phone,
        deliveryTemplatesData.deliveryType,
        deliveryTemplatesData.deliveryService.id,
        deliveryTemplatesData.address,
        deliveryTemplatesData.building,
        deliveryTemplatesData.deliveryServiceDepartment
      ).then(response => {
        response.ok ? resolve(response.json()) : resolve(null);
      });
    });

    Promise.all([getCalculatedDeliveryConst]).then((res) => {
      const [calculatedDeliveryConst] = res;

      setDeliveryPrice(calculatedDeliveryConst);
    });
  };

  const deliverySchemaVariants = () => {
    let validationSchema = Yup.object().shape({
      firstName: Yup.string().required(deliveryForm.nameError),
      lastName: Yup.string().required(deliveryForm.lastNameError),
      telephone: Yup.string()
        .required(deliveryForm.telephoneError)
        .test('test', deliveryForm.telephoneRegexError, (value) => {
          const isValidPhone = phoneRegex.test(value);
          const isValidPhoneInt = phoneIntRegex.test(value);
          if (phoneRegex.test(value)) {
            setPhone(true);
          }
          return !(!isValidPhone && !isValidPhoneInt);
        }),
      city: Yup.string().required(deliveryForm.lastNameError),
      deliveryAndPaymentTypeData: Yup.string().required(deliveryForm.lastNameError),
      saveToTemplates: Yup.boolean(),
      captchaToken: Yup.string().required('Captcha is required'),
      templateName: Yup.string().when('saveToTemplates', (saveToTemplates) => {
        if (!saveToTemplates) {
          return Yup.string();
        }

        //required if saveToTemplates checkbox is checked
        return Yup.string().required(deliveryForm.lastNameError);
      }),
    });

    if (deliveryAndPaymentTypeData.type === 'COURIER') {
      validationSchema = validationSchema.shape({
        address: Yup.string().required(deliveryForm.telephoneError),
        building: Yup.string().required(deliveryForm.telephoneError)
      });
    } else if (deliveryAndPaymentTypeData.type === 'PROVIDER') {
      validationSchema = validationSchema.shape({
        department: Yup.string().required(deliveryForm.lastNameError)
      });
    }

    return validationSchema;
  };

  const renderLastInput = (deliveryAndPaymentTypeData, props, errors, touched, setFieldValue, setFieldError) => {

    switch (deliveryAndPaymentTypeData.type) {
    case 'COURIER':
      return (
        <label className='last-input'>
          <div className='inputs-wrapper'>
            <div>
              <p className='required'>{deliveryForm.adress}</p>
              <Field type='text'
                name='address'
                placeholder={deliveryForm.adressPlaceholder}
                selectedCityId={props.values.city.id}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                component={Street} />
              {errors.address && touched.address ? ( // Error
                <div className='error'>{errors.address}</div>
              ) : null}
            </div>
            <div>
              <p className='required'>{deliveryForm.building}</p>
              <Field type='text' name='building' placeholder={deliveryForm.buildingPlaceholder} onKeyUp={handleChange} />
              {errors.address && touched.address ? ( // Error
                <div className='error'>{errors.address}</div>
              ) : null}
            </div>
          </div>
        </label>
      );
    case 'PROVIDER':
      return (
        <label>
          <p className='required'>{deliveryForm.department}</p>
          <Field
            name='department'
            component={PostDepartment}
            setFieldValue={(val) => setFieldValue('department', val)}
            setFieldError={setFieldError}
            setDeliveryPrice={val => setDeliveryPrice(val)}
            notRegisterCustomerId={notRegisterCustomerId}
          />

          {
            typeof props.values.department.value === 'string'
              && props.values.department.value.startsWith('Поштомат')
              && errors.department !== 'Postomat' ? (
                <>
                  <div className='error'>{deliveryForm.npPostomatAttention}
                    <span className='no-error'> {deliveryForm.npPostomatAttentionText}</span>
                  </div>
                </>
              ) : null
          }

          {errors.department === 'Postomat' ? (
            <>
              <div className='error'>
                {deliveryForm.npPostomatErrorTitle}
                <br />
                {deliveryForm.npPostomatErrorDescription}
              </div>
              <ul className='error-description'>
                <li> {deliveryForm.npPostomatSpecs}
                  {deliveryForm.npPostomatSpecsWidthLetter}: <span className='gold-text'>{deliveryForm.npPostomatSpecsWidth}</span>;
                  {deliveryForm.npPostomatSpecsLengthLetter}: <span className='gold-text'>{deliveryForm.npPostomatSpecsLength}</span>;
                  {deliveryForm.npPostomatSpecsHeightLetter}: <span className='gold-text'>{deliveryForm.npPostomatSpecsHeight}</span>;
                </li>
                <li>{deliveryForm.npPostomatMaxWeightTitle} <span className='gold-text'>{deliveryForm.npPostomatMaxWeight}</span></li>
              </ul>
            </>) : null}
        </label>
      );
    default:
      return null;
    }
  };

  const deliveryFormClassNames = classNames(
    'g-form-style',
    'delivery-form',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const theme = blackTheme ? 'dark' : 'light';

  const radioButtonsClasses = classNames(
    'radio-button',
    `radio-button--${theme}`
  );

  const radioBtnsOnChangeHandler = (name, value) => {
    setDeliveryDateType(value);
  };

  const numberFormat = (e) => {
    return e.target.value.replace(/-/g, '').replace('(', '').replace(')', '').replace(' ', '');
  };

  const handleChange = (event) => {
    console.log('langKey', langKey);

    const array = localRegestration;
    array[event.target.name] = event.target.value;
    localStorage.setItem('regestration', JSON.stringify(array));

    if (!array.address?.value || !array.building) {
      return;
    }

    const getCalculatedDeliveryConst = new Promise((resolve, reject) => {
      calculateDeliveryConst(
        userData.id || notRegisterCustomerId,
        array.city.id,
        array.firstName,
        array.lastName,
        'midName',
        array.email,
        array.telephone,
        array.deliveryAndPaymentTypeData.type,
        array.deliveryAndPaymentTypeData.id,
        array.address.value,
        array.building,
        null
      ).then(response => {
        response.ok ? resolve(response.json()) : resolve(null);
      });
    });

    Promise.all([getCalculatedDeliveryConst]).then((res) => {
      const [calculatedDeliveryConst] = res;

      setDeliveryPrice(calculatedDeliveryConst);
    });
  };

  return (
    <Formik
      initialValues={formFieldsData}
      validationSchema={deliverySchemaVariants()}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          firstName,
          lastName,
          telephone,
          address,
          building,
          city,
          deliveryAndPaymentTypeData,
          department,
          departureAt,
          comment,
          templateName,
          captchaToken
        } = values;

        let modeLogin;
        if (phone && (telephone.split('')[0] === 0)) {
          modeLogin = '+38' + telephone;
        } else {
          modeLogin = telephone;
        }
        const email = () => {
          if (isLogined) {
            return userData.email;
          }
          return getEmailFromLocalStorage();
        };

        const dateOnMiliseconds = new Date(departureAt).getTime();

        const setRecipient = new Promise((resolve, reject) => {
          recipient(
            isLogined,
            notRegisterCustomerId,
            city.id,
            firstName,
            lastName,
            'midName',
            email,
            modeLogin,
            saveToTemplates,
            deliveryAndPaymentTypeData.type,
            deliveryAndPaymentTypeData.id,
            address,
            building,
            department.deliveryServiceDepartment,
            templateName,
            captchaToken
          ).then(response => {
            response.ok ? resolve(response.json()) : resolve(null);
          });
        });

        const getCalculatedDeliveryConst = new Promise((resolve, reject) => {
          calculateDeliveryConst(
            userData.id || notRegisterCustomerId,
            city.id,
            firstName,
            lastName,
            'midName',
            email,
            modeLogin,
            // saveToTemplates,
            deliveryAndPaymentTypeData.type,
            deliveryAndPaymentTypeData.id,
            address,
            building,
            department.deliveryServiceDepartment
          ).then(response => {
            response.ok ? resolve(response.json()) : resolve(null);
          });
        });

        // set this date only if clicked on radio button at desiredDate datepicker
        const desiredDeliveryDate = deliveryDateType === 'DEFAULT' ? null : deliveryDesiredDate;

        Promise.all([setRecipient, getCalculatedDeliveryConst]).then((res) => {
          const [getedRecipientData, calculatedDeliveryConst] = res;

          if (!isLogined) {
            removeEmailFromLocalStorage();
          }

          if (getedRecipientData) {
            setPaymentTypes(deliveryAndPaymentTypeData.paymentTypes);
            setRecipientId(getedRecipientData.id);
            setDepartureAt(dateOnMiliseconds);
            setDesiredDeliveryDate(desiredDeliveryDate);
            setComment(comment);
            setTemplateName(templateName);
            setDeliveryPrice(calculatedDeliveryConst);
            changeStep('next');
          } else {
            getRecipientId(modeLogin.substr(1), notRegisterCustomerId).then(res => {
              return res.json();
            }).then(el => {
              rewriteRecipient(
                el.result[0].id,
                isLogined,
                notRegisterCustomerId,
                city.id,
                firstName,
                lastName,
                'midName',
                email,
                modeLogin,
                true,
                deliveryAndPaymentTypeData.type,
                deliveryAndPaymentTypeData.id,
                address,
                building,
                department.deliveryServiceDepartment,
                templateName
              ).then(res => {
                return res.json();
              }).then(el => {
                setPaymentTypes(deliveryAndPaymentTypeData.paymentTypes);
                setRecipientId(el.id);
                setDepartureAt(dateOnMiliseconds);
                setDesiredDeliveryDate(desiredDeliveryDate);
                setComment(comment);
                setTemplateName(templateName);
                setDeliveryPrice(calculatedDeliveryConst);
                changeStep('next');
              });
            });
          }
        });

      }}
    >
      {
        ({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          form,
          ...props
        }) => (

          <Form className={deliveryFormClassNames}>
            {deliveryTemplatesOptions.length > 0 && <label>
              <p>{deliveryForm.deliveryTemplates}</p>
              <Select
                name='delivery-templates'
                onChange={val => {
                  localStorage.setItem('deliveryTemplates', JSON.stringify(val));
                  deliveryTemplateIsSelected(val.data, setFieldValue, true);
                }
                }
                value={deliveryTemplates ? { label: deliveryTemplates?.label } : {}}
                placeholder={deliveryForm.deliveryTemplatesPlaceholder}
                className='drop-down'
                classNamePrefix='react-select'
                options={deliveryTemplatesOptions}
                noOptionsMessage={({ inputValue: string }) => deliveryForm.deliveryTemplatesNoOptionsMessage}
              />
            </label>}

            <label>
              <p className='required'>{deliveryForm.name}</p>
              <Field
                type='text'
                name='firstName'
                placeholder={deliveryForm.namePlaceholder}
                onKeyUp={handleChange}
              />
              {errors.firstName && touched.firstName ? ( // Error
                <div className='error'>{errors.firstName}</div>
              ) : null}
            </label>

            <label>
              <p className='required'>{deliveryForm.lastName}</p>
              <Field
                type='text'
                name='lastName'
                placeholder={deliveryForm.lastNamePlaceholder}
                onKeyUp={handleChange}
              />
              {errors.lastName && touched.lastName ? ( // Error
                <div className='error'>{errors.lastName}</div>
              ) : null}
            </label>

            <label>
              <p className='required'>{deliveryForm.telephone}</p>
              <PhoneMask handleChange={handleChange} handleBlur={handleBlur}
                placeholder={deliveryForm.telephonePlaceholder} value={props.values.telephone}>
              </PhoneMask>
              {errors.telephone && touched.telephone ? ( // Error
                <div className='error'>{errors.telephone}</div>
              ) : null}
            </label>

            <label>
              <p className='required'>{deliveryForm.city}</p>
              <Field
                name='city'
                placeholder={deliveryForm.city}
                component={City}
                setFieldValue={setFieldValue}
                localRegestration={localRegestration}
              />
              {errors.city && touched.city ? ( // Error
                <div className='error'>{errors.city}</div>
              ) : null}
            </label>


            {
              props.values.city !== '' ?
                <label>
                  <p className='required'>{deliveryForm.deliveryAndPaymentTypesPlaceholder}</p>
                  <Field
                    name='deliveryAndPaymentTypeData'
                    component={DeliveryAndPaymentTypes}
                    placeholder={deliveryForm.namePlaceholder}
                    setDeliveryAndPaymentTypeData={val => setDeliveryAndPaymentTypeData(val)}
                    setDeliveryPrice={val => setDeliveryPrice(val)}
                    setFieldValue={setFieldValue}
                    notRegisterCustomerId={notRegisterCustomerId}
                    setStartOfDeliveryPeriodDays={setStartOfDeliveryPeriodDays}
                  />
                  {errors.deliveryAndPaymentTypeData && touched.deliveryAndPaymentTypeData ? ( // Error
                    <div className='error'>{errors.deliveryAndPaymentTypeData}</div>
                  ) : null}
                </label>
                :
                null
            }

            {
              renderLastInput(props.values.deliveryAndPaymentTypeData, props, errors, touched, setFieldValue, setFieldError)
            }

            {/* if selected delivery service with regionWarehouseDelivery
            && selected street and building (for courier) or delivery department (nova poshta) */}
            {/*<div>{JSON.stringify(startOfDeliveryPeriodDays)}</div>*/}
            {/*<div>{JSON.stringify(props.values)}</div>*/}
            {startOfDeliveryPeriodDays > 0 && (
              props.values.deliveryAndPaymentTypeData.type === 'SELF_PICKUP' ||
              ((props.values.deliveryAndPaymentTypeData.type === 'COURIER' && props.values.address && props.values.building) ||
                (props.values.deliveryAndPaymentTypeData.type === 'PROVIDER' && props.values.department?.value))) ?
              <label className='radio-buttons-wrapper'>
                <p className='required'>{order.dateOfDelivery}</p>
                <label className={radioButtonsClasses}>
                  <input className='radio-button__input'
                    type='radio'
                    name='deliveryDate'
                    value='DEFAULT'
                    onClick={e => radioBtnsOnChangeHandler(e.target.name, e.target.value)}
                    readOnly
                    checked={deliveryDateType === 'DEFAULT'}
                  />
                  <div className='radio-button__box'>
                    <RadioButtonSVG />
                  </div>
                  <div className='radio-button__content'>
                    <div className='title'>
                      {startOfDeliveryPeriodDaysString}
                    </div>
                    <div className='description'>
                      {order.deliveryScheduleException}
                    </div>
                  </div>
                </label>
                <label className={radioButtonsClasses}>
                  <input className='radio-button__input'
                    type='radio'
                    name='deliveryDate'
                    value='CUSTOM'
                    onClick={(e) => radioBtnsOnChangeHandler(e.target.name, e.target.value)}
                    readOnly
                    checked={deliveryDateType === 'CUSTOM'}
                  />
                  <div className='radio-button__box'>
                    <RadioButtonSVG />
                  </div>
                  <div className='radio-button__content'>
                    <div className='title'>
                      <DatePicker
                        selected={deliveryDesiredDate}
                        dateFormat='dd/MM/yyyy'
                        minDate={minDeliveryDesiredDate}
                        className='form-control'
                        name='desiredDeliveryDate'
                        locale={ langKey }
                        onChange={date => {
                          //todo: reinit delivery date fields
                          console.log(langKey);
                          localStorage.setItem('minDeliveryDesiredDate', minDeliveryDesiredDate);
                          localStorage.setItem('deliveryDateType', deliveryDateType);
                          localStorage.setItem('deliveryDateType', deliveryDateType);
                          setDeliveryPeriodDaysDate(date);
                        }}
                      />
                    </div>
                    <div className='description'>
                      {order.changeDeliveryDate}
                    </div>
                  </div>
                </label>
              </label>
              :
              null
            }

            <label>
              <p>{deliveryForm.comment}</p>
              <Field
                as='textarea'
                name='comment'
                placeholder={deliveryForm.commentPlaceholder}
                onChange={(e) => setFieldValue('comment', e.target.value)}
                onKeyUp={handleChange}
                maxLength={1000}
              />
              {errors.comment && touched.comment ? ( // Error
                <div className='error'>{errors.comment}</div>
              ) : null}
            </label>

            {isLogined && <div className='save-to-templates'>
              <CheckBox isChecked={false} onChange={(name, value, checked) => {
                setFieldValue('saveToTemplates', checked).then(() => {
                  setSaveToTemplates(checked);
                });
              }}>
                <span>{deliveryForm.addToTemplates}</span>
              </CheckBox>
              {saveToTemplates &&
                <label>
                  <p className='required'>{deliveryForm.templateName}</p>
                  <Field
                    type='text'
                    name='templateName'
                    placeholder={deliveryForm.templateName}
                    onChange={(e) => setFieldValue('templateName', e.target.value)}
                    onKeyUp={handleChange}
                  />
                  {errors.templateName && touched.templateName ? ( // Error
                    <div className='error'>{errors.templateName}</div>
                  ) : null}
                </label>
              }
            </div>
            }

            <CaptchaWidget onCaptchaChange={setCaptchaValid} />

            <div className='foot-btn-group'>
              <MainButton type='button' palette='secondary' onClick={() => changeStep('prev')}>
                {order.goBack}
              </MainButton>

              <MainButton type='submit'
                palette='primary'
                onMouseDown={handleSubmit}
                disabled={!isCaptchaValid}>
                {deliveryForm.toOrder}
              </MainButton>
            </div>

          </Form>
        )
      }
    </Formik>
  );
};

DeliveryForm.propTypes = {
  values: PropTypes.any,
  changeStep: PropTypes.func,
  setPaymentTypes: PropTypes.func,
  notRegisterCustomerId: PropTypes.number,
  setRecipientId: PropTypes.func,
  setDepartureAt: PropTypes.func,
  setDesiredDeliveryDate: PropTypes.func,
  setComment: PropTypes.func,
  setTemplateName: PropTypes.func,
  setDeliveryPrice: PropTypes.func
};

export default DeliveryForm;
