import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { Context } from '../../../context';
import classNames from 'classnames';

// import { ReactComponent as ThemeSwitcherStandart } from '../../../assets/icons/change-theme--standart.svg' ;
import { ReactComponent as ThemeSwitcherBlack } from '../../../assets/icons/change-theme--black.svg' ;
import { ReactComponent as ThemeSwitcherLight } from '../../../assets/icons/change-theme--light.svg' ;


const ThemeSwitcher = ({setHardCloseSerchBar}) => {

    const { changeTheme, blackTheme } = useContext(Context);

    // const [themeNotChosen, setThemeNotChosen] = useState(true);

    useEffect(() => {
        const theme = sessionStorage.getItem('lms-theme');
        // setThemeNotChosen(theme ? false : true) 
    }, [])

    const handleClick = () => {
        changeTheme();
        setHardCloseSerchBar();
        // setThemeNotChosen(false);
    }

    const themeSwitcherClassNames = classNames(
        'theme-switcher',
        blackTheme ? 'black-theme' : 'light-theme'
    ) 

    return(
      <button type='button' onClick={handleClick} className={themeSwitcherClassNames}>
        {
          // themeNotChosen 
          //   ?
          //   <ThemeSwitcherStandart />
          //   :
            blackTheme ? <ThemeSwitcherBlack /> : <ThemeSwitcherLight />
        }
      </button>

    );
};

ThemeSwitcher.propTypes = {
    setHardCloseSerchBar: PropTypes.func
};

export default ThemeSwitcher;