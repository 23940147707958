import React, { useState, useEffect, useContext } from 'react';
import './SingleCandyPage.scss';
import { Helmet } from 'react-helmet';
import { Context } from '../../context';

import SliderContainer from '../../components/SliderContainer/SliderContainer';
import YouMayLikeIt from '../../components/YouMayLikeIt/YouMayLikeIt';
import Loader from '../../components/Loader/Loader';
import KitFilling from '../../components/KitFilling/KitFilling';
import MainButton from '../../components/MainButton/MainButton';

import { ReactComponent as PreviousArrowSVG } from './../../assets/icons/previous-arrow.svg';

import { getGoodsById, getGoodsBySlug } from '../../utils/api';
import { regexForGettingId } from '../../utils/regexForGettingId';
import { useLocation, useHistory } from 'react-router-dom';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';

import { scrollToTop } from '../../utils/scrollToTop';

const SingleCandyPage = ({ onItemBackClick, otherContent, onClick, buttonTitle }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemData, setItemData] = useState([]);

  const { curentLanguageId, staticLocalization } = useContext(Context);
  const location = useLocation();
  const history = useHistory();
  
  useEffect(() => {
    setIsLoaded(false);
    getGoodsBySlug(history.location.pathname.split('/')[2]).then(res => {
      return res.json();
    }).then(item => {
      setItemData(item[0]);
      setIsLoaded(true);
    });
    scrollToTop(0, 'auto');
  }, [location, history]);

  const metaTitle = getTranslation(itemData, modifidedItemWithTranslationField(itemData), 'metaTitle' , curentLanguageId);
  const metaDescription = getTranslation(itemData, modifidedItemWithTranslationField(itemData), 'metaDescription' , curentLanguageId);

  const renderPage = () => {
    if (isLoaded && itemData) {
      return (
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name='description' content={metaDescription} data-react-helmet='true'/>
          </Helmet>
          { onItemBackClick &&
            <MainButton palette='secondary' onClick={onItemBackClick} className='btns--top-prev'>
              <PreviousArrowSVG /> <span>{staticLocalization.boxesLang.btnsBack}</span>
            </MainButton>
          }
          <SliderContainer data={itemData} onClick={onClick} buttonTitle={buttonTitle} otherContent={otherContent}/>
          {itemData.isSet ? <KitFilling arrOfElem={itemData.setGoods}/> : null}
          { otherContent !== 'fillingBox' &&
            <YouMayLikeIt tags={itemData.tags} categoryId={itemData.category}/>
          }
        </>
      );
    }
    return (
      <div className='main-loader'>
        <Loader/>
      </div>
    );
  };

  return (
    <div className='kits-container'>
      {renderPage()}
    </div>
  );
};


export default SingleCandyPage;
