export const getEllementOffset = (width, xl, l, m, sm) => {

  if(width <= 1700 && width > 1360 ){
    return l;
  }else if(width <= 1360 && width > 991.5){
    return m;
  }
  else if(width <= 991.5){
    return sm;
  }
  return xl;
};

export const getEllementOffsetForTemplates = (width, xxl, xl, l,  m, sm) => {

  if(width <= 1700 && width > 1360 ){
    return xl;
  }else if(width <= 1360 && width > 1200){
    return l;
  }
  else if(width <= 1200 && width > 991.5){
    return m;
  }
  else if(width <= 991.5){
    return sm;
  }
  return xxl;
};

export const getEllementOffsetForPersonalOffiseTemplates = (width, xl, l,  m, sm) => {
  if(width <= 1439.5 && width > 1199.5 ){
    return l;
  }
  else if(width <= 1199.5 && width > 991.5){
    return m;
  }
  else if(width <= 991.5){
    return sm;
  }
  return xl;
};