import React, { useState, useEffect, useContext } from 'react';
import './SecondStepTemplate.scss';
import PropTypes from 'prop-types';
import { Context } from '../../../context';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import MainButton from '../../../components/MainButton/MainButton';
import SignUpForm from '../../../components/Forms/SignUpForm/SignUpForm';
import LogInForm from '../../../components/Forms/LogInForm/LogInForm';
import WithoutRegestration from '../../../components/Forms/WithoutRegestration/WithoutRegestration';
import PhoneVerification from '../../../components/Forms/PhoneVerification/PhoneVerification';
import RecoverPassword from '../../../components/Forms/RecoverPassword/RecoverPassword';
import MainBatton from '../../../components/MainButton/MainButton';

import { ReactComponent as GoogleIcon } from '../../../assets/icons/google-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-icon.svg';
import { ReactComponent as LokalIcon } from '../../../assets/icons/lokal.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';

import { useHistory } from 'react-router-dom';
import LokalLoginPage from '../../../components/LokalLoginPage/LokalLoginPage';

const SecondStepTemplate = ({
  handleChangeStap,
  type,
  setNotRegisterCustomerId,
  setIsWhichForm,
  changeStepLookAfterLoading
}) => {

  const [whichForm, setWhichForm] = useState(true);
  const [registred, setRegistred] = useState(true);
  const [showRecoverPasswordForm, setShowRecoverPasswordForm] = useState(false);
  const [verifyByTelephone, setVerifyByTelephone] = useState(false);
  const [verifyByTelephoneType, setVerifyByTelephoneType] = useState('');
  const [data, setData] = useState({});

  const { blackTheme, staticLocalization } = useContext(Context);
  const { secondOrderStep, order } = staticLocalization;

  const [emailLoginOrLocal, setEmailLoginOrLocal] = useState('localLogin');

  const classes = classNames(
    'second-step__wrapper',
    blackTheme ? 'black-theme' : 'light-theme',
    type === 'user' ? 'second-step__wrapper--user' : null
  );

  const formsWrapperClasses = classNames(
    'forms-wrapper',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  useEffect(() => {
    if (setIsWhichForm) setIsWhichForm(whichForm);
  }, [whichForm]);


  const handleSocialRegister = (type) => {
    localStorage.setItem('social-register', type);
    window.location.replace(`https://demo-chocolate.luxnet.io/api/users/${type === 'GOOGLE' ? 'google' : 'facebook'}`);
  };

  const SocialRegister = () => {
    const query = new URLSearchParams(window.location.search);
    const error = query.get('error') ? query.get('error') : false;
    const history = useHistory();

    return (
      <div className='social-register'>
        <p>{window.innerWidth > 991 ? secondOrderStep.logInThroughSocialNetworks : secondOrderStep.mobileLogInThroughSocialNetworks}</p>

        <MainBatton className='btns btns--secondary' onClick={() => {
          setEmailLoginOrLocal(emailLoginOrLocal === 'localLogin' ? 'emailLogin' : 'localLogin');
        }}>
          {
            emailLoginOrLocal === 'localLogin' ?
              <EmailIcon /> :
              <LokalIcon />
          }
          {
            emailLoginOrLocal === 'localLogin' ?
              <span>Email</span> :
              <span>Local</span>
          }
        </MainBatton>

        <MainBatton className='btns btns--secondary' onClick={() => handleSocialRegister('GOOGLE')}>
          <GoogleIcon />
          <span>Google</span>
        </MainBatton>

        <MainBatton className='btns btns--secondary' onClick={() => handleSocialRegister('FACEBOOK')}>
          <FacebookIcon />
          <span>Facebook</span>
        </MainBatton>

        {error && error === 'fb' ? <div className='error'>{secondOrderStep.fbError}</div> : ''}

      </div>
    );
  };

  const showVerifyForm = (val, type) => {
    setVerifyByTelephone(val);
    setVerifyByTelephoneType(type);
  };

  const changeForm = whichForm ?
    <LogInForm
      changeStep={(val) => handleChangeStap(val)}
      showRegisterForm={() => setWhichForm(false)}
      showRecoverPasswordForm={(val) => setShowRecoverPasswordForm(val)}
      formID={type === 'user' ? '1' : '2'}
      changeStepLookAfterLoading={() => changeStepLookAfterLoading()}
    />
    :
    <SignUpForm
      changeStep={(val) => handleChangeStap(val)}
      showRegisterForm={() => setWhichForm(true)}
      showVerifyForm={(val, type) => showVerifyForm(val, type)}
      formID={type === 'user' ? '1' : '2'}
      data={data}
      setData={setData}
    />;

  const RegistredOrNot = () => {
    if (registred) {
      return (
        <>
          <div className='second-step__registered'>
            <div className={formsWrapperClasses}>
              <div className='forms'>
                {
                  emailLoginOrLocal === 'localLogin' ?
                    <LokalLoginPage
                      changeStep={(val) => handleChangeStap(val)}
                      formID={type === 'user' ? '1' : '2'}
                      changeStepLookAfterLoading={() => changeStepLookAfterLoading()}
                    /> :
                    changeForm
                }
              </div>
              <SocialRegister />
            </div>
            {type === '2' ?
              <div className='foot-btn-group'>
                <MainButton palette='secondary' onClick={() => handleChangeStap('prev')}>
                  {order.goBack}
                </MainButton>
              </div>
              :
              null
            }
          </div>
        </>
      );
    }
    return (
      <div className='without-regestration'>
        <WithoutRegestration changeStep={(val) => handleChangeStap(val)}
          setNotRegisterCustomerId={val => setNotRegisterCustomerId(val)} />
      </div>
    );
  };

  return (
    <div className='second-step'>
      <div className={classes}>
        {
          type === 'user' ?
            null
            :
            <div className='form-toogler'>
              <button
                className={(registred) ? 'active' : null}
                onClick={() => setRegistred(true)}
              >{secondOrderStep.regularCustomer} <span>({secondOrderStep.regularCustomerRegestration})</span></button>

              <button
                className={(!registred) ? 'active' : null}
                onClick={() => setRegistred(false)}
              >{secondOrderStep.buyWithoutRegistration}</button>

            </div>
        }
        <RegistredOrNot type={type} />
        {
          verifyByTelephone ?
            <div className='phone-verification-form-wrapper'>
              <PhoneVerification data={data} showVerifyForm={(val, type) => showVerifyForm(val, type)}
                showRegisterForm={() => setWhichForm(true)} type={verifyByTelephoneType} />
            </div>
            :
            null
        }

        {
          showRecoverPasswordForm ?
            <div className='recover-password-form-wrapper'>
              <RecoverPassword showVerifyForm={(val, type) => showVerifyForm(val, type)}
                showRecoverPasswordForm={(val) => setShowRecoverPasswordForm(val)} />
            </div>
            :
            null
        }
      </div>
    </div>
  );
};

SecondStepTemplate.propTypes = {
  handleChangeStap: PropTypes.func,
  type: PropTypes.string,
  setNotRegisterCustomerId: PropTypes.func,
  changeStepLookAfterLoading: PropTypes.func,
  setIsWhichForm: PropTypes.func
};

export default withRouter(SecondStepTemplate);
