export const getCustomInscriptionPrice = (settings, text) => {
  const textLength = text.replace(/\s/g, '').length;
  const rangeIndex = settings.findIndex(item => (textLength >= item.minLetters && textLength <= item.maxLetters));
  return rangeIndex > -1 ? settings[rangeIndex].price : settings[settings.length - 1].price;
}

export const getCustomInscriptionFullPrice = (price, text) => {
  const textLength = text.replace(/\s/g, '').length;
  return price * textLength;
}

export const getCustomInscriptionAmount = (settings, text) => {
  const textLength = text.replace(/\s/g, '').length;
  const rangeIndex = settings.findIndex(item => (textLength >= item.minLetters && textLength <= item.maxLetters));
  const price = rangeIndex > -1 ? settings[rangeIndex].price : settings[settings.length - 1].price;
  
  return price; //price * textLength
}