import React from 'react';
import Home from '../containers/HomePage/Home';
import CategoryGroupPage from '../containers/CategoryGroupPage/CategoryGroupPage';
import Individual from '../containers/Individual/Individual';
import IndividualSteps from '../containers/Individual/IndividualSteps';
import SingleCandyPage from '../containers/SingleCandyPage/SingleCandyPage';
import Collection from '../containers/Сollection/Collection';
import SingleCollection from '../containers/SingleCollection/SingleCollection';
import Candy from '../containers/Candy/Candy';
import Basket from '../containers/Basket/Basket';
import Order from '../containers/Order/Order';
import OrderStatus from '../containers/Order/OrderStatus';
import Kit from '../containers/Kit/Kit';
import Profile from '../containers/User/Profile';
import ProfilePersonal from '../containers/User/ProfilePersonal';
import ProfileOrders from '../containers/User/ProfileOrders';
import ProfileOrdersDetail from '../containers/User/ProfileOrdersDetail';
import ProfileLocal from '../containers/User/ProfileLocal';
import ProfileLiked from '../containers/User/ProfileLiked';
import ProfileTemplate from '../containers/User/ProfileTemplate';
import NotFound from '../containers/NotFound/NotFound';
import StaticPage from '../containers/StaticPage/StaticPage';
import Boxes from '../containers/Boxes/Boxes';
import MostInterestingIndividual from '../containers/MostInterestingIndividual/MostInterestingIndividual';
import LogInForm from '../components/Forms/LogInForm/LogInForm';

export const ROUTES = [
  {
    path: '/',
    component: () => (<Home/>)
  },
  {
    path: '/category-group/:slug',
    component: () => (<CategoryGroupPage/>)
  },
  {
    path: '/category/:slug',
    component: () => (<Candy/>)
  },
  {
    path: '/product/:slug',
    component: () => <SingleCandyPage/>
  },
  {
    path: '/individual',
    component: () => <Individual/>
  }, {
    path: '/individual/:slug',
    component: () => <IndividualSteps/>
  },
  {
    path: '/collections',
    component: () => <Collection/>
  },
  {
    path: '/collections/:slug',
    component: () => <SingleCollection/>
  },
  {
    path: '/kits',
    component: () => <Kit/>
  },
  {
    path: '/basket',
    component: () => <Basket/>
  },
  {
    path: '/profile',
    component: () => <Profile/>
  },
  {
    path: '/profile/personal',
    component: () => <ProfilePersonal/>
  },
  {
    path: '/profile/orders',
    component: () => <ProfileOrders/>
  },
  {
    path: '/profile/orders/:id',
    component: () => <ProfileOrdersDetail/>
  },
  {
    path: '/profile/local',
    component: () => <ProfileLocal/>
  },
  {
    path: '/profile/liked',
    component: () => <ProfileLiked/>
  },
  {
    path: '/profile/template',
    component: () => <ProfileTemplate/>
  },
  {
    path: '/order',
    component: () => <Order/>
  },
  {
    path: '/order-status/:id',
    component: () => <OrderStatus/>
  },
  {
    path: '/collect-box',
    component: () => <Boxes/>
  },
  {
    path: '/most-interesting',
    component: () => <MostInterestingIndividual/>
  },
  {
    path: '/:slug',
    component: () => <StaticPage/>
  },
  {
    component: () => <NotFound/>
  }
];