import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../../context';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import MainTitle from '../../components/MainTitle/MainTitle';
import ProfileMenu from './ProfileMenu';
import MainButton from '../../components/MainButton/MainButton';
import Loader from '../../components/Loader/Loader';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { ReactComponent as PrevIcon } from './../../assets/icons/previous-arrow.svg';
import './Profile.scss';

import { useWindowSize } from '../../utils/useWindowSizeHook';
import { imagesUrl, getUserOrders } from '../../utils/api';
import { scrollToTop } from '../../utils/scrollToTop';

const ProfileOrdersListItem = ({ content, itemIndex }) => {

  const { staticLocalization, settings } = useContext(Context);
  const { general, personalOfficeMyOrders } = staticLocalization;

  const { id, status, images, totalPrice, orderNumber } = content;

  const currency = general.currency;
  const orderButtonTitle = personalOfficeMyOrders.readMore;

  const { url } = useRouteMatch();

  const getStatus = () => {
    switch(status){
    case 'NEW':
      return personalOfficeMyOrders.statusNEW;
    case 'PENDING_PAYMENT':
      return personalOfficeMyOrders.statusPENDING_PAYMENT;
    case 'CANCELED':
      return personalOfficeMyOrders.statusCANCELED;
    case 'ACCEPTED':
      return personalOfficeMyOrders.statusACCEPTED;
    case 'AWAITING_RECEIVING':
      return personalOfficeMyOrders.statusAWAITING_RECEIVING;
    case 'SENT':
      return personalOfficeMyOrders.statusSENT;
    case 'RECEIVED':
      return personalOfficeMyOrders.statusRECEIVED;
    case 'REFUSED':
      return personalOfficeMyOrders.statusREFUSED;
    case 'FINISHED':
      return personalOfficeMyOrders.statusFINISHED;
    default: return;
    }
  };

  return (
    <div className='profile-orders__list-item'>
      <div className='profile-orders__list-subject g-like-h6'>
        <small className='g-like-p14'>{getStatus()}</small>
        {`${personalOfficeMyOrders.order} №${orderNumber}`}
      </div>
      <div className='profile-orders__list-images'>
        {
          images.map((item, id) => {
            return(
              <div className='profile-orders__list-image' key={`orderNumber=${orderNumber}&img=${id}`}>
                <img className='img-fluid' src={`${imagesUrl}${item}?v=${settings.frontendVersion}`} alt='order-item'/>
              </div>
            );
          })
        }
      </div>
      <div className='profile-orders__list-next'>
        <div className='price'>
          {totalPrice}
          <small>{currency}</small>
        </div>
        <div className='profile-orders__list-button'>
          <Link to={`${url}/${id}`}>
            <MainButton palette='primary'>
              {orderButtonTitle}
            </MainButton>
          </Link>
        </div>
      </div>
    </div>
  );
};
function ProfileOrders({ history }) {

  const { blackTheme, isLogined, staticLocalization } = useContext(Context);
  const { personalOffice, personalOfficeMyOrders } = staticLocalization;
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';

  const subtitle = personalOfficeMyOrders.subTitle;
  const backButtonTitle = personalOfficeMyOrders.backButton;

  const { width } = useWindowSize();
  const LAPTOP = 991;

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [orderIsEmpty, setOrderIsEmpty] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderListData, setOrderListData] = useState([]);

  useEffect(() => {

    scrollToTop();

    if(!isLogined){
      history.push('/profile');
    }

    if (showMenu !== false) {
      setShowMenu(false);
    }

  }, [location.key]);

  useEffect(() => {

    getUserOrders().then(res => {
      return res.json();
    }).then(el => {
      setOrderListData(el.result);
      setIsLoaded(true);
      setOrderIsEmpty(el.count < 1);
    });

  }, []);

  const showMenuHandler = ()=> {
    setShowMenu(true);
  };

  return (
    <>
      <Helmet>
        <title>
          Особистий кабінет у інтернет-крамниці Львівської майстерні шоколаду
        </title>
        <meta
          name='description'
          content='Особистий кабінет ❖ Історія замволень у інтернет-магазині ❖ Система лояльності ❖ Покупки на сайті Львівської майстерні шоколаду'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className={`profile-orders ${getTheme}`}>
        <Row>
          <Col xs={12}>
            <MainTitle>
              {personalOffice.mainTitle}
            </MainTitle>
          </Col>
        </Row>
        { width <= LAPTOP && showMenu !== true && <>
          <Row>
            <Col xs={12} className='d-flex justify-content-center '>
              <MainButton palette='secondary' className='profile__btns-previous' onClick={showMenuHandler}>
                <PrevIcon className='profile__icon-previous'/>
                <span>{backButtonTitle}</span>
              </MainButton>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={12} >
            { ((width <= LAPTOP && !showMenu ) || width > LAPTOP)  && <>
              <h3 className='profile__subtitle'>{subtitle}</h3>
            </>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='profile-orders__wrapper'>
              { ((width <= LAPTOP && showMenu ) || width > LAPTOP) && <>
                <div className='profile__menu-wrapper'>
                  <ProfileMenu />
                </div>
              </>
              }
              { ((width <= LAPTOP && !showMenu ) || width > LAPTOP) ?
                <div className='profile-orders__list'>
                  {
                    isLoaded ?
                      <>
                        {
                          orderIsEmpty ?
                            <div className='emptyOrderList'>
                              <h3>{personalOffice.emptyOrderList}</h3>
                            </div>
                            :
                            orderListData.map(item => <ProfileOrdersListItem key={`order-item=${item.id}`} content={item}/>)
                        }
                      </>
                      :
                      <Loader />
                  }
                </div>
                :
                null
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}


ProfileOrdersListItem.propTypes = {
  content: PropTypes.object,
  itemIndex: PropTypes.number
};

ProfileOrders.propTypes = {
  history: PropTypes.object
};

export default withRouter(ProfileOrders);
