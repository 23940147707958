import React, { useState, useEffect, useRef, useContext } from 'react';
import './MainItem.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Context } from '../../context';
import { imagesUrl, addGoodsToBasket } from '../../utils/api';
import {
  modifidedItemWithTranslationField,
  getTranslation,
  getCategoryName
} from '../../utils/renderFieldWithTransition';
import Counter from '../Counter/Counter';
import MainButton from '../MainButton/MainButton';
import Like from '../Like/Like';
import BasketAddingAnimation from '../BasketAddingAnimation/BasketAddingAnimation';

import ErrorImg from '../../assets/images/candy-1--error.png';

const MainItem = ({
  item,
  withDescription,
  withCounter, buttonLink,
  categoryId,
  onClick = null,
  hideCount,
  buttonTitle,
  additionalClass,
  target,
  relAttr,
  isLikedProp,
  setIsLikedProp,
  onItemClick
}) => {

  const parentRef = useRef();
  const imageRef = useRef();

  const [data, setData] = useState(item);
  const [isLiked, setIsLiked] = useState(item);
  const [counterValue, setCounterValue] = useState(1);
  const [img, setImg] = useState('');
  const [showAnimation, setShowAnimation] = useState(false);

  const {
    settings,
    isLogined,
    blackTheme,
    changeBasketItemsLength,
    staticLocalization,
    curentLanguageId,
    ...getCategories
  } = useContext(Context);

  const name = getTranslation(item, data, 'name', curentLanguageId);
  const description = getTranslation(item, data, 'shortDescription', curentLanguageId);
  const category = getCategoryName(item.category, getCategories.categories, curentLanguageId);
  const { id, isInWishlist, slug, price } = item;

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(item);
    setData(newData);

    const technicalImg = data.images.find(el => el.includes('technical'));
    setImg(technicalImg || data.images[0]);

  }, [isLiked]);

  const onMainButtonClickHandler = (e) => {
    if (onClick) {
      onClick(item);
    } else {
      addGoodsToBasket(isLogined, item, +counterValue, changeBasketItemsLength);
    }
    setShowAnimation(!showAnimation);
  };

  const onItemClickHandler = () => {
    onItemClick(item);
  };

  const AddCounter = () => {
    if (withCounter) {
      return (
        <div className="add-to-cart">
          {hideCount || <Counter counterValueChanged={(val) => setCounterValue(+val)}/>}
          <MainButton className="btns--main" onClick={onMainButtonClickHandler}>
            {buttonTitle || staticLocalization.homePage.mainItemBtnText}
          </MainButton>
        </div>
      );
    }
    return null;
  };

  const AddDescription = () => {
    if (withDescription) {
      return (
        <div className='description'>
          {description}
        </div>
      );
    }
    return null;
  };

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  const classes = classNames(
    'main-item',
    { withCounter: 'main-item--without-counter' },
    whatTheme,
    additionalClass
  );

  const handleLiked = (id) => {
    setIsLiked(id);
    setIsLikedProp(id);
  };

  const imageUrl = img ? imagesUrl + img + '?v' + settings.frontendVersion : '';

  return (
    <div className={classes} ref={parentRef}>
      <div className="wrapper">
        <div className="link" onClick={onItemClick ? onItemClickHandler : null}>
          {!onItemClick &&
          <Link to={{
            pathname: `${buttonLink}${slug}`,
            state: item
          }}
                target={target}
                rel={relAttr}
          >
          </Link>
          }
        </div>
        <div className='like-btn' onClick={() => handleLiked(id)}>
          <Like goodsId={id} isInWishlist={isInWishlist}/>
        </div>
        <div className='img-wrapper'>
          <img src={imageUrl} alt='Candy' onError={(e) => {
            e.target.onerror = null;
            e.target.src = ErrorImg;
          }} ref={imageRef}/>
          <BasketAddingAnimation parentRef={parentRef} imageRef={imageRef} showAnimation={showAnimation}/>
        </div>
        {AddCounter()}
        <div className='name-and-price'>
          <div className='name'>
            <p>
              <small>
                {category}
              </small>
              {name}
            </p>
          </div>
          <div className='price'>
            <p>
              {price}
              <small>{staticLocalization.general.currency}</small>
            </p>
          </div>
        </div>
        <AddDescription/>
      </div>
    </div>
  );
};

MainItem.propTypes = {
  item: PropTypes.object,
  withDescription: PropTypes.bool,
  withCounter: PropTypes.bool,
  buttonLink: PropTypes.string,
  categoryId: PropTypes.number,
  onClick: PropTypes.func,
  hideCount: PropTypes.bool,
  buttonTitle: PropTypes.string,
  additionalClass: PropTypes.string,
  target: PropTypes.string,
  relAttr: PropTypes.string
};

MainItem.defaultProps = {
  item: {},
  withDescription: false,
  withCounter: false,
  buttonLink: '404',
  categoryId: 0,
  additionalClass: '',
  target: '_self',
  relAttr: ''
};
export default MainItem;
