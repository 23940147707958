import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getTranslation, getCategoryName, modifidedItemWithTranslationField } from '../../utils/renderFieldWithTransition';
import { imagesUrl } from '../../utils/api';
import './IndividualItem.scss';

import MainButton from '../MainButton/MainButton';
import Counter from '../Counter/Counter';

import { ReactComponent as RightArrow } from './../../assets/icons/arrow-to-right.svg';
import { ReactComponent as LikeCheckedIcon } from './../../assets/icons/like-checked.svg';
import { ReactComponent as LikeUncheckedIcon } from './../../assets/icons/like-unchecked.svg';
import ErrorImg from '../../assets/images/candy-1--error.png';

import { useWindowSize } from '../../utils/useWindowSizeHook';

import { Context } from '../../context';

const IndividualStepsItem = ({
  content,
  onClick,
  buttonTitle,
  itemType,
  step,
  removeArrow,
  addCounter,
  href,
  addLikeButton,
  category
}) => {
  
  const [data, setData] = useState(content);

  useEffect(() => {
    const newData = modifidedItemWithTranslationField(content);
    setData(newData);
  }, []);

  const [ liked, setLiked ] = useState(false);
  const { settings, blackTheme, curentLanguageId, staticLocalization, ...getCategories } = useContext(Context);
  const getTheme = blackTheme ? 'black-theme' : 'ligth-theme';
    
  const { width } = useWindowSize();

  const MOBILE_WIDTH = 575;

  const RemoveArrow = removeArrow ? null : <RightArrow />;

  // TODO: Добавити логіку каунтера на сторінці комплектів
  const RenderCounter =  addCounter ? <Counter counterValueChanged={()=>null}/> : null;

  const RenderLink =  () => {
    if(href){
      return (
        <Link className='img-wrapper' to={{
          pathname: href,
          state: content
        }}>
          <img src={imgUrl} onError={(e)=>{e.target.onerror = null; e.target.src=ErrorImg;}}/>
        </Link>
      );
    }
    return(
      <div className='img-wrapper' to={href}>
        <img src={imgUrl}  onError={(e)=>{e.target.onerror = null; e.target.src=ErrorImg;}}/>
      </div>
    );
  };

  const onClickHandler = () => {
    onClick(content, step, itemType);
  };
    

  const LikeButton = () => {
    if(addLikeButton){
      return(
        <button type='button' onClick={()=>setLiked(!liked)}>
          {liked ? <LikeCheckedIcon /> : <LikeUncheckedIcon />}
        </button>
      );
    }
    return null;
  };

  const imgUrl = `${imagesUrl}${content.images[0]}?v=${settings.frontendVersion}`;
  const categoryName = category;
  const name = getTranslation(content, data, 'name', curentLanguageId);
  // content?.settings - array with price for individual steps after shape
  const settingsPrice = content?.settings 
  ? content.settings[0].price > 0 ? `+${content.settings[0].price}` : content.settings[0].price
  : null;
  const price = settingsPrice ? settingsPrice : content.price;
  const description = content.description ? getTranslation(content, data, 'shortDescription', curentLanguageId) : null;
  const currency = staticLocalization.general.currency;

  return (
    <div className={`individual-item ${getTheme}`} >
      <div className='border-wrapper'></div>
      <div className='wrapper'>
        <div className='like-btn'>
          <LikeButton />
        </div>
        <RenderLink />
        { width > MOBILE_WIDTH &&
                    <div className='next-and-counter'>
                      {RenderCounter}
                      <div className='next'>
                        <MainButton palette='primary' onClick={onClickHandler}>
                          {buttonTitle}
                          {RemoveArrow}
                        </MainButton>
                      </div>
                    </div>
        }
        <div className='name-and-price'>
          <div className='name'>
            <p>
              <small>
                {categoryName}
              </small>
              {name}
            </p>
          </div>
          { !content?.isCustomInscription && <>
          <div className='price'>
            <p>
              {price}
              <small>{currency}</small>
            </p>
          </div>
          </>
          }
        </div>
        <div className='description'>
          {/* <p >{description}</p> */}
          <div className='text' dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
        { width <= MOBILE_WIDTH &&
                    <div className='next-and-counter'>
                      {RenderCounter}
                      <div className='next'>
                        <MainButton palette='primary' onClick={onClickHandler}>
                          {buttonTitle}
                          {RemoveArrow}
                        </MainButton>
                      </div>
                    </div>
        }
      </div>
    </div>
  );
};

export default IndividualStepsItem;