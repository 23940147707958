import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import './Like.scss';
import classNames from 'classnames';
import { Context } from '../../context';

import { setWishlistItem, getWihsList } from '../../utils/api';

import { ReactComponent as LikeCheckedIcon } from './../../assets/icons/like-checked.svg';
import { ReactComponent as LikeUncheckedIcon } from './../../assets/icons/like-unchecked.svg';

const Like = ({ goodsId, history, isInWishlist }) => {

  const [liked, setLiked] = useState(isInWishlist);

  const { isLogined } = useContext(Context);

  const checkIfLiked = liked ? null : 'unliked';

  const LinkClasses = classNames(
    'like',
    checkIfLiked
  );

  const handleLike = () => {
    setLiked(!liked);
    if(isLogined){
      setWishlistItem(goodsId);
    }else{
      history.push('/profile');
    }
  };

  return(
    <button onClick={() => handleLike()} className={LinkClasses}>
      { liked
        ? <LikeCheckedIcon />
        : <LikeUncheckedIcon />
      }
    </button>
  );
};

export default withRouter(Like);