import React, { useState, useEffect, useContext } from 'react';
import './Basket.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import {
  useLastLocation
} from 'react-router-last-location';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Context } from '../../context';

import { getGeneralPrice } from '../../utils/getGeneralPrice';
import { changeBasketItemsQuantity, removeItemsAfterSuccessOrder } from '../../utils/basketItems';
import { getGoodsForBasket, deleteAllGoodsFromBasket } from '../../utils/api';

import MainTitle from '../../components/MainTitle/MainTitle';
import MainButton from '../../components/MainButton/MainButton';
import BasketItem from '../../components/BasketItem/BasketItem';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import ConfirmDeleteItem from '../../components/BasketItem/ConfirmDeleteItem';

let items = {};
let basketItemIds = [];

const Basket = ({ history }) => {
  const lastLocation = useLastLocation();
  const [newData, setNewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [basketIsEmpty, setBasketIsEmpty] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLogined,
    blackTheme,
    generalPrice,
    changeGeneralPrice,
    basketItemsLength,
    changeBasketItemsLength,
    staticLocalization
  } = useContext(Context);

  const { basket } = staticLocalization;
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let getedGeneralPrice;
    if (isLogined) {
      getGoodsForBasket().then(res => {
        return res.json();
      }).then(el => {
        basketItemIds = el.result.map(item => item.itemEntity.id);

        setNewData(el.result);
        getedGeneralPrice = getGeneralPrice(isLogined, el.result);
        changeGeneralPrice(getedGeneralPrice);
        setBasketIsEmpty(el.count <= 0);
        setIsLoaded(true);
      });
    } else {
      const localData = localStorage.getItem('lmsh-basket'); // get data from local storage
      const getedData = JSON.parse(localData);
      setIsLoaded(true);
      setNewData(getedData || []);

      // TODO check
      getedGeneralPrice = getGeneralPrice(isLogined, getedData);
      changeGeneralPrice(getedGeneralPrice);

      if (getedData !== null && Array.isArray(getedData) && getedData.length) {
        setBasketIsEmpty(false);
      } else {
        setBasketIsEmpty(true);
      }
    }
  }, [basketItemsLength]);


  useEffect(() => {
    const orderItemDel = JSON.parse(localStorage.getItem('orderItemDel'));

    if (orderItemDel && orderItemDel.length > 0) {
      orderItemDel.map(item => {
        delete items[item.id];
      });

      localStorage.setItem('orderItemDel', JSON.stringify([]));
    }

    if (items && Object.keys(items).length > 0) {
      let price = 0;

      items = removeItemsAfterSuccessOrder(isLogined, items, basketItemIds);

      Object.entries(items).map((el) => {
        el[1] ? price += el[1] : delete items[el[0]];
      });

      setTotalPrice(price);
    }
  }, [[], items]);

  const ItemsQuintity = newData ? newData.length : 0;

  const clearBasket = () => {
    if (isLogined) {
      deleteAllGoodsFromBasket();
    } else {
      localStorage.removeItem('lmsh-basket');
    }
    setBasketIsEmpty(true);
    setNewData([]);
    // commented out because of https://gitlab.luxnet.io/lmsh/lmsh.shop/-/issues/43
    // changeBasketItemsQuantity(isLogined, changeBasketItemsLength);
    changeBasketItemsLength(0);
    items = {};
  };

  const whatTheme = blackTheme ? 'black-theme' : 'light-theme';

  const classes = classNames(
    'basket',
    whatTheme,
  );

  const handleBack = () => {
    if (lastLocation?.pathname === '/order' || lastLocation === null) {
      return history.push('/');
    }
    history.goBack();
  };

  return (
    <>
      <Helmet>
        <title>
          Кошик — Львівська майстерня шоколаду
        </title>
        <meta
          name='description'
          content='Перелік продукції замовлення у інтернет-крамниці Львівської майстерні шоколаду ❖ Замовляй шоколад, фігурки, цукерки ручної роботи'
          data-react-helmet='true'
        />
      </Helmet>
      <Container fluid className={classes}>
        <Row>
          <Col xs={12}>
            <MainTitle className='main-title--basket'>
              {basket.mainTitle}
              {basketIsEmpty ? null : <small>({ItemsQuintity})</small>}
            </ MainTitle>
          </Col>
          {
            isLoaded ?
              basketIsEmpty ?
                <Col className='empty-basket'>
                  <h2>{basket.emptyBasket}</h2>
                  <MainButton className='btns--main' onClick={handleBack}>
                    {basket.chooseGoods}
                  </MainButton>
                </Col>
                :
                <Col xs={12} lg={10} xl={8} className='content-field'>
                  <div className='clean-basket'>
                    <MainButton className='btns--main' palette='secondary' onClick={() => setIsModalOpen(true)}>
                      {basket.cleanTheBasket}
                    </MainButton>
                    {isModalOpen && <Modal className='align-items-center'>
                      <ConfirmDeleteItem setIsModalOpen={setIsModalOpen} deleteGoodFromBasket={clearBasket} title={basket.cleanTheBasket} deleteText={'Очистити'} saveText={'Відмінити'}/>
                    </Modal>}
                  </div>
                  <div className='items-wrapper'>
                    {newData && newData.map((el) => {
                      return (
                        <BasketItem
                          key={el.id}
                          item={el}
                          handleChangeGeneralPrice={(price, increment) => false}
                          setIsLoaded={(val) => setIsLoaded(val)}
                          items={items}
                          itemsCallback={(item) => {
                            if (item) {
                              items = { ...items, ...item };
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className='general-price'>
                    <div>
                      <span>{basket.generalPrice}</span>
                      <div>
                        {totalPrice}
                        <span className='currency'>{staticLocalization.general.currency}</span>
                      </div>
                    </div>
                  </div>
                  <div className='foot-btn-group'>
                    <MainButton className='btns--main' palette='secondary' onClick={() => history.goBack()}>
                      {basket.сontinueShopping}
                    </MainButton>
                    <MainButton className='btns--main' href='/order'>
                      {basket.makingAnOrder}
                    </MainButton>
                  </div>
                </Col>
              :
              <Col>
                <Loader/>
              </Col>
          }
        </Row>
      </Container>
    </>
  );
};

Basket.propTypes = {
  history: PropTypes.object
};

export default withRouter(Basket);
