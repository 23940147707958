import React, { useEffect, useContext, useRef } from 'react';
import './Spring.scss';
import classNames from 'classnames';
import { Context } from '../../../../context';
import { useWindowSize } from '../../../../utils/useWindowSizeHook';
import { clearHtml } from '../../../../utils/clearHtml';

import Parallax from 'parallax-js'; // Now published on NPM

// import {ReactComponent as Batterfly} from '../../../../assets/images/batterfly.svg';
import butterflyMask from '../../../../assets/images/butterfly-mask.jpeg';

const ButterfliesContainer = ({ additionalClass = null, containerAmination, butterflyAnimation }) => {

  const useTag = (whichWing = '', style = {}) => {
    const useMarkup = '<use className="left" xlink:href="#shape-butterfly-1"></use>';
    return <svg className={`wing ${whichWing}`} viewBox='0 0 50 100' dangerouslySetInnerHTML={{ __html: useMarkup }} style={{ ...style }}></svg>;
  };

  return(
    <div
      className={`butterfly_container ${additionalClass ? additionalClass : ''}`}
      style={{ ...containerAmination }}
    >
      <var className='rotate3d'>
        <var className='scale'>
          <var className='translate3d'>
            <var className='translate3d-1'>
              <figure className='butterfly'>
                {useTag('left', butterflyAnimation)}
                {useTag('right', butterflyAnimation)}
              </figure>
            </var>
          </var>
        </var>
      </var>
    </div>
  );
};

const Butterflies = () => {

  const BatterflyMask = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xml:space="preserve" class="svg-defs">
      
      <defs>

        <pattern id='image' width="1" height="1" viewBox="0 0 100 100" preserveAspectRatio="none">
          <image xlink:href=${butterflyMask} width="100" height="100" preserveAspectRatio="none"></image>
        </pattern>

        <g id="shape-butterfly-1">
          <path class="path" fill="" clip-rule="evenodd" d="M8.65,2.85c0.934-0.2,2.15-0.333,3.65-0.4c1.534-0.1,2.667-0.083,3.4,0.05
        c1.533,0.267,3.45,0.767,5.75,1.5c2.466,0.8,4.35,1.617,5.65,2.45c2.066,1.2,3.883,2.383,5.45,3.55c2.567,2.1,4.35,3.8,5.35,5.1
        l2.1,2.5c0.933,1.167,1.517,1.983,1.75,2.45c0.333,0.767,1.083,2.117,2.25,4.05c0.233,0.467,0.717,1.683,1.45,3.65
        c0.733,2.067,1.2,3.45,1.4,4.15c0.467,1.733,0.917,3.767,1.35,6.1l0.4,3.85l-0.25-3.4c-0.6-5.967-1.267-10.25-2-12.85
        c-0.733-2.434-2.167-5.467-4.3-9.1c-0.966-1.667-1.566-3-1.8-4c-0.233-0.933-0.1-1.267,0.4-1c1.3,0.733,2.917,3.867,4.85,9.4
        c1.667,4.7,2.85,11.2,3.55,19.5c0.567,6.934,0.667,11.917,0.3,14.95l0.2,0.05c0.231,0,0.348-0.05,0.35-0.15v0.05l0.1,0.05v27.4
        c-0.032-0.018-0.065-0.035-0.1-0.05v-0.05c-0.7,0.267-0.983,0.117-0.85-0.45c0.067-0.333,0.017-0.817-0.15-1.45
        c-0.2-0.6-0.316-0.983-0.35-1.15l-0.5-1.65c-0.533-2.967-0.833-5.034-0.9-6.2c-0.1-1.533-0.133-2.4-0.1-2.6
        c0-0.933,0.167-1.667,0.5-2.2c0.567-0.9,0.684-1.75,0.35-2.55c-0.167-0.367-0.367-0.6-0.6-0.7c-0.333-0.133-0.517,0.283-0.55,1.25
        c-0.033,1.533-0.167,2.9-0.4,4.1c-0.1,2.3-0.267,3.684-0.5,4.15c-0.333,0.667-1.25,2.95-2.75,6.85c-1.167,2.8-2.233,4.817-3.2,6.05
        c-0.9,1.2-1.583,2.1-2.05,2.7c-0.8,1-1.434,1.667-1.9,2c-2.067,1.333-3.633,2.067-4.7,2.2c-3.033,0.267-4.95,0.317-5.75,0.15
        c-0.8-0.167-1.383-0.217-1.75-0.15c-0.533,0.1-1.033,0.45-1.5,1.05c-0.5,0.667-1.217,1.284-2.15,1.85
        c-0.934,0.567-1.85,0.934-2.75,1.1c-2.467,0.433-4.45,0.25-5.95-0.55c-0.7-0.4-1.467-1.15-2.3-2.25c-0.6-0.867-1.033-1.567-1.3-2.1
        c-0.267-0.667-0.483-1.483-0.65-2.45c-0.3-1.467-0.383-2.717-0.25-3.75c0.267-1.9,0.45-3.05,0.55-3.45
        c0.233-1.233,0.566-2.333,1-3.3C9.25,77.45,9.767,76.4,10,76c0.667-1.233,1.55-2.583,2.65-4.05c1.1-1.434,2.184-2.583,3.25-3.45
        c0.367-0.3,1.15-0.867,2.35-1.7c0.767-0.566,1.917-1.25,3.45-2.05c1.733-0.933,3.267-1.633,4.6-2.1
        c2.133-0.733,4.534-1.467,7.2-2.2c0.467-0.1,1.517-0.3,3.15-0.6c0.967-0.233,0.4-0.4-1.7-0.5c-2.434-0.1-4.534-0.3-6.3-0.6
        c-1.566-0.267-3.383-0.7-5.45-1.3c-2.8-0.8-4.467-1.317-5-1.55c-1.567-0.667-3.2-1.75-4.9-3.25c-1.733-1.533-3-3.1-3.8-4.7
        c-0.533-1.067-0.967-2.434-1.3-4.1c-0.233-1.067-0.3-2.133-0.2-3.2c0.133-0.833,0.183-1.3,0.15-1.4v-0.6
        c-2.467-3.233-3.983-5.433-4.55-6.6c-0.533-1.033-0.883-1.833-1.05-2.4c-0.3-0.867-0.466-1.85-0.5-2.95
        c-0.033-2.367,0.034-4.117,0.2-5.25c0.3-1.034,0.483-1.8,0.55-2.3c0.167-0.867,0.034-1.533-0.4-2c-0.6-0.7-1.133-1.517-1.6-2.45
        c-0.566-1.133-0.833-2.117-0.8-2.95c0.033-1.333,0.167-2.367,0.4-3.1c0.367-1.267,1.05-2.267,2.05-3
        C4.417,4.25,6.483,3.317,8.65,2.85z" />
        </g>

      </defs>
    </svg>
  `;

  const ButterfliesArr = [
    {
      id: 1,
      additionalClass: null,
    },
    {
      id: 2,
      additionalClass: null,
      containerAmination: {
        marginTop: '-150px',
        marginLeft: '140px',
        WebkitAnimationDuration: '5s',
        msAnimationDuration: '5s',
        MozAnimationDuration: '5s',
        OAnimationDuration: '5s',
        animationDuration: '5s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '0.75s',
        msAnimationDuration: '0.75s',
        MozAnimationDuration: '0.75s',
        OAnimationDuration: '0.75s',
        animationDuration: '0.75s'
      }
    },
    {
      id: 3,
      additionalClass: 'scale_half',
      containerAmination: {
        marginTop: '-10px',
        marginLeft: '50px',
        WebkitAnimationDuration: '5s',
        msAnimationDuration: '5s',
        MozAnimationDuration: '5s',
        OAnimationDuration: '5s',
        animationDuration: '5s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '0.75s',
        msAnimationDuration: '0.75s',
        MozAnimationDuration: '0.75s',
        OAnimationDuration: '0.75s',
        animationDuration: '0.75s'
      }
    },
    {
      id: 4,
      additionalClass: 'scale_half',
      containerAmination: {
        marginTop: '20px',
        WebkitAnimationDuration: '20s',
        msAnimationDuration: '20s',
        MozAnimationDuration: '20s',
        OAnimationDuration: '20s',
        animationDuration: '20s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '1s',
        msAnimationDuration: '1s',
        MozAnimationDuration: '1s',
        OAnimationDuration: '1s',
        animationDuration: '1s'
      }
    },
    {
      id: 5,
      additionalClass: 'scale_half',
      containerAmination: {
        marginTop: '100px',
        marginLeft: '50px',
        WebkitAnimationDuration: '20s',
        msAnimationDuration: '20s',
        MozAnimationDuration: '20s',
        OAnimationDuration: '20s',
        animationDuration: '20s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '1.2s',
        msAnimationDuration: '1.2s',
        MozAnimationDuration: '1.2s',
        OAnimationDuration: '1.2s',
        animationDuration: '1.2s'
      }
    },
    {
      id: 6,
      additionalClass: 'scale_half',
      containerAmination: {
        marginTop: '150px',
        WebkitAnimationDuration: '20s',
        msAnimationDuration: '20s',
        MozAnimationDuration: '20s',
        OAnimationDuration: '20s',
        animationDuration: '20s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '0.35s',
        msAnimationDuration: '0.35s',
        MozAnimationDuration: '0.35s',
        OAnimationDuration: '0.35s',
        animationDuration: '0.35s'
      }
    },
    {
      id: 7,
      additionalClass: 'scale_half',
      containerAmination: {
        marginTop: '-120px',
        marginLeft: '300px',
        WebkitAnimationDuration: '4s',
        msAnimationDuration: '4s',
        MozAnimationDuration: '4s',
        OAnimationDuration: '4s',
        animationDuration: '4s'
      },
      butterflyAnimation: {
        WebkitAnimationDuration: '0.45s',
        msAnimationDuration: '0.45s',
        MozAnimationDuration: '0.45s',
        OAnimationDuration: '0.45s',
        animationDuration: '0.45s'
      }
    }
  ];

  return (
    <>
      <div className='mask-wrapper' dangerouslySetInnerHTML={{ __html: BatterflyMask }}></div>

      <section className='scene3d'>

        {ButterfliesArr.map(el => <ButterfliesContainer key={el.id} additionalClass={el.additionalClass} containerAmination={el.containerAmination} butterflyAnimation={el.butterflyAnimation} />)}

      </section>
    </>
  );
};

const Spring = () => {


  const { blackTheme, staticLocalization }  = useContext(Context);
  const { general } = staticLocalization;
  
  const scene = useRef();
  const firstStarsCanv = useRef();

  const windowWidth = useWindowSize().width;

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const getRandomIntFromMinToMax = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Включно з мінімальним та виключаючи максимальне значення
  };

  useEffect(() => {

    if(windowWidth > 575){

      var parallaxInstance = new Parallax(scene.current, {
        relativeInput: true,
        hoverOnly: true
      });
    }
    
  }, []);

  useEffect(() => {

    if(windowWidth > 575){
      const firstStarsCanvas = blackTheme ? firstStarsCanv.current : null;

      const windowWidth = window.innerWidth;
      const windowInnerHeight = window.innerHeight;

      if(blackTheme){
        if (firstStarsCanvas.getContext){
          const ctx = firstStarsCanvas.getContext('2d');
      
          const starsCanvasWidth = windowWidth - 120;
          const starsCanvasHeight = windowInnerHeight * 0.7;
          const starsQuintity = 150;
              
          firstStarsCanvas.width = starsCanvasWidth;
          firstStarsCanvas.height = starsCanvasHeight;
              
          for(let i = 0; i <= starsQuintity; i++){
            ctx.beginPath();
            var x = getRandomInt(starsCanvasWidth); // x coordinate
            var y = getRandomInt(starsCanvasHeight); // y coordinate
            var radius = 2; // Arc radius
            var anticlockwise = false; // clockwise or anticlockwise
            const fillOpacity = getRandomIntFromMinToMax(6, 11) / 10;
          
            // ctx.fillStyle = `rgba(247, 201, 101, ${Math.random()})`;
            ctx.fillStyle = `rgba(247, 201, 101, ${fillOpacity})`;
            ctx.arc(x, y, radius, 0, Math.PI * 2, anticlockwise);
          
            ctx.fill();
          }
          
        }
      }
    }

  }, [blackTheme]);


  const springClassNames = classNames(
    'scene',
    'spring',
    blackTheme ? 'black-theme' : 'light-theme'
  );

  const springMobileClassNames = classNames(
    'mobile-plug',
    'mobile-plug--spring',
    blackTheme ? 'black-theme' : 'light-theme'
  );
    

  return (
    <>
      {
        windowWidth > 575 ?
          <div ref={scene} className={springClassNames}>
            <div data-depth='0.21' className='layer layer-5'></div>
            {
              blackTheme ?
                <div data-depth='0.21' className='layer stars'>
                  <canvas ref={firstStarsCanv}></canvas>
                  {/* <canvas ref={starsCanv}></canvas>
                      <canvas ref={starsCanv}></canvas> */}
                </div>
                :
                null
            }
            <div data-depth='0.19' className='layer layer-4'></div>
            <div data-depth='0.16' className='layer layer-3'></div>
            <div data-depth='0.13' className='layer layer-6'></div>
            <div data-depth='0.1' className='layer layer-2'></div>
            <div data-depth='0.13' className='layer layer-1'></div>
            {
              !blackTheme && <>
                <div data-depth='0.1' className='layer butterflies--left' style={{ width: '100%', height: '100%' }}>
                  <Butterflies />
                </div>
                <div data-depth='0.1' className='layer butterflies--right' style={{ width: '100%', height: '100%' }}>
                  <Butterflies />
                </div>
              </>
            }

            <div data-depth='0' className='layer text'>
              <h1>{general.name}</h1>
            </div>
          </div>
          :
          <div className={springMobileClassNames}>
            <h1>{general.name}</h1>
          </div>
      }
    </>
  );
};

export default Spring;