import React, { useState, useEffect, useContext } from 'react';
import './Footer.scss';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Context } from '../../context';
import { getStaticPages, getGeneralSetings } from '../../utils/api';
import { modifidedItemWithTranslationField, getTranslation } from '../../utils/renderFieldWithTransition';

import Loader from '../Loader/Loader';

import Logo from '../../assets/images/logo_lmsh-2.svg';
import { ReactComponent as MastercardLogo } from '../../assets/images/mastercard-logo.svg';
import { ReactComponent as VisaLogo } from '../../assets/images/visa-logo.svg';
import { ReactComponent as Phone } from '../../assets/images/phone2.svg';
import { ReactComponent as YoutubeIcon } from './../../assets/icons/youtube2.svg';
import { ReactComponent as FacebookIcon } from './../../assets/icons/facebook2.svg';
import { ReactComponent as InstagramIcon } from './../../assets/icons/instagram2.svg';

const Footer = () => {

  const [statickPages, setStatickPages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mainPhone, setMainPhone] = useState('');
  function convertPhone(str) {
    return str.replace(/[^0-9\\.]+/g, '');
  }

  const { blackTheme, curentLanguageId, staticLocalization } = useContext(Context);
  const { footer, general } = staticLocalization;


  useEffect(() => {
    const newGetStatickPages = new Promise((resolve, reject) => {
      getStaticPages().then(res => {
        resolve (res.json());
      });
    });
    const newGetGeneralSetings = new Promise((resolve, reject) => {
      getGeneralSetings().then(res => {
        resolve (res.json());
      });
    });

    Promise.all([newGetStatickPages, newGetGeneralSetings]).then(el => {
      const [getStaticPages, getGeneralSetings] = el;

      setStatickPages(getStaticPages);
      const { mainPhone, callCenterPhone } = getGeneralSetings;

      setMainPhone(mainPhone);
      setIsLoaded(true);

    });

  }, []);

  const footerClasses = classNames(
    'footer',
    blackTheme ? 'black-theme' : 'ligth-theme'
  );

  const currantYear = new Date().getFullYear();

  return (
    <Container fluid className={footerClasses}>
      <Row>
        <Col xl={11} lg={11} xs={12} className='wrapper'>
          <div className='footer__item footer__item--order0'>
            <a href='/' className='footer__logo'>
              <img src={Logo} alt='Lviv Handmade Chocolate Logo'></img>
            </a>
          </div>
          <div className='footer__item footer__item--with-payment footer__item--order2'>
            <div className='footer__item-wrapper'>
              <ul className='footer__list'>
                {
                  isLoaded ?
                    statickPages.slice(0, 3).map(el => {
                      if (el.slug.slice(0, 4) === 'http') {
                        return (
                          <li key={el.id}>
                            <a href={el.slug}>{getTranslation(el, modifidedItemWithTranslationField(el), 'name', curentLanguageId)}</a>
                          </li>
                        );
                      }
                      if(el.isShownInFooterMenu === true) {
                        return (
                          <li key={el.id}>
                            <Link
                              to={`/${el.slug}`}>{getTranslation(el, modifidedItemWithTranslationField(el), 'name', curentLanguageId)}</Link>
                          </li>
                        );
                      }

                    })
                    :
                    <Loader />
                }
              </ul>
              <div className='footer__payment-icons'>
                <MastercardLogo className='mr-3'/>
                <VisaLogo />
              </div>
            </div>
          </div>
          <div className='footer__item footer__item--candy footer__item--order1' >
            <div className='footer__item-wrapper'>
              <ul className='footer__list'>
                {
                  isLoaded ?
                    statickPages.slice(3).map(el => {
                      if (el.slug.slice(0, 4) === 'http') {
                        return (
                          <li key={el.id}>
                            <a href={el.slug}>{getTranslation(el, modifidedItemWithTranslationField(el), 'name', curentLanguageId)}</a>
                          </li>
                        );
                      }
                      if(el.isShownInFooterMenu === true) {
                        return (
                          <li key={el.id}>
                            <Link to={`/${el.slug}`}>{getTranslation(el, modifidedItemWithTranslationField(el), 'name', curentLanguageId)}</Link>
                          </li>
                        );
                      }
                    })
                    :
                    <Loader />
                }
              </ul>
            </div>
          </div>
          <div className='footer__item footer__item--copyright footer__item--order3'>
            <div className='footer__item-wrapper'>
              <p className='footer__text-bold'>{ footer.contacts }</p>
              <a className='footer__link-bold' href={`tel:${convertPhone(mainPhone)}`}>
                <Phone className='mr-2'/>{mainPhone}
              </a>
              <ul className='social-links'>
                <li>
                  <a href='https://www.instagram.com/lviv.chocolate/' target='_blank' rel='noopener noreferrer'>
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a href='https://www.youtube.com/channel/UCnKOz33UE-FDovqLzWfCW_g' target='_blank' rel='noopener noreferrer'>
                    <YoutubeIcon />
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/LvivHandmadeChocolate' target='_blank' rel='noopener noreferrer'>
                    <FacebookIcon />
                  </a>
                </li>
              </ul>
              <p className='footer__description-copyright'>{general?.copyright || `© Львівська майстерня шоколаду, 2009-${currantYear}`}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
